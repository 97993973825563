import { CSSProperties } from "react";
import "./card-custom.css";

export const CardCustom: React.FunctionComponent<{
  children: React.ReactNode;
  className?: string;
  style?: CSSProperties;
  id?: string;
}> = ({
  children,
  className,
  style,
  id,
}: {
  children: React.ReactNode;
  className?: string;
  style?: CSSProperties;
  id?: string;
}) => {
  return (
    <template
      id={id}
      className={`card-custom ${className ?? ""}`}
      style={style}
    >
      {children}
    </template>
  );
};
