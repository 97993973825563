import { VCardResponse } from "../../generated-sources/qr-code/api";
import { VCardQrModel } from "../models/VCardQrModel";
import {
  mapContentStyleModel,
  mapQRStyleModel,
  mapSocialNetworkModel,
} from "./qr-adapter";

export function mapVCardModel(dto?: VCardResponse): VCardQrModel {
  return {
    avatarUrl: dto?.avatarUrl ?? "",
    contentStyle: mapContentStyleModel(dto?.contentStyle),
    emails: dto?.emails ?? [],
    firstName: dto?.firstName ?? "",
    id: dto?.id ?? "",
    location: dto?.location ?? "",
    lastName: dto?.lastName ?? "",
    phones: dto?.phones ?? [],
    qrName: dto?.qrName ?? "",
    socialNetworks:
      dto?.socialNetworks?.map((d) => mapSocialNetworkModel(d)) ?? [],
    url: dto?.url ?? "",
    websites: dto?.websites ?? [],
    qrContent: dto?.qrContent ?? "",
    company: dto?.company ?? "",
    title: dto?.title ?? "",
    qrStyle: mapQRStyleModel(dto?.qrStyle),
    backgroundUrl: dto?.backgroundUrl ?? "",
    brandLogoUrl: dto?.brandLogoUrl ?? "",
    images: dto?.images ?? [],
    summary: dto?.summary ?? "",
    template: dto?.template ?? "",
  };
}
