import { QRStyleModel, QrCodeType } from "@/apis/qrfy/models/QrModel";
import { FormCreateQrVCard } from "@/pages/create-qr-vcard/interfaces/FormCreateVCard";
import { FormCreateVietQr } from "@/pages/create-qr-vietqr/interfaces/FormCreateVietQr";
import { FormCreateQrWeb } from "@/pages/create-qr-web/interfaces/FormCreateQrWeb";
import { FormCreateQrWireless } from "@/pages/create-qr-wireless/interfaces/FormCreateQrWireless";
import { AppBloc } from "@/services/base-bloc";
import Environment from "@/services/environment";
import { dataURLtoFile } from "@/utils/fileUtils";
import { isValidHttpUrl } from "@/utils/validate";
import { message } from "antd";

export async function createQr({
  data,
  qrName,
  qrStyle,
  qrType,
}: {
  data: any;
  qrName: string;
  qrStyle: QRStyleModel;
  qrType: QrCodeType;
}) {
  let retCheck = false;
  const _qrStyle = { ...qrStyle };
  const bloc = new AppBloc();

  if (_qrStyle.image.length > 0 && !isValidHttpUrl(_qrStyle.image)) {
    const file = dataURLtoFile(_qrStyle.image, qrName);
    try {
      const _url = await bloc.repository.qrfyRepo.media.uploadImage({
        file: file,
      });
      _qrStyle.image = `${Environment.hostApi}${_url}`;
    } catch (error: any) {
      message.error(error.message);
      throw error;
    }
  }

  // qr vietQR
  if (qrType === QrCodeType.STATIC_VIETQR) {
    const req = data as FormCreateVietQr;
    try {
      bloc.session.isLoading.next(true);
      await bloc.repository.qrfyRepo.staticQr.createVietQr({
        accountNo: req.accountNo,
        bankBin: req.bankBin,
        qrName: qrName,
        amount: req.amount,
        content: req.content,
        qrStyle: _qrStyle,
        accountName: req.accountName,
        templateId: req.template?.id ?? "",
      });
      retCheck = true;
    } catch (error: any) {
      console.error(error);
      message.error(`${error.message}`);
    } finally {
      bloc.session.isLoading.next(false);
    }
    return retCheck;
  }
  // qr wireless
  if (qrType === QrCodeType.STATIC_WIRELESS) {
    const req = data as FormCreateQrWireless;
    try {
      bloc.session.isLoading.next(true);
      await bloc.repository.qrfyRepo.staticQr.createWifi({
        encryptType: req.encryptType,
        networkName: req.networkName,
        networkPassword: req.networkPassword,
        qrName: qrName,
        qrStyle: _qrStyle,
      });
      retCheck = true;
    } catch (error: any) {
      console.error(error);
      message.error(`${error.message}`);
    } finally {
      bloc.session.isLoading.next(false);
    }
    return retCheck;
  }

  // qr static web
  if (qrType === QrCodeType.STATIC_WEBSITE) {
    const req = data as FormCreateQrWeb;
    try {
      bloc.session.isLoading.next(true);
      await bloc.repository.qrfyRepo.staticQr.createStaticWebsite({
        qrName: qrName,
        qrStyle: _qrStyle,
        url: req.url,
      });
      retCheck = true;
    } catch (error: any) {
      console.error(error);
      message.error(`${error.message}`);
    } finally {
      bloc.session.isLoading.next(false);
    }
    return retCheck;
  }

  // qr wireless
  if (qrType === QrCodeType.WEBSITE) {
    const req = data as FormCreateQrWeb;
    try {
      bloc.session.isLoading.next(true);
      await bloc.repository.qrfyRepo.dynamicQr.createWebsite({
        qrName: qrName,
        qrStyle: _qrStyle,
        url: req.url,
        customPath: req.customPath,
      });
      retCheck = true;
    } catch (error: any) {
      console.error(error);
      message.error(`${error.message}`);
    } finally {
      bloc.session.isLoading.next(false);
    }
    return retCheck;
  }

  // qr vcard
  if (qrType === QrCodeType.VCARD) {
    const req = { ...(data as FormCreateQrVCard | null) };
    if (
      req?.avatarUrl &&
      req.avatarUrl.length > 0 &&
      !isValidHttpUrl(req?.avatarUrl)
    ) {
      const file = dataURLtoFile(req.avatarUrl, qrName);
      try {
        const ret = await bloc.repository.qrfyRepo.media.uploadImage({
          file: file,
        });
        req.avatarUrl = `${Environment.hostApi}${ret}`;
      } catch (error: any) {
        message.error(error.message);
        throw error;
      }
    }

    if (
      req?.backgroundUrl &&
      req.backgroundUrl.length > 0 &&
      !isValidHttpUrl(req?.backgroundUrl)
    ) {
      const file = dataURLtoFile(req.backgroundUrl, qrName);
      try {
        const ret = await bloc.repository.qrfyRepo.media.uploadImage({
          file: file,
        });
        req.backgroundUrl = `${Environment.hostApi}${ret}`;
      } catch (error: any) {
        message.error(error.message);
        throw error;
      }
    }

    if (
      req?.brandLogoUrl &&
      req.brandLogoUrl.length > 0 &&
      !isValidHttpUrl(req?.brandLogoUrl)
    ) {
      const file = dataURLtoFile(req.brandLogoUrl, qrName);
      try {
        const ret = await bloc.repository.qrfyRepo.media.uploadImage({
          file: file,
        });
        req.brandLogoUrl = `${Environment.hostApi}${ret}`;
      } catch (error: any) {
        message.error(error.message);
        throw error;
      }
    }
    try {
      bloc.session.isLoading.next(true);
      await bloc.repository.qrfyRepo.dynamicQr.createVCard({
        qrName: qrName,
        qrStyle: _qrStyle,
        avatarUrl: req.avatarUrl,
        company: req.company,
        contentStyle: req.contentStyle,
        emails: req.emails,
        firstName: req?.firstName ?? "",
        lastName: req?.lastName ?? "",
        location: req.location,
        phones: req.phones,
        summary: req.sumary,
        template: req.template,
        title: req.title,
        websites: req.websites,
        customPath: req.customPath,
        socialNetworks: req.socialNetworks,
        backgroundUrl: req.backgroundUrl,
        brandLogoUrl: req.brandLogoUrl,
      });
      retCheck = true;
    } catch (error: any) {
      console.error(error);
      message.error(`${error.message}`);
    } finally {
      bloc.session.isLoading.next(false);
    }
    return retCheck;
  }
}
