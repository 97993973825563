import { ViewportContext } from "@/contexts/ViewPortContext";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
const PageNotFound = ({ content }: { content?: string }) => {
  const { t } = useTranslation();
  const buildPageNotFound = useMemo(
    () => (
      <ViewportContext.Consumer>
        {({ width }) => {
          return (
            <div
              className="column"
              style={{
                width: "100%",
                height: "100vh",
                backgroundColor: "transparent",
                minHeight: width <= 576 ? undefined : "1024px",
                minWidth: width <= 576 ? "320px" : "604px",
                alignItems: "center",
                justifyContent: "flex-start",
                padding: width < 576 ? "0px 0px" : "24px 24px",
              }}
            >
              <div
                className="column"
                style={{
                  maxWidth: width <= 576 ? `${576}px` : "600px",
                  backgroundColor: "transparent",
                  borderRadius: "8px",
                  width: `100%`,
                  height: "100%",
                  padding: width <= 576 ? "0px 0px" : "56px 24px",
                  gap: "16px",
                  justifyContent: "center",
                  maxHeight: width <= 576 ? "100%" : "572px",
                }}
              >
                {/* <img
                  alt=""
                  src={`${process.env.PUBLIC_URL}/images/img-payment-failed.png`}
                /> */}
                <span
                  className="fontSize--14 lineHeight--20 fontWeight--400"
                  style={{
                    color: "#6B7280",
                    width: "100%",
                    textAlign: "center",
                  }}
                >
                  {content ?? `Page not founded`}
                </span>
              </div>
            </div>
          );
        }}
      </ViewportContext.Consumer>
    ),
    [content, t],
  );
  return <>{buildPageNotFound};</>;
};

export default PageNotFound;
