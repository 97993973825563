export const IconAddQrX28 = ({ color = "#12B76A" }: { color?: string }) => {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.375 14C4.375 8.68426 8.68426 4.375 14 4.375C19.3157 4.375 23.625 8.68426 23.625 14C23.625 19.3157 19.3157 23.625 14 23.625C8.68426 23.625 4.375 19.3157 4.375 14ZM14 8.925C14.4832 8.925 14.875 9.31675 14.875 9.8V13.125H18.2C18.6832 13.125 19.075 13.5167 19.075 14C19.075 14.4832 18.6832 14.875 18.2 14.875H14.875V18.2C14.875 18.6832 14.4832 19.075 14 19.075C13.5167 19.075 13.125 18.6832 13.125 18.2V14.875H9.8C9.31675 14.875 8.925 14.4832 8.925 14C8.925 13.5167 9.31675 13.125 9.8 13.125H13.125V9.8C13.125 9.31675 13.5167 8.925 14 8.925Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.375 14C4.375 8.68426 8.68426 4.375 14 4.375C19.3157 4.375 23.625 8.68426 23.625 14C23.625 19.3157 19.3157 23.625 14 23.625C8.68426 23.625 4.375 19.3157 4.375 14ZM14 2.625C7.71776 2.625 2.625 7.71776 2.625 14C2.625 20.2822 7.71776 25.375 14 25.375C20.2822 25.375 25.375 20.2822 25.375 14C25.375 7.71776 20.2822 2.625 14 2.625Z"
        fill={color}
      />
    </svg>
  );
};
