import {
  ColorStyle,
  ContentStyle,
  CornerStyle,
  DotStyle,
  FrameStyle,
  PublicQrCodeResponse,
  QRStyle,
  QrCodeBase,
  QrCodeResponse,
  ShapeStyle,
  SocialNetwork,
} from "../../generated-sources/qr-code/api";
import {
  ColorStyleModel,
  ContentStyleModel,
  CornerStyleModel,
  FrameStyleModel,
  PublicQrCodeModel,
  QrCodeType,
  QRStyleModel,
  QrCodeModel,
  ShapeStyleModel,
  SocialNetworkModel,
  DotStyleModel,
  SocialNetworkType,
} from "../models/QrModel";

export function mapQrCodeBaseModel<T>(dto?: QrCodeBase): T {
  return dto as T;
}

export function mapColorStyleModel(dto?: ColorStyle): ColorStyleModel {
  return {
    fromColor: dto?.fromColor ?? "#000000",
    rotation: dto?.rotation ?? 0,
    toColor: dto?.toColor ?? "#FFFFFF",
    type: dto?.type ?? "",
  };
}

export function mapContentStyleModel(dto?: ContentStyle): ContentStyleModel {
  return {
    contentFont: dto?.contentFont ?? "Roboto",
    primaryColor: dto?.primaryColor ?? "#12B76A",
    secondaryColor: dto?.secondaryColor ?? "#027a48",
    titleFont: dto?.titleFont ?? "Roboto",
  };
}

export function mapCornerStyleModel(dto?: CornerStyle): CornerStyleModel {
  return {
    dotColor: dto?.dotColor ?? "#000000",
    dotStyle: dto?.dotStyle ?? "square",
    squareColor: dto?.squareColor ?? "#000000",
    squareStyle: dto?.squareStyle ?? "square",
  };
}

export function mapFrameStyleModel(dto?: FrameStyle): FrameStyleModel {
  return {
    fontSize: dto?.fontSize ?? 16,
    id: dto?.id ?? 0,
    text: dto?.text ?? "Scan me",
    backgroundColor: mapColorStyleModel(dto?.backgroundColor),
    color: mapColorStyleModel(dto?.color),
  };
}

export function mapShapeStyleModel(dto?: ShapeStyle): ShapeStyleModel {
  return {
    color: mapColorStyleModel(dto?.color),
    backgroundColor: dto?.backgroundColor ?? "#FFFFFF",
    style: dto?.style ?? "square",
  };
}

export function mapDotStyleModel(dto?: DotStyle): DotStyleModel {
  return {
    color: mapColorStyleModel(dto?.color),
    backgroundColor: dto?.backgroundColor ?? "#000000",
    style: dto?.style ?? "square",
  };
}

export function mapQRStyleModel(dto?: QRStyle): QRStyleModel {
  return {
    corner: mapCornerStyleModel(dto?.corner),
    frame: mapFrameStyleModel(dto?.frame),
    image: dto?.image ?? "",
    shape: mapShapeStyleModel(dto?.shape),
    dot: mapDotStyleModel(dto?.dot),
  };
}

export function mapPublicQrCodeModel(
  dto?: PublicQrCodeResponse,
): PublicQrCodeModel {
  return {
    code: dto?.code ?? "",
    data: mapQrCodeBaseModel(dto?.data),
    qrName: dto?.qrName ?? "",
    sessionId: dto?.sessionId ?? "",
    type: QrCodeType[dto?.type as keyof typeof QrCodeType],
    qrStyle: dto?.qrStyle ? mapQRStyleModel(dto.qrStyle) : undefined,
  };
}

export function mapQrCodeModel<T>(dto?: QrCodeResponse): QrCodeModel<T> {
  return {
    code: dto?.code ?? "",
    data: mapQrCodeBaseModel<T>(dto?.data),
    qrName: dto?.qrName ?? "",
    type: QrCodeType[dto?.type as keyof typeof QrCodeType],
    qrStyle: dto?.qrStyle ? mapQRStyleModel(dto.qrStyle) : undefined,
    counter: dto?.counter ?? 0,
    createdDate: dto?.createdDate ?? "",
    domain: dto?.domain ?? "",
    id: dto?.id ?? "",
    modifiedDate: dto?.modifiedDate ?? "",
  };
}

export function mapSocialNetworkModel(dto?: SocialNetwork): SocialNetworkModel {
  return {
    type: SocialNetworkType[dto?.type as keyof typeof SocialNetworkType],
    text: dto?.text ?? "",
    url: dto?.url ?? "",
  };
}
