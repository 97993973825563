import Environment from "./environment";

const icPath = `${Environment.host}/icons`;
const imgPath = `${Environment.host}/images`;

//------------------ import icons
export const icLogoSideBar = `${icPath}/ic-logo-side-bar.svg`;
export const icLogoX32 = `${icPath}/ic-logo-x32.svg`;

export const icAvatar = `${icPath}/ic-avatar.svg`;
export const icArrowDownX20 = `${icPath}/ic-arrow-down-x20.svg`;
export const icFlagEnX20 = `${icPath}/ic-flag-en-x20.svg`;
export const icFlagVnX20 = `${icPath}/ic-flag-vn-x20.svg`;
export const icQuestionX20 = `${icPath}/ic-question-x20.svg`;
export const icEyeOpenedX14 = `${icPath}/ic-eye-opened-x14.svg`;
export const icEyeClosedX14 = `${icPath}/ic-eye-closed-x14.svg`;
export const icSearchX14 = `${icPath}/ic-search-x14.svg`;
export const icArrowDownX14 = `${icPath}/ic-arrow-down-x14.svg`;
export const icWebsite = `${icPath}/ic-website.svg`;
export const icVCard = `${icPath}/ic-v-card.svg`;
export const icBankLogoExample = `${icPath}/ic-bank-logo-example.png`;
export const icVietQr = `${icPath}/ic-vietqr.svg`;
export const icBigWifi = `${icPath}/ic-big-wifi.svg`;

export const icQrTypeWebsite = `${icPath}/ic-qr-type-web.svg`;
export const icQrTypeWifi = `${icPath}/ic-qr-type-wifi.svg`;
export const icQrTypeVietQr = `${icPath}/ic-qr-type-vietqr.svg`;
export const icQrTypeVCard = `${icPath}/ic-qr-type-vcard.svg`;

export const icKienlongbankX40 = `${icPath}/ic-kienlongbank-x40.svg`;

export const icLogoOnepoint = `${icPath}/ic-logo-onepoint.svg`;

//-------------------import images
export const imgBannerDashBoard = `${imgPath}/img-banner-dashboard.webp`;
export const imgBackgroundAuth = `${imgPath}/img-background-auth.webp`;
export const imgIllusQrTypeWebsite = `${imgPath}/img-illus-qr-type-website.webp`;
export const imgIllusQrTypeImage = `${imgPath}/img-illus-qr-type-image.webp`;
export const imgIllusQrTypeVideo = `${imgPath}/img-illus-qr-type-video.webp`;
export const imgIllusQrTypeEmail = `${imgPath}/img-illus-qr-type-email.webp`;
export const imgIllusQrTypePdf = `${imgPath}/img-illus-qr-type-pdf.webp`;
export const imgIllusQrTypeWifi = `${imgPath}/img-illus-qr-type-wifi.webp`;

export const imgQrViewtypeList = `${imgPath}/img-qr-view-type-list.png`;
export const imgQrViewtypeGrid1 = `${imgPath}/img-qr-view-type-grid-1.png`;
export const imgQrViewtypeGrid2 = `${imgPath}/img-qr-view-type-grid-2.png`;
export const imgEmptyImage = `${imgPath}/img-empty-image.png`;

export const imgQrPageTemplate1 = `${imgPath}/img-qr-page-template-1.png`;
export const imgQrPageTemplate2 = `${imgPath}/img-qr-page-template-2.png`;
export const imgQrPageTemplate3 = `${imgPath}/img-qr-page-template-3.png`;
export const imgQrPageTemplate4 = `${imgPath}/img-qr-page-template-4.png`;
export const imgQrPageTemplate5 = `${imgPath}/img-qr-page-template-5.png`;
export const imgUploadDrag = `${imgPath}/img-upload-drag.svg`;
export const imgIphoneMask = `${imgPath}/img-phone-mask.svg`;
export const imgDataNotFount = `${imgPath}/img-not-found.svg`;
export const imgVietQrPhoneExample = `${imgPath}/img-vietqr-phone-example.png`;
export const imgPhoneIndicationBg = `${imgPath}/img-phone-indicator-bg.png`;
export const imgPhoneIndicationBar = `${imgPath}/img-phone-indicator-bar.svg`;
export const imgPhoneIndicationIsland = `${imgPath}/img-phone-indicator-island.svg`;
export const imgWebStaticPhoneExample = `${imgPath}/img-static-web-phone-example.png`;

export const imgHeaderCheckoutPage = `${imgPath}/img-header-checkout-page.svg`;

export const imgDelete = `${imgPath}/img-delete.svg`;
export const imgBgExampleVcard = `${imgPath}/img-background-example-vcard.webp`;
