import { ReactNode, useState } from "react";
import "./nav-link.css";
import { Tooltip } from "antd";

export interface NavlinkCustomTabProps {
  icon?: ReactNode;
  title?: string;
  children?: ReactNode;
  data?: NavlinkCustomTabProps[];
  path?: string;
  isActive?: boolean;
  onTab?: (v?: string) => void;
}

const ArrowDown = ({ color = "#5C5C5C" }: { color?: string }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.20921 9.21967C6.93026 9.51256 6.93026 9.98744 7.20921 10.2803L11.4949 14.7803C11.7739 15.0732 12.2261 15.0732 12.5051 14.7803L16.7908 10.2803C17.0697 9.98744 17.0697 9.51256 16.7908 9.21967C16.5118 8.92678 16.0596 8.92678 15.7806 9.21967L12 13.1893L8.21936 9.21967C7.94042 8.92678 7.48816 8.92678 7.20921 9.21967Z"
        fill={color}
      />
    </svg>
  );
};

export const NavlinkCustom: React.FC<NavlinkCustomTabProps> = ({
  icon,
  title,
  children,
  isActive,
  onTab,
  path,
  data,
}) => {
  const [_isExpended, _setExpanded] = useState(isActive);
  return (
    <Tooltip placement="right" color="#474747" title={title}>
      <details
        // style={{ pointerEvents: (children?.length??0)===0 ? "none" : "all" }}
        className="nav-link"
        open={(data?.length ?? 0) === 0 ? false : _isExpended}
        onToggle={(e) => {
          _setExpanded(e.currentTarget.open);
        }}
        style={{
          backgroundColor: isActive ? "#F7F7F7" : undefined,
          borderLeft: isActive ? "4px solid #12B76A" : undefined,
        }}
        onClick={() => {
          onTab && onTab(path);
        }}
      >
        <summary
          className="nav-link__title"
          // onClick={() => {

          // }}
        >
          {icon}
          <span
            style={{
              width: "calc(100% - 48px)",
              fontSize: "14px",
              fontWeight: isActive ? "500" : "400",
              lineHeight: "20px",
              color: isActive ? "#333333" : "#858585",
              textAlign: "start",
              boxSizing: "border-box",
            }}
          >
            {title}
          </span>

          {(data?.length ?? 0) > 0 && (
            <div
              style={{
                width: "24px",
                height: "24px",
                transform: `rotate(${_isExpended ? 180 : 0}deg)`,
              }}
            >
              <ArrowDown color={isActive ? "#12B76A" : "#5C5C5C"} />
            </div>
          )}
        </summary>
        <div className="nav-link__content">{children}</div>
      </details>
    </Tooltip>
  );
};
