import {
  createContext,
  FC,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { AppBloc } from "@/services/base-bloc";

export const AppContext = createContext<AppBloc>(new AppBloc());

export const AppContextProvider: FC<{
  children: React.ReactNode;
  appProps: AppBloc;
}> = ({
  children,
  appProps,
}: {
  children: React.ReactNode;
  appProps: AppBloc;
}) => {
  const [_appProps, _setAppProps] = useState<AppBloc>(appProps);

  const handleAppProps = useCallback(() => _setAppProps(appProps), [_appProps]);

  useEffect(() => {
    handleAppProps();
    return () => {
      _appProps?.session.closeAll();
    };
  }, []);

  return (
    <AppContext.Provider value={_appProps}>{children}</AppContext.Provider>
  );
};

export const useAppBloc = () => {
  const appBloc = useContext<AppBloc>(AppContext);
  return { appBloc };
};
