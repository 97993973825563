export const IconMyQrX28 = ({ color = "#12B76A" }: { color?: string }) => {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M6.75352 6.8H9.11972V9.2H6.75352V6.8Z" fill={color} />
      <path d="M18.5883 6.8H20.9545V9.2H18.5883V6.8Z" fill={color} />
      <path
        d="M6.75352 18.8012H9.11972V21.2012H6.75352V18.8012Z"
        fill={color}
      />
      <path d="M22.1338 14H24.5V16.4H22.1338V14Z" fill={color} />
      <path d="M13.8521 21.2H16.2183V23.6H13.8521V21.2Z" fill={color} />
      <path d="M18.5845 18.8H20.9507V21.2H18.5845V18.8Z" fill={color} />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.5 5.21459C3.5 4.26776 4.25668 3.5 5.19034 3.5H11.0386C11.9723 3.5 12.729 4.26776 12.729 5.21459V11.1472C12.729 12.094 11.9723 12.8618 11.0386 12.8618H5.19034C4.25668 12.8618 3.5 12.094 3.5 11.1472V5.21459ZM5.27465 5.3V11.0618H10.9543V5.3H5.27465ZM14.9753 5.21459C14.9753 4.26776 15.7319 3.5 16.6656 3.5H22.5139C23.4475 3.5 24.2042 4.26776 24.2042 5.21459V11.1472C24.2042 12.094 23.4475 12.8618 22.5139 12.8618H16.6656C15.7319 12.8618 14.9753 12.094 14.9753 11.1472V5.21459ZM16.7499 5.3V11.0618H22.4296V5.3H16.7499ZM3.5 16.8528C3.5 15.906 4.25668 15.1382 5.19034 15.1382H11.0386C11.9723 15.1382 12.729 15.906 12.729 16.8528V22.7854C12.729 23.7322 11.9723 24.5 11.0386 24.5H5.19034C4.25668 24.5 3.5 23.7322 3.5 22.7854V16.8528ZM5.27465 16.9382V22.7H10.9543V16.9382H5.27465ZM17.023 17.2223H19.5771C20.0672 17.2223 20.4645 16.8194 20.4645 16.3223C20.4645 15.8252 20.0672 15.4223 19.5771 15.4223H16.7092C15.9023 15.4223 15.2483 16.0859 15.2483 16.9041V19.2316C15.2483 19.7286 15.6456 20.1316 16.1357 20.1316C16.6257 20.1316 17.023 19.7286 17.023 19.2316V17.2223ZM23.0186 20.0771C23.5087 20.0771 23.906 20.4801 23.906 20.9771V22.7227C23.906 23.541 23.252 24.2045 22.445 24.2045H18.43C17.9399 24.2045 17.5427 23.8016 17.5427 23.3045C17.5427 22.8075 17.9399 22.4045 18.43 22.4045H22.1313V20.9771C22.1313 20.4801 22.5286 20.0771 23.0186 20.0771Z"
        fill={color}
      />
    </svg>
  );
};
