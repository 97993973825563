import { CSSProperties, ReactNode, useEffect, useState } from "react";
import "./expanded.css";

const ExpandedArrowUp = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.5303 15.5303C18.2374 15.8232 17.7626 15.8232 17.4697 15.5303L12 10.0607L6.53033 15.5303C6.23744 15.8232 5.76256 15.8232 5.46967 15.5303C5.17678 15.2374 5.17678 14.7626 5.46967 14.4697L11.4697 8.46967C11.7626 8.17678 12.2374 8.17678 12.5303 8.46967L18.5303 14.4697C18.8232 14.7626 18.8232 15.2374 18.5303 15.5303Z"
        fill="#858585"
      />
    </svg>
  );
};

interface ExpandedProps {
  opended?: boolean;
  title?: React.ReactNode;
  children?: React.ReactNode;
  isLock?: boolean;
  className?: string;
  style?: CSSProperties;
  classNameTitle?: string;
  styleTitle?: CSSProperties;
  classNameContent?: string;
  styleContent?: CSSProperties;
  iconArrow?: ReactNode;
}

export const Expanded: React.FC<ExpandedProps> = ({
  title,
  children,
  opended = false,
  isLock = false,
  className,
  style,
  classNameContent,
  styleContent,
  classNameTitle,
  styleTitle,
  iconArrow,
}) => {
  const [open, setOpen] = useState(opended);
  useEffect(() => {
    setOpen(opended);
  }, [opended]);
  return (
    <details
      style={Object.assign({ pointerEvents: isLock ? "none" : "all" }, style)}
      className={`expanded ${className ?? ""}`}
      open={open}
      onToggle={(e) => {
        setOpen(e.currentTarget.open);
      }}
    >
      <summary
        className={`expanded__title ${classNameTitle}`}
        style={styleTitle}
      >
        {title}
        {iconArrow ?? (
          <div
            style={{
              width: "24px",
              height: "24px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              transform: `rotate(${open ? 0 : 180}deg)`,
              transition: "240ms",
            }}
          >
            <ExpandedArrowUp />
          </div>
        )}
      </summary>
      <div
        className={`expanded__content ${classNameContent}`}
        style={styleContent}
      >
        {children}
      </div>
    </details>
  );
};
