import "./sidebar-editor-mobile.less";
import { editorLayoutSideBarMobile } from "@/services/constants";
import { icLogoOnepoint } from "@/services/assets";
import { IconCloseX32 } from "@/components/icons/IconCloseX32";
import { FC, ReactNode } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { IconAddQrX28 } from "@/components/icons/IconAddQrX28";
import { NavlinkPath } from "@/services/router-config";
import { IconMyQrX28 } from "@/components/icons/IconMyQrX28";
import { IconWalletX28 } from "@/components/icons/IconWalletX28";
import { IconUserX28 } from "@/components/icons/IconUserX28";
import { IconLogout } from "@/components/icons/IconLogout";

const TabNavLink = ({
  isSelected,
  title,
  icon,
  path,
}: {
  isSelected: boolean;
  title: string;
  icon: ReactNode;
  path: string;
}) => {
  return (
    <NavLink
      to={path}
      className={`sidebar-editor-mobile__tab-nav-link${
        isSelected ? "--selected" : ""
      }`}
    >
      {icon}
      <span
        className={`text-14--reg row`}
        style={{
          width: "100%",
          textAlign: "start",
          justifyContent: "flex-start",
          alignItems: "center",
          flex: "1",
          fontWeight: isSelected ? "500" : undefined,
        }}
      >
        {title}
      </span>
    </NavLink>
  );
};

export const SideBarEditorMobile: FC<{
  onClose: () => void;
  onLogout?: () => void;
}> = ({ onClose, onLogout }) => {
  const location = useLocation();
  return (
    <aside id={editorLayoutSideBarMobile} className={"sidebar-editor-mobile"}>
      {/*logo*/}
      <div
        className={"row"}
        style={{
          width: "100%",
          justifyContent: "space-between",
          gap: "12px",
        }}
      >
        <img
          alt={"logo-app"}
          src={icLogoOnepoint}
          style={{ height: "28px", margin: "10px" }}
        />
        <div
          onClick={() => {
            onClose();
          }}
          className={"column"}
          style={{
            justifyContent: "center",
            height: "48px",
            width: "48px",
            cursor: "pointer",
          }}
        >
          <IconCloseX32 />
        </div>
      </div>

      <nav
        className={"column"}
        style={{
          width: "100%",
          minHeight: "100vh",
          height: "100vh",
          flex: "1",
          overflowY: "auto",
        }}
      >
        <TabNavLink
          key={"Tạo QR"}
          title={"Tạo QR"}
          path={NavlinkPath.creatQrCode}
          icon={
            <IconAddQrX28
              color={
                location.pathname.startsWith(NavlinkPath.creatQrCode)
                  ? "rgba(18, 183, 106, 1)"
                  : "rgba(173, 173, 173, 1)"
              }
            />
          }
          isSelected={location.pathname.startsWith(NavlinkPath.creatQrCode)}
        />
        <TabNavLink
          path={NavlinkPath.qrCode}
          key={"Quản lý qr"}
          title={"Quản lý QR"}
          icon={
            <IconMyQrX28
              color={
                location.pathname.startsWith(NavlinkPath.qrCode)
                  ? "rgba(18, 183, 106, 1)"
                  : "rgba(173, 173, 173, 1)"
              }
            />
          }
          isSelected={location.pathname.startsWith(NavlinkPath.qrCode)}
        />
        <TabNavLink
          path={NavlinkPath.billing}
          key={"Hoá đơn"}
          title={"Hoá đơn"}
          icon={
            <IconWalletX28
              color={
                location.pathname.startsWith(NavlinkPath.billing)
                  ? "rgba(18, 183, 106, 1)"
                  : "rgba(173, 173, 173, 1)"
              }
            />
          }
          isSelected={location.pathname.startsWith(NavlinkPath.billing)}
        />
        <TabNavLink
          path={NavlinkPath.profile}
          key={"Hồ sơ"}
          title={"Hồ sơ"}
          icon={
            <IconUserX28
              color={
                location.pathname.startsWith(NavlinkPath.profile)
                  ? "rgba(18, 183, 106, 1)"
                  : "rgba(173, 173, 173, 1)"
              }
            />
          }
          isSelected={location.pathname.startsWith(NavlinkPath.profile)}
        />
        <div
          onClick={() => {
            onLogout && onLogout();
          }}
          className="sidebar-editor-mobile__tab-nav-link"
          style={{
            alignItems: "center",
            cursor: "pointer",
          }}
        >
          <IconLogout color="#ADADAD" />
          <span
            className="text-14--reg"
            style={{ color: "#858585", fontWeight: "500" }}
          >
            {`Đăng xuất`}
          </span>
        </div>
      </nav>
    </aside>
  );
};
