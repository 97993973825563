export const breakPoint = 1024; //ưu tiên màn hình thao tác tạo QR và đọc documents
export const specialChars = new RegExp(/[#!@$%^*]/);

export const editorLayoutId = "editorLayoutId";
export const editorSubLayoutId = "editorSubLayoutId";
export const editorLayoutSideBarId = "editorLayoutSideBarId";
export const editorLayoutHeaderId = "editorLayoutHeaderId";
export const editorLayoutPageId = "editorLayoutPageId";

export const editorLayoutSideBarMobile = "editorLayoutSideBarMobile";

export const messageId = "messageId";
export const drawerId = "drawerId";
export const loadingId = "loadingId";
export const modalId = "modalId";
export const bottomBarId = "bottomBarId";
