import { ErrorModel } from "@/models/ErrorModel";
import { QrfyHttpClient } from "../QrfyRepositories";
import { TemplateRepository } from "../TemplateRepository";
import { mapTemplateModel } from "../adapters/template-adapter";
import { TemplateModel } from "../models/TemplateModel";

export class TemplateRepositoryImp implements TemplateRepository {
  private client: QrfyHttpClient;
  constructor(c: QrfyHttpClient) {
    this.client = c;
  }

  public async listTemplate(): Promise<TemplateModel[]> {
    try {
      const resp = await this.client.template.getList();
      if (resp.data.code === 0) {
        return resp.data.data?.contents?.map((d) => mapTemplateModel(d)) ?? [];
      } else {
        throw new ErrorModel({
          code: resp.data.code,
          message: resp.data.message,
        });
      }
    } catch (error) {
      throw error;
    }
  }
}
