export const IconCheckX20 = ({ color = "#FFFFFF" }: { color?: string }) => {
  return (
    <svg
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.7756 5.39024C18.0203 5.63373 18.0213 6.02945 17.7778 6.27412L9.5377 14.5542C9.29491 14.7982 8.90056 14.8 8.65561 14.5582L4.47755 10.4338C4.2319 10.1913 4.22934 9.79557 4.47184 9.54992C4.71433 9.30427 5.11005 9.30171 5.3557 9.54421L9.09077 13.2313L16.8917 5.39238C17.1352 5.14771 17.531 5.14675 17.7756 5.39024Z"
        fill={color}
      />
    </svg>
  );
};
