import { CardCustom } from "@/components/card-custom/CardCustom";
import { IconAddX20 } from "@/components/icons/IconAdd";
import { IconArrowBackX20 } from "@/components/icons/IconArrowBackX20";
import { IconArrowFowardX20 } from "@/components/icons/IconArrowFowardX20";
import { IconCloseX32 } from "@/components/icons/IconCloseX32";
import { IconTrashX20 } from "@/components/icons/IconTrashX20";
import { InputForm2 } from "@/components/input-form/InputForm2";
import { useBaseBloc } from "@/services/base-bloc";
import { useEffect } from "react";

interface PopupConfigParamsProps {
  url: string;
  onClose: () => void;
  onConfirm: (url: string) => void;
}

interface PopupConfigParamsState {
  utmSource: string;
  utmMedium: string;
  utmCampaign: string;
  params: { key: string; value: string; id: string }[];
}

export const PopupConfigParams: React.FC<PopupConfigParamsProps> = ({
  url,
  onClose,
  onConfirm,
}) => {
  const [state, setState] = useBaseBloc<PopupConfigParamsState>({
    params: [],
    utmCampaign: "",
    utmMedium: "",
    utmSource: "",
  });

  function onDeleteParam(index: number) {
    let tmp = [...state.params];
    tmp.splice(index, 1);
    state.params = [...tmp];
    setState();
  }

  function onAddParam() {
    let tmp = [...state.params];
    tmp.push({
      id: Date.now().toString(),
      key: "",
      value: "",
    });
    state.params = [...tmp];
    setState();
  }

  useEffect(() => {
    let tmp = new URL(url);
    tmp.searchParams.forEach((v, k) => {
      if (k === "utm_source") {
        state.utmSource = v;
      } else if (k === "utm_medium") {
        state.utmMedium = v;
      } else if (k === "utm_campaign") {
        state.utmCampaign = v;
      } else {
        state.params.push({
          id: k,
          key: k,
          value: v,
        });
      }
    });
    if (state.params.length === 0) {
      state.params.push({
        id: Date.now().toString(),
        key: "",
        value: "",
      });
    }
    setState();
  }, []);

  return (
    <CardCustom
      style={{
        width: "calc(100vw - 32px)",
        maxWidth: "600px",
        padding: "0px",
        maxHeight: "630px",
        height: "100vh-48px",
      }}
    >
      <div
        className="row"
        style={{
          width: "100%",
          padding: "20px",
        }}
      >
        <div style={{ width: "24px", height: "24px" }} />
        <span
          className="text-30--md"
          style={{
            width: "100%",
            flex: "1",
            color: "#333333",
            textAlign: "center",
          }}
        >
          Parameters
        </span>
        <div
          onClick={onClose}
          style={{ width: "24px", height: "24px", cursor: "pointer" }}
        >
          <IconCloseX32 color="#ADADAD" />
        </div>
      </div>

      {/* body */}
      <div
        className="column"
        style={{
          width: "100%",
          gap: "20px",
          padding: "20px 20px 20px 60px",
          height: "100%",
          flex: 1,
          overflow: "auto",
        }}
      >
        <div
          className="row"
          style={{
            width: "100%",
            gap: "12px",
          }}
        >
          <span
            className="text-14--md"
            style={{
              width: "100%",
              textAlign: "start",
              color: "#858585",
              flex: "1",
            }}
          >
            Name
          </span>
          <span
            className="text-14--md"
            style={{
              width: "100%",
              textAlign: "start",
              color: "#858585",
              flex: "1",
            }}
          >
            Value
          </span>
          <div style={{ width: "40px" }} />
        </div>

        {/* utm_source */}
        <div
          className="row"
          style={{
            width: "100%",
            gap: "12px",
          }}
        >
          <span
            className="text-14--reg"
            style={{
              width: "100%",
              textAlign: "start",
              color: "#333333",
              flex: "1",
            }}
          >
            utm_source
          </span>
          <InputForm2
            styleWrap={{ flex: "1" }}
            defaultValue={state.utmSource}
            onChange={(v) => {
              state.utmSource = v;
              setState();
            }}
          />
          <div style={{ width: "40px", height: "40px" }} />
        </div>

        {/* utm_source */}
        <div
          className="row"
          style={{
            width: "100%",
            gap: "12px",
          }}
        >
          <span
            className="text-14--reg"
            style={{
              width: "100%",
              textAlign: "start",
              color: "#333333",
              flex: "1",
            }}
          >
            utm_medium
          </span>
          <InputForm2
            styleWrap={{ flex: "1" }}
            defaultValue={state.utmMedium}
            onChange={(v) => {
              state.utmMedium = v;
              setState();
            }}
          />
          <div style={{ width: "40px", height: "40px" }} />
        </div>

        {/* utm_campaign */}
        <div
          className="row"
          style={{
            width: "100%",
            gap: "12px",
          }}
        >
          <span
            className="text-14--reg"
            style={{
              width: "100%",
              textAlign: "start",
              color: "#333333",
              flex: "1",
            }}
          >
            utm_campaign
          </span>
          <InputForm2
            styleWrap={{ flex: "1" }}
            defaultValue={state.utmCampaign}
            onChange={(v) => {
              state.utmCampaign = v;
              setState();
            }}
          />
          <div style={{ width: "40px", height: "40px" }} />
        </div>

        {/* params */}
        {state.params.map((d, i) => {
          return (
            <div
              key={`${d.id}-${i}`}
              className="row"
              style={{
                width: "100%",
                gap: "12px",
              }}
            >
              <InputForm2
                defaultValue={state.params[i].key}
                onChange={(v) => {
                  state.params[i].key = v;
                  setState();
                }}
              />
              <InputForm2
                defaultValue={state.params[i].value}
                onChange={(v) => {
                  state.params[i].value = v;
                  setState();
                }}
              />
              <button
                style={{
                  padding: "9px",
                  width: "40px",
                  height: "40px",
                }}
                onClick={() => {
                  onDeleteParam(i);
                }}
                className="button-primary-outline"
              >
                <IconTrashX20 />
              </button>
            </div>
          );
        })}
        <button
          className="button-primary-outline"
          style={{
            width: "100%",
            borderStyle: "dashed",
          }}
          onClick={() => {
            onAddParam();
          }}
        >
          <IconAddX20 color={"#333333"} />
          <span className="text-14--md" style={{ color: "#333333" }}>
            Add parameters
          </span>
        </button>
      </div>

      {/* footer */}
      <div
        className="row"
        style={{
          width: "100%",
          gap: "16px",
          padding: "20px",
        }}
      >
        <button
          onClick={() => {
            onClose();
          }}
          style={{ width: "100%" }}
          className="button-primary-outline"
        >
          <IconArrowBackX20 />
          <span>Back</span>
        </button>
        <button
          onClick={() => {
            let tmp = new URL(url);
            tmp.search = "";
            if (state.utmSource.length) {
              tmp.searchParams.append("utm_source", state.utmSource);
            }
            if (state.utmMedium.length) {
              tmp.searchParams.append("utm_medium", state.utmMedium);
            }
            if (state.utmCampaign.length) {
              tmp.searchParams.append("utm_campaign", state.utmCampaign);
            }
            for (let i = 0; i < state.params.length; i++) {
              const ele = state.params[i];
              if (ele.key.length && ele.value.length) {
                tmp.searchParams.append(ele.key, ele.value);
              }
            }
            onConfirm(tmp.toString());
          }}
          style={{ width: "100%" }}
          className="button-primary"
        >
          <span>Next</span>
          <IconArrowFowardX20 color="#FFFFFF" />
        </button>
      </div>
    </CardCustom>
  );
};
