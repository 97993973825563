import { ErrorModel } from "@/models/ErrorModel";
import { DynamicQrRepository } from "../DynamicQrRepository";
import { QrfyHttpClient } from "../QrfyRepositories";
import {
  ContentStyleModel,
  QRStyleModel,
  SocialNetworkModel,
} from "../models/QrModel";
import { WebsiteQrModel } from "../models/WebsiteQrModel";
import { mapVCardModel } from "../adapters/vcard-adapter";
import { VCardQrModel } from "../models/VCardQrModel";

export class DynamicQrRepositoryImp implements DynamicQrRepository {
  private client: QrfyHttpClient;
  constructor(c: QrfyHttpClient) {
    this.client = c;
  }

  public async createVCard({
    qrName,
    qrStyle,
    avatarUrl,
    contentStyle,
    emails,
    firstName,
    location,
    lastName,
    phones,
    socialNetworks,
    url,
    websites,
    company,
    title,
    backgroundUrl,
    brandLogoUrl,
    images,
    summary,
    template,
    customPath,
  }: {
    qrName: string;
    avatarUrl?: string;
    contentStyle?: ContentStyleModel;
    emails?: string[];
    firstName?: string;
    location?: string;
    lastName?: string;
    phones?: string[];
    socialNetworks?: SocialNetworkModel[];
    url?: string;
    websites?: string[];
    qrStyle?: QRStyleModel;
    company?: string;
    title?: string;
    backgroundUrl?: string;
    brandLogoUrl?: string;
    images?: string[];
    summary?: string;
    template?: string;
    customPath?: string;
  }): Promise<VCardQrModel> {
    try {
      const resp = await this.client.dynamicQr.createVCard({
        createVCardRequest: {
          qrName: qrName,
          qrStyle: qrStyle,
          avatarUrl: avatarUrl,
          contentStyle: contentStyle,
          emails: emails,
          firstName: firstName,
          location: location,
          lastName: lastName,
          phones: phones,
          socialNetworks: socialNetworks,
          url: url,
          company: company,
          title: title,
          backgroundUrl: backgroundUrl,
          brandLogoUrl: brandLogoUrl,
          images: images,
          summary: summary,
          template: template,
          websites: websites,
          customPath: customPath,
        },
      });
      if (resp.data.code === 0) {
        return mapVCardModel(resp.data.data);
      } else {
        throw new ErrorModel({
          code: resp.data.code,
          message: resp.data.message,
        });
      }
    } catch (error) {
      throw error;
    }
  }

  public async updateVCard({
    qrName,
    qrStyle,
    avatarUrl,
    contentStyle,
    emails,
    firstName,
    lastName,
    location,
    phones,
    socialNetworks,
    url,
    websites,
    id,
    company,
    title,
    backgroundUrl,
    brandLogoUrl,
    images,
    summary,
    template,
  }: {
    id: string;
    qrName: string;
    avatarUrl?: string;
    contentStyle?: ContentStyleModel;
    emails?: string[];
    firstName?: string;
    lastName?: string;
    location?: string;
    phones?: string[];
    socialNetworks?: SocialNetworkModel[];
    url?: string;
    websites?: string[];
    qrStyle?: QRStyleModel;
    company?: string;
    title?: string;
    backgroundUrl?: string;
    brandLogoUrl?: string;
    images?: string[];
    summary?: string;
    template?: string;
  }): Promise<VCardQrModel> {
    try {
      const resp = await this.client.dynamicQr.updateVCard({
        updateVCardRequest: {
          qrName: qrName,
          qrStyle: qrStyle,
          avatarUrl: avatarUrl,
          contentStyle: contentStyle,
          emails: emails,
          firstName: firstName,
          location: location,
          phones: phones,
          socialNetworks: socialNetworks,
          url: url,
          websites: websites,
          id: id,
          company: company,
          title: title,
          backgroundUrl: backgroundUrl,
          brandLogoUrl: brandLogoUrl,
          images: images,
          lastName: lastName,
          summary: summary,
          template: template,
        },
      });
      if (resp.data.code === 0) {
        return mapVCardModel(resp.data.data);
      } else {
        throw new ErrorModel({
          code: resp.data.code,
          message: resp.data.message,
        });
      }
    } catch (error) {
      throw error;
    }
  }

  public async createWebsite({
    qrName,
    qrStyle,
    url,
    customPath,
  }: {
    qrName: string;
    url: string;
    qrStyle?: QRStyleModel;
    customPath?: string;
  }): Promise<WebsiteQrModel> {
    try {
      const resp = await this.client.dynamicQr.createWebsite({
        createWebsiteRequest: {
          qrName: qrName,
          qrStyle: qrStyle,
          url: url,
          customPath: customPath,
        },
      });
      if (resp.data.code === 0) {
        return {
          id: resp.data.data?.id ?? "",
          qrContent: resp.data.data?.qrContent ?? "",
          qrName: resp.data.data?.qrName ?? "",
          url: resp.data.data?.url ?? "",
        };
      } else {
        throw new ErrorModel({
          code: resp.data.code,
          message: resp.data.message,
        });
      }
    } catch (error) {
      throw error;
    }
  }

  public async updateWebsite({
    qrName,
    qrStyle,
    url,
    id,
  }: {
    id: string;
    qrName: string;
    url: string;
    qrStyle?: QRStyleModel;
  }): Promise<WebsiteQrModel> {
    try {
      const resp = await this.client.dynamicQr.updateWebsite({
        updateWebsiteRequest: {
          qrName: qrName,
          qrStyle: qrStyle,
          url: url,
          id: id,
        },
      });
      if (resp.data.code === 0) {
        return {
          id: resp.data.data?.id ?? "",
          qrContent: resp.data.data?.qrContent ?? "",
          qrName: resp.data.data?.qrName ?? "",
          url: resp.data.data?.url ?? "",
        };
      } else {
        throw new ErrorModel({
          code: resp.data.code,
          message: resp.data.message,
        });
      }
    } catch (error) {
      throw error;
    }
  }
}
