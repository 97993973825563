export const IconAddX20 = ({ color = "#FFFFFF" }: { color?: string }) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.99996 3.54199C10.3451 3.54199 10.625 3.82181 10.625 4.16699V9.37533H15.8333C16.1785 9.37533 16.4583 9.65515 16.4583 10.0003C16.4583 10.3455 16.1785 10.6253 15.8333 10.6253H10.625V15.8337C10.625 16.1788 10.3451 16.4587 9.99996 16.4587C9.65478 16.4587 9.37496 16.1788 9.37496 15.8337V10.6253H4.16663C3.82145 10.6253 3.54163 10.3455 3.54163 10.0003C3.54163 9.65515 3.82145 9.37533 4.16663 9.37533H9.37496V4.16699C9.37496 3.82181 9.65478 3.54199 9.99996 3.54199Z"
        fill={color}
      />
    </svg>
  );
};
