import { QRStyleModel } from "@/apis/qrfy/models/QrModel";
import { QrFrame1 } from "@/components/qr-frame/QrFrame1";
import { QrFrame2 } from "@/components/qr-frame/QrFrame2";
import { QrFrame3 } from "@/components/qr-frame/QrFrame3";
import { useQuery } from "@tanstack/react-query";
import QRCodeStyling, {
  CornerDotType,
  CornerSquareType,
  DotType,
  ShapeType,
} from "qr-code-styling";
import { useEffect } from "react";

interface PreviewQrCodeImageProps {
  qrStyle: QRStyleModel;
  qrContent: string;
}

export const PreviewQrCodeImage: React.FC<PreviewQrCodeImageProps> = ({
  qrStyle,
  qrContent = "example",
}) => {
  const qrStyling = new QRCodeStyling({
    type: "canvas",
    margin: 0,
    height: 512,
    width: 512,
    imageOptions: {
      crossOrigin: "anonymous",
      imageSize: 0.25,
      margin: 0,
      hideBackgroundDots: true,
    },
    qrOptions: {
      errorCorrectionLevel: "H",
      typeNumber: 10,
    },
  });

  const { data, refetch } = useQuery({
    queryKey: ["get-qr-raw-data"],
    queryFn: async () => {
      const data = await qrStyling.getRawData("svg");
      return data && URL.createObjectURL(data);
    },
    enabled: false,
  });

  useEffect(() => {
    mapPingAttributeCanvas();
    refetch().then((v) => {
      if (v.data && qrStyle.frame.id === 0) {
        const imgQr = document.getElementById(
          "qr-image",
        ) as HTMLInputElement | null;
        if (imgQr) {
          imgQr.src = v.data;
        }
      }
    });
  }, [qrContent, qrStyle]);

  async function mapPingAttributeCanvas() {
    qrStyling.update({
      data: qrContent,
      image: qrStyle?.image,
      cornersDotOptions: {
        color: qrStyle.corner.dotColor,
        type: qrStyle.corner.dotStyle as CornerDotType,
      },
      cornersSquareOptions: {
        color: qrStyle.corner.squareColor,
        type: qrStyle.corner.squareStyle as CornerSquareType,
      },
      dotsOptions: {
        color: qrStyle.dot.color.fromColor,
        type: qrStyle.dot.style as DotType,
      },
      backgroundOptions: {
        color: qrStyle.shape.backgroundColor,
      },
      shape: qrStyle.shape.style as ShapeType,
    });
  }

  return (
    <>
      {(() => {
        switch (qrStyle.frame.id) {
          case 0:
            return (
              <img
                id="qr-image"
                style={{
                  width: "280px",
                  height: "280px",
                  aspectRatio: "1",
                  objectFit: "cover",
                  objectPosition: "center center",
                }}
              />
            );
          case 1:
            return (
              <QrFrame1
                style={{
                  content: data ? data : "",
                  backGroundColor:
                    qrStyle.frame.backgroundColor?.fromColor ?? "#D9D9D9",
                  frameColor: qrStyle.frame.color?.toColor ?? "#000000",
                  text: qrStyle.frame.text,
                  textColor: qrStyle.frame.color?.fromColor ?? "#333333",
                  textFontSize: qrStyle.frame.fontSize.toString(),
                  textFontWeight: "500",
                }}
              />
            );
          case 2:
            return (
              <QrFrame2
                style={{
                  content: data ? data : "",
                  backGroundColor:
                    qrStyle.frame.backgroundColor?.fromColor ?? "#D9D9D9",
                  frameColor: qrStyle.frame.color?.toColor ?? "#000000",
                  text: qrStyle.frame.text,
                  textColor: qrStyle.frame.color?.fromColor ?? "#333333",
                  textFontSize: qrStyle.frame.fontSize.toString(),
                  textFontWeight: "500",
                }}
              />
            );
          case 3:
            return (
              <QrFrame3
                style={{
                  content: data ? data : "",
                  backGroundColor:
                    qrStyle.frame.backgroundColor?.fromColor ?? "#D9D9D9",
                  frameColor: qrStyle.frame.color?.toColor ?? "#000000",
                  text: qrStyle.frame.text,
                  textColor: qrStyle.frame.color?.fromColor ?? "#333333",
                  textFontSize: qrStyle.frame.fontSize.toString(),
                  textFontWeight: "500",
                }}
              />
            );
        }
      })()}
    </>
  );
};
