export const IconUserX28 = ({ color = "#12B76A" }: { color?: string }) => {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.0013 4.375C12.199 4.375 10.7282 5.84724 10.7282 7.67544C10.7282 9.50364 12.199 10.9759 14.0013 10.9759C15.8036 10.9759 17.2744 9.50364 17.2744 7.67544C17.2744 5.84724 15.8036 4.375 14.0013 4.375ZM8.97815 7.67544C8.97815 4.89158 11.2217 2.625 14.0013 2.625C16.7809 2.625 19.0244 4.89158 19.0244 7.67544C19.0244 10.4593 16.7809 12.7259 14.0013 12.7259C11.2217 12.7259 8.97815 10.4593 8.97815 7.67544ZM6.7809 16.0736C8.69488 14.147 11.292 13.0636 14.0013 13.0636C16.7106 13.0636 19.3077 14.147 21.2217 16.0736C23.1355 17.9999 24.2096 20.6115 24.2096 23.3333C24.2096 23.8166 23.8179 24.2083 23.3346 24.2083C22.8514 24.2083 22.4596 23.8166 22.4596 23.3333C22.4596 21.0719 21.5671 18.9043 19.9802 17.3069C18.3935 15.7098 16.2427 14.8136 14.0013 14.8136C11.7599 14.8136 9.60908 15.7098 8.02238 17.3069C6.4355 18.9043 5.54297 21.0719 5.54297 23.3333C5.54297 23.8166 5.15122 24.2083 4.66797 24.2083C4.18472 24.2083 3.79297 23.8166 3.79297 23.3333C3.79297 20.6115 4.8671 17.9999 6.7809 16.0736Z"
        fill={color}
      />
    </svg>
  );
};
