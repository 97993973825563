import {
  ContentStyleModel,
  QrCodeModel,
  SocialNetworkModel,
  SocialNetworkType,
} from "@/apis/qrfy/models/QrModel";
import { FormCreateQrVCard } from "./interfaces/FormCreateVCard";
import { VCardQrModel } from "@/apis/qrfy/models/VCardQrModel";
import { useEffect, useMemo, useState } from "react";
import { BaseBloc } from "@/services/base-bloc";
import { distinct } from "rxjs";
import { Expanded } from "@/components/expanded/Expanded";

// interface CreateQrVCardState {
//   qrName: string;
//   url?: string;
//   contentStyle: ContentStyleModel;
//   socialNetworks: (SocialNetworkModel & { id: string })[];
//   avatarUrl?: string;
//   email?: string;
//   firstName?: string;
//   location?: string;
//   lastName?: string;
//   phone?: string;
//   website?: string;
//   title?: string;
//   company?: string;
//   summary?: string;
//   backgroundImage?: string;
//   backgroundImageFile?: File;
//   branchImage?: string;
//   branchImageFile?: File;
//   vCardImages: {
//     id: string;
//     file?: File;
//     content: string;
//   }[];
//   errors: {
//     qrName: string;
//   };
//   fileAvatar?: File;
//   modeView: boolean;
//   vcardQrModel?: VCardQrModel;
//   fileImageQr?: File;
//   qrStyle: QRStyleModel;
//   qrCodeStyling: QRCodeStyling;
// }

// export const CreateQrVCard = () => {
//   const navigate = useNavigate();
//   const { appBloc } = useAppBloc();
//   const [state, setState] = useBaseBloc<CreateQrVCardState>({
//     qrName: "",
//     errors: {
//       qrName: "",
//     },
//     qrCodeStyling: new QRCodeStyling({
//       width: 240,
//       height: 240,
//       type: "canvas",
//       imageOptions: {
//         crossOrigin: "anonymous",
//       },
//     }),
//     qrStyle: {
//       corner: mapCornerStyleModel(),
//       image: "",
//       frame: mapFrameStyleModel(),
//       shape: mapShapeStyleModel(),
//       dot: mapDotStyleModel(),
//     },
//     modeView: false,
//     socialNetworks: [
//       {
//         id: Date.now().toString(),
//         type: SocialNetworkType.FACEBOOK,
//         text: "",
//         url: "",
//       },
//     ],
//     contentStyle: mapContentStyleModel(),
//     vCardImages: [],
//   });

//   useEffect(() => {
//     appBloc.session.bottomBar.next(
//       <div
//         className="row"
//         style={{
//           width: "100%",
//           backgroundColor: "#FFFFFF",
//           boxSizing: "border-box",
//           justifyContent: "flex-end",
//           alignItems: "center",
//         }}
//       >
//         <button
//           onClick={() => {
//             publishVcardQr();
//           }}
//           className="button-3"
//         >
//           Publish
//         </button>
//       </div>,
//     );

//     return () => {
//       appBloc.session.bottomBar.next(null);
//     };
//   }, []);

//   const BuildSocialLinkList = useMemo(() => {
//     return (
//       <>
//         {state.socialNetworks.map((d, i) => {
//           return (
//             <div
//               key={d.id}
//               className="row"
//               style={{
//                 gap: "24px",
//                 width: "100%",
//                 alignItems: "center",
//               }}
//             >
//               <img
//                 style={{ width: "32px", height: "32px" }}
//                 alt=""
//                 src={`${
//                   Environment.host
//                 }/icons/ic-${d.type.toLowerCase()}-x32.svg`}
//               />
//               <div
//                 className="row"
//                 style={{
//                   width: "calc(100% - 40px - 48px - 32px - 24px)",
//                   boxSizing: "border-box",
//                   gap: "24px",
//                 }}
//               >
//                 <InputForm
//                   defaultValue={state.socialNetworks[i].text}
//                   onChange={(v) => {
//                     state.socialNetworks[i].text = v;
//                     setState();
//                   }}
//                   placeHolder="Label here"
//                   isFullwidth
//                 />
//                 <InputForm
//                   defaultValue={state.socialNetworks[i].url}
//                   onChange={(v) => {
//                     state.socialNetworks[i].url = v;
//                     setState();
//                   }}
//                   placeHolder="http://..."
//                   isFullwidth
//                 />
//               </div>
//               <div
//                 onClick={() => {
//                   state.socialNetworks.splice(i, 1);
//                   setState();
//                 }}
//                 style={{
//                   width: "40px",
//                   height: "40px",
//                   display: "flex",
//                   justifyContent: "center",
//                   alignItems: "center",
//                   backgroundColor: "#F5F5F5",
//                   borderRadius: "100%",
//                 }}
//               >
//                 <IconTrashX20 />
//               </div>
//             </div>
//           );
//         })}
//       </>
//     );
//   }, [state.socialNetworks, state.socialNetworks.length, setState]);

//   const BuildListImageData = useMemo(() => {
//     return (
//       <>
//         {state.vCardImages.map((d, i) => {
//           return (
//             <div
//               key={d.id}
//               className="column"
//               style={{
//                 width: "104px",
//                 height: "104px",
//                 border: "1px solid #C2C2C2",
//                 padding: "8px",
//                 position: "relative",
//               }}
//             >
//               <img
//                 alt=""
//                 src={d.content.length > 0 ? d.content : icLogoX32}
//                 style={{ height: "100%", aspectRatio: 1 }}
//               />
//               <div
//                 className="column"
//                 style={{
//                   width: "fit-content",
//                   height: "fit-content",
//                   position: "absolute",
//                   top: "-20px",
//                   right: "-20px",
//                 }}
//               >
//                 <CropImageCustom
//                   labelCancel={`Huỷ`}
//                   labelConfirm={`Xác nhận`}
//                   labelTitle={`Cắt ảnh`}
//                   acceptTypeImg={"image/jpg, image/jpeg, image/png"}
//                   onSaveHandler={({ data, file }) => {
//                     state.vCardImages[i].content = data;
//                     state.vCardImages[i].file = file;
//                     setState();
//                   }}
//                   maxSizeFile={2000 * 1024}
//                   minSizeImg={{ width: 600, height: 600 }}
//                   content={
//                     <div
//                       className="column"
//                       style={{
//                         width: "40px",
//                         height: "40px",
//                         borderRadius: "100%",
//                         justifyContent: "center",
//                         alignItems: "center",
//                         backgroundColor: "#12B76A",
//                         cursor: "pointer",
//                       }}
//                     >
//                       <IconPen color="#FFFFFF" />
//                     </div>
//                   }
//                 />
//               </div>
//               <div
//                 onClick={() => {
//                   state.vCardImages.splice(i, 1);
//                   setState();
//                 }}
//                 className="column"
//                 style={{
//                   width: "fit-content",
//                   height: "fit-content",
//                   position: "absolute",
//                   top: "-20px",
//                   left: "-20px",
//                 }}
//               >
//                 <div
//                   className="column"
//                   style={{
//                     width: "40px",
//                     height: "40px",
//                     borderRadius: "100%",
//                     justifyContent: "center",
//                     alignItems: "center",
//                     backgroundColor: "#F5F5F5",
//                     cursor: "pointer",
//                   }}
//                 >
//                   <IconTrashX20 color="#FF4C4C" />
//                 </div>
//               </div>
//             </div>
//           );
//         })}
//         <div
//           key={"-1"}
//           className="column"
//           style={{
//             width: "104px",
//             height: "104px",
//             border: "1px solid #C2C2C2",
//             padding: "8px",
//             position: "relative",
//           }}
//         >
//           <img
//             alt=""
//             src={imgEmptyImage}
//             style={{ height: "100%", aspectRatio: 1 }}
//           />
//           <div
//             className="column"
//             style={{
//               width: "40px",
//               height: "40px",
//               position: "absolute",
//               top: "-20px",
//               right: "-20px",
//               pointerEvents: state.vCardImages.length < 12 ? "all" : "none",
//             }}
//           >
//             <CropImageCustom
//               labelCancel={`Huỷ`}
//               labelConfirm={`Xác nhận`}
//               labelTitle={`Cắt ảnh`}
//               acceptTypeImg={"image/jpg, image/jpeg, image/png"}
//               onSaveHandler={({ data, file }) => {
//                 state.vCardImages.push({
//                   id: Date.now().toString(),
//                   content: data,
//                   file: file,
//                 });
//                 setState();
//               }}
//               maxSizeFile={2000 * 1024}
//               minSizeImg={{ width: 600, height: 600 }}
//               content={
//                 <div
//                   className="column"
//                   style={{
//                     width: "40px",
//                     height: "40px",
//                     borderRadius: "100%",
//                     justifyContent: "center",
//                     alignItems: "center",
//                     backgroundColor: "#12B76A",
//                     cursor: "pointer",
//                   }}
//                 >
//                   <IconAddX20 color="#FFFFFF" />
//                 </div>
//               }
//             />
//           </div>
//         </div>
//       </>
//     );
//   }, [state.vCardImages, state.vCardImages.length, setState]);

//   function mapPingAttributeCanvas() {
//     const ele = document.getElementById(`qr-vcard-preview-parent-canvas`);
//     if (ele) {
//       ele.innerHTML = "";
//       state.qrCodeStyling.update({
//         data: state.vcardQrModel?.qrContent,
//         image: state.qrStyle?.image,
//         cornersDotOptions: {
//           color: state.qrStyle.corner.dotColor,
//           type: state.qrStyle.corner.dotStyle as CornerDotType,
//         },
//         cornersSquareOptions: {
//           color: state.qrStyle.corner.squareColor,
//           type: state.qrStyle.corner.squareStyle as CornerSquareType,
//         },
//         dotsOptions: {
//           color: state.qrStyle.dot.backgroundColor,
//           type: state.qrStyle.dot.style as DotType,
//           gradient:
//             state.qrStyle.dot.color.type.length === 0
//               ? undefined
//               : {
//                   colorStops: [
//                     {
//                       color: state.qrStyle.dot.color?.fromColor ?? "#00000000",
//                       offset: 0,
//                     },
//                     {
//                       color: state.qrStyle.dot.color?.toColor ?? "#00000000",
//                       offset: 1,
//                     },
//                   ],
//                   type: state.qrStyle.dot.color.type as GradientType,
//                   rotation: (state.qrStyle.dot.color.rotation * Math.PI) / 180,
//                 },
//         },
//         backgroundOptions: {
//           color: state.qrStyle.shape.backgroundColor,
//           gradient:
//             state.qrStyle.shape.color.type.length === 0
//               ? undefined
//               : {
//                   colorStops: [
//                     {
//                       color:
//                         state.qrStyle.shape.color?.fromColor ?? "#00000000",
//                       offset: 0,
//                     },
//                     {
//                       color: state.qrStyle.shape.color?.toColor ?? "#00000000",
//                       offset: 1,
//                     },
//                   ],
//                   type: state.qrStyle.shape.color.type as GradientType,
//                   rotation:
//                     (state.qrStyle.shape.color.rotation * Math.PI) / 180,
//                 },
//         },
//         shape: state.qrStyle.shape.style as ShapeType,
//         qrOptions: {
//           errorCorrectionLevel: "H",
//         },
//       });
//       state.qrCodeStyling.append(ele);
//     }
//   }

//   function validateData(): boolean {
//     let check = true;
//     if (validateEmptyValue(state.qrName)) {
//       check = false;
//       state.errors.qrName = "Không để trống";
//     } else {
//       state.errors.qrName = "";
//     }

//     return check;
//   }

//   async function publishVcardQr() {
//     if (!validateData()) {
//       return setState();
//     }
//     if (state.vcardQrModel) {
//       try {
//         appBloc.session.isLoading.next(true);
//         if (state.fileImageQr) {
//           const url = await appBloc.repository.qrfyRepo.media.uploadImage({
//             file: state.fileImageQr,
//           });
//           state.qrStyle.image = `${Environment.hostApi}${url}`;
//           state.fileImageQr = undefined;
//         }
//         if (state.fileAvatar) {
//           const url = await appBloc.repository.qrfyRepo.media.uploadImage({
//             file: state.fileAvatar,
//           });
//           state.avatarUrl = `${Environment.hostApi}${url}`;
//           state.fileAvatar = undefined;
//         }
//         if (state.backgroundImageFile) {
//           const url = await appBloc.repository.qrfyRepo.media.uploadImage({
//             file: state.backgroundImageFile,
//           });
//           state.backgroundImage = `${Environment.hostApi}${url}`;
//           state.backgroundImageFile = undefined;
//         }

//         if (state.branchImageFile) {
//           const url = await appBloc.repository.qrfyRepo.media.uploadImage({
//             file: state.branchImageFile,
//           });
//           state.branchImage = `${Environment.hostApi}${url}`;
//           state.branchImageFile = undefined;
//         }

//         state.vCardImages.forEach(async (element, index) => {
//           if (element.file) {
//             const url = await appBloc.repository.qrfyRepo.media.uploadImage({
//               file: element.file,
//             });
//             state.vCardImages[index].content = `${Environment.hostApi}${url}`;
//             state.vCardImages[index].file = undefined;
//           }
//         });

//         const ret = await appBloc.repository.qrfyRepo.dynamicQr.updateVCard({
//           qrName: state.qrName,
//           url: state.url,
//           qrStyle: state.qrStyle,
//           contentStyle: state.contentStyle,
//           socialNetworks: state.socialNetworks.filter(
//             (e) => e.text !== "" && e.url !== "",
//           ),
//           avatarUrl: state.avatarUrl,
//           email: state.email,
//           firstName: state.firstName,
//           location: state.location,
//           lastName: state.lastName,
//           phone: state.phone,
//           website: state.website,
//           company: state.company,
//           title: state.title,
//           backgroundUrl: state.backgroundImage,
//           brandLogoUrl: state.branchImage,
//           images: state.vCardImages.map((d) => d.content),
//           summary: state.summary,
//           template: "1",
//           id: state.vcardQrModel.id,
//         });
//         navigate(NavlinkPath.qrCode, { replace: true });
//         message.success("Cập nhật thành công", 2);
//         appBloc.session.queueModal.next(
//           <PopupCreateQrSuccess
//             id={ret.id ?? ""}
//             onClose={() => {
//               appBloc.session.queueModal.next(null);
//             }}
//             qrContent={ret.qrContent}
//           />,
//         );
//       } catch (error: any) {
//         console.error(error);
//         message.error(error.message);
//       } finally {
//         appBloc.session.isLoading.next(false);
//       }
//     } else {
//       try {
//         appBloc.session.isLoading.next(true);
//         if (state.fileImageQr) {
//           const url = await appBloc.repository.qrfyRepo.media.uploadImage({
//             file: state.fileImageQr,
//           });
//           state.qrStyle.image = `${Environment.hostApi}${url}`;
//           state.fileImageQr = undefined;
//         }
//         if (state.fileAvatar) {
//           const url = await appBloc.repository.qrfyRepo.media.uploadImage({
//             file: state.fileAvatar,
//           });
//           state.avatarUrl = `${Environment.hostApi}${url}`;
//           state.fileAvatar = undefined;
//         }

//         if (state.backgroundImageFile) {
//           const url = await appBloc.repository.qrfyRepo.media.uploadImage({
//             file: state.backgroundImageFile,
//           });
//           state.backgroundImage = `${Environment.hostApi}${url}`;
//           state.backgroundImageFile = undefined;
//         }

//         if (state.branchImageFile) {
//           const url = await appBloc.repository.qrfyRepo.media.uploadImage({
//             file: state.branchImageFile,
//           });
//           state.branchImage = `${Environment.hostApi}${url}`;
//           state.branchImageFile = undefined;
//         }

//         state.vCardImages.forEach(async (element, index) => {
//           if (element.file) {
//             const url = await appBloc.repository.qrfyRepo.media.uploadImage({
//               file: element.file,
//             });
//             state.vCardImages[index].content = `${Environment.hostApi}${url}`;
//             state.vCardImages[index].file = undefined;
//           }
//         });

//         const ret = await appBloc.repository.qrfyRepo.dynamicQr.createVCard({
//           qrName: state.qrName,
//           url: state.url,
//           qrStyle: state.qrStyle,
//           contentStyle: state.contentStyle,
//           socialNetworks: state.socialNetworks.filter(
//             (e) => e.text !== "" && e.url !== "",
//           ),
//           avatarUrl: state.avatarUrl,
//           email: state.email,
//           firstName: state.firstName,
//           location: state.location,
//           lastName: state.lastName,
//           phone: state.phone,
//           website: state.website,
//           company: state.company,
//           title: state.title,
//           backgroundUrl: state.backgroundImage,
//           brandLogoUrl: state.branchImage,
//           images: state.vCardImages.map((d) => d.content),
//           summary: state.summary,
//           template: "1",
//         });
//         navigate(NavlinkPath.qrCode, { replace: true });
//         message.success("Tạo thành công", 2);
//         appBloc.session.queueModal.next(
//           <PopupCreateQrSuccess
//             id={ret.id ?? ""}
//             onClose={() => {
//               appBloc.session.queueModal.next(null);
//             }}
//             qrContent={ret.qrContent}
//           />,
//         );
//       } catch (error: any) {
//         console.error(error);
//         message.error(error.message);
//       } finally {
//         appBloc.session.isLoading.next(false);
//       }
//     }
//   }

//   async function previewVcardQr() {
//     if (!validateData()) {
//       return setState();
//     }
//     if (state.vcardQrModel) {
//       try {
//         appBloc.session.isLoading.next(true);
//         if (state.fileImageQr) {
//           const url = await appBloc.repository.qrfyRepo.media.uploadImage({
//             file: state.fileImageQr,
//           });
//           state.qrStyle.image = `${Environment.hostApi}${url}`;
//           state.fileImageQr = undefined;
//         }
//         if (state.fileAvatar) {
//           const url = await appBloc.repository.qrfyRepo.media.uploadImage({
//             file: state.fileAvatar,
//           });
//           state.avatarUrl = `${Environment.hostApi}${url}`;
//           state.fileAvatar = undefined;
//         }

//         if (state.backgroundImageFile) {
//           const url = await appBloc.repository.qrfyRepo.media.uploadImage({
//             file: state.backgroundImageFile,
//           });
//           state.backgroundImage = `${Environment.hostApi}${url}`;
//           state.backgroundImageFile = undefined;
//         }

//         if (state.branchImageFile) {
//           const url = await appBloc.repository.qrfyRepo.media.uploadImage({
//             file: state.branchImageFile,
//           });
//           state.branchImage = `${Environment.hostApi}${url}`;
//           state.branchImageFile = undefined;
//         }

//         state.vCardImages.forEach(async (element, index) => {
//           if (element.file) {
//             const url = await appBloc.repository.qrfyRepo.media.uploadImage({
//               file: element.file,
//             });
//             state.vCardImages[index].content = `${Environment.hostApi}${url}`;
//             state.vCardImages[index].file = undefined;
//           }
//         });

//         const ret = await appBloc.repository.qrfyRepo.dynamicQr.updateVCard({
//           qrName: state.qrName,
//           url: state.url,
//           qrStyle: state.qrStyle,
//           contentStyle: state.contentStyle,
//           socialNetworks: state.socialNetworks.filter(
//             (e) => e.text !== "" && e.url !== "",
//           ),
//           avatarUrl: state.avatarUrl,
//           email: state.email,
//           firstName: state.firstName,
//           location: state.location,
//           lastName: state.lastName,
//           phone: state.phone,
//           website: state.website,
//           company: state.company,
//           title: state.title,
//           backgroundUrl: state.backgroundImage,
//           brandLogoUrl: state.branchImage,
//           images: state.vCardImages.map((d) => d.content),
//           summary: state.summary,
//           template: "1",
//           id: state.vcardQrModel.id,
//         });
//         message.success("Cập nhật thành công", 2);
//         state.vcardQrModel = ret;
//         if (!state.modeView) {
//           state.modeView = true;
//         }
//         setState();
//       } catch (error: any) {
//         console.error(error);
//         message.error(error.message);
//       } finally {
//         appBloc.session.isLoading.next(false);
//       }
//     } else {
//       try {
//         appBloc.session.isLoading.next(true);
//         if (state.fileImageQr) {
//           const url = await appBloc.repository.qrfyRepo.media.uploadImage({
//             file: state.fileImageQr,
//           });
//           state.qrStyle.image = `${Environment.hostApi}${url}`;
//           state.fileImageQr = undefined;
//         }
//         if (state.fileAvatar) {
//           const url = await appBloc.repository.qrfyRepo.media.uploadImage({
//             file: state.fileAvatar,
//           });
//           state.avatarUrl = `${Environment.hostApi}${url}`;
//           state.fileAvatar = undefined;
//         }

//         if (state.backgroundImageFile) {
//           const url = await appBloc.repository.qrfyRepo.media.uploadImage({
//             file: state.backgroundImageFile,
//           });
//           state.backgroundImage = `${Environment.hostApi}${url}`;
//           state.backgroundImageFile = undefined;
//         }

//         if (state.branchImageFile) {
//           const url = await appBloc.repository.qrfyRepo.media.uploadImage({
//             file: state.branchImageFile,
//           });
//           state.branchImage = `${Environment.hostApi}${url}`;
//           state.branchImageFile = undefined;
//         }

//         state.vCardImages.forEach(async (element, index) => {
//           if (element.file) {
//             const url = await appBloc.repository.qrfyRepo.media.uploadImage({
//               file: element.file,
//             });
//             state.vCardImages[index].content = `${Environment.hostApi}${url}`;
//             state.vCardImages[index].file = undefined;
//           }
//         });

//         const ret = await appBloc.repository.qrfyRepo.dynamicQr.createVCard({
//           qrName: state.qrName,
//           url: state.url,
//           qrStyle: state.qrStyle,
//           contentStyle: state.contentStyle,
//           socialNetworks: state.socialNetworks.filter(
//             (e) => e.text !== "" && e.url !== "",
//           ),
//           avatarUrl: state.avatarUrl,
//           email: state.email,
//           firstName: state.firstName,
//           location: state.location,
//           lastName: state.lastName,
//           phone: state.phone,
//           website: state.website,
//           company: state.company,
//           title: state.title,
//           backgroundUrl: state.backgroundImage,
//           brandLogoUrl: state.branchImage,
//           images: state.vCardImages.map((d) => d.content),
//           summary: state.summary,
//           template: "1",
//         });
//         message.success("Tạo thành công", 2);
//         state.vcardQrModel = ret;
//         if (!state.modeView) {
//           state.modeView = true;
//         }
//         setState();
//       } catch (error: any) {
//         console.error(error);
//         message.error(error.message);
//       } finally {
//         appBloc.session.isLoading.next(false);
//       }
//     }
//     setTimeout(() => {
//       mapPingAttributeCanvas();
//     }, 500);
//   }

//   return (
//     <>
//       <div className="create-qr-page__setting">
//         <>
//           <main className="create-qr-vcard">
//             {/* basic info */}
//             <Expanded
//               styleContent={{ gap: "24px" }}
//               opended
//               title="Thông tin cơ bản"
//             >
//               <InputForm
//                 errorLabel={state.errors.qrName}
//                 defaultValue={state.qrName}
//                 onChange={(v) => {
//                   state.qrName = v;
//                   setState();
//                 }}
//                 isFullwidth
//                 title="Tên QR"
//                 placeHolder="Nhập tên QR"
//               />
//             </Expanded>

//             {/* v card info info */}
//             <Expanded
//               styleContent={{ gap: "24px" }}
//               opended
//               title="Nội dung VCard"
//             >
//               {/* background Vcard */}
//               <span className="text-16--md" style={{ color: "#333333" }}>
//                 Ảnh nền
//               </span>
//               <div
//                 className="column"
//                 style={{
//                   width: "104px",
//                   height: "104px",
//                   border: "1px solid #C2C2C2",
//                   padding: "8px",
//                   position: "relative",
//                 }}
//               >
//                 <img
//                   alt=""
//                   src={
//                     state.backgroundImageFile
//                       ? state.backgroundImage
//                       : imgEmptyImage
//                   }
//                   style={{ height: "100%", aspectRatio: 1 }}
//                 />
//                 <div
//                   className="column"
//                   style={{
//                     width: "40px",
//                     height: "40px",
//                     position: "absolute",
//                     top: "-20px",
//                     right: "-20px",
//                   }}
//                 >
//                   <CropImageCustom
//                     labelCancel={`Huỷ`}
//                     labelConfirm={`Xác nhận`}
//                     labelTitle={`Cắt ảnh`}
//                     acceptTypeImg={"image/jpg, image/jpeg, image/png"}
//                     onSaveHandler={async ({ data, file }) => {
//                       state.backgroundImage = data;
//                       state.backgroundImageFile = file;
//                       setState();
//                     }}
//                     maxSizeFile={2000 * 1024}
//                     minSizeImg={{ width: 500, height: 500 }}
//                     content={
//                       <div
//                         className="column"
//                         style={{
//                           width: "40px",
//                           height: "40px",
//                           borderRadius: "100%",
//                           justifyContent: "center",
//                           alignItems: "center",
//                           backgroundColor: "#12B76A",
//                           cursor: "pointer",
//                         }}
//                       >
//                         {state.backgroundImageFile ? (
//                           <IconPen color="#FFFFFF" />
//                         ) : (
//                           <IconAddX20 color="#FFFFFF" />
//                         )}
//                       </div>
//                     }
//                   />
//                 </div>

//                 {state.backgroundImageFile && (
//                   <div
//                     onClick={() => {
//                       state.backgroundImageFile = undefined;
//                       state.backgroundImage = undefined;
//                       setState();
//                     }}
//                     className="column"
//                     style={{
//                       width: "fit-content",
//                       height: "fit-content",
//                       position: "absolute",
//                       top: "-20px",
//                       left: "-20px",
//                     }}
//                   >
//                     <div
//                       className="column"
//                       style={{
//                         width: "40px",
//                         height: "40px",
//                         borderRadius: "100%",
//                         justifyContent: "center",
//                         alignItems: "center",
//                         backgroundColor: "#F5F5F5",
//                         cursor: "pointer",
//                       }}
//                     >
//                       <IconTrashX20 color="#FF4C4C" />
//                     </div>
//                   </div>
//                 )}
//               </div>

//               {/* avatar Vcard */}
//               <span className="text-16--md" style={{ color: "#333333" }}>
//                 Ảnh đại diện
//               </span>
//               <div
//                 className="column"
//                 style={{
//                   width: "104px",
//                   height: "104px",
//                   border: "1px solid #C2C2C2",
//                   padding: "8px",
//                   position: "relative",
//                 }}
//               >
//                 <img
//                   alt=""
//                   src={state.fileAvatar ? state.avatarUrl : imgEmptyImage}
//                   style={{ height: "100%", aspectRatio: 1 }}
//                 />
//                 <div
//                   className="column"
//                   style={{
//                     width: "40px",
//                     height: "40px",
//                     position: "absolute",
//                     top: "-20px",
//                     right: "-20px",
//                   }}
//                 >
//                   <CropImageCustom
//                     labelCancel={`Huỷ`}
//                     labelConfirm={`Xác nhận`}
//                     labelTitle={`Cắt ảnh`}
//                     acceptTypeImg={"image/jpg, image/jpeg, image/png"}
//                     onSaveHandler={async ({ data, file }) => {
//                       state.avatarUrl = data;
//                       state.fileAvatar = file;
//                       setState();
//                     }}
//                     maxSizeFile={2000 * 1024}
//                     minSizeImg={{ width: 320, height: 320 }}
//                     content={
//                       <div
//                         className="column"
//                         style={{
//                           width: "40px",
//                           height: "40px",
//                           borderRadius: "100%",
//                           justifyContent: "center",
//                           alignItems: "center",
//                           backgroundColor: "#12B76A",
//                           cursor: "pointer",
//                         }}
//                       >
//                         {state.fileAvatar ? (
//                           <IconPen color="#FFFFFF" />
//                         ) : (
//                           <IconAddX20 color="#FFFFFF" />
//                         )}
//                       </div>
//                     }
//                   />
//                 </div>

//                 {state.fileAvatar && (
//                   <div
//                     onClick={() => {
//                       state.fileAvatar = undefined;
//                       state.avatarUrl = undefined;
//                       setState();
//                     }}
//                     className="column"
//                     style={{
//                       width: "fit-content",
//                       height: "fit-content",
//                       position: "absolute",
//                       top: "-20px",
//                       left: "-20px",
//                     }}
//                   >
//                     <div
//                       className="column"
//                       style={{
//                         width: "40px",
//                         height: "40px",
//                         borderRadius: "100%",
//                         justifyContent: "center",
//                         alignItems: "center",
//                         backgroundColor: "#F5F5F5",
//                         cursor: "pointer",
//                       }}
//                     >
//                       <IconTrashX20 color="#FF4C4C" />
//                     </div>
//                   </div>
//                 )}
//               </div>

//               {/* branch Vcard */}
//               <span className="text-16--md" style={{ color: "#333333" }}>
//                 Branch
//               </span>
//               <div
//                 className="column"
//                 style={{
//                   width: "104px",
//                   height: "104px",
//                   border: "1px solid #C2C2C2",
//                   padding: "8px",
//                   position: "relative",
//                 }}
//               >
//                 <img
//                   alt=""
//                   src={
//                     state.branchImageFile ? state.branchImage : imgEmptyImage
//                   }
//                   style={{ height: "100%", aspectRatio: 1 }}
//                 />
//                 <div
//                   className="column"
//                   style={{
//                     width: "40px",
//                     height: "40px",
//                     position: "absolute",
//                     top: "-20px",
//                     right: "-20px",
//                   }}
//                 >
//                   <CropImageCustom
//                     labelCancel={`Huỷ`}
//                     labelConfirm={`Xác nhận`}
//                     labelTitle={`Cắt ảnh`}
//                     acceptTypeImg={"image/jpg, image/jpeg, image/png"}
//                     onSaveHandler={async ({ data, file }) => {
//                       state.branchImage = data;
//                       state.branchImageFile = file;
//                       setState();
//                     }}
//                     maxSizeFile={2000 * 1024}
//                     minSizeImg={{ width: 500, height: 500 }}
//                     content={
//                       <div
//                         className="column"
//                         style={{
//                           width: "40px",
//                           height: "40px",
//                           borderRadius: "100%",
//                           justifyContent: "center",
//                           alignItems: "center",
//                           backgroundColor: "#12B76A",
//                           cursor: "pointer",
//                         }}
//                       >
//                         {state.branchImageFile ? (
//                           <IconPen color="#FFFFFF" />
//                         ) : (
//                           <IconAddX20 color="#FFFFFF" />
//                         )}
//                       </div>
//                     }
//                   />
//                 </div>

//                 {state.branchImageFile && (
//                   <div
//                     onClick={() => {
//                       state.branchImageFile = undefined;
//                       state.branchImage = undefined;
//                       setState();
//                     }}
//                     className="column"
//                     style={{
//                       width: "fit-content",
//                       height: "fit-content",
//                       position: "absolute",
//                       top: "-20px",
//                       left: "-20px",
//                     }}
//                   >
//                     <div
//                       className="column"
//                       style={{
//                         width: "40px",
//                         height: "40px",
//                         borderRadius: "100%",
//                         justifyContent: "center",
//                         alignItems: "center",
//                         backgroundColor: "#F5F5F5",
//                         cursor: "pointer",
//                       }}
//                     >
//                       <IconTrashX20 color="#FF4C4C" />
//                     </div>
//                   </div>
//                 )}
//               </div>

//               <InputForm
//                 defaultValue={state.lastName}
//                 onChange={(v) => {
//                   state.lastName = v;
//                   setState();
//                 }}
//                 isFullwidth
//                 title="Họ"
//                 placeHolder="Nhập họ"
//               />
//               <InputForm
//                 defaultValue={state.firstName}
//                 onChange={(v) => {
//                   state.firstName = v;
//                   setState();
//                 }}
//                 isFullwidth
//                 title="Tên"
//                 placeHolder="Nhập Tên"
//               />
//               <InputForm
//                 checkNumber
//                 maxLenght={12}
//                 defaultValue={state.phone}
//                 onChange={(v) => {
//                   state.phone = v;
//                   setState();
//                 }}
//                 isFullwidth
//                 title="Số điện thoại"
//                 placeHolder="Nhập số điện thoại"
//               />
//               <InputForm
//                 defaultValue={state.email}
//                 onChange={(v) => {
//                   state.email = v;
//                   setState();
//                 }}
//                 isFullwidth
//                 title="Email"
//                 placeHolder="Nhập Email"
//               />
//               <InputForm
//                 defaultValue={state.company}
//                 onChange={(v) => {
//                   state.company = v;
//                   setState();
//                 }}
//                 isFullwidth
//                 title="Công ty"
//                 placeHolder="Nhập công ty"
//               />
//               <InputForm
//                 defaultValue={state.title}
//                 onChange={(v) => {
//                   state.title = v;
//                   setState();
//                 }}
//                 isFullwidth
//                 title="Chức danh"
//                 placeHolder="Nhập chức danh"
//               />
//               <InputForm
//                 defaultValue={state.location}
//                 onChange={(v) => {
//                   state.location = v;
//                   setState();
//                 }}
//                 isFullwidth
//                 title="Địa chỉ"
//                 placeHolder="Nhập địa chỉ"
//               />
//               <InputForm
//                 defaultValue={state.website}
//                 onChange={(v) => {
//                   state.website = v;
//                   setState();
//                 }}
//                 isFullwidth
//                 title="Trang web"
//                 placeHolder="Nhập trang web"
//               />
//               <InputForm
//                 defaultValue={state.url}
//                 onChange={(v) => {
//                   state.url = v;
//                   setState();
//                 }}
//                 isFullwidth
//                 title="Đường dẫn"
//                 placeHolder="Nhập đường dẫn"
//               />
//               <InputTextArea
//                 rows={16}
//                 maxLenght={1000}
//                 defaultValue={state.summary}
//                 onChange={(v) => {
//                   state.summary = v;
//                   setState();
//                 }}
//                 isFullwidth
//                 title="Tóm tắt"
//                 placeHolder="Nhập tóm tắt"
//               />
//             </Expanded>

//             {/* vcard - images */}
//             <Expanded opended title="Ảnh Vcard">
//               {/* photos */}
//               <div
//                 style={{
//                   display: "flex",
//                   gap: "16px",
//                   flexDirection: "column",
//                   justifyContent: "flex-start",
//                   alignItems: "flex-start",
//                   width: "100%",
//                   boxSizing: "border-box",
//                 }}
//               >
//                 <span
//                   className="text-14--md"
//                   style={{
//                     color: "#333333",
//                   }}
//                 >
//                   Danh sách ảnh (600x600px, 1:1, max 12 images)
//                 </span>

//                 <div
//                   className="row custom-scroll-bar"
//                   style={{
//                     width: "100%",
//                     gap: "48px",
//                     justifyContent: "flex-start",
//                     maxWidth: "100%",
//                     overflowX: "auto",
//                     scrollbarGutter: "stable",
//                     padding: "20px",
//                     boxSizing: "border-box",
//                   }}
//                 >
//                   {BuildListImageData}
//                 </div>
//               </div>
//             </Expanded>

//             {/* color and font vcard */}
//             <Expanded opended title="Màu và phông chữ Vcard">
//               <div
//                 className="column"
//                 style={{
//                   width: "100%",
//                   boxSizing: "border-box",
//                   justifyContent: "space-between",
//                   backgroundColor: "#F7F7F7",
//                   gap: "24px",
//                   padding: "16px 24px",
//                 }}
//               >
//                 <span
//                   className="text-16--md"
//                   style={{ width: "100%", textAlign: "start" }}
//                 >
//                   Màu và phông chữ
//                 </span>
//                 {/* color */}
//                 <div
//                   style={{
//                     width: "100%",
//                     gap: "24px",
//                     alignItems: "flex-end",
//                     display: "grid",
//                     gridTemplateColumns: "1fr 40px 1fr",
//                     boxSizing: "border-box",
//                   }}
//                 >
//                   <InputForm
//                     isFullwidth
//                     styleForm={{
//                       backgroundColor: "#FFFFFF",
//                       color: "#333333",
//                     }}
//                     isDisable
//                     title={"Màu chính"}
//                     defaultValue={state.contentStyle.primaryColor}
//                     suffix={
//                       <div
//                         style={{
//                           padding: "0px 8px",
//                         }}
//                       >
//                         <InputColor
//                           onChange={(v) => {
//                             state.contentStyle.primaryColor = v;
//                             setState();
//                           }}
//                           defaultValue={state.contentStyle.primaryColor}
//                         />
//                       </div>
//                     }
//                   />
//                   <button
//                     onClick={() => {
//                       const tmp = state.contentStyle.primaryColor;
//                       state.contentStyle.primaryColor =
//                         state.contentStyle.secondaryColor;
//                       state.contentStyle.secondaryColor = tmp;
//                       setState();
//                     }}
//                     className="button-3"
//                     style={{
//                       backgroundColor: "#333333",
//                       padding: "0px",
//                       borderRadius: "100%",
//                       height: "40px",
//                       width: "40px",
//                       border: "none",
//                     }}
//                   >
//                     <IconChange />
//                   </button>
//                   <InputForm
//                     isFullwidth
//                     styleForm={{
//                       backgroundColor: "#FFFFFF",
//                       color: "#333333",
//                     }}
//                     isDisable
//                     title={"Màu phụ"}
//                     defaultValue={state.contentStyle.secondaryColor}
//                     suffix={
//                       <div
//                         style={{
//                           padding: "0px 8px",
//                         }}
//                       >
//                         <InputColor
//                           onChange={(v) => {
//                             state.contentStyle.secondaryColor = v;
//                             setState();
//                           }}
//                           defaultValue={state.contentStyle.secondaryColor}
//                         />
//                       </div>
//                     }
//                   />
//                 </div>

//                 {/* font */}
//                 <div
//                   style={{
//                     width: "100%",
//                     gap: "24px",
//                     alignItems: "flex-end",
//                     display: "grid",
//                     gridTemplateColumns: "1fr 40px 1fr",
//                     boxSizing: "border-box",
//                   }}
//                 >
//                   <InputSelector
//                     iconDropDown={icArrowDownX20}
//                     data={["Roboto", "Lato"]}
//                     isFullwidth
//                     styleForm={{
//                       backgroundColor: "#FFFFFF",
//                       color: "#333333",
//                     }}
//                     title={"Phông tiêu đề"}
//                     defaultValue={state.contentStyle.titleFont}
//                     onChange={(v) => {
//                       state.contentStyle.titleFont = v;
//                       setState();
//                     }}
//                   />
//                   <div style={{ width: "40px" }} />

//                   <InputSelector
//                     iconDropDown={icArrowDownX20}
//                     isFullwidth
//                     data={["Roboto", "Lato"]}
//                     styleForm={{
//                       backgroundColor: "#FFFFFF",
//                     }}
//                     title={"Phông nội dung"}
//                     defaultValue={state.contentStyle.contentFont}
//                     onChange={(v) => {
//                       state.contentStyle.contentFont = v;
//                       setState();
//                     }}
//                   />
//                 </div>
//               </div>
//             </Expanded>

//             {/* Social vcard */}
//             <Expanded opended title="Đường dẫn mạng xã hội">
//               <div
//                 className="column"
//                 style={{
//                   width: "100%",
//                   gap: "16px",
//                   alignItems: "flex-start",
//                 }}
//               >
//                 <span className="text-14--md">Các trang mạng xã hội</span>
//                 {BuildSocialLinkList}
//                 <div className="create-qr-vcard__list-social-network-type">
//                   {Object.keys(SocialNetworkType).map((d) => {
//                     return (
//                       <div
//                         onClick={() => {
//                           if (state.socialNetworks.length == 6) {
//                             return;
//                           }
//                           state.socialNetworks.push({
//                             id: Date.now().toString(),
//                             text: "",
//                             url: "",
//                             type: SocialNetworkType[
//                               d as keyof typeof SocialNetworkType
//                             ],
//                           });
//                           setState();
//                         }}
//                         key={d}
//                         className="create-qr-vcard__list-social-network-type__item"
//                       >
//                         <img
//                           style={{ width: "32px", height: "32px" }}
//                           alt=""
//                           src={`${
//                             Environment.host
//                           }/icons/ic-${d.toLowerCase()}-x32.svg`}
//                         />
//                       </div>
//                     );
//                   })}
//                 </div>
//               </div>
//             </Expanded>

//             {/* qr style */}
//             <Expanded
//               styleContent={{ gap: "24px" }}
//               opended
//               title="Thiết kế QR"
//             >
//               {/* corner setup */}
//               <div
//                 className="column"
//                 style={{
//                   width: "100%",
//                   boxSizing: "border-box",
//                   justifyContent: "space-between",

//                   gap: "16px",
//                 }}
//               >
//                 <span
//                   className="text-18--md"
//                   style={{
//                     width: "100%",
//                     textAlign: "start",
//                   }}
//                 >
//                   Corner Options
//                 </span>

//                 {/* corner color */}
//                 <div
//                   style={{
//                     width: "100%",
//                     gap: "24px",
//                     alignItems: "flex-end",
//                     display: "grid",
//                     gridTemplateColumns: "1fr 40px 1fr",
//                     boxSizing: "border-box",
//                   }}
//                 >
//                   <InputForm
//                     isFullwidth
//                     styleForm={{
//                       backgroundColor: "#FFFFFF",
//                       color: "#333333",
//                     }}
//                     isDisable
//                     title={"Dot"}
//                     defaultValue={state.qrStyle.corner?.dotColor}
//                     suffix={
//                       <div
//                         style={{
//                           padding: "0px 8px",
//                         }}
//                       >
//                         <InputColor
//                           onChange={(v) => {
//                             state.qrStyle.corner.dotColor = v;
//                             setState();
//                             mapPingAttributeCanvas();
//                           }}
//                           defaultValue={state.qrStyle.corner?.dotColor}
//                         />
//                       </div>
//                     }
//                   />
//                   <button
//                     onClick={() => {
//                       const tmp = state.qrStyle.corner.dotColor;
//                       state.qrStyle.corner.dotColor =
//                         state.qrStyle.corner.squareColor;
//                       state.qrStyle.corner.squareColor = tmp;
//                       setState();
//                       mapPingAttributeCanvas();
//                     }}
//                     className="button-3"
//                     style={{
//                       backgroundColor: "#333333",
//                       padding: "0px",
//                       borderRadius: "100%",
//                       height: "40px",
//                       width: "40px",
//                       border: "none",
//                     }}
//                   >
//                     <IconChange />
//                   </button>
//                   <InputForm
//                     isFullwidth
//                     styleForm={{
//                       backgroundColor: "#FFFFFF",
//                       color: "#333333",
//                     }}
//                     isDisable
//                     title={"Square"}
//                     defaultValue={state.qrStyle.corner.squareColor}
//                     suffix={
//                       <div
//                         style={{
//                           padding: "0px 8px",
//                         }}
//                       >
//                         <InputColor
//                           onChange={(v) => {
//                             state.qrStyle.corner.squareColor = v;
//                             setState();
//                             mapPingAttributeCanvas();
//                           }}
//                           defaultValue={state.qrStyle.corner.squareColor}
//                         />
//                       </div>
//                     }
//                   />
//                 </div>

//                 {/* corner style */}
//                 <div
//                   style={{
//                     width: "100%",
//                     gap: "24px",
//                     alignItems: "flex-end",
//                     display: "grid",
//                     gridTemplateColumns: "1fr 40px 1fr",
//                     boxSizing: "border-box",
//                   }}
//                 >
//                   <InputSelector
//                     isFullwidth
//                     onChange={(v) => {
//                       state.qrStyle.corner.dotStyle = v;
//                       setState();
//                       mapPingAttributeCanvas();
//                     }}
//                     defaultValue={state.qrStyle.corner.dotStyle}
//                     data={["dot", "square"]}
//                   />
//                   <div />
//                   <InputSelector
//                     isFullwidth
//                     onChange={(v) => {
//                       state.qrStyle.corner.squareStyle = v;
//                       setState();
//                       mapPingAttributeCanvas();
//                     }}
//                     defaultValue={state.qrStyle.corner.squareStyle}
//                     data={["dot", "square", "extra-rounded"]}
//                   />
//                 </div>
//               </div>
//               <DividerGradient />

//               {/* shape setup */}
//               <div
//                 className="column"
//                 style={{
//                   width: "100%",
//                   boxSizing: "border-box",
//                   justifyContent: "space-between",
//                   gap: "16px",
//                 }}
//               >
//                 <div
//                   className="row"
//                   style={{
//                     gap: "24px",
//                     justifyContent: "flex-start",
//                     width: "100%",
//                   }}
//                 >
//                   <span
//                     className="text-18--md"
//                     style={{
//                       width: "100%",
//                       textAlign: "start",
//                     }}
//                   >
//                     Shape Options
//                   </span>

//                   <InputToggle
//                     defaultValue={state.qrStyle.shape.color.type !== ""}
//                     onChange={(v) => {
//                       if (v) {
//                         state.qrStyle.shape.color.type = "radial";
//                       } else {
//                         state.qrStyle.shape.color.type = "";
//                       }
//                       setState();
//                       mapPingAttributeCanvas();
//                     }}
//                   />
//                   <span className="text-14--reg" style={{ color: "#333333" }}>
//                     Use gradient?
//                   </span>
//                 </div>

//                 {/* backgroundcolor and shape style */}
//                 <div
//                   style={{
//                     width: "100%",
//                     gap: "24px",
//                     alignItems: "center",
//                     display: "grid",
//                     gridTemplateColumns: "1fr 40px 1fr",
//                     boxSizing: "border-box",
//                   }}
//                 >
//                   <InputSelector
//                     title="Shape Style"
//                     isFullwidth
//                     onChange={(v) => {
//                       state.qrStyle.shape.style = v;
//                       setState();
//                       mapPingAttributeCanvas();
//                     }}
//                     defaultValue={state.qrStyle.shape.style}
//                     data={["square", "circle"]}
//                   />
//                   <div />

//                   <InputForm
//                     style={{
//                       display:
//                         state.qrStyle.shape.color.type === ""
//                           ? undefined
//                           : "none",
//                     }}
//                     isFullwidth
//                     styleForm={{
//                       backgroundColor: "#FFFFFF",
//                       color: "#333333",
//                     }}
//                     isDisable
//                     title={"Background Color"}
//                     defaultValue={state.qrStyle.shape.backgroundColor}
//                     suffix={
//                       <div
//                         style={{
//                           padding: "0px 8px",
//                         }}
//                       >
//                         <InputColor
//                           onChange={(v) => {
//                             state.qrStyle.shape.backgroundColor = v;
//                             setState();
//                             mapPingAttributeCanvas();
//                           }}
//                           defaultValue={state.qrStyle.shape.backgroundColor}
//                         />
//                       </div>
//                     }
//                   />
//                 </div>

//                 {/* rotation and gradien stype */}
//                 <div
//                   style={{
//                     width: "100%",
//                     gap: "24px",
//                     alignItems: "flex-end",
//                     display:
//                       state.qrStyle.shape.color.type !== "" ? "grid" : "none",
//                     gridTemplateColumns: "1fr 40px 1fr",
//                     boxSizing: "border-box",
//                   }}
//                 >
//                   <InputForm
//                     title="Rotation"
//                     checkNumber
//                     isFullwidth
//                     maxLenght={3}
//                     defaultValue={state.qrStyle.shape.color.rotation.toString()}
//                     onChange={(v) => {
//                       state.qrStyle.shape.color.rotation = parseInt(
//                         v.length > 0 ? v : "0",
//                       );
//                       setState();
//                       mapPingAttributeCanvas();
//                     }}
//                   />
//                   <div />
//                   <InputSelector
//                     isFullwidth
//                     onChange={(v) => {
//                       state.qrStyle.shape.color.type = v;
//                       setState();
//                       mapPingAttributeCanvas();
//                     }}
//                     defaultValue={state.qrStyle.shape.color.type}
//                     data={["radial", "linear"]}
//                   />
//                 </div>

//                 {/* gradient color */}
//                 <div
//                   style={{
//                     width: "100%",
//                     gap: "24px",
//                     alignItems: "flex-end",
//                     display:
//                       state.qrStyle.shape.color.type !== "" ? "grid" : "none",
//                     gridTemplateColumns: "1fr 40px 1fr",
//                     boxSizing: "border-box",
//                   }}
//                 >
//                   <InputForm
//                     isFullwidth
//                     styleForm={{
//                       backgroundColor: "#FFFFFF",
//                       color: "#333333",
//                     }}
//                     isDisable
//                     title={"From Color"}
//                     defaultValue={state.qrStyle.shape.color.fromColor}
//                     suffix={
//                       <div
//                         style={{
//                           padding: "0px 8px",
//                         }}
//                       >
//                         <InputColor
//                           onChange={(v) => {
//                             state.qrStyle.shape.color.fromColor = v;
//                             setState();
//                             mapPingAttributeCanvas();
//                           }}
//                           defaultValue={state.qrStyle.shape.color.fromColor}
//                         />
//                       </div>
//                     }
//                   />
//                   <button
//                     onClick={() => {
//                       const tmp = state.qrStyle.shape.color.fromColor;
//                       state.qrStyle.shape.color.fromColor =
//                         state.qrStyle.shape.color.toColor;
//                       state.qrStyle.shape.color.toColor = tmp;
//                       setState();
//                       mapPingAttributeCanvas();
//                     }}
//                     className="button-3"
//                     style={{
//                       backgroundColor: "#333333",
//                       padding: "0px",
//                       borderRadius: "100%",
//                       height: "40px",
//                       width: "40px",
//                       border: "none",
//                     }}
//                   >
//                     <IconChange />
//                   </button>
//                   <InputForm
//                     isFullwidth
//                     styleForm={{
//                       backgroundColor: "#FFFFFF",
//                       color: "#333333",
//                     }}
//                     isDisable
//                     title={"To Color"}
//                     defaultValue={state.qrStyle.shape.color.toColor}
//                     suffix={
//                       <div
//                         style={{
//                           padding: "0px 8px",
//                         }}
//                       >
//                         <InputColor
//                           onChange={(v) => {
//                             state.qrStyle.shape.color.toColor = v;
//                             setState();
//                             mapPingAttributeCanvas();
//                           }}
//                           defaultValue={state.qrStyle.shape.color.toColor}
//                         />
//                       </div>
//                     }
//                   />
//                 </div>
//               </div>
//               <DividerGradient />

//               {/* dot setup */}
//               <div
//                 className="column"
//                 style={{
//                   width: "100%",
//                   boxSizing: "border-box",
//                   justifyContent: "space-between",
//                   gap: "16px",
//                 }}
//               >
//                 <div
//                   className="row"
//                   style={{
//                     gap: "24px",
//                     justifyContent: "flex-start",
//                     width: "100%",
//                   }}
//                 >
//                   <span
//                     className="text-18--md"
//                     style={{
//                       width: "100%",
//                       textAlign: "start",
//                     }}
//                   >
//                     Dot Options
//                   </span>

//                   <InputToggle
//                     defaultValue={state.qrStyle.dot.color.type !== ""}
//                     onChange={(v) => {
//                       if (v) {
//                         state.qrStyle.dot.color.type = "radial";
//                       } else {
//                         state.qrStyle.dot.color.type = "";
//                       }
//                       setState();
//                       mapPingAttributeCanvas();
//                     }}
//                   />
//                   <span className="text-14--reg" style={{ color: "#333333" }}>
//                     Use gradient?
//                   </span>
//                 </div>

//                 {/* backgroundcolor and shape style */}
//                 <div
//                   style={{
//                     width: "100%",
//                     gap: "24px",
//                     alignItems: "center",
//                     display: "grid",
//                     gridTemplateColumns: "1fr 40px 1fr",
//                     boxSizing: "border-box",
//                   }}
//                 >
//                   <InputSelector
//                     title="Dot Style"
//                     isFullwidth
//                     onChange={(v) => {
//                       state.qrStyle.dot.style = v;
//                       setState();
//                       mapPingAttributeCanvas();
//                     }}
//                     defaultValue={state.qrStyle.dot.style}
//                     data={[
//                       "square",
//                       "extra-rounded",
//                       "dots",
//                       "rounded",
//                       "classy",
//                       "classy-rounded",
//                     ]}
//                   />
//                   <div />

//                   <InputForm
//                     style={{
//                       display:
//                         state.qrStyle.dot.color.type === ""
//                           ? undefined
//                           : "none",
//                     }}
//                     isFullwidth
//                     styleForm={{
//                       backgroundColor: "#FFFFFF",
//                       color: "#333333",
//                     }}
//                     isDisable
//                     title={"Background Color"}
//                     defaultValue={state.qrStyle.dot.backgroundColor}
//                     suffix={
//                       <div
//                         style={{
//                           padding: "0px 8px",
//                         }}
//                       >
//                         <InputColor
//                           onChange={(v) => {
//                             state.qrStyle.dot.backgroundColor = v;
//                             setState();
//                             mapPingAttributeCanvas();
//                           }}
//                           defaultValue={state.qrStyle.dot.backgroundColor}
//                         />
//                       </div>
//                     }
//                   />
//                 </div>

//                 {/* rotation and gradien stype */}
//                 <div
//                   style={{
//                     width: "100%",
//                     gap: "24px",
//                     alignItems: "flex-end",
//                     display:
//                       state.qrStyle.dot.color.type !== "" ? "grid" : "none",
//                     gridTemplateColumns: "1fr 40px 1fr",
//                     boxSizing: "border-box",
//                   }}
//                 >
//                   <InputForm
//                     title="Rotation"
//                     checkNumber
//                     isFullwidth
//                     maxLenght={3}
//                     defaultValue={state.qrStyle.dot.color.rotation.toString()}
//                     onChange={(v) => {
//                       state.qrStyle.dot.color.rotation = parseInt(
//                         v.length > 0 ? v : "0",
//                       );
//                       setState();
//                       mapPingAttributeCanvas();
//                     }}
//                   />
//                   <div />
//                   <InputSelector
//                     isFullwidth
//                     onChange={(v) => {
//                       state.qrStyle.dot.color.type = v;
//                       setState();
//                       mapPingAttributeCanvas();
//                     }}
//                     defaultValue={state.qrStyle.dot.color.type}
//                     data={["radial", "linear"]}
//                   />
//                 </div>

//                 {/* gradient color */}
//                 <div
//                   style={{
//                     width: "100%",
//                     gap: "24px",
//                     alignItems: "flex-end",
//                     display:
//                       state.qrStyle.dot.color.type !== "" ? "grid" : "none",
//                     gridTemplateColumns: "1fr 40px 1fr",
//                     boxSizing: "border-box",
//                   }}
//                 >
//                   <InputForm
//                     isFullwidth
//                     styleForm={{
//                       backgroundColor: "#FFFFFF",
//                       color: "#333333",
//                     }}
//                     isDisable
//                     title={"From Color"}
//                     defaultValue={state.qrStyle.dot.color.fromColor}
//                     suffix={
//                       <div
//                         style={{
//                           padding: "0px 8px",
//                         }}
//                       >
//                         <InputColor
//                           onChange={(v) => {
//                             state.qrStyle.dot.color.fromColor = v;
//                             setState();
//                             mapPingAttributeCanvas();
//                           }}
//                           defaultValue={state.qrStyle.dot.color.fromColor}
//                         />
//                       </div>
//                     }
//                   />
//                   <button
//                     onClick={() => {
//                       const tmp = state.qrStyle.dot.color.fromColor;
//                       state.qrStyle.dot.color.fromColor =
//                         state.qrStyle.dot.color.toColor;
//                       state.qrStyle.dot.color.toColor = tmp;
//                       setState();
//                       mapPingAttributeCanvas();
//                     }}
//                     className="button-3"
//                     style={{
//                       backgroundColor: "#333333",
//                       padding: "0px",
//                       borderRadius: "100%",
//                       height: "40px",
//                       width: "40px",
//                       border: "none",
//                     }}
//                   >
//                     <IconChange />
//                   </button>
//                   <InputForm
//                     isFullwidth
//                     styleForm={{
//                       backgroundColor: "#FFFFFF",
//                       color: "#333333",
//                     }}
//                     isDisable
//                     title={"To Color"}
//                     defaultValue={state.qrStyle.dot.color.toColor}
//                     suffix={
//                       <div
//                         style={{
//                           padding: "0px 8px",
//                         }}
//                       >
//                         <InputColor
//                           onChange={(v) => {
//                             state.qrStyle.dot.color.toColor = v;
//                             setState();
//                             mapPingAttributeCanvas();
//                           }}
//                           defaultValue={state.qrStyle.dot.color.toColor}
//                         />
//                       </div>
//                     }
//                   />
//                 </div>
//               </div>
//               <DividerGradient />

//               {/* ảnh center qr */}
//               <div
//                 className="column"
//                 style={{
//                   width: "100%",
//                   gap: "24px",
//                   color: "#333333",
//                   boxSizing: "border-box",
//                   alignItems: "center",
//                 }}
//               >
//                 <span className="text-14--reg">
//                   Image ( Minimum width : 400px, 1:1 Ratio)
//                 </span>
//                 {state.qrStyle?.image ? (
//                   <>
//                     <div
//                       className="column"
//                       style={{
//                         width: "300px",
//                         aspectRatio: 1,
//                         border: "1px solid #C2C2C2",
//                         padding: "8px",
//                         position: "relative",
//                       }}
//                     >
//                       <img
//                         alt=""
//                         src={state.qrStyle?.image}
//                         style={{ height: "100%", aspectRatio: 1 }}
//                       />

//                       <div
//                         onClick={() => {
//                           state.qrStyle.image = "";
//                           state.fileImageQr = undefined;
//                           setState();
//                           mapPingAttributeCanvas();
//                         }}
//                         className="column"
//                         style={{
//                           width: "fit-content",
//                           height: "fit-content",
//                           position: "absolute",
//                           top: "-20px",
//                           left: "-20px",
//                         }}
//                       >
//                         <div
//                           className="column"
//                           style={{
//                             width: "40px",
//                             height: "40px",
//                             borderRadius: "100%",
//                             justifyContent: "center",
//                             alignItems: "center",
//                             backgroundColor: "#F5F5F5",
//                             cursor: "pointer",
//                           }}
//                         >
//                           <IconTrashX20 color="#FF4C4C" />
//                         </div>
//                       </div>
//                     </div>
//                   </>
//                 ) : (
//                   <div style={{ maxWidth: "420px", width: "100%" }}>
//                     <CropImageCustom
//                       labelCancel={`Huỷ`}
//                       labelConfirm={`Xác nhận`}
//                       labelTitle={`Cắt ảnh`}
//                       acceptTypeImg={"image/jpg, image/jpeg, image/png"}
//                       onSaveHandler={async ({ data, file }) => {
//                         state.qrStyle.image = data;
//                         state.fileImageQr = file;
//                         setState();
//                         mapPingAttributeCanvas();
//                       }}
//                       maxSizeFile={2000 * 1024}
//                       minSizeImg={{ width: 400, height: 400 }}
//                       content={
//                         <>
//                           <div
//                             className="column"
//                             style={{
//                               width: "100%",
//                               boxSizing: "border-box",
//                               padding: "24px 16px",
//                               gap: "24px",
//                               backgroundColor: "#FAFAFA",
//                               border: "1px dashed #D9D9D9",
//                             }}
//                           >
//                             <img alt="" src={imgUploadDrag} />
//                             <span
//                               className="column"
//                               style={{
//                                 alignItems: "center",
//                               }}
//                             >
//                               <span
//                                 style={{ color: "#333333" }}
//                                 className="text-20--md"
//                               >
//                                 Drag and drop or click here
//                               </span>
//                               <span
//                                 style={{ color: "#858585" }}
//                                 className="text-14--reg"
//                               >
//                                 To upload your image (max 2MB)
//                               </span>
//                             </span>
//                           </div>
//                         </>
//                       }
//                     />
//                   </div>
//                 )}
//               </div>
//               <DividerGradient />
//             </Expanded>
//           </main>
//         </>
//       </div>

//       {/* preview */}
//       <div className="create-qr-page__preview">
//         {/* link */}
//         {state.vcardQrModel && (
//           <div
//             className="row"
//             style={{
//               width: "100%",
//               padding: "8px 16px",
//               gap: "12px",
//               backgroundColor: "rgba(247, 247, 247, 1)",
//               boxSizing: "border-box",
//             }}
//           >
//             <Typography.Text
//               className="row"
//               style={{
//                 gap: "8px",
//                 width: "100%",
//                 justifyContent: "space-between",
//               }}
//               copyable={{
//                 icon: [
//                   <div
//                     key={"copy"}
//                     className="row"
//                     style={{
//                       width: "36px",
//                       height: "36px",
//                       borderRadius: "4px",
//                       backgroundColor: "#FFFFFF",
//                       border: "1px solid rgba(224, 224, 224, 1)",
//                       justifyContent: "center",
//                       alignItems: "center",
//                       cursor: "pointer",
//                       boxSizing: "border-box",
//                     }}
//                   >
//                     <IconCopy key={"icon-1"} />
//                   </div>,
//                   <div
//                     key={"check"}
//                     className="row"
//                     style={{
//                       width: "36px",
//                       height: "36px",
//                       borderRadius: "4px",
//                       backgroundColor: "#FFFFFF",
//                       border: "1px solid rgba(224, 224, 224, 1)",
//                       justifyContent: "center",
//                       alignItems: "center",
//                       cursor: "pointer",
//                       boxSizing: "border-box",
//                     }}
//                   >
//                     <IconCheck key={"icon-2"} color="#12B76A" />
//                   </div>,
//                 ],
//                 text: state.vcardQrModel.qrContent,
//               }}
//             >
//               <a
//                 href={state.vcardQrModel.qrContent ?? "#"}
//                 target="_blank"
//                 rel="noopener noreferrer"
//                 className="row"
//                 style={{
//                   width: "calc(100% - 36px - 36px - 36px)",
//                   color: "rgba(28, 87, 211, 1)",
//                   boxSizing: "border-box",
//                 }}
//               >
//                 {state.vcardQrModel.qrContent}
//               </a>
//             </Typography.Text>
//           </div>
//         )}
//         {/* mode preview */}
//         <div
//           className="row"
//           style={{
//             width: "100%",
//             justifyContent: "center",
//             alignItems: "center",
//             gap: "24px",
//           }}
//         >
//           <div
//             onClick={() => {
//               if (state.modeView) {
//                 state.modeView = false;
//                 setState();
//               }
//             }}
//             className="row"
//             style={{
//               width: "36px",
//               height: "36px",
//               borderRadius: "4px",
//               backgroundColor: !state.modeView ? "#12B76A" : "#FFFFFF",
//               border: "1px solid rgba(224, 224, 224, 1)",
//               justifyContent: "center",
//               alignItems: "center",
//               cursor: "pointer",
//             }}
//           >
//             <IconPhone
//               color={!state.modeView ? "#FFFFFF" : "rgba(92, 92, 92, 1)"}
//             />
//           </div>

//           <div
//             onClick={() => {
//               previewVcardQr();
//             }}
//             className="row"
//             style={{
//               width: "36px",
//               height: "36px",
//               borderRadius: "4px",
//               backgroundColor: state.modeView ? "#12B76A" : "#FFFFFF",
//               border: "1px solid rgba(224, 224, 224, 1)",
//               justifyContent: "center",
//               alignItems: "center",
//               cursor: "pointer",
//             }}
//           >
//             <IconMyQrX28
//               color={state.modeView ? "#FFFFFF" : "rgba(92, 92, 92, 1)"}
//             />
//           </div>
//         </div>

//         {/* preview QR */}
//         <>
//           <div
//             style={{
//               display: state.modeView ? undefined : "none",
//             }}
//             className="create-qr-page__preview-page__box-qr"
//           >
//             <div
//               id={"qr-preview-parent"}
//               className="create-qr-page__preview-page__box-qr__qr"
//             >
//               <div id={`qr-vcard-preview-parent-canvas`}></div>
//             </div>
//           </div>
//           <div
//             style={{
//               display: state.modeView ? "flex" : "none",
//               flexDirection: "row",
//               gap: "16px",
//               width: "100%",
//               boxSizing: "border-box",
//               padding: "16px",
//               justifyContent: "center",
//             }}
//           >
//             <a
//               id="download-qr-svg"
//               download="qr.svg"
//               onClick={() => {
//                 state.qrCodeStyling.download({
//                   extension: "svg",
//                   name: state.qrName,
//                 });
//               }}
//               className="button-3"
//             >
//               <IconDownload color="#FFFFFF" /> <span>SVG</span>
//             </a>
//             <a
//               id="download-qr-png"
//               download="qr.png"
//               onClick={() => {
//                 state.qrCodeStyling.download({
//                   extension: "png",
//                   name: state.qrName,
//                 });
//               }}
//               className="button-3"
//             >
//               <IconDownload color="#FFFFFF" /> <span>PNG</span>
//             </a>
//           </div>
//         </>

//         {/* phone preview */}
//         {!state.modeView && (
//           <div
//             className="column hide-scrollbar"
//             style={{ width: "fit-content", overflowY: "auto" }}
//           >
//             <PreviewPhone>
//               <VCardPreview
//                 avatarUrl={state.avatarUrl}
//                 company={state.company}
//                 email={state.email}
//                 firstName={state.firstName}
//                 lastname={state.lastName}
//                 location={state.location}
//                 phone={state.phone}
//                 title={state.title}
//                 url={state.url}
//                 website={state.website}
//                 contentStyle={state.contentStyle}
//                 socialNetworks={state.socialNetworks}
//                 summary={state.summary}
//                 backgroundImage={state.backgroundImage}
//                 vCardImages={state.vCardImages.map((d) => d.content)}
//                 branchImage={state.branchImage}
//                 isPreview
//               />
//             </PreviewPhone>
//             {/* <div className="create-qr-page__preview-page__phone-preview">
//               <iframe id="test" style={{
//                 height:"100%",
//                 width:"100%",
//                 borderRadius:"24px",
//                 border:"none",
//                 padding:"0",
//                 overflow:"hidden",
//                 backgroundColor:"#00000000",
//                 boxSizing:"border-box",
//               }} srcDoc={renderToString(
//               <VCardPreview
//                 avatarUrl={state.avatarUrl}
//                 company={state.company}
//                 email={state.email}
//                 firstName={state.firstName}
//                 lastname={state.lastName}
//                 location={state.location}
//                 phone={state.phone}
//                 title={state.title}
//                 url={state.url}
//                 website={state.website}
//                 contentStyle={state.contentStyle}
//                 socialNetworks={state.socialNetworks}
//                 summary={state.summary}
//                 backgroundImage={state.backgroundImage}
//                 vCardImages={state.vCardImages.map((d) => d.content)}
//                 branchImage={state.branchImage}
//                 isPreview
//               />)}/>

//               <div
//                 className="row"
//                 style={{
//                   width: "calc(100% - 36px)",
//                   padding: "12px 0px",
//                   backgroundColor: "transparent",
//                   display: "flex",
//                   justifyContent: "center",
//                   alignItems: "center",
//                   position: "absolute",
//                   left: "18px",
//                   top: "12px",
//                   borderRadius: "18px",
//                   backdropFilter: "blur(1px)",
//                 }}
//               >
//                 <img alt="" src={imgPhoneIndicationIsland} style={{}} />
//               </div>
//               <div
//                 className="row"
//                 style={{
//                   width: "calc(100% - 36px)",
//                   padding: "12px 0px",
//                   backgroundColor: "transparent",
//                   display: "flex",
//                   justifyContent: "center",
//                   alignItems: "center",
//                   position: "absolute",
//                   left: "18px",
//                   bottom: "12px",
//                   borderRadius: "18px",
//                   backdropFilter: "blur(1px)",
//                 }}
//               >
//                 <img alt="" src={imgPhoneIndicationBar} style={{}} />
//               </div>
//             </div> */}
//           </div>
//         )}
//       </div>
//     </>
//   );
// };

import "./style.less";
import Environment from "@/services/environment";
import { mapContentStyleModel } from "@/apis/qrfy/adapters/qr-adapter";
import { dataColor } from "./composables/data_color";
import { ColorPicker, Switch } from "antd";
import { InputForm2 } from "@/components/input-form/InputForm2";
import { IconSwapX20 } from "@/components/icons/IconSwap";
import { InputSelector } from "@/components/input-selector/InputSelector";
import { dataFont } from "./composables/data_font";
import { InputImageCrop } from "@/components/input-image-crop/InputImageCrop";
import { InputTextArea } from "@/components/input-text-area/InputTextArea";
import { dataTemplate } from "./composables/data_template";
import { IconTrashX20 } from "@/components/icons/IconTrashX20";
import { IconAddX20 } from "@/components/icons/IconAdd";
import { v4 as uuidv4 } from "uuid";

interface CreateQrVCardState {
  template: string;
  contentStyle: ContentStyleModel;
  profilePhoto: string;
  firstName: string;
  lastName: string;
  companyName: string;
  position: string;
  sumary: string;
  phone: { key: string; value: string }[];
  email: { key: string; value: string }[];
  website: { key: string; value: string }[];
  location: string;
  backgroundUrl: string;
  brandUrl: string;

  // configURL
  autoGenURL?: boolean;
  customPath: string;
  errorCustomPath: string;

  // social network
  socialNetworks: (SocialNetworkModel & { id: string })[];
}

interface CreateQrVCardProps {
  onChage: (v: FormCreateQrVCard) => void;
  initData?: QrCodeModel<VCardQrModel> | null;
}

export const CreateQrVCard: React.FC<CreateQrVCardProps> = ({
  onChage,
  initData,
}) => {
  const _bloc = useMemo<BaseBloc<CreateQrVCardState>>(() => {
    return new BaseBloc<CreateQrVCardState>({
      initState: {
        template: "0",
        contentStyle: mapContentStyleModel({
          primaryColor: "#12B76A",
          secondaryColor: "#ADEDC6",
          contentFont: "Roboto",
          titleFont: "Roboto",
        }),
        companyName: "Công ty trách nhiệm hữu hạn",
        email: [
          {
            key: "default-email",
            value: "example@gmail.com",
          },
        ],
        firstName: "Nguyễn",
        lastName: "Văn A",
        location: "Việt nam",
        phone: [
          {
            key: "default-phone",
            value: "0967618471",
          },
        ],
        profilePhoto: "",
        sumary: "Thích đọc sách và làm marketing",
        position: "Marketing",
        website: [
          {
            key: "default-website",
            value: "",
          },
        ],
        autoGenURL: true,
        customPath: Environment.host + "/" + `${uuidv4().substring(0, 7)}`,
        errorCustomPath: "",
        socialNetworks: [
          {
            id: Date.now().toString(),
            type: SocialNetworkType.FACEBOOK,
            text: "Trang cá nhân",
            url: "https://www.facebook.com/",
          },
        ],
        backgroundUrl: "",
        brandUrl: "",
      },
    });
  }, []);
  const [state, setState] = useState<CreateQrVCardState>(_bloc.state);

  const BuildSocialLinkList = useMemo(() => {
    return (
      <>
        {state.socialNetworks.map((d, i) => {
          return (
            <div
              key={d.id}
              className="row"
              style={{
                gap: "24px",
                width: "100%",
                alignItems: "center",
              }}
            >
              <img
                style={{ width: "32px", height: "32px" }}
                alt=""
                src={`${
                  Environment.host
                }/icons/ic-${d.type.toLowerCase()}-x32.svg`}
              />
              <div
                className="row"
                style={{
                  width: "calc(100% - 40px - 48px - 32px - 24px)",
                  boxSizing: "border-box",
                  gap: "24px",
                }}
              >
                <InputForm2
                  defaultValue={state.socialNetworks[i].text}
                  onChange={(v) => {
                    state.socialNetworks[i].text = v;
                    _bloc.upDateState();
                  }}
                  placeHolder="Label here"
                />
                <InputForm2
                  defaultValue={state.socialNetworks[i].url}
                  onChange={(v) => {
                    state.socialNetworks[i].url = v;
                    _bloc.upDateState();
                  }}
                  placeHolder="http://..."
                />
              </div>
              <button
                onClick={() => {
                  state.socialNetworks.splice(i, 1);
                  _bloc.upDateState();
                }}
                disabled={i === 0 && state.socialNetworks.length === 1}
                style={{
                  width: "40px",
                  height: "40px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: "#F5F5F5",
                  borderRadius: "100%",
                  outline: "none",
                  border: "none",
                  cursor: "pointer",
                }}
              >
                <IconTrashX20 />
              </button>
            </div>
          );
        })}
      </>
    );
  }, [state.socialNetworks, state.socialNetworks.length, setState]);

  useEffect(() => {
    _bloc.stream.pipe(distinct()).subscribe((v) => {
      let check = true;
      const tempPhones = v.phone
        .map((v) => v.value)
        .filter((e) => e.length > 0);
      const tempEmails = v.email
        .map((v) => v.value)
        .filter((e) => e.length > 0);
      const tempWebsites = v.website
        .map((v) => v.value)
        .filter((e) => e.length > 0);
      const socialNetwork = v.socialNetworks.filter(
        (e) => e.text.length > 0 && e.url.length > 0,
      );

      if (
        tempPhones.length === 0 ||
        tempEmails.length === 0 ||
        v.firstName.length === 0 ||
        v.lastName.length === 0 ||
        v.profilePhoto.length === 0 ||
        v.customPath?.length === 0 ||
        v.errorCustomPath.length > 0 ||
        (v.template === "1" && v.backgroundUrl.length === 0)
      ) {
        check = false;
      }

      if (v && check) {
        onChage({
          template: v.template,
          contentStyle: v.contentStyle,
          done: true,
          company: v.companyName,
          emails: tempEmails,
          firstName: v.firstName,
          lastName: v.lastName,
          location: v.location,
          phones: tempPhones,
          avatarUrl: v.profilePhoto,
          sumary: v.sumary,
          title: v.position,
          websites: tempWebsites,
          customPath: (v.customPath ?? "").replace(`${Environment.host}/`, ""),
          socialNetworks: socialNetwork,
          backgroundUrl: v.backgroundUrl,
          brandLogoUrl: v.brandUrl,
        });
      } else {
        onChage({
          template: v.template,
          contentStyle: v.contentStyle,
          done: false,
          company: v.companyName,
          emails: tempEmails,
          firstName: v.firstName,
          lastName: v.lastName,
          location: v.location,
          phones: tempPhones,
          avatarUrl: v.profilePhoto,
          sumary: v.sumary,
          title: v.position,
          websites: tempWebsites,
          customPath: (v.customPath ?? "").replace(`${Environment.host}/`, ""),
          socialNetworks: socialNetwork,
          backgroundUrl: v.backgroundUrl,
          brandLogoUrl: v.brandUrl,
        });
      }
      setState({ ...v });
    });
  }, []);

  useEffect(() => {
    if (initData !== null) {
      _bloc.state.template = initData?.data.template ?? "0";
      _bloc.state.contentStyle =
        initData?.data.contentStyle ?? _bloc.state.contentStyle;
      _bloc.state.companyName = initData?.data.company ?? "";
      _bloc.state.location = initData?.data.location ?? "";
      _bloc.state.firstName = initData?.data.firstName ?? "";
      _bloc.state.lastName = initData?.data.lastName ?? "";
      _bloc.state.sumary = initData?.data.summary ?? "";
      _bloc.state.position = initData?.data.title ?? "";
      _bloc.state.profilePhoto = initData?.data.avatarUrl ?? "";
      _bloc.state.backgroundUrl = initData?.data.backgroundUrl ?? "";
      _bloc.state.brandUrl = initData?.data.brandLogoUrl ?? "";
      _bloc.state.customPath =
        initData?.data.qrContent ?? _bloc.state.customPath;

      _bloc.state.phone = initData?.data?.phones
        ? initData?.data?.phones.map((d) => {
            return {
              key: d + Math.random(),
              value: d,
            };
          }) ?? []
        : [
            {
              key: "default-phone",
              value: "",
            },
          ];
      _bloc.state.email = initData?.data?.emails
        ? initData?.data?.emails.map((d) => {
            return {
              key: d + Math.random(),
              value: d,
            };
          }) ?? []
        : [
            {
              key: "default-email",
              value: "",
            },
          ];
      _bloc.state.website =
        initData?.data?.websites && initData.data.websites.length > 0
          ? initData?.data?.websites.map((d) => {
              return {
                key: d + Math.random(),
                value: d,
              };
            }) ?? []
          : [
              {
                key: "default-wesite",
                value: "",
              },
            ];
      _bloc.state.socialNetworks =
        initData?.data?.socialNetworks &&
        initData.data.socialNetworks.length > 0
          ? initData?.data?.socialNetworks.map((d) => {
              return {
                id: Date.now().toString() + Math.random(),
                text: d.text,
                type: d.type,
                url: d.url,
              };
            }) ?? []
          : [
              {
                id: Date.now().toString(),
                type: SocialNetworkType.FACEBOOK,
                text: "Trang cá nhân",
                url: "https://www.facebook.com/",
              },
            ];
      _bloc.upDateState();
    }
  }, [initData]);

  return (
    <div className="create-qr-vcard">
      <Expanded opended title="Template">
        <div className="create-qr-vcard__list-template">
          {dataTemplate.map((d, i) => {
            return (
              <div
                onClick={() => {
                  _bloc.state.template = `${i}`;
                  _bloc.upDateState();
                }}
                key={`template_${d}`}
                className={`create-qr-vcard__list-template__template${
                  _bloc.state.template === `${i}` ? "--sected" : ""
                }`}
              >
                <img
                  src={`${Environment.host}/images/vcard-templates/template_${i}.svg`}
                  style={{ width: "100%" }}
                />
              </div>
            );
          })}
        </div>
      </Expanded>

      {/* color */}
      <Expanded opended title="Color">
        <div className="create-qr-vcard__list-content-color">
          {dataColor.map((d, i) => {
            return (
              <div
                onClick={() => {
                  _bloc.state.contentStyle.primaryColor = d[0];
                  _bloc.state.contentStyle.secondaryColor = d[1];
                  _bloc.upDateState();
                }}
                key={`color${d[0]}-${d[1]}-${i}`}
                className={`create-qr-vcard__list-content-color__color${
                  _bloc.state.contentStyle.primaryColor === d[0] &&
                  _bloc.state.contentStyle.secondaryColor === d[1]
                    ? "--sected"
                    : ""
                }`}
              >
                <div
                  className="create-qr-vcard__list-content-color__color__element"
                  style={{ backgroundColor: d[0] }}
                />
                <div
                  className="create-qr-vcard__list-content-color__color__element"
                  style={{ backgroundColor: d[1] }}
                />
              </div>
            );
          })}
        </div>

        {/* color */}
        <div
          className="row"
          style={{
            width: "100%",
            gap: "12px",
            alignItems: "flex-end",
          }}
        >
          <InputForm2
            isDisable
            styleWrap={{
              maxWidth: "220px",
            }}
            styleInput={{
              backgroundColor: "#FFFFFF",
              color: "#333333",
            }}
            defaultValue={_bloc.state.contentStyle.primaryColor}
            title="Primary"
            suffix={
              <>
                <ColorPicker
                  onChangeComplete={(v) => {
                    _bloc.state.contentStyle.primaryColor = v.toHexString();
                    _bloc.upDateState();
                  }}
                  defaultValue={_bloc.state.contentStyle.primaryColor}
                  style={{
                    margin: "auto 8px",
                    border: "none",
                  }}
                />
              </>
            }
          />
          <div
            className="row"
            style={{
              width: "100%",
              flex: "1",
              justifyContent: "center",
            }}
          >
            <button
              className="button-primary-outline"
              onClick={() => {
                const temp = _bloc.state.contentStyle.secondaryColor;
                _bloc.state.contentStyle.secondaryColor =
                  _bloc.state.contentStyle.primaryColor;
                _bloc.state.contentStyle.primaryColor = temp;
                _bloc.upDateState();
              }}
              style={{
                padding: "10px",
              }}
            >
              <IconSwapX20 />
            </button>
          </div>
          <InputForm2
            isDisable
            styleWrap={{
              maxWidth: "220px",
            }}
            styleInput={{
              backgroundColor: "#FFFFFF",
              color: "#333333",
            }}
            defaultValue={_bloc.state.contentStyle.secondaryColor}
            title="Secondary"
            suffix={
              <>
                <ColorPicker
                  onChangeComplete={(v) => {
                    _bloc.state.contentStyle.secondaryColor = v.toHexString();
                    _bloc.upDateState();
                  }}
                  defaultValue={_bloc.state.contentStyle.secondaryColor}
                  disabledAlpha
                  style={{
                    margin: "auto 8px",
                    border: "none",
                  }}
                />
              </>
            }
          />
        </div>
      </Expanded>

      {/* font */}
      <Expanded opended title="Font">
        <div
          className="row"
          style={{
            width: "100%",
            gap: "12px",
            alignItems: "flex-end",
            justifyContent: "space-between",
          }}
        >
          <InputSelector
            title="Title :"
            isFullwidth
            onChange={(v) => {
              state.contentStyle.titleFont = v;
              _bloc.upDateState();
            }}
            defaultValue={state.contentStyle.titleFont}
            data={dataFont}
          />
          <InputSelector
            title="Text :"
            isFullwidth
            onChange={(v) => {
              state.contentStyle.contentFont = v;
              _bloc.upDateState();
            }}
            defaultValue={state.contentStyle.contentFont}
            data={dataFont}
          />
        </div>
      </Expanded>

      {/* vcard-infomation */}
      <Expanded opended title="Vcard Information">
        <div
          className="column"
          style={{
            width: "100%",
            gap: "12px",
            alignItems: "flex-start",
            justifyContent: "flex-start",
          }}
        >
          <span
            className="text-18--md"
            style={{
              color: "#333333",
            }}
          >
            About you
          </span>
          <div
            className="column"
            style={{
              width: "100%",
              gap: "8px",
              alignItems: "flex-start",
            }}
          >
            <span
              className="text-14--reg"
              style={{
                color: "#333333",
              }}
            >
              Profile Photo{" "}
              <span
                style={{
                  color: "#F05252",
                }}
              >
                {" * "}
              </span>
              :
            </span>
            <InputImageCrop
              defauleImage={state.profilePhoto}
              onChange={({ data }) => {
                _bloc.state.profilePhoto = data;
                _bloc.upDateState();
              }}
            />
          </div>
          {state.template === "1" && (
            <div
              className="column"
              style={{
                width: "100%",
                gap: "8px",
                alignItems: "flex-start",
              }}
            >
              <span
                className="text-14--reg"
                style={{
                  color: "#333333",
                }}
              >
                Background{" "}
                <span
                  style={{
                    color: "#F05252",
                  }}
                >
                  {" * "}
                </span>
                :
              </span>
              <InputImageCrop
                defauleImage={_bloc.state.backgroundUrl}
                onChange={({ data }) => {
                  _bloc.state.backgroundUrl = data;
                  _bloc.upDateState();
                }}
              />
            </div>
          )}
          <div
            className="row"
            style={{
              width: "100%",
              gap: "12px",
            }}
          >
            <InputForm2
              defaultValue={state.firstName}
              onChange={(v) => {
                _bloc.state.firstName = v;
                _bloc.upDateState();
              }}
              required
              title="First name"
              placeHolder="Enter your first name"
            />
            <InputForm2
              defaultValue={state.lastName}
              onChange={(v) => {
                _bloc.state.lastName = v;
                _bloc.upDateState();
              }}
              required
              title="Last name"
              placeHolder="Enter your last name"
            />
          </div>
          <div
            className="row"
            style={{
              width: "100%",
              gap: "12px",
            }}
          >
            <InputForm2
              defaultValue={state.companyName}
              onChange={(v) => {
                _bloc.state.companyName = v;
                _bloc.upDateState();
              }}
              title="Company"
              placeHolder="E.g: Company LLC"
            />
            <InputForm2
              defaultValue={state.position}
              onChange={(v) => {
                _bloc.state.position = v;
                _bloc.upDateState();
              }}
              title="Position"
              placeHolder="E.g: your Profession/Position"
            />
          </div>
          <InputTextArea
            maxLenght={170}
            defaultValue={state.sumary}
            onChange={(v) => {
              _bloc.state.sumary = v;
              _bloc.upDateState();
            }}
            placeHolder="A brief description about you and your skills"
            isFullwidth
            title="Summary (Max 170 characters)"
          />
        </div>

        {/* contact info */}
        <div
          className="column"
          style={{
            width: "100%",
            gap: "12px",
            alignItems: "flex-start",
            justifyContent: "flex-start",
          }}
        >
          <span
            className="text-18--md"
            style={{
              color: "#333333",
            }}
          >
            Contact info
          </span>
          {/* block phone */}
          <div
            key={_bloc.state.phone[0].key}
            className="row"
            style={{
              width: "100%",
              gap: "12px",
              alignItems: "flex-end",
            }}
          >
            <InputForm2
              checkNumber
              defaultValue={_bloc.state.phone[0].value}
              onChange={(v) => {
                _bloc.state.phone[0].value = v;
                _bloc.upDateState();
              }}
              placeHolder="E.g: 123@gmail.com"
              title="Phone"
              required
            />
            <button
              disabled
              className="button-primary-outline"
              style={{ padding: "10px" }}
            >
              <IconTrashX20 />
            </button>
          </div>
          {state.phone.map((phone, i) => {
            if (i > 0) {
              return (
                <div
                  key={`email-${i}-${phone.key}`}
                  className="row"
                  style={{
                    width: "100%",
                    gap: "12px",
                    alignItems: "flex-end",
                  }}
                >
                  <InputForm2
                    checkNumber
                    defaultValue={_bloc.state.phone[i].value}
                    onChange={(v) => {
                      _bloc.state.phone[i].value = v;
                      _bloc.upDateState();
                    }}
                    placeHolder="E.g: 123@gmail.com"
                  />
                  <button
                    onClick={() => {
                      _bloc.state.phone.splice(i, 1);
                      _bloc.upDateState();
                    }}
                    className="button-primary-outline"
                    style={{ padding: "10px" }}
                  >
                    <IconTrashX20 />
                  </button>
                </div>
              );
            }
          })}
          <button
            onClick={() => {
              _bloc.state.phone.push({
                key: Date.now().toString(),
                value: "",
              });
              _bloc.upDateState();
            }}
            className="button-primary-outline"
            style={{
              width: "100%",
              borderStyle: "dashed",
            }}
          >
            <IconAddX20 color="#333333" />
            <span>Add Phone</span>
          </button>
          <div
            style={{ width: "100%", height: "1px", backgroundColor: "#D9D9D9" }}
          />
          {/* block phone */}

          {/* block email */}
          <div
            key={_bloc.state.email[0].key}
            className="row"
            style={{
              width: "100%",
              gap: "12px",
              alignItems: "flex-end",
            }}
          >
            <InputForm2
              defaultValue={_bloc.state.email[0].value}
              onChange={(v) => {
                _bloc.state.email[0].value = v;
                _bloc.upDateState();
              }}
              placeHolder="E.g: 123@gmail.com"
              title="Email"
              required
            />
            <button
              disabled
              className="button-primary-outline"
              style={{ padding: "10px" }}
            >
              <IconTrashX20 />
            </button>
          </div>
          {state.email.map((email, i) => {
            if (i > 0) {
              return (
                <div
                  key={`email-${i}-${email.key}`}
                  className="row"
                  style={{
                    width: "100%",
                    gap: "12px",
                    alignItems: "flex-end",
                  }}
                >
                  <InputForm2
                    defaultValue={_bloc.state.email[i].value}
                    onChange={(v) => {
                      _bloc.state.email[i].value = v;
                      _bloc.upDateState();
                    }}
                    placeHolder="E.g: 123@gmail.com"
                  />
                  <button
                    onClick={() => {
                      _bloc.state.email.splice(i, 1);
                      _bloc.upDateState();
                    }}
                    className="button-primary-outline"
                    style={{ padding: "10px" }}
                  >
                    <IconTrashX20 />
                  </button>
                </div>
              );
            }
          })}
          <button
            onClick={() => {
              _bloc.state.email.push({
                key: Date.now().toString(),
                value: "",
              });
              _bloc.upDateState();
            }}
            className="button-primary-outline"
            style={{
              width: "100%",
              borderStyle: "dashed",
            }}
          >
            <IconAddX20 color="#333333" />
            <span>Add email</span>
          </button>
          <div
            style={{ width: "100%", height: "1px", backgroundColor: "#D9D9D9" }}
          />
          {/* block email */}

          {/* block website */}
          <div
            key={_bloc.state.website[0].key}
            className="row"
            style={{
              width: "100%",
              gap: "12px",
              alignItems: "flex-end",
            }}
          >
            <InputForm2
              defaultValue={_bloc.state.website[0].value}
              onChange={(v) => {
                _bloc.state.website[0].value = v;
                _bloc.upDateState();
              }}
              placeHolder="E.g: 123@gmail.com"
              title="Website"
            />
            <button
              disabled
              className="button-primary-outline"
              style={{ padding: "10px" }}
            >
              <IconTrashX20 />
            </button>
          </div>
          {state.website.map((website, i) => {
            if (i > 0) {
              return (
                <div
                  key={`email-${i}-${website.key}`}
                  className="row"
                  style={{
                    width: "100%",
                    gap: "12px",
                    alignItems: "flex-end",
                  }}
                >
                  <InputForm2
                    defaultValue={_bloc.state.website[i].value}
                    onChange={(v) => {
                      _bloc.state.website[i].value = v;
                      _bloc.upDateState();
                    }}
                    placeHolder="E.g: 123@gmail.com"
                  />
                  <button
                    onClick={() => {
                      _bloc.state.website.splice(i, 1);
                      _bloc.upDateState();
                    }}
                    className="button-primary-outline"
                    style={{ padding: "10px" }}
                  >
                    <IconTrashX20 />
                  </button>
                </div>
              );
            }
          })}
          <button
            onClick={() => {
              _bloc.state.website.push({
                key: Date.now().toString(),
                value: "",
              });
              _bloc.upDateState();
            }}
            className="button-primary-outline"
            style={{
              width: "100%",
              borderStyle: "dashed",
            }}
          >
            <IconAddX20 color="#333333" />
            <span>Add website</span>
          </button>
          <div
            style={{ width: "100%", height: "1px", backgroundColor: "#D9D9D9" }}
          />
          {/* block website */}
        </div>

        {/* location */}
        <div
          className="column"
          style={{
            width: "100%",
            gap: "12px",
            alignItems: "flex-start",
            justifyContent: "flex-start",
          }}
        >
          <span
            className="text-18--md"
            style={{
              color: "#333333",
            }}
          >
            Location
          </span>
          <InputForm2
            defaultValue={state.location}
            onChange={(v) => {
              _bloc.state.location = v;
              _bloc.upDateState();
            }}
            placeHolder="Enter your address"
          />
        </div>
      </Expanded>

      {/* Social network */}
      <Expanded opended title="Social Network">
        {BuildSocialLinkList}
        <div className="create-qr-vcard__list-social-network-type">
          {Object.keys(SocialNetworkType).map((d) => {
            return (
              <div
                onClick={() => {
                  if (state.socialNetworks.length == 6) {
                    return;
                  }
                  state.socialNetworks.push({
                    id: Date.now().toString(),
                    text: "",
                    url: "",
                    type: SocialNetworkType[
                      d as keyof typeof SocialNetworkType
                    ],
                  });
                  _bloc.upDateState();
                }}
                key={d}
                className="create-qr-vcard__list-social-network-type__item"
              >
                <img
                  style={{ width: "32px", height: "32px" }}
                  alt=""
                  src={`${
                    Environment.host
                  }/icons/ic-${d.toLowerCase()}-x32.svg`}
                />
              </div>
            );
          })}
        </div>
      </Expanded>

      {/* url config */}
      <Expanded opended title="URL Config">
        <span
          className="text-14--reg"
          style={{
            color: "rgba(133, 133, 133, 1)",
          }}
        >
          Tùy chỉnh định danh địa chỉ của bạn trên qrid.com.
        </span>
        <div
          className="row"
          style={{
            gap: "8px",
          }}
        >
          <Switch
            defaultChecked={_bloc.state.autoGenURL}
            disabled={(initData?.id ?? "") !== ""}
            checked={state.autoGenURL}
            onChange={(e) => {
              _bloc.state.autoGenURL = e;
              if (e) {
                _bloc.state.customPath =
                  Environment.host + "/" + `${uuidv4().substring(0, 7)}`;
              }
              _bloc.upDateState();
            }}
          />
          <span
            className="text-14--reg"
            style={{ color: "rgba(51, 51, 51, 1)" }}
          >
            Tạo tự động
          </span>
        </div>
        <InputForm2
          defaultValue={_bloc.state.customPath}
          isDisable={_bloc.state.autoGenURL}
          title="URL"
          required
          onChange={(v) => {
            _bloc.state.customPath = v;
            _bloc.upDateState();
          }}
          isCheckError
          errorReport
          defaultErrorLabel={state.errorCustomPath}
          valid={(v) => {
            if (v.length === 0) {
              return "Vui lòng không để trống";
            }
            if (v.replace(`${Environment.host}/`, "").includes(" ")) {
              return "Vui lòng không để khoảng trống";
            }
            if (!v.startsWith(Environment.host + "/")) {
              return "URl không hợp lệ";
            }
            if (
              v.startsWith(Environment.host + "/") &&
              v.replace(`${Environment.host}/`, "").length < 2
            ) {
              return "URl không hợp lệ";
            }
            return "";
          }}
          onError={(e) => {
            _bloc.state.errorCustomPath = e;
            _bloc.upDateState();
          }}
        />
      </Expanded>
    </div>
  );
};
