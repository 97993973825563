import { dataURLtoFile } from "./fileUtils";

export function checkTypeImg(expected: string[], input: string): boolean {
  return expected.includes(input);
}

export function isImage(url: string) {
  return /\.(jpg|jpeg|png|webp|avif|gif|svg)$/.test(url);
}

export function isResponseImage(type: string) {
  return /\/(jpg|jpeg|png|webp|avif|gif|svg)$/.test(type);
}

export function createImage(url: string) {
  return new Promise<HTMLImageElement>((resolve, reject) => {
    const image = new Image();
    image.addEventListener("load", () => resolve(image));
    image.addEventListener("error", (error) => reject(error));
    image.setAttribute("crossOrigin", "anonymous"); // needed to avoid cross-origin issues on CodeSandbox
    image.src = url;
  });
}

export async function cropImage(
  imageSrc: string,
  imageName: string,
  tag: string,
  pixelCrop: {
    width: number;
    height: number;
    x: number;
    y: number;
  },
): Promise<{
  image: File;
  dataImage: string;
} | null> {
  const image = await createImage(imageSrc);
  const scaleX = image.naturalWidth / image.width;
  const scaleY = image.naturalHeight / image.height;
  const pixelRatio = window.devicePixelRatio;

  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d");

  if (!ctx) {
    return null;
  }
  canvas.width = pixelCrop.width * pixelRatio;
  canvas.height = pixelCrop.height * pixelRatio;
  ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
  ctx.imageSmoothingQuality = "high";
  ctx.imageSmoothingEnabled = true;

  // draw rotated image
  ctx.drawImage(
    image,
    pixelCrop.x * scaleX,
    pixelCrop.y * scaleY,
    pixelCrop.width * scaleX,
    pixelCrop.height * scaleY,
    0,
    0,
    pixelCrop.width,
    pixelCrop.height,
  );

  // croppedAreaPixels values are bounding box relative
  // extract the cropped image using these values
  // const data = ctx.getImageData(
  //   pixelCrop.x,
  //   pixelCrop.y,
  //   pixelCrop.width,
  //   pixelCrop.height,
  // );
  // set canvas width to final desired crop size - this will clear existing context
  // canvas.width = pixelCrop.width;
  // canvas.height = pixelCrop.height;

  // paste generated rotate image at the top left corner
  // ctx.putImageData(data, 0, 0);

  // As File and base64
  return {
    image: dataURLtoFile(canvas.toDataURL(`image/${tag}`), imageName),
    dataImage: canvas.toDataURL(`image/${tag}`),
  };
}
