import { CSSProperties, FC, useId } from "react";
import "./input-file.css";

interface InputFileCustomProps {
  onChange: (dataUrl: string, file: File) => void;
  accept?: string;
  children?: React.ReactNode;
  classname?: string;
  style?: CSSProperties;
}

export const InputFileCustom: FC<InputFileCustomProps> = ({
  onChange,
  accept,
  children,
  classname,
  style,
}) => {
  const id = useId();

  function loadFile(event: any) {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onload = function (e) {
      if (e.target !== null && e.target.result !== null) {
        if (e.target.result.toString().split(",").length > 1) {
          onChange(e.target?.result.toString(), file);
          const tmp = document.getElementById(id) as HTMLInputElement | null;
          if (tmp) {
            tmp.value = "";
          }
        }
      }
    };
    reader.readAsDataURL(file);
  }
  function dropHandler(ev: any) {
    ev.preventDefault();
    if (
      ev.dataTransfer.items &&
      ev.dataTransfer.items.length > 0 &&
      ev.dataTransfer.items[0].kind === "file"
    ) {
      const file = ev.dataTransfer.items[0].getAsFile();
      const reader = new FileReader();
      reader.onload = function (e) {
        if (e.target !== null && e.target.result !== null) {
          if (e.target.result.toString().split(",").length > 1) {
            onChange(e.target?.result.toString(), file);
            const tmp = document.getElementById(id) as HTMLInputElement | null;
            if (tmp) {
              tmp.value = "";
            }
          }
        }
      };
      reader.readAsDataURL(file);
    } else {
      [...ev.dataTransfer.files].forEach((file, i) => {
        console.debug(`… file[${i}].name = ${file.name}`);
      });
    }
  }

  function dragOverHandler(ev: any) {
    ev.preventDefault();
  }
  return (
    <label
      style={Object.assign(
        {
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "flex-start",
          flexDirection: "column",
          position: "relative",
        },
        style,
      )}
      htmlFor={id}
      className={classname}
      onDragOver={dragOverHandler}
      onDrop={dropHandler}
    >
      {children}
      <input
        id={id}
        className="input-file-custom"
        type="file"
        accept={accept}
        onChange={loadFile}
      />
    </label>
  );
};

// export const InputImageCustom = ({
//   classname,
//   onChange,
//   title,
//   accept,
//   content,
// }: {
//   classname?: string;
//   title?: string;
//   onChange: (image: string, file: File) => void;
//   accept?: string;
//   content?: React.ReactNode;
// }) => {
//   const loadFile = function (event: any) {
//     const file = event.target.files[0];
//     const reader = new FileReader();
//     reader.onload = function (e) {
//       if (e.target !== null && e.target.result !== null) {
//         if (e.target.result.toString().split(",").length > 1) {
//           onChange(e.target?.result.toString(), file);
//         }
//       }
//     };
//     reader.readAsDataURL(file);
//   };

//   return (
//     <label className={`${classname ?? "wrap-input-file-custom"}`}>
//       <input
//         className="input-file-custom"
//         type="file"
//         accept={accept}
//         onChange={loadFile}
//       />
//       {title !== undefined && (
//         <span
//           style={{
//             cursor: "pointer",
//             color: "var(--color-global-brand-90)",
//           }}
//         >
//           {title}
//         </span>
//       )}
//       {content !== undefined && <>{content}</>}
//     </label>
//   );
// };
