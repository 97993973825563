import { InputForm2 } from "@/components/input-form/InputForm2";
import "./style.less";
import { useQuery } from "@tanstack/react-query";
import QrPageLoading from "@/components/page-loading/QrPageLoading";
import { BaseBloc } from "@/services/base-bloc";
import { VietQrBankModel } from "@/apis/qrfy/models/VietQrBankModel";
import axios from "axios";
import { InputSelectorCustom } from "@/components/input-selector/InputSelector";
import { useAppBloc } from "@/contexts/AppContext";
import { IconCloseX12 } from "@/components/icons/IconCloseX12";
import { FormCreateVietQr } from "./interfaces/FormCreateVietQr";
import { useEffect, useMemo, useState } from "react";
import { distinct } from "rxjs";
import { QRStyleModel, QrCodeModel } from "@/apis/qrfy/models/QrModel";
import { VietQrModel } from "@/apis/qrfy/models/VietQrModel";
import { Expanded } from "@/components/expanded/Expanded";
import { TemplateModel, TemplateType } from "@/apis/qrfy/models/TemplateModel";
import QRCodeStyling, {
  CornerDotType,
  CornerSquareType,
  DotType,
  ShapeType,
} from "qr-code-styling";

interface CreateVietQrState {
  banks: VietQrBankModel[];
  accountNumber: string;
  accountName: string;
  bankSelected: VietQrBankModel | null;
  bankSearchValue: string;
  amount: string;
  content: string;
  templateSelected: TemplateModel | null;
  qrImage: string;
  errors: {
    accountNumber: string;
    bankSelected: string;
    accountName: string;
  };
}

interface CreateVietQrProps {
  initData?: QrCodeModel<VietQrModel> | null;
  onChage: (v: FormCreateVietQr) => void;
}

export const CreateVietQr: React.FC<CreateVietQrProps> = ({
  onChage,
  initData = null,
}) => {
  const { appBloc } = useAppBloc();
  const _bloc = useMemo<BaseBloc<CreateVietQrState>>(() => {
    return new BaseBloc<CreateVietQrState>({
      initState: {
        qrImage: "",
        templateSelected: null,
        accountNumber: "",
        amount: "",
        banks: [],
        bankSearchValue: "",
        content: "",
        errors: {
          accountNumber: "",
          bankSelected: "",
          accountName: "",
        },
        bankSelected: null,
        accountName: "",
      },
    });
  }, []);
  const [state, setState] = useState<CreateVietQrState>(_bloc.state);
  const qrStyling = new QRCodeStyling({
    type: "canvas",
    margin: 0,
    height: 512,
    width: 512,
    imageOptions: {
      crossOrigin: "anonymous",
      imageSize: 0.25,
      margin: 0,
      hideBackgroundDots: true,
    },
    qrOptions: {
      errorCorrectionLevel: "H",
      typeNumber: 10,
    },
  });

  const { data: dataBank, isLoading: isLoadingDataBank } = useQuery({
    queryKey: ["get-data-viet-qr-bank"],
    queryFn: async () => {
      const ret = (await axios.get("https://api.vietqr.io/v2/banks")).data
        .data as VietQrBankModel[] | undefined;
      _bloc.state.banks = ret ?? [];
      if (initData !== null) {
        _bloc.state.bankSelected =
          [...(ret ?? [])].find(
            (e) => e.bin.toString() === initData.data.bankBin,
          ) ?? null;
      }
      _bloc.upDateState();
      return ret ?? [];
    },
  });

  const { data: dataTemplate, isLoading: isLoadingTemplate } = useQuery({
    queryKey: ["get-templates-viet-qr"],
    queryFn: async () => {
      let ret = await appBloc.repository.qrfyRepo.template.listTemplate();
      ret = [...ret].filter((e) => e.type === TemplateType.VIET_QR);
      if (initData) {
        const tmp = ret.filter((e) => e.id === initData.data.templateId);
        if (tmp.length > 0) {
          _bloc.state.templateSelected = tmp[0];
        }
        _bloc.state.accountNumber = initData.data.accountNo ?? "";
        _bloc.state.accountName = initData.data.accountName ?? "";
        _bloc.state.amount = `${initData.data.amount ?? ""}`;
        _bloc.state.content = initData.data.content ?? "";
        if (initData.qrStyle) {
          mapPingAttributeCanvas(
            initData.data.qrContent ?? "",
            initData.qrStyle,
          );
          const rawQrData = await qrStyling.getRawData("jpeg");
          if (rawQrData) {
            _bloc.state.qrImage = URL.createObjectURL(rawQrData);
          }
        }
      } else if (ret.length > 0) {
        _bloc.state.templateSelected = ret[0];
      }
      _bloc.upDateState();
      return ret ?? [];
    },
  });

  async function mapPingAttributeCanvas(
    qrContent: string,
    qrStyle: QRStyleModel,
  ) {
    qrStyling.update({
      data: qrContent,
      image: qrStyle?.image,
      cornersDotOptions: {
        color: qrStyle.corner.dotColor,
        type: qrStyle.corner.dotStyle as CornerDotType,
      },
      cornersSquareOptions: {
        color: qrStyle.corner.squareColor,
        type: qrStyle.corner.squareStyle as CornerSquareType,
      },
      dotsOptions: {
        color: qrStyle.dot.color.fromColor,
        type: qrStyle.dot.style as DotType,
      },
      backgroundOptions: {
        color: qrStyle.shape.backgroundColor,
      },
      shape: qrStyle.shape.style as ShapeType,
    });
  }

  useEffect(() => {
    _bloc.stream.pipe(distinct()).subscribe((v) => {
      let check = true;
      if (v.bankSelected === null) {
        check = false;
        v.errors.bankSelected = "Vui lòng chọn 1 ngân hàng";
      } else {
        v.errors.bankSelected = "";
      }
      if (v.accountNumber === "") {
        check = false;
      }
      if (v.accountName === "") {
        check = false;
      }
      if (v.templateSelected === null) {
        check = false;
      }
      if (v && check) {
        onChage({
          accountNo: v.accountNumber,
          bankBin: v.bankSelected?.bin ? `${v?.bankSelected?.bin ?? ""}` : "",
          done: true,
          amount: v.amount ? parseInt(v.amount) : undefined,
          content: v.content,
          logo: v.bankSelected?.logo ?? "",
          bankName: v.bankSelected?.name ?? "",
          accountName: v.accountName,
          template: v.templateSelected,
          qrImage: v.qrImage,
        });
      } else {
        onChage({
          accountNo: v.accountNumber,
          bankBin: v.bankSelected?.bin ? `${v?.bankSelected?.bin ?? ""}` : "",
          done: false,
          amount: v.amount ? parseInt(v.amount) : undefined,
          content: v.content,
          logo: v.bankSelected?.logo ?? "",
          bankName: v.bankSelected?.name ?? "",
          accountName: v.accountName,
          template: v.templateSelected,
          qrImage: v.qrImage,
        });
      }
      setState({ ...v });
    });
  }, []);

  if (isLoadingDataBank || isLoadingTemplate) {
    return <QrPageLoading />;
  }

  return (
    <>
      <Expanded opended title="Template">
        <div className="create-vietqr__list-template">
          {dataTemplate &&
            dataTemplate?.map((d, i) => {
              return (
                <div
                  onClick={() => {
                    _bloc.state.templateSelected = d;
                    _bloc.upDateState();
                  }}
                  key={`template_${d}_${i}`}
                  className={`create-vietqr__list-template__template${
                    _bloc.state.templateSelected?.id === `${d.id}`
                      ? "--sected"
                      : ""
                  }`}
                >
                  <img src={`${d.content}`} style={{ width: "100%" }} />
                </div>
              );
            })}
        </div>
      </Expanded>
      <div className="create-vietqr">
        <span className="text-16--md" style={{ color: "#333333" }}>
          VietQR Information
        </span>
        <div
          className="row"
          style={{
            width: "100%",
            alignItems: "flex-start",
            gap: "12px",
          }}
        >
          <InputSelectorCustom
            required
            prefix={
              <img
                style={{
                  display: _bloc.state?.bankSelected?.logo ? "flex" : "none",
                  paddingLeft: "2px",
                  height: "28px",
                  aspectRatio: _bloc.state?.bankSelected?.logo ? 831 / 311 : 1,
                }}
                alt=""
                src={_bloc.state?.bankSelected?.logo}
              />
            }
            isSuffixDivider
            height={40}
            isFullwidth
            searchFn={async (v) => {
              _bloc.state.bankSearchValue = v;
              _bloc.state.banks = [...(dataBank ?? [])].filter(
                (e) =>
                  e.name.toLowerCase().includes(v.toLowerCase()) ||
                  e.shortName.toLowerCase().includes(v.toLowerCase()) ||
                  e.code.toLowerCase().includes(v.toLowerCase()) ||
                  `${e.bin}`.toLowerCase().includes(v.toLowerCase()),
              );
              return _bloc.state.banks;
            }}
            allowSearch
            searchDebound={0}
            defaultValue={_bloc.state.bankSelected ?? undefined}
            title={"Ngân hàng"}
            data={dataBank ?? []}
            placeHolder={"Chọn ngân hàng "}
            iconClear={<IconCloseX12 />}
            onChange={(v, key) => {
              _bloc.state.errors.bankSelected = "";
              if (v.length === 0 && _bloc.state.bankSelected) {
                _bloc.state.bankSelected = null;
                return _bloc.upDateState();
              }
              if (v.length > 0) {
                appBloc.session.isLoading.next(true);
                const tmp =
                  _bloc.state.banks.filter((e) => e.id === parseInt(key)) ?? [];
                if (tmp.length > 0) {
                  _bloc.state.bankSelected = tmp[0];
                  _bloc.upDateState();
                }
                appBloc.session.isLoading.next(false);
              }
            }}
            onClear={() => {
              _bloc.state.bankSearchValue = "";
              _bloc.state.errors.bankSelected = "";
              _bloc.state.banks = [...(dataBank ?? [])];
              _bloc.state.bankSelected = null;
              _bloc.upDateState();
            }}
            errorLabel={_bloc.state.errors.bankSelected}
          />
          <InputForm2
            defaultValue={state.accountNumber}
            maxLenght={50}
            intercepter={(v) => {
              if (v.length > 50) {
                return true;
              } else {
                return false;
              }
            }}
            valid={(v) => {
              if (v.length === 0) {
                return "Vui lòng không để trống";
              } else {
                return "";
              }
            }}
            onError={(v) => {
              _bloc.state.errors.accountNumber = v;
              _bloc.upDateState();
            }}
            onChange={(v) => {
              _bloc.state.accountNumber = v;
              _bloc.upDateState();
            }}
            required
            title="Beneficiary account"
            isCheckError
          />
        </div>

        <div
          className="row"
          style={{
            width: "100%",
            alignItems: "flex-start",
            gap: "12px",
          }}
        >
          <InputForm2
            defaultValue={state.accountName}
            maxLenght={50}
            intercepter={(v) => {
              if (v.length > 50) {
                return true;
              } else {
                return false;
              }
            }}
            valid={(v) => {
              if (v.length === 0) {
                return "Vui lòng không để trống";
              } else {
                return "";
              }
            }}
            onError={(v) => {
              _bloc.state.errors.accountName = v;
              _bloc.upDateState();
            }}
            onChange={(v) => {
              _bloc.state.accountName = v;
              _bloc.upDateState();
            }}
            required
            title="Account Name"
            isCheckError
          />
          <div style={{ width: "100%" }} />
        </div>

        {/* options */}
        <div
          className="column"
          style={{
            width: "100%",
            backgroundColor: "rgba(247, 247, 247, 1)",
            padding: "20px",
            gap: "20px",
            alignItems: "flex-start",
          }}
        >
          <span className="text-18--md" style={{ color: "#333333" }}>
            Advanced (Optional)
          </span>
          <div
            className="row"
            style={{
              width: "100%",
              gap: "12px",
              alignItems: "flex-start",
            }}
          >
            <InputForm2
              defaultValue={state.amount}
              maxLenght={15}
              intercepter={(v) => {
                if (v.length > 15) {
                  return true;
                } else {
                  return false;
                }
              }}
              formatMoney
              placeHolder="Enter transfer amount"
              onChange={(v) => {
                _bloc.state.amount = v.replaceAll(".", "");
                _bloc.upDateState();
              }}
              title="Transfer amount"
            />
            <InputForm2
              defaultValue={state.content}
              maxLenght={50}
              intercepter={(v) => {
                if (v.length > 50) {
                  return true;
                } else {
                  return false;
                }
              }}
              placeHolder="Enter message"
              onChange={(v) => {
                _bloc.state.content = v;
                _bloc.upDateState();
              }}
              title="Message"
            />
          </div>
        </div>
      </div>
    </>
  );
};
