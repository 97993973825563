import { ErrorModel } from "@/models/ErrorModel";
import { QrfyHttpClient } from "../QrfyRepositories";
import { StaticQrRepository } from "../StaticQrRepository";
import { VietQrModel } from "../models/VietQrModel";
import { QRStyleModel } from "../models/QrModel";
import { WirelessEncrypType, WirelessQrModel } from "../models/WirelessQrModel";
import { StaticWebSiteQrModel } from "../models/StaticWebsiteQrModel";
import { mapTextQrModel } from "../adapters/text-qr-adapter";
import { TextQrModel } from "../models/TextQrModel";

export class StaticQrRepositoryImp implements StaticQrRepository {
  private client: QrfyHttpClient;
  constructor(c: QrfyHttpClient) {
    this.client = c;
  }

  public async createVietQr({
    qrStyle,
    accountNo,
    bankBin,
    qrName,
    amount,
    content,
    accountName,
    templateId,
  }: {
    accountNo: string;
    bankBin: string;
    qrName: string;
    amount?: number;
    content?: string;
    accountName: string;
    templateId: string;
    qrStyle?: QRStyleModel;
  }): Promise<VietQrModel> {
    try {
      const resp = await this.client.staticQr.createVietQr({
        createVietQrRequest: {
          qrName: qrName,
          accountNo: accountNo,
          bankBin: bankBin,
          amount: amount,
          content: content,
          qrStyle: qrStyle,
          accountName: accountName,
          templateId: templateId,
        },
      });
      if (resp.data.code === 0) {
        return {
          accountNo: resp.data.data?.accountNo ?? "",
          amount: resp.data.data?.amount ?? 0,
          bankBin: resp.data.data?.bankBin ?? "",
          content: resp.data.data?.content ?? "",
          id: resp.data.data?.id ?? "",
          qrContent: resp.data.data?.qrContent ?? "",
          qrName: resp.data.data?.qrName ?? "",
          accountName: resp.data.data?.accountName ?? "",
          templateId: resp.data.data?.templateId ?? "",
        };
      } else {
        throw new ErrorModel({
          code: resp.data.code,
          message: resp.data.message,
        });
      }
    } catch (error) {
      throw error;
    }
  }

  public async createStaticWebsite({
    qrStyle,
    url,
    qrName,
  }: {
    url: string;
    qrName: string;
    qrStyle?: QRStyleModel;
  }): Promise<StaticWebSiteQrModel> {
    try {
      const resp = await this.client.staticQr.createStaticWebsite({
        createStaticWebsiteRequest: {
          qrName: qrName,
          url: url,
          qrStyle: qrStyle,
        },
      });
      if (resp.data.code === 0) {
        return {
          id: resp.data.data?.id ?? "",
          qrContent: resp.data.data?.qrContent ?? "",
          qrName: resp.data.data?.qrName ?? "",
          url: resp.data.data?.url ?? "",
        };
      } else {
        throw new ErrorModel({
          code: resp.data.code,
          message: resp.data.message,
        });
      }
    } catch (error) {
      throw error;
    }
  }

  public async createWifi({
    qrStyle,
    qrName,
    encryptType,
    networkName,
    networkPassword,
  }: {
    qrName: string;
    qrStyle?: QRStyleModel;
    encryptType: WirelessEncrypType;
    networkName: string;
    networkPassword: string;
  }): Promise<WirelessQrModel> {
    try {
      const resp = await this.client.staticQr.createWifi({
        createWirelessRequest: {
          qrName: qrName,
          qrStyle: qrStyle,
          encryptType: encryptType,
          networkName: networkName,
          networkPassword: networkPassword,
        },
      });
      if (resp.data.code === 0) {
        return {
          id: resp.data.data?.id ?? "",
          qrContent: resp.data.data?.qrContent ?? "",
          qrName: resp.data.data?.qrName ?? "",
          encryptType:
            WirelessEncrypType[
              resp.data.data?.encryptType as keyof typeof WirelessEncrypType
            ],
          networkName: resp.data.data?.networkName ?? "",
          networkPassword: resp.data.data?.networkPassword ?? "",
        };
      } else {
        throw new ErrorModel({
          code: resp.data.code,
          message: resp.data.message,
        });
      }
    } catch (error) {
      throw error;
    }
  }

  public async createTextQr({
    qrStyle,
    qrName,
    content,
  }: {
    qrName: string;
    qrStyle?: QRStyleModel;
    content: string;
  }): Promise<TextQrModel> {
    try {
      const resp = await this.client.staticQr.createTextQr({
        createTextQrRequest: {
          qrName: qrName,
          qrStyle: qrStyle,
          content: content,
        },
      });
      if (resp.data.code === 0) {
        return mapTextQrModel(resp.data.data);
      } else {
        throw new ErrorModel({
          code: resp.data.code,
          message: resp.data.message,
        });
      }
    } catch (error) {
      throw error;
    }
  }

  public async updateVietQr({
    qrStyle,
    accountNo,
    bankBin,
    qrName,
    amount,
    content,
    accountName,
    templateId,
    id,
  }: {
    accountNo: string;
    bankBin: string;
    qrName: string;
    amount?: number;
    content?: string;
    accountName: string;
    templateId: string;
    qrStyle?: QRStyleModel;
    id: string;
  }): Promise<VietQrModel> {
    try {
      const resp = await this.client.staticQr.updateVietQr({
        updateVietQrRequest: {
          qrName: qrName,
          accountNo: accountNo,
          bankBin: bankBin,
          amount: amount,
          content: content,
          qrStyle: qrStyle,
          accountName: accountName,
          templateId: templateId,
          id: id,
        },
      });
      if (resp.data.code === 0) {
        return {
          accountNo: resp.data.data?.accountNo ?? "",
          amount: resp.data.data?.amount ?? 0,
          bankBin: resp.data.data?.bankBin ?? "",
          content: resp.data.data?.content ?? "",
          id: resp.data.data?.id ?? "",
          qrContent: resp.data.data?.qrContent ?? "",
          qrName: resp.data.data?.qrName ?? "",
          accountName: resp.data.data?.accountName ?? "",
          templateId: resp.data.data?.templateId ?? "",
        };
      } else {
        throw new ErrorModel({
          code: resp.data.code,
          message: resp.data.message,
        });
      }
    } catch (error) {
      throw error;
    }
  }

  public async updateWifi({
    qrStyle,
    qrName,
    encryptType,
    networkName,
    networkPassword,
    id,
  }: {
    qrName: string;
    qrStyle?: QRStyleModel;
    encryptType: WirelessEncrypType;
    networkName: string;
    networkPassword: string;
    id: string;
  }): Promise<WirelessQrModel> {
    try {
      const resp = await this.client.staticQr.updateWifi({
        updateWirelessRequest: {
          qrName: qrName,
          qrStyle: qrStyle,
          networkName: networkName,
          networkPassword: networkPassword,
          encryptType: encryptType,
          id: id,
        },
      });
      if (resp.data.code === 0) {
        return {
          id: resp.data.data?.id ?? "",
          qrContent: resp.data.data?.qrContent ?? "",
          qrName: resp.data.data?.qrName ?? "",
          encryptType:
            WirelessEncrypType[
              resp.data.data?.encryptType as keyof typeof WirelessEncrypType
            ],
          networkName: resp.data.data?.networkName ?? "",
          networkPassword: resp.data.data?.networkPassword ?? "",
        };
      } else {
        throw new ErrorModel({
          code: resp.data.code,
          message: resp.data.message,
        });
      }
    } catch (error) {
      throw error;
    }
  }

  public async updateStaticWebsite({
    qrStyle,
    url,
    qrName,
    id,
  }: {
    url: string;
    qrName: string;
    qrStyle?: QRStyleModel;
    id: string;
  }): Promise<StaticWebSiteQrModel> {
    try {
      const resp = await this.client.staticQr.updateStaticWebsite({
        updateStaticWebsiteRequest: {
          qrName: qrName,
          url: url,
          qrStyle: qrStyle,
          id: id,
        },
      });
      if (resp.data.code === 0) {
        return {
          id: resp.data.data?.id ?? "",
          qrContent: resp.data.data?.qrContent ?? "",
          qrName: resp.data.data?.qrName ?? "",
          url: resp.data.data?.url ?? "",
        };
      } else {
        throw new ErrorModel({
          code: resp.data.code,
          message: resp.data.message,
        });
      }
    } catch (error) {
      throw error;
    }
  }

  public async updateTextQr({
    qrStyle,
    qrName,
    content,
    id,
  }: {
    qrName: string;
    qrStyle?: QRStyleModel;
    content: string;
    id: string;
  }): Promise<TextQrModel> {
    try {
      const resp = await this.client.staticQr.updateTextQr({
        updateTextRequest: {
          qrName: qrName,
          qrStyle: qrStyle,
          content: content,
          id: id,
        },
      });
      if (resp.data.code === 0) {
        return mapTextQrModel(resp.data.data);
      } else {
        throw new ErrorModel({
          code: resp.data.code,
          message: resp.data.message,
        });
      }
    } catch (error) {
      throw error;
    }
  }
}
