import {
  QRStyleModel,
  QrCodeModel,
  QrCodeType,
} from "@/apis/qrfy/models/QrModel";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import {
  icQrTypeVCard,
  icQrTypeVietQr,
  icQrTypeWebsite,
  icQrTypeWifi,
} from "@/services/assets";
import "./style.less";
import StepIndicator from "@/components/step-indicator/StepIndicator";
import { useEffect, useMemo } from "react";
import { BaseBloc } from "@/services/base-bloc";
import StreamBuilder from "@/components/stream-builder/StreamBuilder";
import { ItemOptionQrType } from "./components/ItemOptionQr";
import { PreviewQr } from "./components/PreviewQr";
import { PageWithStep } from "@/components/page-with-step/PageWithStep";
import {
  mapCornerStyleModel,
  mapDotStyleModel,
  mapFrameStyleModel,
  mapShapeStyleModel,
} from "@/apis/qrfy/adapters/qr-adapter";
import { CreateVietQr } from "../create-qr-vietqr";
import { InputForm2 } from "@/components/input-form/InputForm2";
import { DesignQr } from "./components/DesignQr";
import { createQr } from "./composables/createQr";
import { message } from "antd";
import { CreateQrWireless } from "../create-qr-wireless";
import { CreateQrWeb } from "../create-qr-web";
import { useQuery } from "@tanstack/react-query";
import { useAppBloc } from "@/contexts/AppContext";
import PageNotFound from "@/components/page-not-found/PageNotFound";
import QrPageLoading from "@/components/page-loading/QrPageLoading";
import { updateQr } from "./composables/updateQr";
import { CreateQrVCard } from "../create-qr-vcard";
import { NavlinkPath } from "@/services/router-config";

interface CreateQrPageState {
  qrType?: QrCodeType;
  step: number;
  archiveStep: number;
  data?: any;
  dataQrDetail: QrCodeModel<any> | null;
  qrStyle: QRStyleModel;
  qrName?: string;
  qrContent?: string;
}

const CreateQrPage = () => {
  const { appBloc } = useAppBloc();
  const navigate = useNavigate();
  const [searchParam, setSearchParam] = useSearchParams();
  const { qrId } = useParams();
  // const qrId = searchParam.get("qrId") ?? "";

  const _cubit = useMemo<BaseBloc<CreateQrPageState>>(() => {
    return new BaseBloc<CreateQrPageState>({
      initState: {
        dataQrDetail: null,
        data: null,
        step: qrId ? 1 : 0,
        archiveStep: qrId ? 2 : 0,
        qrType:
          searchParam.get("qrType") &&
          Object.keys(QrCodeType).includes(searchParam.get("qrType") ?? "")
            ? QrCodeType[searchParam.get("qrType") as keyof typeof QrCodeType]
            : undefined,
        qrStyle: {
          corner: mapCornerStyleModel(),
          image: "",
          frame: mapFrameStyleModel({
            backgroundColor: { fromColor: "#D9D9D9" },
            color: { fromColor: "#FFFFFF", toColor: "#000000" },
          }),
          shape: mapShapeStyleModel(),
          dot: mapDotStyleModel(),
        },
        qrName: searchParam.get("qrName") ?? "",
        qrContent: "example",
      },
    });
  }, []);

  const { isLoading, isError } = useQuery({
    queryKey: [qrId ?? "get-detail-qr"],
    queryFn: async () => {
      if (!qrId) {
        return null;
      }
      const ret = await appBloc.repository.qrfyRepo.qrCode.getDetail({
        id: qrId ?? "",
      });
      _cubit.state.data = {};
      _cubit.state.data.done = true;
      if (ret.qrStyle) {
        _cubit.state.qrStyle = ret.qrStyle;
      }
      _cubit.state.qrContent = ret.data.qrContent;
      _cubit.state.dataQrDetail = ret ?? null;
      _cubit.upDateState();
      return _cubit.state.dataQrDetail;
    },
    initialData: undefined,
    gcTime: 0,
  });

  function resetToDefault() {
    _cubit.state.qrType = undefined;
    _cubit.state.data = null;
    _cubit.state.step = 0;
    _cubit.state.archiveStep = 0;
    _cubit.state.qrStyle = {
      corner: mapCornerStyleModel(),
      image: "",
      frame: mapFrameStyleModel(),
      shape: mapShapeStyleModel(),
      dot: mapDotStyleModel(),
    };
    searchParam.set("qrType", "");
    setSearchParam(searchParam);
    _cubit.upDateState();
  }

  function resetDataWhenChangeQrType(qrtype: QrCodeType) {
    _cubit.state.qrType = qrtype;
    _cubit.state.data = null;
    _cubit.state.step = 0;
    _cubit.state.archiveStep = 0;
    // _cubit.state.qrStyle = {
    //   corner: mapCornerStyleModel(),
    //   image: "",
    //   frame: mapFrameStyleModel(),
    //   shape: mapShapeStyleModel(),
    //   dot: mapDotStyleModel(),
    // };
    searchParam.set("qrType", qrtype);
    setSearchParam(searchParam);
    _cubit.upDateState();
  }

  useEffect(() => {
    if (!qrId) {
      resetToDefault();
    }
  }, [qrId]);

  if (isError) {
    return <PageNotFound />;
  }
  if (isLoading) {
    return <QrPageLoading />;
  }

  return (
    <div key={qrId} className="create-qr-page animation-faded--in">
      {/* header */}
      <div className="create-qr-page__header">
        <span
          className="text-30--md"
          style={{ color: "#333333", width: "100%", textAlign: "start" }}
        >
          {qrId ? "Chỉnh sửa QR" : "Tạo QR"}
        </span>
        <StreamBuilder<CreateQrPageState, any, string>
          key={qrId ? "Chỉnh sửa QR" : "Tạo QR"}
          context="stream-indicator-create-qr-page"
          stream={_cubit.stream}
          initData={_cubit.state}
          selector={(pre, next) => {
            return (
              pre?.archiveStep !== next?.archiveStep || pre?.step !== next?.step
            );
          }}
        >
          {(context, snap) => {
            return (
              <StepIndicator
                locks={qrId ? [0] : []}
                key={(context ?? "") + (qrId ? "Chỉnh sửa QR" : "Tạo QR")}
                data={["Loại QR", "Nội dung", "Thiết kế"]}
                archivedStep={snap?.data?.archiveStep}
                defaultStep={snap?.data?.step}
                onChange={(v) => {
                  if (v <= (snap?.data?.archiveStep ?? 0)) {
                    _cubit.state.step = v;
                    _cubit.upDateState();
                  }
                }}
              />
            );
          }}
        </StreamBuilder>
      </div>

      {/* body */}
      <div className="create-qr-page__body">
        {/* list qr */}
        <div
          className="column custom-scroll-bar"
          style={{
            width: "100%",
            overflowY: "auto",
            // minHeight: "fit-content",
            height: "100%",
            flex: 1,
          }}
        >
          <StreamBuilder<CreateQrPageState, any, any>
            selector={(pre, next) => {
              return pre?.step !== next?.step || pre?.qrType !== next?.qrType;
            }}
            stream={_cubit.stream}
            initData={_cubit.state}
          >
            {(c, snap) => {
              return (
                <>
                  {/* step list qr */}
                  <PageWithStep step={0} currentStep={snap?.data?.step ?? 0}>
                    <div
                      className="column"
                      style={{
                        width: "100%",
                        gap: "20px",
                        height: "100%",
                      }}
                    >
                      {/* dynamic qr */}
                      <div
                        className="column"
                        style={{
                          width: "100%",
                          gap: "12px",
                          height: "fit-content",
                        }}
                      >
                        {/* title */}
                        <div
                          className="column"
                          style={{
                            width: "100%",
                            alignItems: "flex-start",
                          }}
                        >
                          <span
                            className="text-24--reg row"
                            style={{
                              color: "#333333",
                              alignItems: "center",
                              gap: "12px",
                            }}
                          >
                            <span>QR động</span>
                            <span
                              className="text-12--md"
                              style={{
                                color: "#1849A9",
                                backgroundColor: "#D1E9FF",
                                boxSizing: "border-box",
                                padding: "4px 10px",
                                borderRadius: "100px",
                              }}
                            >
                              Theo dõi
                            </span>
                          </span>
                          <span
                            className="text-14--reg"
                            style={{ color: "#858585" }}
                          >
                            Cập nhật nội dung trong thời gian thực mà không phải
                            thay đổi mã QR của bạn.
                          </span>
                        </div>
                        {/* list options */}
                        <div className="create-qr-page__body__list-type-qr">
                          {/* vcard */}
                          <ItemOptionQrType
                            content="Chia sẻ thông tin liên lạc"
                            isSelected={
                              _cubit.state.qrType === QrCodeType.VCARD
                            }
                            icon={icQrTypeVCard}
                            title="VCard"
                            type={QrCodeType.VCARD}
                            onTap={() => {
                              resetDataWhenChangeQrType(QrCodeType.VCARD);
                            }}
                          />
                          {/* dynamic website */}
                          <ItemOptionQrType
                            content="Truy cập trang web bằng URL"
                            isSelected={
                              _cubit.state.qrType === QrCodeType.WEBSITE
                            }
                            icon={icQrTypeWebsite}
                            title="Website"
                            type={QrCodeType.WEBSITE}
                            onTap={() => {
                              resetDataWhenChangeQrType(QrCodeType.WEBSITE);
                            }}
                          />
                        </div>
                      </div>
                      {/* static qr */}
                      <div
                        className="column"
                        style={{
                          width: "100%",
                          gap: "12px",
                          height: "fit-content",
                        }}
                      >
                        {/* title */}
                        <div
                          className="column"
                          style={{
                            width: "100%",
                            alignItems: "flex-start",
                          }}
                        >
                          <span
                            className="text-24--reg row"
                            style={{
                              color: "#333333",
                              alignItems: "center",
                              gap: "12px",
                            }}
                          >
                            <span>QR tĩnh</span>
                            <span
                              className="text-12--md"
                              style={{
                                color: "#723B13",
                                backgroundColor: "#FDF6B2",
                                boxSizing: "border-box",
                                padding: "4px 10px",
                                borderRadius: "100px",
                              }}
                            >
                              Không theo dõi
                            </span>
                          </span>
                          <span
                            className="text-14--reg"
                            style={{ color: "#858585" }}
                          >
                            Yêu cầu in lại khi cập nhật nội dung.
                          </span>
                        </div>
                        {/* list options */}
                        <div className="create-qr-page__body__list-type-qr">
                          {/* viet qr */}
                          <ItemOptionQrType
                            content="QR code payment"
                            isSelected={
                              _cubit.state.qrType === QrCodeType.STATIC_VIETQR
                            }
                            icon={icQrTypeVietQr}
                            title="VietQR"
                            type={QrCodeType.STATIC_VIETQR}
                            onTap={() => {
                              resetDataWhenChangeQrType(
                                QrCodeType.STATIC_VIETQR,
                              );
                            }}
                          />
                          {/* static website */}
                          <ItemOptionQrType
                            content="Truy cập trang web bằng URL"
                            isSelected={
                              _cubit.state.qrType === QrCodeType.STATIC_WEBSITE
                            }
                            icon={icQrTypeWebsite}
                            title="Website"
                            type={QrCodeType.STATIC_WEBSITE}
                            onTap={() => {
                              resetDataWhenChangeQrType(
                                QrCodeType.STATIC_WEBSITE,
                              );
                            }}
                          />
                          {/* static wifi */}
                          <ItemOptionQrType
                            content="Chia sẻ kết nối Wi-Fi"
                            isSelected={
                              _cubit.state.qrType === QrCodeType.STATIC_WIRELESS
                            }
                            icon={icQrTypeWifi}
                            title="Wi-Fi"
                            type={QrCodeType.STATIC_WIRELESS}
                            onTap={() => {
                              resetDataWhenChangeQrType(
                                QrCodeType.STATIC_WIRELESS,
                              );
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </PageWithStep>

                  {/* step content */}
                  <PageWithStep step={1} currentStep={snap?.data?.step ?? 0}>
                    <div
                      className="column"
                      style={{
                        width: "100%",
                        gap: "20px",
                        alignItems: "flex-start",
                      }}
                    >
                      <div
                        className="column"
                        style={{
                          width: "100%",
                          gap: "12px",
                          alignItems: "flex-start",
                        }}
                      >
                        <span
                          className="text-20--reg"
                          style={{ color: "#333333", height: "40px" }}
                        >
                          Complete the content of QR
                        </span>
                        <InputForm2
                          defaultValue={_cubit.state.qrName}
                          placeHolder="Name of your QR code"
                          onChange={(v) => {
                            _cubit.state.qrName = v;
                            if (
                              _cubit.state.qrName.length === 0 &&
                              _cubit.state.archiveStep === 2
                            ) {
                              _cubit.state.archiveStep = 1;
                            }
                            _cubit.upDateState();
                          }}
                          styleWrap={{ maxWidth: "360px" }}
                        />
                      </div>
                      {(() => {
                        switch (snap?.data?.qrType) {
                          case QrCodeType.STATIC_VIETQR:
                            return (
                              <CreateVietQr
                                initData={_cubit.state.dataQrDetail}
                                onChage={(v) => {
                                  _cubit.state.data = v;
                                  if (
                                    v.done === false &&
                                    _cubit.state.archiveStep === 2
                                  ) {
                                    _cubit.state.archiveStep = 1;
                                  }
                                  _cubit.upDateState();
                                }}
                              />
                            );
                          case QrCodeType.STATIC_WIRELESS:
                            return (
                              <CreateQrWireless
                                initData={_cubit.state.dataQrDetail}
                                onChage={(v) => {
                                  _cubit.state.data = v;
                                  if (
                                    v.done === false &&
                                    _cubit.state.archiveStep === 2
                                  ) {
                                    _cubit.state.archiveStep = 1;
                                  }
                                  _cubit.upDateState();
                                }}
                              />
                            );
                          case QrCodeType.WEBSITE:
                          case QrCodeType.STATIC_WEBSITE:
                            return (
                              <CreateQrWeb
                                initData={_cubit.state.dataQrDetail}
                                onChage={(v) => {
                                  _cubit.state.data = v;
                                  if (
                                    v.done === false &&
                                    _cubit.state.archiveStep === 2
                                  ) {
                                    _cubit.state.archiveStep = 1;
                                  }
                                  _cubit.upDateState();
                                }}
                              />
                            );
                          case QrCodeType.VCARD:
                            return (
                              <CreateQrVCard
                                onChage={(v) => {
                                  _cubit.state.data = v;
                                  if (
                                    v.done === false &&
                                    _cubit.state.archiveStep === 2
                                  ) {
                                    _cubit.state.archiveStep = 1;
                                  }
                                  _cubit.upDateState();
                                }}
                                initData={snap.data.dataQrDetail}
                              />
                            );
                          default:
                            return <></>;
                        }
                      })()}
                    </div>
                  </PageWithStep>

                  {/* step design qr */}
                  <PageWithStep step={2} currentStep={snap?.data?.step ?? 0}>
                    <DesignQr
                      qrStyle={snap!.data!.qrStyle}
                      onChange={(v) => {
                        _cubit.state.qrStyle = v;
                        _cubit.upDateState();
                      }}
                    />
                  </PageWithStep>
                </>
              );
            }}
          </StreamBuilder>
        </div>

        {/* preview */}
        <StreamBuilder<CreateQrPageState, any, any>
          stream={_cubit.stream}
          initData={_cubit.state}
        >
          {(c, snap) => {
            return (
              <PreviewQr
                qrContent={snap?.data?.qrContent ?? "example"}
                qrStyle={snap!.data!.qrStyle}
                qrName={snap?.data?.qrName ?? ""}
                data={snap?.data?.data}
                onBack={() => {
                  if (
                    snap?.data?.step === 0 &&
                    snap?.data?.qrType !== undefined
                  ) {
                    resetToDefault();
                  }
                  // else if (qrId.length > 0 && snap?.data?.step === 1) {
                  // }
                  else if (_cubit.state.step > 0) {
                    _cubit.state.step--;
                    _cubit.upDateState();
                  }
                }}
                onNext={async () => {
                  if ((snap?.data?.step ?? 0) < 2) {
                    _cubit.state.archiveStep++;
                    _cubit.state.step++;
                    _cubit.upDateState();
                  } else if (
                    qrId &&
                    _cubit.state.qrType &&
                    _cubit.state.qrName &&
                    _cubit.state.qrName.length > 0
                  ) {
                    try {
                      appBloc.session.isLoading.next(true);
                      await updateQr({
                        data: _cubit.state.data,
                        qrName: _cubit.state.qrName,
                        qrStyle: _cubit.state.qrStyle,
                        qrType: _cubit.state.qrType,
                        id: qrId,
                      });
                      message.success("Cập nhật QR thành công!");
                      navigate(NavlinkPath.qrCode);
                    } catch (error: any) {
                      console.error(error);
                      message.error(`${error.message}`);
                    } finally {
                      appBloc.session.isLoading.next(false);
                    }
                  } else if (
                    _cubit.state.qrType &&
                    _cubit.state.qrName &&
                    _cubit.state.qrName.length > 0
                  ) {
                    try {
                      appBloc.session.isLoading.next(true);
                      await createQr({
                        data: _cubit.state.data,
                        qrName: _cubit.state.qrName,
                        qrStyle: _cubit.state.qrStyle,
                        qrType: _cubit.state.qrType,
                      });
                      message.success("Tạo QR thành công!");
                      resetToDefault();
                    } catch (error: any) {
                      console.error(error);
                      message.error(`${error.message}`);
                    } finally {
                      appBloc.session.isLoading.next(false);
                    }
                  }
                }}
                step={snap?.data?.step ?? 0}
                qrType={snap?.data?.qrType}
              />
            );
          }}
        </StreamBuilder>
      </div>
    </div>
  );
};

export default CreateQrPage;
