import { IconPhone } from "@/components/icons/IconPhone";
import { FormCreateQrVCard } from "../interfaces/FormCreateVCard";
import { ItemInfoPreviewInfo } from "./ItemPreviewInfo";
import { IconEmail } from "@/components/icons/IconEmail";
import { IconLocation } from "@/components/icons/IconLocation";
import { IconWebsite2 } from "@/components/icons/IconWebsite2";
import { IconCompany } from "@/components/icons/IconCompany";
import { wcHexIsLight } from "@/utils/checkBrightness";
import { icAvatar, imgBgExampleVcard } from "@/services/assets";
import { ItemSocialNetworkPreview } from "./ItemSocialNetworkPreview";
import { IconEmailX16 } from "@/components/icons/IconEmailX16";
import { IconTelephoneX16 } from "@/components/icons/IconTelephoneX16";
import { hexToRgb } from "@/utils/convertColor";

interface VCardTemplateProps {
  data: FormCreateQrVCard;
}

export const VCardTemplate: React.FC<VCardTemplateProps> = ({ data }) => {
  return (
    <div className="create-qr-vcard-preview__template-0">
      {/* header */}
      <div
        className="column"
        style={{
          width: "100%",
          padding: "20px 0px 12px 0px",
          gap: "12px",
          backgroundColor: data.contentStyle.primaryColor,
          boxShadow: "0px 2px 4px 0px rgba(0, 0, 0, 0.15)",
        }}
      >
        {/* avatar */}
        <div
          className="row"
          style={{
            border: "3px solid #FFFFFF",
            aspectRatio: "1",
            width: "100px",
            height: "100px",
            borderRadius: "100%",
            backgroundColor: "#FFFFFF",
          }}
        >
          <img
            src={data.avatarUrl.length === 0 ? icAvatar : data.avatarUrl}
            style={{
              objectFit: "cover",
              objectPosition: "center center",
              width: "100%",
              aspectRatio: "1",
              borderRadius: "100%",
            }}
          />
        </div>
        <div
          className="column"
          style={{
            width: "100%",
            gap: "4px",
          }}
        >
          {/* full name */}
          {(data.firstName || data.lastName) && (
            <span
              className="text-16--md"
              style={{
                color: wcHexIsLight(data.contentStyle.primaryColor)
                  ? "#000000"
                  : "#FFFFFF",
                whiteSpace: "initial",
                wordWrap: "break-word",
                textAlign: "center",
              }}
            >
              {`${data.firstName} ${data.lastName}`}
            </span>
          )}
          {/* position */}
          {(data.title || data.company) && (
            <span
              className="text-12--reg"
              style={{
                color: wcHexIsLight(data.contentStyle.primaryColor)
                  ? "#000000"
                  : "#FFFFFF",
                whiteSpace: "initial",
                wordWrap: "break-word",
                textAlign: "center",
              }}
            >
              {data.title && data.company ? (
                <>{`${data.title} tại ${data.company}`}</>
              ) : (
                <>
                  {data.title}
                  {data.company}
                </>
              )}
            </span>
          )}
        </div>
        <div
          className="row"
          style={{
            width: "100%",
            gap: "12px",
            justifyContent: "center",
          }}
        >
          {data.phones.length > 0 && (
            <a
              href={`tel:${data.phones[0]}`}
              className="row"
              style={{
                height: "32px",
                aspectRatio: "1",
                backgroundColor: data.contentStyle.secondaryColor,
                borderRadius: "100%",
                justifyContent: "center",
                textTransform: "none",
                textDecoration: "none",
              }}
            >
              <IconTelephoneX16 color={data.contentStyle.primaryColor} />
            </a>
          )}
          {data.emails.length > 0 && (
            <div
              onClick={() => {
                window.open(`mailto:${data.emails[0]}?subject=LIÊN%20HỆ`);
              }}
              className="row"
              style={{
                height: "32px",
                aspectRatio: "1",
                backgroundColor: data.contentStyle.secondaryColor,
                borderRadius: "100%",
                justifyContent: "center",
              }}
            >
              <IconEmailX16 color={data.contentStyle.primaryColor} />
            </div>
          )}
        </div>
      </div>

      {/* sumary */}
      <div
        className="column"
        style={{
          backgroundColor: "#F7F7F7",
          gap: "4px",
          width: "100%",
          padding: "12px",
        }}
      >
        <span
          className="text-12--reg"
          style={{
            color: "#333333",
            width: "100%",
            textAlign: "start",
            whiteSpace: "initial",
            wordWrap: "break-word",
          }}
        >
          {data.sumary}
        </span>
      </div>
      {/* contact us */}
      <div
        className="column"
        style={{
          alignItems: "flex-start",
          width: "100%",
          padding: "12px",
        }}
      >
        <span
          className="text-14--md"
          style={{
            color: data.contentStyle.primaryColor,
          }}
        >
          Liên hệ
        </span>
        {data.company && (
          <ItemInfoPreviewInfo
            onTap={() => {
              window.open("https://maps.google.com?q=" + `${data.company}`);
            }}
            font={data.contentStyle.contentFont}
            icon={<IconCompany color={data.contentStyle.primaryColor} />}
            title="Công ty"
            content={data.company}
          />
        )}
        {data.phones.map((phone, i) => {
          return (
            <ItemInfoPreviewInfo
              key={`${phone}=${i}`}
              font={data.contentStyle.contentFont}
              icon={<IconPhone color={data.contentStyle.primaryColor} />}
              title="Điện thoại"
              content={
                <a
                  className="text-14--reg text-overflow--new-line"
                  style={{
                    color: "#333333",
                    font: data.contentStyle.contentFont,
                    boxSizing: "border-box",
                    width: "100%",
                    textTransform: "none",
                    textDecoration: "none",
                  }}
                  href={`tel:${phone}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {phone}
                </a>
              }
            />
          );
        })}

        {data.emails.map((email, i) => {
          return (
            <ItemInfoPreviewInfo
              onTap={() => {
                window.open(`mailto:${email}?subject=LIÊN%20HỆ`);
              }}
              key={`${email}=${i}`}
              font={data.contentStyle.contentFont}
              icon={<IconEmail color={data.contentStyle.primaryColor} />}
              title="Email"
              content={email}
            />
          );
        })}

        {data.websites.map((website, i) => {
          return (
            <ItemInfoPreviewInfo
              key={`${website}=${i}`}
              font={data.contentStyle.contentFont}
              icon={<IconWebsite2 color={data.contentStyle.primaryColor} />}
              title="Website"
              content={
                <a
                  className="text-14--reg text-overflow--new-line"
                  style={{
                    color: "#333333",
                    font: data.contentStyle.contentFont,
                    boxSizing: "border-box",
                    width: "100%",
                    textTransform: "none",
                    textDecoration: "none",
                  }}
                  href={website}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {website}
                </a>
              }
            />
          );
        })}
        {data.location && (
          <ItemInfoPreviewInfo
            onTap={() => {
              window.open("https://maps.google.com?q=" + `${data.location}`);
            }}
            font={data.contentStyle.contentFont}
            icon={<IconLocation color={data.contentStyle.primaryColor} />}
            title="Địa chỉ"
            content={data.location}
          />
        )}
        {data.socialNetworks &&
          data.socialNetworks.map((social, i) => {
            return (
              <ItemSocialNetworkPreview
                key={`social=${i}`}
                socialNetwork={social}
              />
            );
          })}
      </div>
      <div className="column" style={{ width: "100%", height: "56px" }} />
    </div>
  );
};

export const VCardTemplate1: React.FC<VCardTemplateProps> = ({ data }) => {
  return (
    <div className="create-qr-vcard-preview__template-0">
      {/* header */}
      <div
        className="column"
        style={{
          width: "100%",
          position: "relative",
        }}
      >
        <img
          src={
            data.backgroundUrl?.length ? data.backgroundUrl : imgBgExampleVcard
          }
          style={{
            width: "100%",
            objectFit: "cover",
            objectPosition: "center center",
          }}
        />

        {/* avatar */}
        <div
          className="column"
          style={{
            width: "100%",
            gap: "12px",
            position: "absolute",
            // top: "0",
            bottom: "100px",
            left: "0",
            right: "0",
            margin: "auto",
            zIndex: 1,
            // transform: "translateY(40%)",
          }}
        >
          <div
            className="row"
            style={{
              border: "3px solid #FFFFFF",
              aspectRatio: "1",
              width: "100px",
              height: "100px",
              borderRadius: "100%",
              backgroundColor: "#FFFFFF",
            }}
          >
            <img
              src={data.avatarUrl.length === 0 ? icAvatar : data.avatarUrl}
              style={{
                objectFit: "cover",
                objectPosition: "center center",
                width: "100%",
                aspectRatio: "1",
                borderRadius: "100%",
              }}
            />
          </div>
        </div>

        {/* banner backgournd and info */}
        <div
          className="column"
          style={{
            width: "100%",
            justifyContent: "flex-end",
            padding: "60px 0px 12px 0px",
            gap: "12px",
            backgroundColor: data.contentStyle.primaryColor,
            boxShadow: "0px 2px 4px 0px rgba(0, 0, 0, 0.15)",
          }}
        >
          <div
            className="column"
            style={{
              width: "100%",
              gap: "4px",
            }}
          >
            {/* full name */}
            {(data.firstName || data.lastName) && (
              <span
                className="text-16--md"
                style={{
                  color: wcHexIsLight(data.contentStyle.primaryColor)
                    ? "#000000"
                    : "#FFFFFF",
                  whiteSpace: "initial",
                  wordWrap: "break-word",
                  textAlign: "center",
                }}
              >
                {`${data.firstName} ${data.lastName}`}
              </span>
            )}
            {/* position */}
            {(data.title || data.company) && (
              <span
                className="text-12--reg"
                style={{
                  color: wcHexIsLight(data.contentStyle.primaryColor)
                    ? "#000000"
                    : "#FFFFFF",
                  whiteSpace: "initial",
                  wordWrap: "break-word",
                  textAlign: "center",
                }}
              >
                {data.title && data.company ? (
                  <>{`${data.title} tại ${data.company}`}</>
                ) : (
                  <>
                    {data.title}
                    {data.company}
                  </>
                )}
              </span>
            )}
          </div>
          <div
            className="row"
            style={{
              width: "100%",
              justifyContent: "center",
              gap: "12px",
            }}
          >
            {data.phones.length > 0 && (
              <a
                href={`tel:${data.phones[0]}`}
                className="row"
                style={{
                  height: "32px",
                  aspectRatio: "1",
                  backgroundColor: data.contentStyle.secondaryColor,
                  borderRadius: "100%",
                  justifyContent: "center",
                  textTransform: "none",
                  textDecoration: "none",
                }}
              >
                <IconTelephoneX16 color={data.contentStyle.primaryColor} />
              </a>
            )}
            {data.emails.length > 0 && (
              <div
                onClick={() => {
                  window.open(`mailto:${data.emails[0]}?subject=LIÊN%20HỆ`);
                }}
                className="row"
                style={{
                  height: "32px",
                  aspectRatio: "1",
                  backgroundColor: data.contentStyle.secondaryColor,
                  borderRadius: "100%",
                  justifyContent: "center",
                }}
              >
                <IconEmailX16 color={data.contentStyle.primaryColor} />
              </div>
            )}
          </div>
        </div>
      </div>

      {/* sumary */}
      <div
        className="column"
        style={{
          backgroundColor: "#F7F7F7",
          gap: "4px",
          width: "100%",
          padding: "12px",
        }}
      >
        <span
          className="text-12--reg"
          style={{
            color: "#333333",
            width: "100%",
            textAlign: "start",
            whiteSpace: "initial",
            wordWrap: "break-word",
          }}
        >
          {data.sumary}
        </span>
      </div>
      {/* contact us */}
      <div
        className="column"
        style={{
          alignItems: "flex-start",
          width: "100%",
          padding: "12px",
        }}
      >
        <span
          className="text-14--md"
          style={{
            color: data.contentStyle.primaryColor,
          }}
        >
          Liên hệ
        </span>
        {data.company && (
          <ItemInfoPreviewInfo
            onTap={() => {
              window.open("https://maps.google.com?q=" + `${data.company}`);
            }}
            font={data.contentStyle.contentFont}
            icon={<IconCompany color={data.contentStyle.primaryColor} />}
            title="Công ty"
            content={data.company}
          />
        )}
        {data.phones.map((phone, i) => {
          return (
            <ItemInfoPreviewInfo
              key={`${phone}=${i}`}
              font={data.contentStyle.contentFont}
              icon={<IconPhone color={data.contentStyle.primaryColor} />}
              title="Điện thoại"
              content={
                <a
                  className="text-14--reg text-overflow--new-line"
                  style={{
                    color: "#333333",
                    font: data.contentStyle.contentFont,
                    boxSizing: "border-box",
                    width: "100%",
                    textTransform: "none",
                    textDecoration: "none",
                  }}
                  href={`tel:${phone}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {phone}
                </a>
              }
            />
          );
        })}

        {data.emails.map((email, i) => {
          return (
            <ItemInfoPreviewInfo
              onTap={() => {
                window.open(`mailto:${email}?subject=LIÊN%20HỆ`);
              }}
              key={`${email}=${i}`}
              font={data.contentStyle.contentFont}
              icon={<IconEmail color={data.contentStyle.primaryColor} />}
              title="Email"
              content={email}
            />
          );
        })}

        {data.websites.map((website, i) => {
          return (
            <ItemInfoPreviewInfo
              key={`${website}=${i}`}
              font={data.contentStyle.contentFont}
              icon={<IconWebsite2 color={data.contentStyle.primaryColor} />}
              title="Website"
              content={
                <a
                  className="text-14--reg text-overflow--new-line"
                  style={{
                    color: "#333333",
                    font: data.contentStyle.contentFont,
                    boxSizing: "border-box",
                    width: "100%",
                    textTransform: "none",
                    textDecoration: "none",
                  }}
                  href={website}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {website}
                </a>
              }
            />
          );
        })}
        {data.location && (
          <ItemInfoPreviewInfo
            onTap={() => {
              window.open("https://maps.google.com?q=" + `${data.location}`);
            }}
            font={data.contentStyle.contentFont}
            icon={<IconLocation color={data.contentStyle.primaryColor} />}
            title="Địa chỉ"
            content={data.location}
          />
        )}
        {data.socialNetworks &&
          data.socialNetworks.map((social, i) => {
            return (
              <ItemSocialNetworkPreview
                key={`social=${i}`}
                socialNetwork={social}
              />
            );
          })}
      </div>
      <div className="column" style={{ width: "100%", height: "56px" }} />
    </div>
  );
};

export const VCardTemplate2: React.FC<VCardTemplateProps> = ({ data }) => {
  const primaryColorRgb = hexToRgb(data.contentStyle.primaryColor);
  const linearColor = `linear-gradient(to bottom, ${primaryColorRgb
    .replace(")", ", 0)")
    .replace("rgb", "rgba")} 10%, ${primaryColorRgb
    .replace(")", ", 1)")
    .replace("rgb", "rgba")} 100%)`;

  return (
    <div className="create-qr-vcard-preview__template-0">
      {/* header */}
      <div
        className="column"
        style={{
          width: "100%",
          position: "relative",
        }}
      >
        <img
          src={data.avatarUrl.length === 0 ? imgBgExampleVcard : data.avatarUrl}
          style={{
            width: "100%",
            objectFit: "cover",
            objectPosition: "center center",
          }}
        />

        {/* banner backgournd and info */}
        <div
          className="column"
          style={{
            width: "100%",
            justifyContent: "flex-end",
            padding: "12px 12px 12px 12px",
            gap: "12px",
            boxShadow: "0px 2px 4px 0px rgba(0, 0, 0, 0.15)",
            position: "absolute",
            bottom: "0px",
            left: "0px",
            height: "100%",
            background: linearColor,
          }}
        >
          <div
            className="column"
            style={{
              width: "100%",
              gap: "4px",
              alignItems: "flex-start",
            }}
          >
            {/* full name */}
            {(data.firstName || data.lastName) && (
              <span
                className="text-16--md"
                style={{
                  color: wcHexIsLight(data.contentStyle.primaryColor)
                    ? "#000000"
                    : "#FFFFFF",
                  whiteSpace: "initial",
                  wordWrap: "break-word",
                  textAlign: "start",
                }}
              >
                {`${data.firstName} ${data.lastName}`}
              </span>
            )}
            {/* position */}
            {(data.title || data.company) && (
              <span
                className="text-12--reg"
                style={{
                  color: wcHexIsLight(data.contentStyle.primaryColor)
                    ? "#000000"
                    : "#FFFFFF",
                  whiteSpace: "initial",
                  wordWrap: "break-word",
                  textAlign: "start",
                }}
              >
                {data.title && data.company ? (
                  <>{`${data.title} tại ${data.company}`}</>
                ) : (
                  <>
                    {data.title}
                    {data.company}
                  </>
                )}
              </span>
            )}
          </div>
          <div
            className="row"
            style={{
              width: "100%",
              justifyContent: "flex-start",
              gap: "12px",
            }}
          >
            {data.phones.length > 0 && (
              <a
                href={`tel:${data.phones[0]}`}
                className="row"
                style={{
                  height: "32px",
                  aspectRatio: "1",
                  backgroundColor: data.contentStyle.secondaryColor,
                  borderRadius: "100%",
                  justifyContent: "center",
                  textTransform: "none",
                  textDecoration: "none",
                }}
              >
                <IconTelephoneX16 color={data.contentStyle.primaryColor} />
              </a>
            )}
            {data.emails.length > 0 && (
              <div
                onClick={() => {
                  window.open(`mailto:${data.emails[0]}?subject=LIÊN%20HỆ`);
                }}
                className="row"
                style={{
                  height: "32px",
                  aspectRatio: "1",
                  backgroundColor: data.contentStyle.secondaryColor,
                  borderRadius: "100%",
                  justifyContent: "center",
                }}
              >
                <IconEmailX16 color={data.contentStyle.primaryColor} />
              </div>
            )}
          </div>
        </div>
      </div>

      {/* sumary */}
      <div
        className="column"
        style={{
          backgroundColor: "#F7F7F7",
          gap: "4px",
          width: "100%",
          padding: "12px",
        }}
      >
        <span
          className="text-12--reg"
          style={{
            color: "#333333",
            width: "100%",
            textAlign: "start",
            whiteSpace: "initial",
            wordWrap: "break-word",
          }}
        >
          {data.sumary}
        </span>
      </div>
      {/* contact us */}
      <div
        className="column"
        style={{
          alignItems: "flex-start",
          width: "100%",
          padding: "12px",
        }}
      >
        <span
          className="text-14--md"
          style={{
            color: data.contentStyle.primaryColor,
          }}
        >
          Liên hệ
        </span>
        {data.company && (
          <ItemInfoPreviewInfo
            onTap={() => {
              window.open("https://maps.google.com?q=" + `${data.company}`);
            }}
            font={data.contentStyle.contentFont}
            icon={<IconCompany color={data.contentStyle.primaryColor} />}
            title="Công ty"
            content={data.company}
          />
        )}
        {data.phones.map((phone, i) => {
          return (
            <ItemInfoPreviewInfo
              key={`${phone}=${i}`}
              font={data.contentStyle.contentFont}
              icon={<IconPhone color={data.contentStyle.primaryColor} />}
              title="Điện thoại"
              content={
                <a
                  className="text-14--reg text-overflow--new-line"
                  style={{
                    color: "#333333",
                    font: data.contentStyle.contentFont,
                    boxSizing: "border-box",
                    width: "100%",
                    textTransform: "none",
                    textDecoration: "none",
                  }}
                  href={`tel:${phone}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {phone}
                </a>
              }
            />
          );
        })}

        {data.emails.map((email, i) => {
          return (
            <ItemInfoPreviewInfo
              onTap={() => {
                window.open(`mailto:${email}?subject=LIÊN%20HỆ`);
              }}
              key={`${email}=${i}`}
              font={data.contentStyle.contentFont}
              icon={<IconEmail color={data.contentStyle.primaryColor} />}
              title="Email"
              content={email}
            />
          );
        })}

        {data.websites.map((website, i) => {
          return (
            <ItemInfoPreviewInfo
              key={`${website}=${i}`}
              font={data.contentStyle.contentFont}
              icon={<IconWebsite2 color={data.contentStyle.primaryColor} />}
              title="Website"
              content={
                <a
                  className="text-14--reg text-overflow--new-line"
                  style={{
                    color: "#333333",
                    font: data.contentStyle.contentFont,
                    boxSizing: "border-box",
                    width: "100%",
                    textTransform: "none",
                    textDecoration: "none",
                  }}
                  href={website}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {website}
                </a>
              }
            />
          );
        })}
        {data.location && (
          <ItemInfoPreviewInfo
            onTap={() => {
              window.open("https://maps.google.com?q=" + `${data.location}`);
            }}
            font={data.contentStyle.contentFont}
            icon={<IconLocation color={data.contentStyle.primaryColor} />}
            title="Địa chỉ"
            content={data.location}
          />
        )}
        {data.socialNetworks &&
          data.socialNetworks.map((social, i) => {
            return (
              <ItemSocialNetworkPreview
                key={`social=${i}`}
                socialNetwork={social}
              />
            );
          })}
      </div>
      <div className="column" style={{ width: "100%", height: "56px" }} />
    </div>
  );
};
