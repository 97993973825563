export enum NavlinkPath {
  root = "/",

  //home
  home = "/home",

  //dashboard
  dashboard = `${home}/dashboard`,

  // qrcode
  qrCode = `${home}/qr-code`,
  creatQrCode = `${home}/create-qr`,
  editQrCode = `${qrCode}/:qrId/edit`,
  detailQrCode = `${qrCode}/:qrId`,

  // profile
  profile = `${home}/profile`,

  // billing
  billing = `${home}/billing`,

  // checkout
  checkout = `/checkout`,

  //auth
  auth = "/auth",
  login = `${auth}/login`,
  register = `${auth}/register`,
  resetPassword = `${auth}/reset-password`,
  resetPasswordValidEmail = `${auth}/reset-pasword-valid-email`,
  socialAuthRedirect = `${auth}/oauth2/redirect`,

  //public
  public = "/public",
  publicQr = `/:id`,
}
