export const IconArrowBackX20 = ({ color = "#333333" }: { color?: string }) => {
  return (
    <svg
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.1919 4.55806C11.436 4.80214 11.436 5.19786 11.1919 5.44194L7.25888 9.375H15.75C16.0952 9.375 16.375 9.65482 16.375 10C16.375 10.3452 16.0952 10.625 15.75 10.625H7.25888L11.1919 14.5581C11.436 14.8021 11.436 15.1979 11.1919 15.4419C10.9479 15.686 10.5521 15.686 10.3081 15.4419L5.30806 10.4419C5.06398 10.1979 5.06398 9.80214 5.30806 9.55806L10.3081 4.55806C10.5521 4.31398 10.9479 4.31398 11.1919 4.55806Z"
        fill={color}
      />
    </svg>
  );
};
