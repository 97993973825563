export const IconQrX20 = ({ color = "#333333" }: { color?: string }) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.82394 4.85714H6.51409V6.57143H4.82394V4.85714Z"
        fill={color}
      />
      <path
        d="M13.2773 4.85714H14.9675V6.57143H13.2773V4.85714Z"
        fill={color}
      />
      <path
        d="M4.82394 13.4294H6.51409V15.1437H4.82394V13.4294Z"
        fill={color}
      />
      <path d="M15.8099 10H17.5V11.7143H15.8099V10Z" fill={color} />
      <path
        d="M9.89437 15.1429H11.5845V16.8571H9.89437V15.1429Z"
        fill={color}
      />
      <path
        d="M13.2746 13.4286H14.9648V15.1429H13.2746V13.4286Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.5 3.72471C2.5 3.0484 3.04048 2.5 3.70738 2.5H7.88473C8.55163 2.5 9.09212 3.0484 9.09212 3.72471V7.9623C9.09212 8.63861 8.55163 9.18701 7.88473 9.18701H3.70738C3.04048 9.18701 2.5 8.63861 2.5 7.9623V3.72471ZM3.76761 3.78571V7.90129H7.82451V3.78571H3.76761ZM10.6966 3.72471C10.6966 3.0484 11.2371 2.5 11.904 2.5H16.0814C16.7482 2.5 17.2887 3.0484 17.2887 3.72471V7.9623C17.2887 8.63861 16.7482 9.18701 16.0814 9.18701H11.904C11.2371 9.18701 10.6966 8.63861 10.6966 7.9623V3.72471ZM11.9642 3.78571V7.90129H16.0211V3.78571H11.9642ZM2.5 12.0377C2.5 11.3614 3.04049 10.813 3.70738 10.813H7.88473C8.55163 10.813 9.09212 11.3614 9.09212 12.0377V16.2753C9.09212 16.9516 8.55163 17.5 7.88473 17.5H3.70738C3.04048 17.5 2.5 16.9516 2.5 16.2753V12.0377ZM3.76761 12.0987V16.2143H7.82451V12.0987H3.76761ZM12.1593 12.3016H13.9837C14.3337 12.3016 14.6175 12.0138 14.6175 11.6588C14.6175 11.3037 14.3337 11.0159 13.9837 11.0159H11.9352C11.3588 11.0159 10.8917 11.4899 10.8917 12.0744V13.7368C10.8917 14.0919 11.1754 14.3797 11.5255 14.3797C11.8755 14.3797 12.1593 14.0919 12.1593 13.7368V12.3016ZM16.4419 14.3408C16.7919 14.3408 17.0757 14.6286 17.0757 14.9837V16.2305C17.0757 16.815 16.6086 17.2889 16.0322 17.2889H13.1643C12.8142 17.2889 12.5305 17.0011 12.5305 16.6461C12.5305 16.291 12.8142 16.0032 13.1643 16.0032H15.8081V14.9837C15.8081 14.6286 16.0918 14.3408 16.4419 14.3408Z"
        fill={color}
      />
    </svg>
  );
};
