import { ReactNode } from "react";
import "./style.less";
import "@/styles";

const AuthPage: React.FC<{ children: ReactNode }> = ({ children }) => {
  return (
    <div className="auth-page">
      <div className="auth-page__left animation-faded--in">{children}</div>

      <div className="auth-page__right animation-faded--in"></div>
    </div>
  );
};

export default AuthPage;
