import { QRStyleModel } from "@/apis/qrfy/models/QrModel";
import { IconPenX20 } from "@/components/icons/IconPenX20";
import { IconTriangleX20 } from "@/components/icons/IconTriangleX20";
import StreamBuilder from "@/components/stream-builder/StreamBuilder";
import { BaseBloc } from "@/services/base-bloc";
import { Tabs } from "antd";
import { useMemo } from "react";
import { DesignQrPersonal } from "./DesignQrPersonal";

interface DesignQrProps {
  onChange: (v: QRStyleModel, save?: boolean) => void;
  qrStyle: QRStyleModel;
}

interface DesignQrState {
  activeKey: string;
}

export const DesignQr: React.FC<DesignQrProps> = ({ onChange, qrStyle }) => {
  const cubit = useMemo<BaseBloc<DesignQrState>>(() => {
    return new BaseBloc<DesignQrState>({
      initState: {
        activeKey: "0",
      },
    });
  }, []);

  return (
    <>
      <Tabs
        style={{
          width: "100%",
          boxSizing: "border-box",
        }}
        onTabClick={(k) => {
          cubit.state.activeKey = k;
          cubit.upDateState();
        }}
        defaultActiveKey={cubit.state.activeKey}
        items={[
          {
            label: (
              <StreamBuilder<DesignQrState, any, any>
                selector={(pre, next) => {
                  return pre?.activeKey !== next?.activeKey;
                }}
                initData={cubit.state}
                stream={cubit.stream}
              >
                {(c, snap) => {
                  return (
                    <span className="row" style={{ gap: "8px" }}>
                      <IconPenX20
                        color={
                          snap?.data?.activeKey === "0" ? "#000000" : "#ADADAD"
                        }
                      />
                      <span
                        className="text-14--reg"
                        style={{
                          color:
                            snap?.data?.activeKey === "0"
                              ? "#000000"
                              : "#858585",
                          fontWeight:
                            snap?.data?.activeKey === "0" ? "500" : "400",
                        }}
                      >
                        Personalized design
                      </span>
                    </span>
                  );
                }}
              </StreamBuilder>
            ),
            key: "0",
            children: (
              <DesignQrPersonal initQrStyle={qrStyle} onChange={onChange} />
            ),
          },
          {
            label: (
              <StreamBuilder<DesignQrState, any, any>
                selector={(pre, next) => {
                  return pre?.activeKey !== next?.activeKey;
                }}
                initData={cubit.state}
                stream={cubit.stream}
              >
                {(c, snap) => {
                  return (
                    <span className="row" style={{ gap: "8px" }}>
                      <IconTriangleX20
                        color={
                          snap?.data?.activeKey === "1" ? "#000000" : "#ADADAD"
                        }
                      />
                      <span
                        className="text-14--reg"
                        style={{
                          color:
                            snap?.data?.activeKey === "1"
                              ? "#000000"
                              : "#858585",
                          fontWeight:
                            snap?.data?.activeKey === "1" ? "500" : "400",
                        }}
                      >
                        Template
                      </span>
                    </span>
                  );
                }}
              </StreamBuilder>
            ),
            key: "1",
            children: "Tab 3",
          },
        ]}
      />
    </>
  );
};
