export const IconEmailX16 = ({ color = "#12B76A" }: { color?: string }) => {
  return (
    <svg
      width="17"
      height="17"
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.01953 4.07324C2.01953 3.7971 2.24339 3.57324 2.51953 3.57324H14.5195C14.7957 3.57324 15.0195 3.7971 15.0195 4.07324V13.4066C15.0195 13.6827 14.7957 13.9066 14.5195 13.9066H2.51953C2.24339 13.9066 2.01953 13.6827 2.01953 13.4066V4.07324ZM4.05943 4.57324L8.51953 7.81095L12.9796 4.57324H4.05943ZM14.0195 5.05406L8.81326 8.83343C8.63809 8.96059 8.40098 8.96059 8.2258 8.83343L3.01953 5.05406V12.9066H14.0195V5.05406Z"
        fill={color}
      />
    </svg>
  );
};
