import { QRStyleModel } from "@/apis/qrfy/models/QrModel";
import { Expanded } from "@/components/expanded/Expanded";
import { InputCheck } from "@/components/input-check/InputCheck";
import { BaseBloc } from "@/services/base-bloc";
import { useEffect, useMemo } from "react";
import { distinct } from "rxjs";
import "./style.less";
import StreamBuilder from "@/components/stream-builder/StreamBuilder";
import { QrFrame1 } from "@/components/qr-frame/QrFrame1";
import { QrFrame2 } from "@/components/qr-frame/QrFrame2";
import { QrFrame3 } from "@/components/qr-frame/QrFrame3";
import { InputForm2 } from "@/components/input-form/InputForm2";
import { ColorPicker } from "antd";
import Environment from "@/services/environment";
import { InputImageCrop } from "@/components/input-image-crop/InputImageCrop";

const listDotType = [
  "dots",
  "rounded",
  "classy",
  "classy-rounded",
  "square",
  "extra-rounded",
];

const listCornerType = [
  ["dot", "dot"],
  ["dot", "square"],
  ["dot", "extra-rounded"],
  ["square", "dot"],
  ["square", "square"],
  ["square", "extra-rounded"],
];

interface DesignQrPersonalProps {
  onChange: (v: QRStyleModel, save?: boolean) => void;
  initQrStyle: QRStyleModel;
}

interface DesignQrPersonalState {
  qrStyle: QRStyleModel;
  save: boolean;
}

export const DesignQrPersonal: React.FC<DesignQrPersonalProps> = ({
  onChange,
  initQrStyle,
}) => {
  const cubit = useMemo<BaseBloc<DesignQrPersonalState>>(() => {
    return new BaseBloc<DesignQrPersonalState>({
      initState: {
        qrStyle: initQrStyle,
        save: false,
      },
    });
  }, []);

  useEffect(() => {
    cubit.stream.pipe(distinct()).subscribe((v) => {
      onChange({ ...v.qrStyle }, v.save);
    });
  }, []);

  return (
    <div
      className="column"
      style={{
        width: "100%",
        gap: "20px",
      }}
    >
      <div
        className="row"
        style={{
          width: "100%",
          gap: "20px",
        }}
      >
        <InputCheck
          onChange={(v) => {
            cubit.state.save = v;
            cubit.upDateState();
          }}
          defaultValue={cubit.state.save}
          wrapStyle={{ gap: "8px" }}
          suffix={
            <span className="text-14--reg" style={{ color: "#333333" }}>
              Save template when finished
            </span>
          }
        />
      </div>
      {/* frame */}
      <Expanded title="Frame" opended>
        <StreamBuilder<DesignQrPersonalState, any, any>
          stream={cubit.stream}
          initData={cubit.state}
        >
          {(c, snap) => {
            return (
              <>
                <div
                  className="column custom-scroll-bar"
                  style={{
                    width: "100%",
                    overflow: "auto",
                    maxHeight: "480px",
                  }}
                >
                  <div
                    className="column"
                    style={{
                      width: "100%",
                    }}
                  >
                    <div className="column create-qr-page__list-frame">
                      {/* reset */}
                      <div
                        onClick={() => {
                          cubit.state.qrStyle.frame.id = 0;
                          cubit.upDateState();
                        }}
                        className={`create-qr-page__list-frame__frame${
                          snap?.data?.qrStyle.frame.id === 0 ? "--sected" : ""
                        }`}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="34"
                          height="34"
                          viewBox="0 0 34 34"
                          fill="none"
                        >
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M3.5625 17C3.5625 9.40608 9.71858 3.25 17.3125 3.25C20.6853 3.25 23.7744 4.46436 26.1665 6.47969L6.79219 25.854C4.77686 23.4619 3.5625 20.3728 3.5625 17ZM8.56917 27.6126C10.9456 29.5727 13.9915 30.75 17.3125 30.75C24.9064 30.75 31.0625 24.5939 31.0625 17C31.0625 13.679 29.8852 10.6331 27.9251 8.25667L8.56917 27.6126ZM17.3125 0.75C8.33787 0.75 1.0625 8.02537 1.0625 17C1.0625 25.9746 8.33787 33.25 17.3125 33.25C26.2871 33.25 33.5625 25.9746 33.5625 17C33.5625 8.02537 26.2871 0.75 17.3125 0.75Z"
                            fill="#333333"
                          />
                        </svg>
                      </div>

                      {/* frame 1 */}
                      <div
                        onClick={() => {
                          cubit.state.qrStyle.frame.id = 1;
                          cubit.upDateState();
                        }}
                        className={`create-qr-page__list-frame__frame${
                          snap?.data?.qrStyle?.frame?.id === 1 ? "--sected" : ""
                        }`}
                      >
                        <QrFrame1 />
                      </div>

                      {/* frame 2 */}
                      <div
                        onClick={() => {
                          cubit.state.qrStyle.frame.id = 2;
                          cubit.upDateState();
                        }}
                        className={`create-qr-page__list-frame__frame${
                          snap?.data?.qrStyle?.frame?.id === 2 ? "--sected" : ""
                        }`}
                      >
                        <QrFrame2 />
                      </div>

                      {/* frame 3 */}
                      <div
                        onClick={() => {
                          cubit.state.qrStyle.frame.id = 3;
                          cubit.upDateState();
                        }}
                        className={`create-qr-page__list-frame__frame${
                          snap?.data?.qrStyle?.frame?.id === 3 ? "--sected" : ""
                        }`}
                      >
                        <QrFrame3 />
                      </div>
                    </div>
                  </div>
                </div>
                {/* frame style */}
                {snap?.data?.qrStyle.frame.id !== 0 && (
                  <div
                    className="column"
                    style={{
                      gap: "12px",
                      width: "100%",
                    }}
                  >
                    <div
                      className="row"
                      style={{
                        width: "100%",
                        gap: "12px",
                      }}
                    >
                      <InputForm2
                        defaultValue={snap?.data?.qrStyle.frame.text}
                        onChange={(v) => {
                          cubit.state.qrStyle.frame.text = v;
                          cubit.upDateState();
                        }}
                        title="Title"
                      />
                      <InputForm2
                        isDisable
                        styleInput={{
                          backgroundColor: "#FFFFFF",
                          color: "#333333",
                        }}
                        defaultValue={
                          cubit.state.qrStyle.frame.backgroundColor?.fromColor
                        }
                        title="BackgroundColor Color"
                        suffix={
                          <>
                            <ColorPicker
                              onChangeComplete={(v) => {
                                cubit.state.qrStyle.frame.backgroundColor!.fromColor =
                                  v.toHexString();
                                cubit.upDateState();
                              }}
                              defaultValue={"#FFFFFF"}
                              style={{
                                margin: "auto 8px",
                              }}
                            />
                          </>
                        }
                      />
                    </div>

                    <div
                      className="row"
                      style={{
                        width: "100%",
                        gap: "12px",
                      }}
                    >
                      <InputForm2
                        isDisable
                        styleInput={{
                          backgroundColor: "#FFFFFF",
                          color: "#333333",
                        }}
                        defaultValue={
                          snap?.data?.qrStyle.frame.color?.fromColor
                        }
                        title="Text Color"
                        suffix={
                          <>
                            <ColorPicker
                              onChangeComplete={(v) => {
                                cubit.state.qrStyle.frame.color!.fromColor =
                                  v.toHexString();
                                cubit.upDateState();
                              }}
                              defaultValue={
                                snap?.data?.qrStyle.frame.color?.toColor
                              }
                              style={{
                                margin: "auto 8px",
                                border: "none",
                              }}
                            />
                          </>
                        }
                      />
                      <InputForm2
                        isDisable
                        styleInput={{
                          backgroundColor: "#FFFFFF",
                          color: "#333333",
                        }}
                        defaultValue={snap?.data?.qrStyle.frame.color?.toColor}
                        title="Frame Color"
                        suffix={
                          <>
                            <ColorPicker
                              onChangeComplete={(v) => {
                                cubit.state.qrStyle.frame.color!.toColor =
                                  v.toHexString();
                                cubit.upDateState();
                              }}
                              defaultValue={
                                snap?.data?.qrStyle.frame.color?.toColor
                              }
                              style={{
                                margin: "auto 8px",
                                border: "none",
                              }}
                            />
                          </>
                        }
                      />
                    </div>
                  </div>
                )}
              </>
            );
          }}
        </StreamBuilder>
      </Expanded>

      {/* shape */}
      <Expanded styleContent={{ gap: "20px" }} title="Shape and color" opended>
        {/* instruction */}
        <div
          className="row"
          style={{
            width: "100%",
            gap: "12px",
            padding: "20px",
            backgroundColor: "#F7F7F7",
            border: "1px solid #D9D9D9",
            borderRadius: "4px",
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M19.75 10C19.75 15.3848 15.3848 19.75 10 19.75C4.61522 19.75 0.25 15.3848 0.25 10C0.25 4.61522 4.61522 0.25 10 0.25C15.3848 0.25 19.75 4.61522 19.75 10ZM8.25 8.25C8.25 7.69771 8.69771 7.25 9.25 7.25H10.25C10.8023 7.25 11.25 7.69771 11.25 8.25V13.25H11.75C12.3023 13.25 12.75 13.6977 12.75 14.25C12.75 14.8023 12.3023 15.25 11.75 15.25H8.75C8.19771 15.25 7.75 14.8023 7.75 14.25C7.75 13.6977 8.19771 13.25 8.75 13.25H9.25V9.25C8.69771 9.25 8.25 8.80229 8.25 8.25ZM10.0073 6.44824C10.6184 6.44824 11.1138 5.95287 11.1138 5.3418C11.1138 4.73072 10.6184 4.23535 10.0073 4.23535C9.39625 4.23535 8.90088 4.73072 8.90088 5.3418C8.90088 5.95287 9.39625 6.44824 10.0073 6.44824Z"
              fill="#858585"
            />
          </svg>
          <span
            style={{
              width: "100%",
              whiteSpace: "initial",
              wordBreak: "break-word",
              textAlign: "start",
              flex: "1",
              color: "#5C5C5C",
            }}
            className="text-14--md"
          >
            Remember! For the optimal reading of the QR code, you need to use
            high contrast colours. We recommend selecting a light and a dark
            colour for your QR code.
          </span>
        </div>
        {/* dot option and shape background */}
        <StreamBuilder<DesignQrPersonalState, any, any>
          stream={cubit.stream}
          initData={cubit.state}
        >
          {(c, snap) => {
            return (
              <div
                className="column"
                style={{
                  width: "100%",
                  gap: "12px",
                  alignItems: "flex-start",
                }}
              >
                <span className="text-16--md" style={{ color: "#333333" }}>
                  Body Patterns
                </span>
                {/* dot option */}
                <div className="create-qr-page__list-dot-type">
                  {listDotType.map((d, i) => {
                    return (
                      <div
                        onClick={() => {
                          cubit.state.qrStyle.dot.style = d;
                          cubit.upDateState();
                        }}
                        key={d + i.toString()}
                        className={`create-qr-page__list-dot-type__dot-type${
                          snap?.data?.qrStyle.dot.style === d ? "--sected" : ""
                        }`}
                      >
                        <img
                          src={`${Environment.host}/images/qr-dot-types/${d}.svg`}
                          style={{ height: "100%", aspectRatio: "1" }}
                        />
                      </div>
                    );
                  })}
                </div>
                {/* dot color */}
                <div
                  className="row"
                  style={{
                    width: "100%",
                    gap: "12px",
                    alignItems: "flex-end",
                  }}
                >
                  <InputForm2
                    isDisable
                    styleInput={{
                      backgroundColor: "#FFFFFF",
                      color: "#333333",
                    }}
                    defaultValue={snap?.data?.qrStyle.dot.color.fromColor}
                    title="Pattern Color"
                    suffix={
                      <>
                        <ColorPicker
                          onChangeComplete={(v) => {
                            cubit.state.qrStyle.dot.color.fromColor =
                              v.toHexString();
                            cubit.upDateState();
                          }}
                          defaultValue={snap?.data?.qrStyle.dot.color.fromColor}
                          style={{
                            margin: "auto 8px",
                            border: "none",
                          }}
                        />
                      </>
                    }
                  />
                  <InputForm2
                    isDisable
                    styleInput={{
                      backgroundColor: "#FFFFFF",
                      color: "#333333",
                    }}
                    defaultValue={snap?.data?.qrStyle.shape.backgroundColor}
                    title="Background Color"
                    suffix={
                      <>
                        <ColorPicker
                          disabled={
                            snap?.data?.qrStyle.shape.backgroundColor ===
                            "#00000000"
                          }
                          onChangeComplete={(v) => {
                            cubit.state.qrStyle.shape.backgroundColor =
                              v.toHexString();
                            cubit.upDateState();
                          }}
                          defaultValue={
                            snap?.data?.qrStyle.shape.backgroundColor
                          }
                          value={snap?.data?.qrStyle.shape.backgroundColor}
                          disabledAlpha
                          style={{
                            margin: "auto 8px",
                            border: "none",
                          }}
                        />
                      </>
                    }
                  />
                  <InputCheck
                    onChange={(v) => {
                      if (v) {
                        cubit.state.qrStyle.shape.backgroundColor = "#00000000";
                      } else {
                        cubit.state.qrStyle.shape.backgroundColor = "#FFFFFF";
                      }
                      cubit.upDateState();
                    }}
                    defaultValue={
                      snap?.data?.qrStyle.shape.backgroundColor === "#00000000"
                    }
                    wrapStyle={{ gap: "8px", paddingBottom: "8px" }}
                    suffix={
                      <span
                        className="text-14--reg"
                        style={{ color: "#333333" }}
                      >
                        Transparent BackgroundColor
                      </span>
                    }
                  />
                </div>
              </div>
            );
          }}
        </StreamBuilder>
        {/* corner dot */}
        <StreamBuilder<DesignQrPersonalState, any, any>
          stream={cubit.stream}
          initData={cubit.state}
        >
          {(c, snap) => {
            return (
              <div
                className="column"
                style={{
                  width: "100%",
                  gap: "12px",
                  alignItems: "flex-start",
                }}
              >
                <span className="text-16--md" style={{ color: "#333333" }}>
                  Eyes Patterns
                </span>
                {/* dot option */}
                <div className="create-qr-page__list-corner-type">
                  {listCornerType.map((d, i) => {
                    return (
                      <div
                        onClick={() => {
                          cubit.state.qrStyle.corner.dotStyle = d[0];
                          cubit.state.qrStyle.corner.squareStyle = d[1];
                          cubit.upDateState();
                        }}
                        key={d + i.toString()}
                        className={`create-qr-page__list-corner-type__corner-type${
                          cubit.state.qrStyle.corner.dotStyle === d[0] &&
                          cubit.state.qrStyle.corner.squareStyle === d[1]
                            ? "--sected"
                            : ""
                        }`}
                      >
                        <img
                          src={`${Environment.host}/images/qr-corner-types/${d[0]}-${d[1]}.svg`}
                          style={{ aspectRatio: "1", width: "100%" }}
                        />
                      </div>
                    );
                  })}
                </div>
                {/* dot color */}
                <div
                  className="row"
                  style={{
                    width: "100%",
                    gap: "12px",
                  }}
                >
                  <InputForm2
                    isDisable
                    styleInput={{
                      backgroundColor: "#FFFFFF",
                      color: "#333333",
                    }}
                    defaultValue={snap?.data?.qrStyle.corner.squareColor}
                    title="External Color"
                    suffix={
                      <>
                        <ColorPicker
                          onChangeComplete={(v) => {
                            cubit.state.qrStyle.corner.squareColor =
                              v.toHexString();
                            cubit.upDateState();
                          }}
                          defaultValue={snap?.data?.qrStyle.corner.squareColor}
                          style={{
                            margin: "auto 8px",
                            border: "none",
                          }}
                        />
                      </>
                    }
                  />
                  <InputForm2
                    isDisable
                    styleInput={{
                      backgroundColor: "#FFFFFF",
                      color: "#333333",
                    }}
                    defaultValue={snap?.data?.qrStyle.corner.dotColor}
                    title="Internal Color"
                    suffix={
                      <>
                        <ColorPicker
                          onChangeComplete={(v) => {
                            cubit.state.qrStyle.corner.dotColor =
                              v.toHexString();
                            cubit.upDateState();
                          }}
                          defaultValue={snap?.data?.qrStyle.corner.dotColor}
                          disabledAlpha
                          style={{
                            margin: "auto 8px",
                            border: "none",
                          }}
                        />
                      </>
                    }
                  />
                </div>
              </div>
            );
          }}
        </StreamBuilder>
      </Expanded>

      {/* logo */}
      <Expanded styleContent={{ gap: "20px" }} title="Logo" opended>
        <StreamBuilder<DesignQrPersonalState, any, any>
          stream={cubit.stream}
          initData={cubit.state}
        >
          {(c, snap) => {
            return (
              <InputImageCrop
                defauleImage={snap?.data?.qrStyle.image}
                onChange={({ data }) => {
                  cubit.state.qrStyle.image = data;
                  cubit.upDateState();
                }}
              />
            );
          }}
        </StreamBuilder>
      </Expanded>
    </div>
  );
};
