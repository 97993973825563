import "./input-text-area.css";
import { FC, useEffect, useRef, useState } from "react";

interface InputTextAreaProps {
  title?: string;
  placeHolder?: string;
  errorLabel?: string;
  onChange?: (v: string) => void;
  onSubmit?: () => void;
  styleForm?: React.CSSProperties;
  isReadOnly?: boolean;
  isDisable?: boolean;
  iconClear?: React.ReactNode;
  onClear?: () => void;
  autoComplete?: string;
  isFullwidth?: boolean;
  maxLenght?: number;
  required?: boolean;
  defaultValue?: string;
  valid?: (v: string) => boolean;
  rows?: number;
}

export const InputTextArea: FC<InputTextAreaProps> = ({
  title = "",
  placeHolder = "",
  errorLabel = "",
  onChange = () => {},
  onSubmit = () => {},
  isReadOnly = false,
  autoComplete = "new-password",
  isFullwidth = false,
  isDisable = false,
  maxLenght = 250,
  styleForm,
  required,
  defaultValue,
  valid = () => true,
  rows = 5,
}) => {
  const inputRef = useRef<HTMLTextAreaElement>(null);
  const [value, setValue] = useState(defaultValue);

  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);
  return (
    <label
      className="wrap-input-text-area"
      style={{ width: isFullwidth ? "100%" : undefined }}
    >
      {title.length > 0 && (
        <span
          className="input-text-area-custom__title"
          // style={{
          //   color: isDisable || isReadOnly ? "#9CA3AF" : undefined,
          // }}
        >
          {required && (
            <span style={{ color: "rgba(240, 82, 82, 1)" }}>* </span>
          )}
          {title}
        </span>
      )}

      <div
        style={{
          position: "relative",
          width: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          alignItems: "flex-start",
          gap: "2px",
          height: "fit-content",
        }}
      >
        <textarea
          ref={inputRef}
          value={value}
          // defaultValue={value}
          autoComplete={autoComplete}
          className="input-text-area-custom hide-scrollbar"
          rows={rows}
          onKeyDown={(e) => {
            if (onSubmit !== undefined) {
              if (e.code === "Enter" || e.code === "NumpadEnter") {
                e.preventDefault();
                onSubmit();
              }
            }
          }}
          onChange={(e) => {
            if (e.target.value.length > maxLenght || !valid(e.target.value)) {
              return;
            }
            onChange(e.target.value);
            setValue(e.target.value);
          }}
          style={Object.assign(
            {
              border: errorLabel.length > 0 ? "1px solid #F05252" : undefined,
              height: `${rows * 14 + 32 + 16}px`,
              minWidth: "100%",
              resize: "vertical",
              overflowY: "auto",
              boxShadow: errorLabel.length > 0 ? "none" : undefined,
            },
            styleForm,
          )}
          placeholder={placeHolder}
          disabled={isDisable}
          readOnly={isReadOnly}
          maxLength={maxLenght}
        />
      </div>

      {/* footer */}
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-end",
          padding: "0px",
          width: "100%",
          gap: "8px",
        }}
      >
        {/* error */}
        {errorLabel.length > 0 ? (
          <span className="input-text-area-custom__error-label">
            {errorLabel}
          </span>
        ) : (
          <></>
        )}

        {/* couter letter */}
        {maxLenght > 0 ? (
          <span className="input-text-area-custom__count-letter">
            ({`${value?.length ?? 0}/${maxLenght}`})
          </span>
        ) : (
          <></>
        )}
      </div>
    </label>
  );
};
