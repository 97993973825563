import { BillDataModel } from "./BillDataModel";
import { SubscriptionModel } from "./SubcriptionModel";

export interface UserModel {
  id: string;
  name: string;
  username: string;
  email: string;
  projectId: string;
  projectName: string;
  createDate: string;
  avatar: string;
  role: UserRole;
}

export enum UserRole {
  ADMIN = "ADMIN",
  OWNER = "OWNER",
  USER = "USER",
}

export interface UserDetailModel {
  id: string;
  name: string;
  username: string;
  email: string;
  createDate: string;
  avatar: string;
  role: UserRole;
  billingData: BillDataModel;
  subscription: SubscriptionModel;
}
