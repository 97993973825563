import { useEffect, useState } from "react";
import { CropImageCustom } from "../crop-image/CropImageCustom";
import "./input-image-crop.css";

interface InputImageCropProps {
  labelDelete?: string;
  labelEmpty?: string;
  defauleImage?: string;
  onChange?: ({ data, file }: { data: string; file: File | null }) => void;
}

export const InputImageCrop: React.FC<InputImageCropProps> = ({
  labelDelete = "Delete Logo",
  defauleImage = "",
  labelEmpty = "Upload your logo",
  onChange,
}) => {
  const [image, setImage] = useState(defauleImage);
  useEffect(() => {
    setImage(defauleImage);
  }, [defauleImage]);
  return (
    <div className="input-image-crop">
      {image.length > 0 && (
        <CropImageCustom
          style={{ width: "fit-content" }}
          labelCancel={`Huỷ`}
          labelConfirm={`Xác nhận`}
          labelTitle={`Cắt ảnh`}
          acceptTypeImg={"image/jpg, image/jpeg, image/png"}
          onSaveHandler={async ({ data, file }) => {
            setImage(data);
            onChange && onChange({ data: data, file: file });
          }}
          maxSizeFile={2000 * 1024}
          minSizeImg={{ width: 400, height: 400 }}
          content={
            <>
              <div className="input-image-crop__image-frame">
                <img src={image} alt="logo" />
                <div>
                  <svg
                    width="40"
                    height="40"
                    viewBox="0 0 40 40"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect
                      x="0.5"
                      y="0.5"
                      width="39"
                      height="39"
                      rx="3.5"
                      fill="white"
                    />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M22.902 12.0474C23.1476 11.8135 23.5351 11.8182 23.775 12.0581L26.6916 14.9747C26.9357 15.2188 26.9357 15.6145 26.6916 15.8586L17.9416 24.6086C17.8244 24.7258 17.6654 24.7917 17.4997 24.7917H14.583C14.2378 24.7917 13.958 24.5118 13.958 24.1667V20.8333C13.958 20.6623 14.0281 20.4987 14.152 20.3807L22.902 12.0474ZM15.208 21.1012V23.5417H17.2408L25.3658 15.4167L23.3224 13.3732L15.208 21.1012ZM12.708 27.5C12.708 27.1548 12.9878 26.875 13.333 26.875H27.4997C27.8449 26.875 28.1247 27.1548 28.1247 27.5C28.1247 27.8452 27.8449 28.125 27.4997 28.125H13.333C12.9878 28.125 12.708 27.8452 12.708 27.5Z"
                      fill="#333333"
                    />
                    <rect
                      x="0.5"
                      y="0.5"
                      width="39"
                      height="39"
                      rx="3.5"
                      stroke="#D9D9D9"
                    />
                  </svg>
                </div>
              </div>
            </>
          }
        />
      )}
      {image.length === 0 && (
        <CropImageCustom
          style={{ width: "fit-content" }}
          labelCancel={`Huỷ`}
          labelConfirm={`Xác nhận`}
          labelTitle={`Cắt ảnh`}
          acceptTypeImg={"image/jpg, image/jpeg, image/png"}
          onSaveHandler={async ({ data, file }) => {
            setImage(data);
            onChange && onChange({ data: data, file: file });
          }}
          maxSizeFile={2000 * 1024}
          minSizeImg={{ width: 400, height: 400 }}
          content={
            <>
              <div className="input-image-crop__image-frame--empty">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="21"
                  height="20"
                  viewBox="0 0 21 20"
                  fill="none"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M10.5 2.25C10.9142 2.25 11.25 2.58579 11.25 3V9.25H17.5C17.9142 9.25 18.25 9.58579 18.25 10C18.25 10.4142 17.9142 10.75 17.5 10.75H11.25V17C11.25 17.4142 10.9142 17.75 10.5 17.75C10.0858 17.75 9.75 17.4142 9.75 17V10.75H3.5C3.08579 10.75 2.75 10.4142 2.75 10C2.75 9.58579 3.08579 9.25 3.5 9.25H9.75V3C9.75 2.58579 10.0858 2.25 10.5 2.25Z"
                    fill="#858585"
                  />
                </svg>
                <span>{labelEmpty}</span>
              </div>
            </>
          }
        />
      )}
      <button
        onClick={() => {
          setImage("");
          onChange && onChange({ data: "", file: null });
        }}
        className="input-image-crop__delete-button"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M10.5637 0.883972C10.7333 0.913028 10.8835 1.01077 10.9788 1.15415L13.71 5.26525H14.8332C15.0101 5.26525 15.1787 5.34022 15.2972 5.47158C15.4157 5.60293 15.473 5.77836 15.4549 5.95434L14.3611 16.5641C14.3283 16.8828 14.0598 17.125 13.7394 17.125H4.26026C3.93989 17.125 3.6714 16.8828 3.63855 16.5641L2.5448 5.95434C2.52666 5.77836 2.58395 5.60293 2.70246 5.47158C2.82097 5.34022 2.98959 5.26525 3.16651 5.26525H4.41593L10.0823 1.00063C10.2199 0.89712 10.394 0.854916 10.5637 0.883972ZM4.61857 6.51525C4.62307 6.5153 4.62756 6.5153 4.63206 6.51525H13.3662C13.3723 6.51534 13.3785 6.51534 13.3846 6.51525H14.1404L13.1755 15.875H4.82414L3.85925 6.51525H4.61857ZM12.2093 5.26525H6.49463L10.3044 2.39798L12.2093 5.26525ZM7.54151 8.92378C7.88669 8.92378 8.16651 9.20361 8.16651 9.54878V13.2073C8.16651 13.5525 7.88669 13.8323 7.54151 13.8323C7.19633 13.8323 6.91651 13.5525 6.91651 13.2073V9.54878C6.91651 9.20361 7.19633 8.92378 7.54151 8.92378ZM10.4582 8.92378C10.8034 8.92378 11.0832 9.20361 11.0832 9.54878V13.2073C11.0832 13.5525 10.8034 13.8323 10.4582 13.8323C10.113 13.8323 9.83317 13.5525 9.83317 13.2073V9.54878C9.83317 9.20361 10.113 8.92378 10.4582 8.92378Z"
            fill="#F05252"
          />
        </svg>
        <span>{labelDelete}</span>
      </button>
    </div>
  );
};
