export const IconPhoneX20 = ({ color = "#333333" }: { color?: string }) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.63743 1.66669C4.84846 1.66669 4.20886 2.34504 4.20886 3.18184V16.8182C4.20886 17.655 4.84846 18.3334 5.63743 18.3334H14.414C15.203 18.3334 15.8426 17.655 15.8426 16.8182V3.18184C15.8426 2.34504 15.203 1.66669 14.414 1.66669H5.63743ZM5.45886 3.33335H14.5926V15H5.45886V3.33335ZM8.54167 16.6667C8.54167 16.3215 8.82149 16.0417 9.16667 16.0417H10.8333C11.1785 16.0417 11.4583 16.3215 11.4583 16.6667C11.4583 17.0119 11.1785 17.2917 10.8333 17.2917H9.16667C8.82149 17.2917 8.54167 17.0119 8.54167 16.6667Z"
        fill={color}
      />
    </svg>
  );
};
