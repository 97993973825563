export const IconWalletX28 = ({ color = "#12B76A" }: { color?: string }) => {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.70833 22.8973V4.375H21.2917V22.8973L18.5565 21.139C18.2683 20.9537 17.8984 20.9537 17.6102 21.139L14 23.4598L10.3898 21.139C10.1016 20.9537 9.73174 20.9537 9.4435 21.139L6.70833 22.8973ZM9.625 8.75C9.625 8.26675 10.0168 7.875 10.5 7.875H17.5C17.9832 7.875 18.375 8.26675 18.375 8.75C18.375 9.23325 17.9832 9.625 17.5 9.625H10.5C10.0168 9.625 9.625 9.23325 9.625 8.75ZM9.625 12.95C9.625 12.4668 10.0168 12.075 10.5 12.075H17.5C17.9832 12.075 18.375 12.4668 18.375 12.95C18.375 13.4332 17.9832 13.825 17.5 13.825H10.5C10.0168 13.825 9.625 13.4332 9.625 12.95ZM9.625 17.15C9.625 16.6667 10.0168 16.275 10.5 16.275H17.5C17.9832 16.275 18.375 16.6667 18.375 17.15C18.375 17.6332 17.9832 18.025 17.5 18.025H10.5C10.0168 18.025 9.625 17.6332 9.625 17.15Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.83333 2.625C5.35008 2.625 4.95833 3.01675 4.95833 3.5V24.5C4.95833 24.8201 5.13311 25.1146 5.41404 25.268C5.69498 25.4214 6.03725 25.4091 6.3065 25.236L9.91667 22.9152L13.5268 25.236C13.8151 25.4213 14.1849 25.4213 14.4732 25.236L18.0833 22.9152L21.6935 25.236C21.9627 25.4091 22.305 25.4214 22.586 25.268C22.8669 25.1146 23.0417 24.8201 23.0417 24.5V3.5C23.0417 3.01675 22.6499 2.625 22.1667 2.625H5.83333ZM6.70833 22.8973V4.375H21.2917V22.8973L18.5565 21.139C18.2683 20.9537 17.8984 20.9537 17.6102 21.139L14 23.4598L10.3898 21.139C10.1016 20.9537 9.73174 20.9537 9.4435 21.139L6.70833 22.8973Z"
        fill={color}
      />
    </svg>
  );
};
