export const IconPhone = ({ color = "white" }: { color?: string }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.2857 0C17.1793 0 18.7143 1.53502 18.7143 3.42857V20.5714C18.7143 22.465 17.1793 24 15.2857 24H8.42857C6.53502 24 5 22.465 5 20.5714V3.42857C5 1.53502 6.53502 0 8.42857 0H15.2857ZM15.2857 1.71429H8.42857C7.52483 1.71429 6.78443 2.41361 6.71899 3.30063L6.71429 3.42857V20.5714C6.71429 21.4752 7.41361 22.2156 8.30063 22.281L8.42857 22.2857H15.2857C16.1895 22.2857 16.9299 21.5864 16.9953 20.6994L17 20.5714V3.42857C17 2.4818 16.2325 1.71429 15.2857 1.71429ZM11.8571 17.1429C12.8039 17.1429 13.5714 17.9104 13.5714 18.8571C13.5714 19.8039 12.8039 20.5714 11.8571 20.5714C10.9104 20.5714 10.1429 19.8039 10.1429 18.8571C10.1429 17.9104 10.9104 17.1429 11.8571 17.1429Z"
        fill={color}
      />
    </svg>
  );
};
