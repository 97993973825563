import { CSSProperties } from "react";

export const PageWithStep: React.FC<{
  children?: React.ReactNode;
  step: number;
  currentStep: number;
  classPage?: string;
  style?: CSSProperties;
}> = ({ step, currentStep, children, classPage, style }) => {
  return (
    <div
      className={classPage}
      style={Object.assign(
        {
          display: step === currentStep ? "flex" : "none",
          width: "100%",
          height: "fit-content",
          flexDirection: "column",
          justifyContent: "flex-start",
          alignItems: "flex-start",
        },
        style,
      )}
    >
      {children}
    </div>
  );
};
