import { SideBarEditor } from "./components/sidebar-editor/SideBarEditor";
import "./index.less";
import "@/styles";
import {
  bottomBarId,
  editorLayoutId,
  editorLayoutPageId,
  editorLayoutSideBarMobile,
  editorSubLayoutId,
} from "@/services/constants";
import { useAppBloc } from "@/contexts/AppContext";
import { ReactNode, useCallback, useEffect } from "react";
import { LoginStatus } from "@/models/LoginStatus";
import { useBaseBloc } from "@/services/base-bloc";
// import BottomBarCustom from "@/components/bottom-bar/BottomBarCustom";
import { PopupCustom } from "@/components/popup/PopupCustom";
import { useTranslation } from "react-i18next";
import BottomBarCustom from "@/components/bottom-bar/BottomBarCustom";
import { HeaderEditor } from "@/pages/editor/components/header-editor/HeaderEditor";
import { SideBarEditorMobile } from "@/pages/editor/components/sidebar-editor/SideBarEditorMobile";

interface EditorLayoutState {
  showBreadCrumd: boolean;
  bottomBar: JSX.Element | null;
}

const EditorLayout: React.FC<{ children: ReactNode }> = ({ children }) => {
  const { t } = useTranslation();
  const { appBloc } = useAppBloc();
  const [state, setState] = useBaseBloc<EditorLayoutState>({
    showBreadCrumd: appBloc.session.breadcrumb.value !== null,
    bottomBar: appBloc.session.bottomBar.value,
  });

  const toggleMenu = useCallback((open: boolean) => {
    const sidebar = document.getElementById(editorLayoutSideBarMobile);
    if (sidebar) {
      sidebar.style.display = "flex";
      requestAnimationFrame(() => {
        if (open) {
          sidebar.classList.remove("animation-faded--out");
          sidebar.classList.add("animation-faded--in");
          sidebar.style.display = "flex";
          sidebar.style.translate = "100%";
        } else {
          sidebar.classList.remove("animation-faded--in");
          sidebar.classList.add("animation-faded--out");
          sidebar.style.translate = "0%";
        }
      });
    }
  }, []);

  useEffect(() => {
    // breadcrumb stream
    appBloc.session.breadcrumb.subscribe((v) => {
      state.showBreadCrumd = v !== null;
      setState();
    });

    // bottom bar stream
    appBloc.session.bottomBar.subscribe((v) => {
      state.bottomBar = v;
      setState();
    });
  }, []);

  function onTapLogout() {
    appBloc.session.queueModal.next(
      <PopupCustom
        title={t("logout") ?? ""}
        content={t("layout_editor.you_wanna_logout") ?? ""}
        labelConfirm={t("logout") ?? ""}
        labelClose={t("cancel") ?? ""}
        onClose={() => {
          appBloc.session.queueModal.next(null);
        }}
        typeConfirm="delete"
        onConfirm={() => {
          appBloc.session.queueModal.next(null);
          appBloc.session.loginStatus.next(LoginStatus.Expired);
          appBloc.session.isShowSideBar.next(false);
          toggleMenu(false);
        }}
      />,
    );
  }

  return (
    <section id={editorLayoutId} className="editor-layout custom-scroll-bar">
      {/* side bar left */}
      <SideBarEditor
        onLogout={() => {
          onTapLogout();
        }}
      />
      <SideBarEditorMobile
        onClose={() => {
          appBloc.session.isShowSideBar.next(false);
          toggleMenu(false);
        }}
        onLogout={() => {
          onTapLogout();
        }}
      />

      {/* sub layout */}
      <section
        className="editor-sublayout sidebar-expanded"
        id={editorSubLayoutId}
      >
        {/* header */}
        <HeaderEditor
          onShowDrawer={() => {
            appBloc.session.isShowSideBar.next(true);
            toggleMenu(true);
          }}
        />

        {/* main page */}
        <main
          className="editor-sublayout__content custom-scroll-bar"
          id={editorLayoutPageId}
        >
          {children}
        </main>

        {/* bottom bar */}
        {state.bottomBar && (
          <BottomBarCustom id={bottomBarId}>{state.bottomBar}</BottomBarCustom>
        )}
      </section>
    </section>
  );
};

export default EditorLayout;
