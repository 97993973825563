export const IconTrashX20 = ({ color = "#FF4C4C" }: { color?: string }) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.92194 2.26235C7.01829 2.02799 7.24661 1.875 7.5 1.875H12.5C12.7534 1.875 12.9817 2.02799 13.0781 2.26235L13.7521 3.90203H17.5C17.8452 3.90203 18.125 4.18185 18.125 4.52703C18.125 4.87221 17.8452 5.15203 17.5 5.15203H16.4583V16.6892C16.4583 17.4982 15.7892 18.125 15 18.125H5C4.21084 18.125 3.54167 17.4982 3.54167 16.6892V5.15203H2.5C2.15482 5.15203 1.875 4.87221 1.875 4.52703C1.875 4.18185 2.15482 3.90203 2.5 3.90203H6.24786L6.92194 2.26235ZM6.65215 5.15203C6.66169 5.15225 6.67121 5.15225 6.68071 5.15203H13.3193C13.3288 5.15225 13.3383 5.15225 13.3478 5.15203H15.2083V16.6892C15.2083 16.7758 15.1313 16.875 15 16.875H5C4.86869 16.875 4.79167 16.7758 4.79167 16.6892V5.15203H6.65215ZM12.4006 3.90203H7.59937L7.91881 3.125H12.0812L12.4006 3.90203ZM8.33333 7.95608C8.67851 7.95608 8.95833 8.2359 8.95833 8.58108V13.8514C8.95833 14.1965 8.67851 14.4764 8.33333 14.4764C7.98816 14.4764 7.70833 14.1965 7.70833 13.8514V8.58108C7.70833 8.2359 7.98816 7.95608 8.33333 7.95608ZM11.6667 7.95608C12.0118 7.95608 12.2917 8.2359 12.2917 8.58108V13.8514C12.2917 14.1965 12.0118 14.4764 11.6667 14.4764C11.3215 14.4764 11.0417 14.1965 11.0417 13.8514V8.58108C11.0417 8.2359 11.3215 7.95608 11.6667 7.95608Z"
        fill={color}
      />
    </svg>
  );
};
