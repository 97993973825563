export interface WirelessQrModel {
  id: string;
  qrContent: string;
  qrName: string;
  networkName: string;
  networkPassword: string;
  encryptType: WirelessEncrypType;
}

export enum WirelessEncrypType {
  WEP = "WEP",
  WPA = "WPA",
}
