export const IconEmail = ({ color = "#12B76A" }: { color?: string }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19 4H5C4.20435 4 3.44129 4.31607 2.87868 4.87868C2.31607 5.44129 2 6.20435 2 7V17C2 17.7956 2.31607 18.5587 2.87868 19.1213C3.44129 19.6839 4.20435 20 5 20H19C19.7956 20 20.5587 19.6839 21.1213 19.1213C21.6839 18.5587 22 17.7956 22 17V7C22 6.20435 21.6839 5.44129 21.1213 4.87868C20.5587 4.31607 19.7956 4 19 4ZM19 6L13.16 11C12.8266 11.2628 12.4145 11.4057 11.99 11.4057C11.5655 11.4057 11.1534 11.2628 10.82 11L5 6H19ZM19 18H5C4.73478 18 4.48043 17.8946 4.29289 17.7071C4.10536 17.5196 4 17.2652 4 17V7.79L9.53 12.5C10.2276 13.0685 11.1 13.379 12 13.379C12.9 13.379 13.7724 13.0685 14.47 12.5L20 7.79V17C20 17.2652 19.8946 17.5196 19.7071 17.7071C19.5196 17.8946 19.2652 18 19 18Z"
        fill={color}
      />
      <path
        d="M7.29 13.2902L5.29 15.2902C5.19627 15.3831 5.12188 15.4937 5.07111 15.6156C5.02034 15.7375 4.9942 15.8682 4.9942 16.0002C4.9942 16.1322 5.02034 16.2629 5.07111 16.3848C5.12188 16.5066 5.19627 16.6172 5.29 16.7102C5.38296 16.8039 5.49356 16.8783 5.61542 16.9291C5.73728 16.9798 5.86799 17.006 6 17.006C6.13201 17.006 6.26272 16.9798 6.38458 16.9291C6.50644 16.8783 6.61704 16.8039 6.71 16.7102L8.71 14.7102C8.8983 14.5219 9.00409 14.2665 9.00409 14.0002C9.00409 13.7339 8.8983 13.4785 8.71 13.2902C8.5217 13.1019 8.2663 12.9961 8 12.9961C7.7337 12.9961 7.4783 13.1019 7.29 13.2902Z"
        fill={color}
      />
      <path
        d="M16.71 13.2902C16.5217 13.1019 16.2663 12.9961 16 12.9961C15.7337 12.9961 15.4783 13.1019 15.29 13.2902C15.1017 13.4785 14.9959 13.7339 14.9959 14.0002C14.9959 14.2665 15.1017 14.5219 15.29 14.7102L17.29 16.7102C17.383 16.8039 17.4936 16.8783 17.6154 16.9291C17.7373 16.9798 17.868 17.006 18 17.006C18.132 17.006 18.2627 16.9798 18.3846 16.9291C18.5064 16.8783 18.617 16.8039 18.71 16.7102C18.8037 16.6172 18.8781 16.5066 18.9289 16.3848C18.9797 16.2629 19.0058 16.1322 19.0058 16.0002C19.0058 15.8682 18.9797 15.7375 18.9289 15.6156C18.8781 15.4937 18.8037 15.3831 18.71 15.2902L16.71 13.2902Z"
        fill={color}
      />
    </svg>
  );
};
