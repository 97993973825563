import { ErrorModel } from "@/models/ErrorModel";
import { QrfyHttpClient } from "../QrfyRepositories";
import { ReportRepository } from "../ReportRepository";

export class ReportRepositoryImp implements ReportRepository {
  private client: QrfyHttpClient;
  constructor(c: QrfyHttpClient) {
    this.client = c;
  }

  public async getDashboard({
    fromDate,
    toDate,
  }: {
    fromDate: string;
    toDate: string;
  }): Promise<{
    totalQrCode: number;
    totalScan: number;
    daysReports: Array<{
      totalScan: number;
      date: string;
    }>;
  }> {
    try {
      const resp = await this.client.report.getDashboard({
        fromDate: fromDate,
        toDate: toDate,
      });
      if (resp.data.code === 0) {
        return {
          daysReports:
            resp.data.data?.daysReports?.map((d) => {
              return {
                date: d.date ?? "",
                totalScan: d.totalScan ?? 0,
              };
            }) ?? [],
          totalQrCode: resp.data.data?.totalQrCode ?? -1,
          totalScan: resp.data.data?.totalScan ?? -1,
        };
      } else {
        throw new ErrorModel({
          code: resp.data.code,
          message: resp.data.message,
        });
      }
    } catch (error) {
      throw error;
    }
  }
}
