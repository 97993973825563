export enum QrCodeType {
  WEBSITE = "WEBSITE",
  PDF = "PDF",
  LIST_OF_LINKS = "LIST_OF_LINKS",
  PICTURES = "PICTURES",
  VIDEO = "VIDEO",
  BUSINESS = "BUSINESS",
  VCARD = "VCARD",
  MENU = "MENU",
  APP = "APP",
  MP3 = "MP3",
  COUPON = "COUPON",
  FEEDBACK = "FEEDBACK",
  PLAYLIST = "PLAYLIST",
  LANDING_PAGE = "LANDING_PAGE",
  EVENT = "EVENT",
  STATIC_WEBSITE = "STATIC_WEBSITE",
  STATIC_TEXT = "STATIC_TEXT",
  STATIC_WIRELESS = "STATIC_WIRELESS",
  STATIC_VIETQR = "STATIC_VIETQR",
}

export interface QrCodeBaseModel {
  name: string;
  type: QrCodeType;
  qrContent: string;
}

export interface PublicQrCodeModel {
  sessionId: string;
  qrName: string;
  code: string;
  type: QrCodeType;
  data: QrCodeBaseModel;
  qrStyle?: QRStyleModel;
}

export interface QRStyleModel {
  corner: CornerStyleModel;
  frame: FrameStyleModel;
  image: string;
  shape: ShapeStyleModel;
  dot: DotStyleModel;
}

export interface ShapeStyleModel {
  backgroundColor: string;
  color: ColorStyleModel;
  style: string;
}

export interface FrameStyleModel {
  backgroundColor?: ColorStyleModel;
  color?: ColorStyleModel;
  fontSize: number;
  id: number;
  text: string;
}

export interface ColorStyleModel {
  fromColor: string;
  toColor: string;
  rotation: number;
  type: string;
}

export interface ContentStyleModel {
  primaryColor: string;
  secondaryColor: string;
  titleFont: string;
  contentFont: string;
}

export interface CornerStyleModel {
  dotColor: string;
  dotStyle: string;
  squareColor: string;
  squareStyle: string;
}

export interface QrCodeModel<T> {
  id: string;
  qrName: string;
  code: string;
  domain: string;
  type: QrCodeType;
  data: T;
  qrStyle?: QRStyleModel;
  counter: number;
  createdDate: string;
  modifiedDate: string;
}

export interface SocialNetworkModel {
  type: SocialNetworkType;
  url: string;
  text: string;
}
export enum SocialNetworkType {
  FACEBOOK = "FACEBOOK",
  SKYPE = "SKYPE",
  ZALO = "ZALO",
  GITHUB = "GITHUB",

  GOOGLE = "GOOGLE",
  LINKEDIN = "LINKEDIN",
  SNAPCHAT = "SNAPCHAT",
  TWITTER = "TWITTER",
  YOUTUBE = "YOUTUBE",

  INSTAGRAM = "INSTAGRAM",
  TIKTOK = "TIKTOK",
  WHATSAPP = "WHATSAPP",
  TELEGRAM = "TELEGRAM",
  MESSENGER = "MESSENGER",
}
export interface DotStyleModel {
  backgroundColor: string;
  color: ColorStyleModel;
  style: string;
}
