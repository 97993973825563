import { icArrowDownX14, icSearchX14 } from "@/services/assets";
import { useState, useRef, FC, useEffect } from "react";
import "./input-selector.css";
import { getPropertyInObject } from "@/utils/getPropsInObject";

const NoDataSearch = () => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "center",
        width: "100%",
        gap: "8px",
      }}
    >
      <svg
        width="124"
        height="124"
        viewBox="0 0 124 124"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M61.7092 112.492C86.9536 112.492 107.418 108.761 107.418 104.158C107.418 99.555 86.9536 95.8237 61.7092 95.8237C36.4647 95.8237 16 99.555 16 104.158C16 108.761 36.4647 112.492 61.7092 112.492Z"
          fill="#F5F5F7"
          fillOpacity="0.8"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M98.2762 79.6737L82.1458 60.3019C81.3718 59.3901 80.2405 58.8381 79.0492 58.8381H44.368C43.1773 58.8381 42.046 59.3901 41.272 60.3019L25.1423 79.6737V89.7941H98.2768V79.6737H98.2762Z"
          fill="#AEB8C2"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M38.8084 33.8355H84.6092C85.3245 33.8355 86.0104 34.1128 86.5162 34.6063C87.0219 35.0998 87.3061 35.7692 87.3061 36.4671V97.8776C87.3061 98.5755 87.0219 99.2449 86.5162 99.7384C86.0104 100.232 85.3245 100.509 84.6092 100.509H38.8084C38.0932 100.509 37.4072 100.232 36.9015 99.7384C36.3957 99.2449 36.1116 98.5755 36.1116 97.8776V36.4671C36.1116 35.7692 36.3957 35.0998 36.9015 34.6063C37.4072 34.1128 38.0932 33.8355 38.8084 33.8355V33.8355Z"
          fill="#F5F5F7"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M44.7738 40.3835H78.6439C79.0015 40.3835 79.3445 40.5222 79.5974 40.7689C79.8503 41.0157 79.9923 41.3504 79.9923 41.6993V58.1184C79.9923 58.4674 79.8503 58.8021 79.5974 59.0488C79.3445 59.2956 79.0015 59.4342 78.6439 59.4342H44.7738C44.4162 59.4342 44.0732 59.2956 43.8203 59.0488C43.5675 58.8021 43.4254 58.4674 43.4254 58.1184V41.6993C43.4254 41.3504 43.5675 41.0157 43.8203 40.7689C44.0732 40.5222 44.4162 40.3835 44.7738 40.3835ZM44.9505 66.577H78.4673C78.8717 66.577 79.2596 66.7338 79.5456 67.0128C79.8316 67.2919 79.9923 67.6704 79.9923 68.0651C79.9923 68.4598 79.8316 68.8383 79.5456 69.1174C79.2596 69.3965 78.8717 69.5533 78.4673 69.5533H44.9505C44.546 69.5533 44.1581 69.3965 43.8721 69.1174C43.5861 68.8383 43.4254 68.4598 43.4254 68.0651C43.4254 67.6704 43.5861 67.2919 43.8721 67.0128C44.1581 66.7338 44.546 66.577 44.9505 66.577ZM44.9505 74.3158H78.4673C78.8718 74.3158 79.2598 74.4726 79.5459 74.7518C79.832 75.0309 79.9927 75.4095 79.9927 75.8043C79.9927 76.199 79.832 76.5776 79.5459 76.8568C79.2598 77.1359 78.8718 77.2928 78.4673 77.2928H44.9505C44.5459 77.2928 44.1579 77.1359 43.8718 76.8568C43.5858 76.5776 43.4251 76.199 43.4251 75.8043C43.4251 75.4095 43.5858 75.0309 43.8718 74.7518C44.1579 74.4726 44.5459 74.3158 44.9505 74.3158ZM98.1271 102.936C97.6046 104.956 95.7694 106.462 93.5863 106.462H29.8314C27.6483 106.462 25.8131 104.955 25.2913 102.936C25.1917 102.551 25.1415 102.155 25.1416 101.758V79.6743H42.8854C44.8453 79.6743 46.4249 81.2849 46.4249 83.2401V83.2664C46.4249 85.221 48.0228 86.7993 49.9827 86.7993H73.435C75.3949 86.7993 76.9928 85.2066 76.9928 83.2513V83.2434C76.9928 81.2881 78.5725 79.6737 80.5324 79.6737H98.2761V101.759C98.2761 102.164 98.2242 102.559 98.1271 102.936Z"
          fill="#DCE0E6"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M100.357 34.9026L95.7525 36.6461C95.6342 36.6909 95.5053 36.7023 95.3806 36.6789C95.256 36.6555 95.1407 36.5982 95.0479 36.5137C94.9552 36.4292 94.8887 36.3208 94.8563 36.2011C94.8238 36.0813 94.8267 35.9551 94.8645 35.8368L96.1705 31.7533C94.4249 29.8164 93.4001 27.4546 93.4001 24.9059C93.4001 18.3303 100.222 13 108.637 13C117.051 13 123.873 18.3303 123.873 24.9059C123.873 31.4816 117.051 36.8118 108.637 36.8118C105.584 36.8118 102.741 36.1105 100.357 34.9026Z"
          fill="#DCE0E6"
        />
        <path
          d="M114.639 27.0559C115.7 27.0559 116.56 26.2268 116.56 25.204C116.56 24.1811 115.7 23.352 114.639 23.352C113.578 23.352 112.718 24.1811 112.718 25.204C112.718 26.2268 113.578 27.0559 114.639 27.0559Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M104.555 26.8243H100.714L102.668 23.5836L104.555 26.8243ZM106.956 23.5836H110.317V26.8243H106.956V23.5836Z"
          fill="white"
        />
      </svg>

      <span
        style={{
          color: "#6B7280",
          fontSize: "14px",
          lineHeight: "20px",
          fontWeight: 400,
        }}
      >
        There was no information found
      </span>
    </div>
  );
};

interface InputSelectorProps<NonNullable> {
  title?: string;
  placeHolder?: string;
  errorLabel?: string;
  height?: number;
  styleForm?: React.CSSProperties;
  prefix?: React.ReactNode;
  suffix?: React.ReactNode;
  isReadOnly?: boolean;
  isDisable?: boolean;
  iconClear?: React.ReactNode;
  iconDropDown?: string;
  iconSearch?: string;
  autoComplete?: string;
  isFullwidth?: boolean;
  isSuffixDivider?: boolean;
  isPrefixDivider?: boolean;
  allowSearch?: boolean;
  onClear?: () => void;
  onChange?: (v: string, key: string, data: NonNullable[]) => void;
  onSubmit?: () => void;
  dropdown?: (onTap: (v: string, key: string) => void) => React.ReactNode;
  searchFn?: (v: string) => Promise<NonNullable[]>;
  dropdownExtend?: React.ReactNode;
  data?: NonNullable[];
  defaultValue?: NonNullable;
  iconSelected?: React.ReactNode;
  refKey?: string;
  refValue?: string;
  searchDebound?: number;
  required?: boolean;
}

export const InputSelectorCustom: FC<
  InputSelectorProps<NonNullable<unknown>>
> = ({
  title = "",
  placeHolder = "",
  errorLabel = "",
  onChange = () => {},
  onSubmit = () => {},
  isReadOnly = false,
  onClear = () => {},
  autoComplete = "new-password",
  isFullwidth = false,
  isSuffixDivider = false,
  isPrefixDivider = false,
  isDisable = false,
  height = 32,
  allowSearch = false,
  iconDropDown = icArrowDownX14,
  iconSearch = icSearchX14,
  dropdown = undefined,
  data = [],
  defaultValue = undefined,
  refKey = "id",
  refValue = "name",
  dropdownExtend,
  iconClear,
  iconSelected,
  prefix,
  styleForm,
  suffix,
  searchFn,
  searchDebound = 120,
  required,
}) => {
  const prefixRef = useRef<HTMLDivElement>(null);
  const suffixRef = useRef<HTMLDivElement>(null);
  const arrowDownRef = useRef<HTMLImageElement>(null);
  const clearRef = useRef<HTMLDivElement>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const debountTime = useRef<number | null>(null);

  const [_prefixWidth, _setPrefixWidth] = useState(0);
  const [_suffixWidth, _setSuffixWidth] = useState(0);
  const [_selected, _setSelected] = useState(
    defaultValue
      ? getPropertyInObject<any, string | undefined>(defaultValue, refValue) ??
          ""
      : "",
  );
  const [_data, _setData] = useState(data ?? []);

  useEffect(() => {
    // scope prefix ezist
    if (prefixRef.current) {
      _setPrefixWidth(prefixRef.current?.clientWidth);
    }

    // scope suffix ezist
    if (suffixRef.current) {
      _setSuffixWidth(suffixRef.current?.clientWidth);
    }

    if (inputRef.current) {
      inputRef.current.addEventListener("input", () => {
        if (!inputRef.current) {
          return;
        }
        if (!allowSearch) {
          inputRef.current.value = "";
        }

        // check clear
        if (clearRef && clearRef.current && iconClear) {
          if ((inputRef.current?.value ?? "").length > 0) {
            clearRef.current.style.display = "flex";
          } else {
            clearRef.current.style.display = "none";
          }
        }
      });
    }
  }, []);

  useEffect(() => {
    _setData(data);
  }, [data]);

  useEffect(() => {
    // scope prefix ezist
    if (defaultValue && prefixRef.current && inputRef && inputRef.current) {
      // _setPrefixWidth(prefixRef.current?.clientWidth);
      inputRef.current.style.paddingLeft = `${
        prefixRef.current?.clientWidth + 16
      }px`;
      // console.log(prefix)
    }

    // scope suffix ezist
    if (defaultValue && suffixRef.current && inputRef && inputRef.current) {
      // _setSuffixWidth(suffixRef.current?.clientWidth);
      inputRef.current.style.paddingLeft = `${
        suffixRef.current?.clientWidth + 16
      }px`;
    }
  }, [prefix, suffix, defaultValue]);

  useEffect(() => {
    if (defaultValue === undefined && inputRef && inputRef.current) {
      inputRef.current.value = "";
      _setSelected("");
      _setData(data);

      if (clearRef && clearRef.current) {
        clearRef.current.style.display = "none";
      }
    } else if (defaultValue && inputRef && inputRef.current) {
      if (clearRef && clearRef.current) {
        clearRef.current.style.display = "flex";
      }
      inputRef.current.value =
        getPropertyInObject<any, string | undefined>(defaultValue, refValue) ??
        "";
      _setSelected(inputRef.current.value);
      _setData(data);
    }
  }, [defaultValue]);

  return (
    <label
      className="wrap-input-selector"
      style={{ width: isFullwidth ? "100%" : undefined }}
    >
      {/* title */}
      {title && title.length > 0 && (
        <span className="input-selector__title">
          {title}
          {required && (
            <span style={{ color: "rgba(240, 82, 82, 1)", height: "16px" }}>
              {required ? "* " : ""}
            </span>
          )}
          {":"}
        </span>
      )}

      <div
        style={{
          position: "relative",
          width: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          alignItems: "flex-start",
          gap: "2px",
          height: "fit-content",
        }}
      >
        <input
          autoComplete={autoComplete}
          style={Object.assign(
            {
              border: isDisable
                ? "1px solid  #D1D5DB"
                : errorLabel && errorLabel.length > 0
                  ? "1px solid #F05252"
                  : undefined,
              height: `${height}px`,
              paddingLeft: `${prefix !== undefined ? _prefixWidth + 12 : 12}px`,
              paddingRight: `${
                (iconClear !== undefined ? 32 : 0) +
                (iconDropDown !== null ? 32 : 0) +
                (suffix !== undefined ? _suffixWidth + 12 : 12)
              }px`,
              color: isDisable ? "#9CA3AF" : undefined,
              boxShadow:
                errorLabel && errorLabel.length > 0 ? "none" : undefined,
              backgroundColor: isDisable ? "#F3F4F6" : undefined,
              minWidth: "100%",
              caretColor: allowSearch ? undefined : "transparent",
            },
            styleForm,
          )}
          className="input-selector"
          onKeyDown={(e) => {
            if (onSubmit !== undefined) {
              if (e.code === "Enter" || e.code === "NumpadEnter") {
                e.preventDefault();
                onSubmit();
              }
            }
          }}
          disabled={isDisable}
          readOnly={isReadOnly}
          placeholder={placeHolder}
          ref={inputRef}
          onBlur={() => {
            if (
              allowSearch &&
              arrowDownRef &&
              arrowDownRef.current &&
              iconDropDown
            ) {
              arrowDownRef.current.src = iconDropDown;
            }
            if (dropdownRef && dropdownRef.current) {
              dropdownRef.current.style.display = "none";
            }
            if (inputRef && inputRef.current && placeHolder) {
              inputRef.current.placeholder = placeHolder;
              inputRef.current.value = _selected.length > 0 ? _selected : "";
              if (
                inputRef.current.value.length > 0 &&
                clearRef &&
                clearRef.current
              ) {
                clearRef.current.style.display = "flex";
              }
              _setData(data ?? []);
            }
          }}
          onFocus={() => {
            if (
              iconSearch &&
              allowSearch &&
              arrowDownRef &&
              arrowDownRef.current
            ) {
              arrowDownRef.current.src = iconSearch;
            }
            if (dropdownRef && dropdownRef.current) {
              dropdownRef.current.style.display = "flex";
            }

            if (inputRef && inputRef.current && _selected.length > 0) {
              inputRef.current.placeholder = _selected;
              inputRef.current.value = "";
              if (clearRef && clearRef.current) {
                clearRef.current.style.display = "none";
              }
            }
          }}
          onChange={async (e) => {
            if (allowSearch && searchFn) {
              if (debountTime.current === null) {
                debountTime.current = setTimeout(async () => {
                  debountTime.current = null;
                  let tmp: NonNullable<unknown>[] = [];
                  tmp = await searchFn(e.target.value);
                  _setData(tmp);
                }, searchDebound);
                let tmp: NonNullable<unknown>[] = [];
                tmp = await searchFn(e.target.value);
                _setData(tmp);
              }
            }
          }}
          defaultValue={
            defaultValue
              ? getPropertyInObject<unknown, string | undefined>(
                  defaultValue,
                  refValue,
                )
              : ""
          }
        />

        {prefix !== undefined && (
          <div
            ref={prefixRef}
            onClick={() => {
              // e.preventDefault();
            }}
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              height: `${height}px`,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <div
              style={{
                borderRight: isPrefixDivider ? "1px solid #E2E9FC" : undefined,
                display: "flex",
                width: "fit-content",
                height: "fit-content",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                cursor: "pointer",
              }}
            >
              {prefix}
            </div>
          </div>
        )}

        {suffix !== undefined && (
          <div
            ref={suffixRef}
            onClick={(e) => {
              e.preventDefault();
            }}
            style={{
              position: "absolute",
              top: 0,
              right: 0,
              height: `${height}px`,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <div
              onClick={(e) => {
                e.preventDefault();
              }}
              style={{
                // padding: "8px 8px",
                borderLeft: isSuffixDivider ? "1px solid #E2E9FC" : undefined,
                display: "flex",
                width: "fit-content",
                height: "fit-content",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                cursor: "pointer",
              }}
            >
              {suffix}
            </div>
          </div>
        )}

        <div
          ref={clearRef}
          style={{
            position: "absolute",
            top: 0,
            right: `${_suffixWidth + 24}px`,
            height: `${height}px`,
            display: "none",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            width: "32px",
          }}
        >
          <div
            onClick={() => {
              if (inputRef.current) {
                if (_selected.length > 0) {
                  inputRef.current.value = "";
                  _setSelected("");
                  onChange && onChange("", "", data);
                  _setData(data ?? []);
                }
              }
              if (clearRef.current) {
                clearRef.current.style.display = "none";
              }
              onClear && onClear();
            }}
            style={{
              width: "24px",
              height: "24px",
              cursor: "pointer",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {iconClear}
          </div>
        </div>

        {/* arrow down */}
        <div
          onClick={(e) => {
            e.preventDefault();
          }}
          style={{
            position: "absolute",
            top: 0,
            right: _suffixWidth,
            height: `${height}px`,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            width: `${height}px`,
          }}
        >
          <img
            ref={arrowDownRef}
            alt=""
            src={iconDropDown}
            style={{ height: "14px", width: "14px" }}
          />
        </div>

        {/* downdown selector */}
        <div
          ref={dropdownRef}
          onMouseDown={(e) => {
            e.preventDefault();
            e.stopPropagation();
          }}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
          }}
          className={`input-selector__downdown-menu hide-scrollbar`}
        >
          {dropdown !== undefined ? (
            dropdown((v, key) => {
              if (
                inputRef &&
                inputRef.current &&
                dropdownRef &&
                dropdownRef.current
              ) {
                inputRef.current.blur();
                inputRef.current.value = v;
                if (clearRef && clearRef.current && iconClear) {
                  if ((inputRef.current?.value ?? "").length > 0) {
                    clearRef.current.style.display = "flex";
                  } else {
                    clearRef.current.style.display = "none";
                  }
                }
                _setSelected(v);
                _setData(_data ?? []);
                onChange && onChange(v, key, _data);
              }
            })
          ) : (
            <div
              className="hide-scrollbar"
              style={{
                width: `100%`,
                overflowY: "auto",
                padding: "4px 0px",
                maxHeight: "240px",
                justifyContent: "flex-start",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              {_data.length === 0 && <NoDataSearch />}
              {_data.length > 0 &&
                _data.map((d, i) => {
                  return (
                    <label
                      onClick={() => {
                        if (
                          inputRef &&
                          inputRef.current &&
                          dropdownRef &&
                          dropdownRef.current
                        ) {
                          inputRef.current.blur();
                          inputRef.current.value =
                            getPropertyInObject<unknown, string | undefined>(
                              d,
                              refValue,
                            ) ?? "";
                          if (clearRef && clearRef.current && iconClear) {
                            if ((inputRef.current?.value ?? "").length > 0) {
                              clearRef.current.style.display = "flex";
                            } else {
                              clearRef.current.style.display = "none";
                            }
                          }
                          _setSelected(inputRef.current.value);
                          _setData(_data ?? []);

                          onChange &&
                            onChange(
                              inputRef.current.value,
                              getPropertyInObject<unknown, string | undefined>(
                                d,
                                refKey,
                              )?.toString() ?? "",
                              _data,
                            );
                        }
                      }}
                      key={`${d}-${i}`}
                      style={{
                        backgroundColor:
                          (getPropertyInObject<unknown, string | undefined>(
                            d,
                            refValue,
                          )?.toString() ?? "") === _selected
                            ? "#F3F4F6"
                            : undefined,
                      }}
                      className="input-selector__downdown-menu__item"
                    >
                      <span>
                        {getPropertyInObject<unknown, string | undefined>(
                          d,
                          refValue,
                        )}
                      </span>
                      {d === _selected && iconSelected}
                    </label>
                  );
                })}
            </div>
          )}
          {dropdownExtend !== undefined && (
            <div
              onMouseDown={(e) => {
                e.preventDefault();
                e.stopPropagation();
              }}
              className="column"
              style={{
                width: "100%",
              }}
            >
              {dropdownExtend}
            </div>
          )}
        </div>

        {/* error label */}
        {errorLabel && errorLabel.length > 0 && (
          <div className="input-selector__error-label">
            <span className="input-selector__error-label__content">
              {errorLabel}
            </span>
          </div>
        )}
      </div>
    </label>
  );
};

export const InputSelector: FC<InputSelectorProps<string>> = ({
  title = "",
  placeHolder = "",
  errorLabel = "",
  onChange = () => {},
  onSubmit = () => {},
  isReadOnly = false,
  onClear = () => {},
  autoComplete = "new-password",
  isFullwidth = false,
  isSuffixDivider = false,
  isPrefixDivider = false,
  isDisable = false,
  height = 40,
  iconClear,
  prefix,
  styleForm,
  suffix,
  allowSearch = false,
  iconDropDown,
  iconSearch,
  dropdown = undefined,
  dropdownExtend,
  data = [],
  defaultValue,
  iconSelected,
}) => {
  const prefixRef = useRef<HTMLDivElement>(null);
  const suffixRef = useRef<HTMLDivElement>(null);
  const arrowDownRef = useRef<HTMLImageElement>(null);
  const clearRef = useRef<HTMLDivElement>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const [_prefixWidth, _setPrefixWidth] = useState(0);
  const [_suffixWidth, _setSuffixWidth] = useState(0);
  const [_selected, _setSelected] = useState(defaultValue ?? "");
  const [_data, _setData] = useState(data);

  useEffect(() => {
    // let currentValue: string = "";

    // scope prefix ezist
    if (prefixRef.current) {
      _setPrefixWidth(prefixRef.current?.clientWidth);
    }

    // scope suffix ezist
    if (suffixRef.current) {
      _setSuffixWidth(suffixRef.current?.clientWidth);
    }

    if (inputRef.current) {
      inputRef.current.addEventListener("input", () => {
        if (!inputRef.current) {
          return;
        }
        if (!allowSearch) {
          inputRef.current.value = "";
        }

        // check clear
        if (clearRef && clearRef.current && iconClear) {
          if ((inputRef.current?.value ?? "").length > 0) {
            clearRef.current.style.display = "flex";
          } else {
            clearRef.current.style.display = "none";
          }
        }
      });
    }
  }, []);

  useEffect(() => {
    if (
      defaultValue === undefined &&
      inputRef &&
      inputRef.current &&
      inputRef.current.value.length > 0
    ) {
      inputRef.current.value = "";
      _setSelected("");
      _setData(data);

      if (clearRef && clearRef.current) {
        clearRef.current.style.display = "none";
      }
    } else if (
      defaultValue &&
      inputRef &&
      inputRef.current &&
      inputRef.current.value.length > 0 &&
      defaultValue !== inputRef.current.value
    ) {
      inputRef.current.value = defaultValue;
      _setSelected(defaultValue);
      _setData(data);
    }
  }, [defaultValue]);

  return (
    <label
      className="wrap-input-selector"
      style={{ width: isFullwidth ? "100%" : undefined }}
    >
      {/* title */}
      {title.length > 0 && (
        <span
          className="input-selector__title"
          // style={{
          //   color: isReadOnly ? "#9CA3AF" : undefined,
          // }}
        >
          {title}
        </span>
      )}

      <div
        style={{
          position: "relative",
          width: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          alignItems: "flex-start",
          gap: "2px",
          height: "fit-content",
        }}
      >
        <input
          autoComplete={autoComplete}
          style={Object.assign(
            {
              border: isDisable
                ? "1px solid  #D1D5DB"
                : errorLabel.length > 0
                  ? "1px solid #F05252"
                  : undefined,
              height: `${height}px`,
              paddingLeft: `${prefix !== undefined ? _prefixWidth + 12 : 12}px`,
              paddingRight: `${
                (iconClear !== undefined ? 32 : 0) +
                (iconDropDown !== null ? 32 : 0) +
                (suffix !== undefined ? _suffixWidth + 12 : 12)
              }px`,
              color: isDisable ? "#9CA3AF" : undefined,
              boxShadow: errorLabel.length > 0 ? "none" : undefined,
              backgroundColor: isDisable ? "#F3F4F6" : undefined,
              minWidth: "100%",
              caretColor: allowSearch ? undefined : "transparent",
            },
            styleForm,
          )}
          className="input-selector"
          onKeyDown={(e) => {
            if (onSubmit !== undefined) {
              if (e.code === "Enter" || e.code === "NumpadEnter") {
                e.preventDefault();
                onSubmit();
              }
            }
          }}
          disabled={isDisable}
          readOnly={isReadOnly}
          placeholder={placeHolder}
          ref={inputRef}
          onBlur={() => {
            if (
              allowSearch &&
              arrowDownRef &&
              arrowDownRef.current &&
              iconDropDown
            ) {
              arrowDownRef.current.src = iconDropDown;
            }
            if (dropdownRef && dropdownRef.current) {
              dropdownRef.current.style.display = "none";
            }
            if (inputRef && inputRef.current) {
              inputRef.current.placeholder = placeHolder;
              inputRef.current.value = _selected.length > 0 ? _selected : "";
              if (
                inputRef.current.value.length > 0 &&
                clearRef &&
                clearRef.current
              ) {
                clearRef.current.style.display = "flex";
              }
              _setData(data);
            }
          }}
          onFocus={() => {
            if (
              allowSearch &&
              arrowDownRef &&
              arrowDownRef.current &&
              iconSearch
            ) {
              arrowDownRef.current.src = iconSearch;
            }
            if (dropdownRef && dropdownRef.current) {
              dropdownRef.current.style.display = "flex";
            }

            if (inputRef && inputRef.current && _selected.length > 0) {
              inputRef.current.placeholder = _selected;
              inputRef.current.value = "";
              if (clearRef && clearRef.current) {
                clearRef.current.style.display = "none";
              }
            }
          }}
          onChange={(e) => {
            if (allowSearch) {
              let tmp: string[] = [];
              if (e.target.value.length > 0) {
                tmp = [...data].filter((ele) => ele.includes(e.target.value));
              } else {
                tmp = [...data];
              }
              // if (inputRef&&inputRef.current) {
              //   inputRef.current.value = e.target.value;
              // }

              _setData(tmp);
            }
          }}
          defaultValue={defaultValue}
        />

        {prefix !== undefined && (
          <div
            ref={prefixRef}
            onClick={() => {
              // e.preventDefault();
            }}
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              height: `${height}px`,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <div
              style={{
                borderRight: isPrefixDivider ? "1px solid #E2E9FC" : undefined,
                display: "flex",
                width: "fit-content",
                height: "fit-content",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                cursor: "pointer",
              }}
            >
              {prefix}
            </div>
          </div>
        )}

        {suffix !== undefined && (
          <div
            ref={suffixRef}
            onClick={(e) => {
              e.preventDefault();
            }}
            style={{
              position: "absolute",
              top: 0,
              right: 0,
              height: `${height}px`,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <div
              onClick={(e) => {
                e.preventDefault();
              }}
              style={{
                // padding: "8px 8px",
                borderLeft: isSuffixDivider ? "1px solid #E2E9FC" : undefined,
                display: "flex",
                width: "fit-content",
                height: "fit-content",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                cursor: "pointer",
              }}
            >
              {suffix}
            </div>
          </div>
        )}

        <div
          ref={clearRef}
          style={{
            position: "absolute",
            top: 0,
            right: `${_suffixWidth + 32}px`,
            height: `${height}px`,
            display: "none",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            width: "32px",
          }}
        >
          <div
            onClick={() => {
              if (inputRef.current) {
                if (_selected.length > 0) {
                  inputRef.current.value = "";
                  _setSelected("");
                  onChange("", "", data);
                  _setData(data);
                }
              }
              if (clearRef.current) {
                clearRef.current.style.display = "none";
              }
              onClear();
            }}
            style={{
              width: "24px",
              height: "24px",
              cursor: "pointer",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {iconClear}
          </div>
        </div>

        {/* arrow down */}
        {iconDropDown && (
          <div
            onClick={(e) => {
              e.preventDefault();
            }}
            style={{
              position: "absolute",
              top: 0,
              right: _suffixWidth,
              height: `${height}px`,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              width: `${height}px`,
            }}
          >
            <img
              ref={arrowDownRef}
              alt=""
              src={iconDropDown}
              style={{ height: "14px", width: "14px" }}
            />
          </div>
        )}

        {/* downdown selector */}
        <div
          ref={dropdownRef}
          onMouseDown={(e) => {
            e.preventDefault();
            e.stopPropagation();
          }}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
          }}
          className={`input-selector__downdown-menu hide-scrollbar`}
        >
          {dropdown !== undefined ? (
            dropdown((v, key) => {
              if (
                inputRef &&
                inputRef.current &&
                dropdownRef &&
                dropdownRef.current
              ) {
                inputRef.current.blur();
                inputRef.current.value = v;
                if (clearRef && clearRef.current && iconClear) {
                  if ((inputRef.current?.value ?? "").length > 0) {
                    clearRef.current.style.display = "flex";
                  } else {
                    clearRef.current.style.display = "none";
                  }
                }
                _setSelected(v);
                _setData(data);
                onChange(v, key.toString(), data);
              }
            })
          ) : (
            <div
              className="hide-scrollbar"
              style={{
                width: `100%`,
                overflowY: "auto",
                padding: "4px 0px",
                maxHeight: "250px",
                justifyContent: "flex-start",
              }}
            >
              {data.length === 0 && <NoDataSearch />}
              {data.length > 0 &&
                _data.map((d, i) => {
                  return (
                    <div
                      onClick={() => {
                        if (
                          inputRef &&
                          inputRef.current &&
                          dropdownRef &&
                          dropdownRef.current
                        ) {
                          inputRef.current.blur();
                          inputRef.current.value = d;
                          if (clearRef && clearRef.current && iconClear) {
                            if ((inputRef.current?.value ?? "").length > 0) {
                              clearRef.current.style.display = "flex";
                            } else {
                              clearRef.current.style.display = "none";
                            }
                          }
                          _setSelected(d);
                          _setData(data);
                          onChange(d, i.toString(), data);
                        }
                      }}
                      key={`${d}-${i}`}
                      style={{
                        backgroundColor:
                          d === _selected ? "#F3F4F6" : undefined,
                      }}
                      className="input-selector__downdown-menu__item"
                    >
                      <span>{d}</span>
                      {d === _selected && iconSelected}
                    </div>
                  );
                })}
            </div>
          )}
          {dropdownExtend !== undefined && (
            <div
              onMouseDown={(e) => {
                e.preventDefault();
                e.stopPropagation();
              }}
              className="column"
              style={{
                width: "100%",
              }}
            >
              {dropdownExtend}
            </div>
          )}
        </div>

        {/* error label */}
        {errorLabel.length > 0 && (
          <div className="input-selector__error-label">
            <span className="input-selector__error-label__content">
              {errorLabel}
            </span>
          </div>
        )}
      </div>
    </label>
  );
};
