import { CSSProperties, ReactNode } from "react";
import "./style.less";

interface ItemInfoPreviewInfoProps {
  title?: string;
  content?: ReactNode;
  icon: ReactNode;
  font?: string;
  style?: CSSProperties;
  isDivider?: boolean;
  onTap?: () => void;
}

export const ItemInfoPreviewInfo: React.FC<ItemInfoPreviewInfoProps> = ({
  content,
  icon,
  title,
  font,
  style,
  isDivider = true,
  onTap,
}) => {
  return (
    <div
      style={Object.assign(
        {
          borderBottom: isDivider ? undefined : "none",
        },
        style,
      )}
      className="create-qr-vcard-preview__item-info"
      onClick={onTap}
    >
      {icon}
      <div
        className="column"
        style={{
          gap: "4px",
          boxSizing: "border-box",
          width: "calc(100% - 24px - 12px)",
          alignItems: "flex-start",
        }}
      >
        <span
          className="text-12--reg"
          style={{
            color: "#707070",
            font: font,
            boxSizing: "border-box",
            width: "100%",
          }}
        >
          {title}
        </span>
        <span
          className="text-12--reg text-overflow--new-line"
          style={{
            color: "#333333",
            font: font,
            boxSizing: "border-box",
            width: "100%",
          }}
        >
          {content}
        </span>
      </div>
    </div>
  );
};
