import { ErrorModel } from "@/models/ErrorModel";
import { PaymentRepository } from "../PaymentRepository";
import { QrfyHttpClient } from "../QrfyRepositories";
import { PaymentMethodType } from "../models/PaymentModel";

export class PaymentRepositoryImp implements PaymentRepository {
  private client: QrfyHttpClient;
  constructor(c: QrfyHttpClient) {
    this.client = c;
  }

  public async klbPay({
    planId,
    paymentMethod,
  }: {
    planId: string;
    paymentMethod: PaymentMethodType;
  }): Promise<{
    orderId: string;
    paylink: string;
  }> {
    try {
      const resp = await this.client.payment.klbPay({
        paymentRequest: {
          paymentMethod: paymentMethod,
          planId: planId,
        },
      });
      if (resp.data.code === 0) {
        return {
          orderId: resp.data.data?.orderId ?? "",
          paylink: resp.data.data?.payLink ?? "",
        };
      } else {
        throw new ErrorModel({
          code: resp.data.code,
          message: resp.data.message,
        });
      }
    } catch (error) {
      throw error;
    }
  }
}
