export const IconCloseX32 = ({ color = "#5C5C5C" }: { color?: string }) => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.6259 8.62688C9.01643 8.23635 9.64959 8.23635 10.0401 8.62688L15.9997 14.5864L21.9592 8.62688C22.3498 8.23635 22.9829 8.23635 23.3734 8.62688C23.764 9.0174 23.764 9.65057 23.3734 10.0411L17.4139 16.0007L23.3734 21.9602C23.764 22.3507 23.764 22.9839 23.3734 23.3744C22.9829 23.765 22.3498 23.765 21.9592 23.3744L15.9997 17.4149L10.0401 23.3744C9.64959 23.765 9.01643 23.765 8.6259 23.3744C8.23538 22.9839 8.23538 22.3507 8.6259 21.9602L14.5855 16.0007L8.6259 10.0411C8.23538 9.65057 8.23538 9.0174 8.6259 8.62688Z"
        fill={color}
      />
    </svg>
  );
};
