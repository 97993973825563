export const IconTriangleX20 = ({ color = "#ADADAD" }: { color?: string }) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.62718 1.875C9.84923 1.875 10.0546 1.99281 10.1667 2.18449L13.8948 8.55949C14.0078 8.75278 14.0088 8.99177 13.8974 9.18599C13.786 9.38022 13.5792 9.5 13.3553 9.5H5.89911C5.6752 9.5 5.4684 9.38022 5.35698 9.18599C5.24556 8.99177 5.24656 8.75278 5.35959 8.55949L9.08766 2.18449C9.19976 1.99281 9.40513 1.875 9.62718 1.875ZM6.98864 8.25H12.2657L9.62718 3.73808L6.98864 8.25ZM14.1009 12.125C12.8023 12.125 11.7434 13.1849 11.7434 14.5C11.7434 15.8151 12.8023 16.875 14.1009 16.875C15.3994 16.875 16.4583 15.8151 16.4583 14.5C16.4583 13.1849 15.3994 12.125 14.1009 12.125ZM10.4934 14.5C10.4934 12.5014 12.1051 10.875 14.1009 10.875C16.0967 10.875 17.7083 12.5014 17.7083 14.5C17.7083 16.4986 16.0967 18.125 14.1009 18.125C12.1051 18.125 10.4934 16.4986 10.4934 14.5ZM2.29166 11.875C2.29166 11.5298 2.57148 11.25 2.91666 11.25H8.13595C8.48113 11.25 8.76095 11.5298 8.76095 11.875V17.125C8.76095 17.4702 8.48113 17.75 8.13595 17.75H2.91666C2.57148 17.75 2.29166 17.4702 2.29166 17.125V11.875ZM3.54166 12.5V16.5H7.51095V12.5H3.54166Z"
        fill={color}
      />
    </svg>
  );
};
