import { editorLayoutHeaderId } from "@/services/constants";
import "./header-editor.css";
import "@/styles";
import { icLogoOnepoint } from "@/services/assets";

const IconMenu = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.875 4.16666C1.875 3.82148 2.15482 3.54166 2.5 3.54166H17.5C17.8452 3.54166 18.125 3.82148 18.125 4.16666C18.125 4.51183 17.8452 4.79166 17.5 4.79166H2.5C2.15482 4.79166 1.875 4.51183 1.875 4.16666ZM1.875 9.99999C1.875 9.65481 2.15482 9.37499 2.5 9.37499H17.5C17.8452 9.37499 18.125 9.65481 18.125 9.99999C18.125 10.3452 17.8452 10.625 17.5 10.625H2.5C2.15482 10.625 1.875 10.3452 1.875 9.99999ZM1.875 15.8333C1.875 15.4881 2.15482 15.2083 2.5 15.2083H17.5C17.8452 15.2083 18.125 15.4881 18.125 15.8333C18.125 16.1785 17.8452 16.4583 17.5 16.4583H2.5C2.15482 16.4583 1.875 16.1785 1.875 15.8333Z"
        fill="#333333"
      />
    </svg>
  );
};

interface HeaderEditorProps {
  onShowDrawer?: () => void;
}
export const HeaderEditor: React.FC<HeaderEditorProps> = ({ onShowDrawer }) => {
  return (
    <header
      className="header-editor sidebar-expanded"
      id={editorLayoutHeaderId}
    >
      <div
        className={"row"}
        style={{
          width: "100%",
          flex: "1",
          padding: "10px",
        }}
      >
        <img alt={"logo app"} src={icLogoOnepoint} style={{ height: "28px" }} />
      </div>
      <div
        onClick={onShowDrawer}
        className={"row"}
        style={{
          width: "48px",
          height: "48px",
          justifyContent: "center",
          alignItems: "center",
          cursor: "pointer",
        }}
      >
        <IconMenu />
      </div>
    </header>
  );
};
