export interface PlanModel {
  id: string;
  name: string;
  description: string;
  price: number;
  timeUnit: PlanTimeUnitType;
  value: number;
  enable: boolean;
  createDate: string;
  trial: boolean;
}

export enum PlanTimeUnitType {
  DATE = "DATE",
  MONTH = "MONTH",
}
