import { FC, useEffect, useId, useState } from "react";
import "./input-check.css";

const IconCheckLocal = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.1374 4.19526C13.3978 4.45561 13.3978 4.87772 13.1374 5.13807L7.13742 11.1381C6.87707 11.3984 6.45496 11.3984 6.19461 11.1381L2.86128 7.80474C2.60093 7.54439 2.60093 7.12228 2.86128 6.86193C3.12163 6.60158 3.54374 6.60158 3.80409 6.86193L6.66602 9.72386L12.1946 4.19526C12.455 3.93491 12.8771 3.93491 13.1374 4.19526Z"
        fill="white"
      />
    </svg>
  );
};

const IconCheckDisabledLocal = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.1374 4.19526C13.3978 4.45561 13.3978 4.87772 13.1374 5.13807L7.13742 11.1381C6.87707 11.3984 6.45496 11.3984 6.19461 11.1381L2.86128 7.80474C2.60093 7.54439 2.60093 7.12228 2.86128 6.86193C3.12163 6.60158 3.54374 6.60158 3.80409 6.86193L6.66602 9.72386L12.1946 4.19526C12.455 3.93491 12.8771 3.93491 13.1374 4.19526Z"
        fill="#E5E7EB"
      />
    </svg>
  );
};

type InputCheckProps = {
  defaultValue?: boolean;
  onChange?: (v: boolean) => void;
  disable?: boolean;
  prefix?: React.ReactNode;
  suffix?: React.ReactNode;
  wrapStyle?: React.CSSProperties;
  wrapClass?: string;
};

export const InputCheck: FC<InputCheckProps> = ({
  defaultValue = false,
  onChange = () => {},
  disable = false,
  prefix,
  suffix,
  wrapStyle,
  wrapClass,
}) => {
  const [check, setCheck] = useState(defaultValue);
  const id = useId();

  useEffect(() => {
    setCheck(defaultValue);
  }, [defaultValue]);

  return (
    <label className={`wrap-input-check ${wrapClass ?? ""}`} style={wrapStyle}>
      {prefix && (
        <label style={{ cursor: "pointer", width: "100%" }} htmlFor={id}>
          {prefix}
        </label>
      )}
      <div
        style={{
          position: "relative",
          width: "fit-content",
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          alignItems: "flex-start",
          height: "fit-content",
        }}
      >
        <input
          id={id}
          checked={check}
          disabled={disable}
          className="input-check"
          type="checkbox"
          onChange={() => {
            let tmp = check;
            setCheck(!tmp);
            onChange(!tmp);
          }}
        />
        {check && (
          <div
            style={{
              width: "16px",
              height: "16px",
              position: "absolute",
              top: "2px",
              left: "2px",
            }}
          >
            {disable ? <IconCheckDisabledLocal /> : <IconCheckLocal />}
          </div>
        )}
      </div>

      {suffix && (
        <label style={{ cursor: "pointer", width: "100%" }} htmlFor={id}>
          {suffix}
        </label>
      )}
    </label>
  );
};
