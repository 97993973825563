import { FrameQrStyle } from "./interfaces";

export const QrFrame3 = ({
  style = {
    backGroundColor: "#D9D9D9",
    content:
      "https://cdn.britannica.com/17/155017-050-9AC96FC8/Example-QR-code.jpg",
    frameColor: "#000000",
    text: "scan me",
    textColor: "#FFFFFF",
    textFontSize: "32",
    textFontWeight: "500",
  },
}: {
  style?: FrameQrStyle;
}) => {
  return (
    <svg viewBox="0 0 340 471" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M272.716 71.6037V398.487H41.124V89.4006H41.4001L41.6762 89.3828L272.716 71.6037ZM272.716 64.46L41.124 82.2747C37.1869 82.2747 33.998 85.4636 33.998 89.4006V398.487C33.998 402.424 37.1869 405.613 41.124 405.613H272.716C276.653 405.613 279.842 402.424 279.842 398.487V71.5859C279.842 67.6488 276.653 64.46 272.716 64.46Z"
        fill={style.frameColor}
      />
      <path
        d="M298.65 405.613H41.3495C37.2878 405.613 33.998 402.426 33.998 398.492V89.631C33.998 85.6968 37.2878 82.5103 41.3495 82.5103H298.65C302.712 82.5103 306.002 85.6968 306.002 89.631V398.492C306.002 402.426 302.712 405.613 298.65 405.613Z"
        fill={style.frameColor}
      />
      <path
        d="M40.3252 92.1169C40.3252 90.3701 41.7412 88.9541 43.488 88.9541H296.511C298.258 88.9541 299.674 90.3701 299.674 92.1169V346.722H40.3252V92.1169Z"
        fill={style.backGroundColor}
      />

      <image
        x="64.8369"
        y="112.5577"
        width="210.326"
        height="210.326"
        href={style.content}
      />
      <foreignObject
        width="78%"
        height="128"
        x="11%"
        y="345"
        fontSize={`${style.textFontSize}px`}
        fontWeight={`${style.textFontWeight}`}
        dominantBaseline="middle"
        textAnchor="middle"
        alignmentBaseline="central"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "60",
        }}
      >
        <p
          style={{
            margin: "0",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "inherit",
            color: style.textColor,
            width: "100%",
            textAlign: "center",
            fontSize: `${style.textFontSize}px`,
            lineHeight: `${style.textFontSize}px`,
            fontWeight: style.textFontWeight,
            wordWrap: "break-word",
            whiteSpace: "initial",
            verticalAlign: "middle",
          }}
        >
          {style?.text}
        </p>
      </foreignObject>
      <path
        d="M49.0754 146.268C50.554 147.746 50.2219 150.237 48.3875 151.257C45.9363 152.625 42.7656 153.598 39.2233 153.985C38.1084 154.104 36.9619 154.167 35.7837 154.167C25.9554 154.167 17.993 149.739 17.993 144.283C17.993 138.819 25.9554 134.399 35.7837 134.399C36.9619 134.399 38.1084 134.463 39.2233 134.581V140.954C38.1716 140.812 37.0251 140.725 35.7837 140.725C29.1498 140.725 25.1726 143.113 24.3898 144.283C25.1726 145.445 29.1498 147.841 35.7837 147.841C37.0251 147.841 38.1716 147.754 39.2233 147.612C41.9116 147.24 43.9675 146.489 45.3354 145.73C46.5609 145.058 48.0791 145.279 49.0754 146.268Z"
        fill={style.frameColor}
      />
      <path
        d="M49.0754 219.834C50.554 221.313 50.2219 223.803 48.3875 224.823C45.9363 226.191 42.7656 227.164 39.2233 227.551C38.1084 227.67 36.9619 227.733 35.7837 227.733C25.9554 227.733 17.993 223.305 17.993 217.85C17.993 212.386 25.9554 207.966 35.7837 207.966C36.9619 207.966 38.1084 208.029 39.2233 208.148V214.521C38.1716 214.378 37.0251 214.291 35.7837 214.291C29.1498 214.291 25.1726 216.679 24.3898 217.85C25.1726 219.012 29.1498 221.408 35.7837 221.408C37.0251 221.408 38.1716 221.321 39.2233 221.178C41.9116 220.807 43.9675 220.056 45.3354 219.297C46.5609 218.624 48.0791 218.846 49.0754 219.834Z"
        fill={style.frameColor}
      />
      <path
        d="M49.0754 293.377C50.554 294.856 50.2219 297.346 48.3875 298.366C45.9363 299.734 42.7656 300.707 39.2233 301.094C38.1084 301.213 36.9619 301.276 35.7837 301.276C25.9554 301.276 17.993 296.848 17.993 291.393C17.993 285.929 25.9554 281.509 35.7837 281.509C36.9619 281.509 38.1084 281.572 39.2233 281.691V288.064C38.1716 287.921 37.0251 287.834 35.7837 287.834C29.1498 287.834 25.1726 290.222 24.3898 291.393C25.1726 292.555 29.1498 294.951 35.7837 294.951C37.0251 294.951 38.1716 294.864 39.2233 294.721C41.9116 294.35 43.9675 293.599 45.3354 292.84C46.5609 292.167 48.0791 292.389 49.0754 293.377Z"
        fill={style.frameColor}
      />
    </svg>
  );
};
