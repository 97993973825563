export const IconLogout = ({ color = "#FF0000" }: { color?: string }) => {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.60938 2.625C3.12613 2.625 2.73438 3.01675 2.73438 3.5V24.5C2.73438 24.9832 3.12613 25.375 3.60938 25.375H14.1094C14.5926 25.375 14.9844 24.9832 14.9844 24.5C14.9844 24.0168 14.5926 23.625 14.1094 23.625H4.48438V4.375H14.1094C14.5926 4.375 14.9844 3.98325 14.9844 3.5C14.9844 3.01675 14.5926 2.625 14.1094 2.625H3.60938ZM19.5063 9.95205C19.1646 9.61034 19.1646 9.05632 19.5063 8.71461C19.848 8.37291 20.402 8.37291 20.7437 8.71461L25.4104 13.3813C25.7521 13.723 25.7521 14.277 25.4104 14.6187L20.7437 19.2854C20.402 19.6271 19.848 19.6271 19.5063 19.2854C19.1646 18.9437 19.1646 18.3897 19.5063 18.0479L22.6792 14.875H9.625C9.14175 14.875 8.75 14.4832 8.75 14C8.75 13.5168 9.14175 13.125 9.625 13.125H22.6792L19.5063 9.95205Z"
        fill={color}
      />
    </svg>
  );
};
