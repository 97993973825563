import { FrameQrStyle } from "./interfaces";

export const QrFrame1 = ({
  style = {
    backGroundColor: "#D9D9D9",
    content:
      "https://cdn.britannica.com/17/155017-050-9AC96FC8/Example-QR-code.jpg",
    frameColor: "#000000",
    text: "scan me",
    textColor: "#FFFFFF",
    textFontSize: "32",
    textFontWeight: "500",
  },
}: {
  style?: FrameQrStyle;
}) => {
  return (
    <svg viewBox="0 0 340 471" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_2166_67620)">
        <rect
          x="35.5814"
          y="59.3022"
          width="268.837"
          height="268.837"
          rx="3.16279"
          fill={style.backGroundColor}
        />
        <rect
          width="221.395"
          height="221.395"
          transform="translate(59.3023 83.0229)"
          fill={style.backGroundColor}
        />
        <rect
          width="210.326"
          height="210.326"
          transform="translate(65.9442 89.665)"
          fill={style.backGroundColor}
        />
        <rect
          width="210.326"
          height="210.326"
          transform="translate(65.9442 89.665)"
          fill={style.backGroundColor}
        />
        <image
          x="64.8369"
          y="88.5577"
          width="210.326"
          height="210.326"
          href={style.content}
        />
      </g>
      <path
        d="M0 35.5812V351.86C0 355.006 1.24958 358.023 3.47385 360.247C5.69812 362.471 8.71487 363.721 11.8605 363.721H328.14C331.285 363.721 334.302 362.471 336.526 360.247C338.75 358.023 340 355.006 340 351.86V35.5812C340 32.4356 338.75 29.4188 336.526 27.1946C334.302 24.9703 331.285 23.7207 328.14 23.7207H11.8605C8.71487 23.7207 5.69812 24.9703 3.47385 27.1946C1.24958 29.4188 0 32.4356 0 35.5812ZM328.14 351.86H11.8605V35.5812H328.14V351.86Z"
        fill={style.frameColor}
      />
      <path
        d="M328.14 379.535H11.8605C5.31011 379.535 0 384.845 0 391.395V434.884C0 441.434 5.31011 446.744 11.8605 446.744H328.14C334.69 446.744 340 441.434 340 434.884V391.395C340 384.845 334.69 379.535 328.14 379.535Z"
        fill={style.frameColor}
      />
      <foreignObject
        width="78%"
        height="128"
        x="11%"
        y="382"
        fontSize={`${style.textFontSize}px`}
        fontWeight={`${style.textFontWeight}`}
        dominantBaseline="middle"
        textAnchor="middle"
        alignmentBaseline="central"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "60",
        }}
      >
        <p
          style={{
            margin: "0",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "inherit",
            color: style.textColor,
            width: "100%",
            textAlign: "center",
            fontSize: `${style.textFontSize}px`,
            lineHeight: `${style.textFontSize}px`,
            fontWeight: style.textFontWeight,
            wordWrap: "break-word",
            whiteSpace: "initial",
            verticalAlign: "middle",
          }}
        >
          {style?.text}
        </p>
      </foreignObject>

      <defs>
        <clipPath id="clip0_2166_67620">
          <rect
            x="35.5814"
            y="59.3022"
            width="268.837"
            height="268.837"
            rx="3.16279"
            fill="white"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
