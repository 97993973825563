import { Navigate, Outlet, createBrowserRouter } from "react-router-dom";
import { AppBloc } from "./services/base-bloc";
import { NavlinkPath } from "./services/router-config";
import { I18nextProvider } from "react-i18next";
import i18n from "./services/i18n";
import { AppContextProvider } from "./contexts/AppContext";
import { ViewportContextProvider } from "./contexts/ViewPortContext";
import { FutureComponent } from "./components/future-component/FutureComponent";
import { Suspense, lazy } from "react";
import AuthPage from "./pages/auth";
import EditorLayout from "./pages/editor";
import App from "./App";
import Environment from "./services/environment";
import CreateQrPage from "./pages/create-qr";

const PageNotFound = lazy(
  () => import("@/components/page-not-found/PageNotFound"),
);

const PublicQrPage = lazy(() => import("@/pages/public-qr"));

//---------auth-------------//
const SocialAuth = lazy(() => import("@/pages/social-auth"));
const Login = lazy(() => import("@/pages/login"));
const Register = lazy(() => import("@/pages/register"));
const ResetPasswordValidEmail = lazy(
  () => import("@/pages/reset-password-valid-email"),
);
const ResetPassword = lazy(() => import("@/pages/reset-password"));

// --------profile---------//
const ProfilePage = lazy(() => import("@/pages/profile"));

// --------qrs-------------//
const MyQrPage = lazy(() => import("@/pages/my-qr"));
// const CreateQrPage = lazy(() => import("@/pages/create-qr"));
const DetailQrPage = lazy(() => import("@/pages/detail-qr"));

// --------billing-------------//
const BillingPage = lazy(() => import("@/pages/billing"));

// --------checkout-------------//
const CheckoutPage = lazy(() => import("@/pages/checkout"));

export function appRouterConfig(appBloc: AppBloc) {
  return createBrowserRouter(
    [
      {
        path: NavlinkPath.root,
        element: (
          <I18nextProvider i18n={i18n}>
            <FutureComponent>
              <AppContextProvider appProps={appBloc}>
                <ViewportContextProvider>
                  <App />
                </ViewportContextProvider>
              </AppContextProvider>
            </FutureComponent>
          </I18nextProvider>
        ),
        children: [
          {
            index: true,
            element: (
              <Navigate
                replace
                to={
                  appBloc.storage.isLogin ? NavlinkPath.home : NavlinkPath.login
                }
              />
            ),
          },
          {
            path: NavlinkPath.home,
            element: (
              <EditorLayout>
                <Outlet />
              </EditorLayout>
            ),
            children: [
              {
                index: true,
                element: <Navigate replace to={NavlinkPath.creatQrCode} />,
              },
              {
                path: NavlinkPath.qrCode,
                element: <Outlet />,
                children: [
                  {
                    index: true,
                    element: (
                      <Suspense>
                        <MyQrPage />
                      </Suspense>
                    ),
                  },
                  {
                    path: NavlinkPath.detailQrCode,
                    element: (
                      <Suspense>
                        <DetailQrPage />
                      </Suspense>
                    ),
                  },
                  {
                    path: NavlinkPath.editQrCode,
                    element: (
                      <Suspense>
                        <CreateQrPage key={"edit-qr"} />
                      </Suspense>
                    ),
                  },
                ],
              },
              {
                path: NavlinkPath.creatQrCode,
                element: (
                  <Suspense>
                    <CreateQrPage key={"create-qr"} />
                  </Suspense>
                ),
              },
              {
                path: NavlinkPath.billing,
                element: (
                  <Suspense>
                    <BillingPage />
                  </Suspense>
                ),
              },
              {
                path: NavlinkPath.profile,
                element: (
                  <Suspense>
                    <ProfilePage />
                  </Suspense>
                ),
              },
            ],
          },
          {
            path: NavlinkPath.checkout,
            element: (
              <Suspense>
                <CheckoutPage />
              </Suspense>
            ),
          },
          {
            path: NavlinkPath.auth,
            element: (
              <AuthPage>
                <Outlet />
              </AuthPage>
            ),
            children: [
              {
                index: true,
                element: (
                  <Navigate
                    replace
                    to={`${NavlinkPath.login}${window.location.search}`}
                  />
                ),
              },
              {
                path: NavlinkPath.login,
                element: (
                  <Suspense>
                    <Login />
                  </Suspense>
                ),
              },
              {
                path: NavlinkPath.register,
                element: (
                  <Suspense>
                    <Register />
                  </Suspense>
                ),
              },
              {
                path: NavlinkPath.socialAuthRedirect,
                element: (
                  <Suspense>
                    <SocialAuth />
                  </Suspense>
                ),
              },
              {
                path: NavlinkPath.resetPasswordValidEmail,
                element: (
                  <Suspense>
                    <ResetPasswordValidEmail />
                  </Suspense>
                ),
              },
              {
                path: NavlinkPath.resetPassword,
                element: (
                  <Suspense>
                    <ResetPassword />
                  </Suspense>
                ),
              },
            ],
          },
          {
            path: NavlinkPath.publicQr,
            children: [
              {
                path: NavlinkPath.publicQr,
                element: (
                  <Suspense>
                    <PublicQrPage />
                  </Suspense>
                ),
              },
            ],
            errorElement: (
              <Suspense>
                <div style={{ width: "100vw", display: "block" }}>
                  <PageNotFound />
                </div>
              </Suspense>
            ),
          },
        ],
        errorElement: (
          <Suspense>
            <div style={{ width: "100vw", display: "block" }}>
              <PageNotFound />
            </div>
          </Suspense>
        ),
      },
    ],
    { basename: Environment.hostBasePath ?? "/" },
  );
}
