import { ErrorModel } from "@/models/ErrorModel";
import { QrfyHttpClient } from "../QrfyRepositories";
import { UserRepository } from "../UserRepository";
import { mapAccessTokenModel } from "../adapters/access-token-adapter";
import { AccessTokenModel } from "../models/AccessTokenModel";
import { mapUserDetailMode, mapUserModel } from "../adapters/user-adapter";
import { UserDetailModel, UserModel } from "../models/UserModel";
import { Currency } from "../models/Curency";

export class UserRepositoryImp implements UserRepository {
  private client: QrfyHttpClient;
  constructor(c: QrfyHttpClient) {
    this.client = c;
  }

  public async register({
    email,
    password,
  }: {
    email: string;
    password: string;
  }): Promise<AccessTokenModel> {
    try {
      const resp = await this.client.user.register({
        registerUserRequest: {
          password: password,
          email: email,
        },
      });
      if (resp.data.code === 0) {
        return mapAccessTokenModel(resp.data.data);
      } else {
        throw new ErrorModel({
          code: resp.data.code,
          message: resp.data.message,
        });
      }
    } catch (error) {
      throw error;
    }
  }

  public async updatePassword({
    currentPassword,
    newPassword,
  }: {
    currentPassword: string;
    newPassword: string;
  }): Promise<boolean> {
    try {
      const resp = await this.client.user.updatePassword({
        changePasswordRequest: {
          currentPassword: currentPassword,
          newPassword: newPassword,
        },
      });
      if (resp.data.code === 0) {
        return resp.data.data?.success ?? false;
      } else {
        throw new ErrorModel({
          code: resp.data.code,
          message: resp.data.message,
        });
      }
    } catch (error) {
      throw error;
    }
  }

  public async updateAvatar({
    avatarUrl,
  }: {
    avatarUrl: string;
  }): Promise<UserModel> {
    try {
      const resp = await this.client.user.updateAvatar({
        updateAvatarRequest: {
          url: avatarUrl,
        },
      });
      if (resp.data.code === 0) {
        return mapUserModel(resp.data.data);
      } else {
        throw new ErrorModel({
          code: resp.data.code,
          message: resp.data.message,
        });
      }
    } catch (error) {
      throw error;
    }
  }

  public async updateInfo({
    email,
    name,
    phone,
  }: {
    email: string;
    name: string;
    phone: string;
  }): Promise<UserModel> {
    try {
      const resp = await this.client.user.updateInfo({
        updateUserInfoRequest: {
          email: email,
          name: name,
          phone: phone,
        },
      });
      if (resp.data.code === 0) {
        return mapUserModel(resp.data.data);
      } else {
        throw new ErrorModel({
          code: resp.data.code,
          message: resp.data.message,
        });
      }
    } catch (error) {
      throw error;
    }
  }

  public async updateBillData({
    address,
    country,
    currency,
    email,
    firstname,
    lastName,
    companyName,
    taxId,
  }: {
    address: string;
    country: string;
    currency: Currency;
    email: string;
    firstname: string;
    lastName: string;
    companyName?: string;
    taxId?: string;
  }): Promise<UserDetailModel> {
    try {
      const resp = await this.client.user.updateBillData({
        updateBillDataRequest: {
          address: address,
          country: country,
          currency: currency,
          email: email,
          firstname: firstname,
          lastName: lastName,
          companyName: companyName,
          taxId: taxId,
        },
      });
      if (resp.data.code === 0) {
        return mapUserDetailMode(resp.data.data);
      } else {
        throw new ErrorModel({
          code: resp.data.code,
          message: resp.data.message,
        });
      }
    } catch (error) {
      throw error;
    }
  }

  public async getUserInfo(): Promise<UserDetailModel> {
    try {
      const resp = await this.client.user.getUserInfo();
      if (resp.data.code === 0) {
        return mapUserDetailMode(resp.data.data);
      } else {
        throw new ErrorModel({
          code: resp.data.code,
          message: resp.data.message,
        });
      }
    } catch (error) {
      throw error;
    }
  }

  public async validEmailResetPassword({
    email,
  }: {
    email: string;
  }): Promise<boolean> {
    try {
      const resp = await this.client.user.resetPassword({
        resetPasswordRequest: { email: email },
      });
      if (resp.data.code === 0) {
        return resp.data.data?.success ?? false;
      } else {
        throw new ErrorModel({
          code: resp.data.code,
          message: resp.data.message,
        });
      }
    } catch (error) {
      throw error;
    }
  }

  public async submitResetPassword({
    email,
    newPassword,
    session,
  }: {
    email: string;
    newPassword: string;
    session: string;
  }): Promise<AccessTokenModel> {
    try {
      const resp = await this.client.user.submitResetPassword({
        resetPasswordSubmitRequest: {
          email: email,
          newPassword: newPassword,
          session: session,
        },
      });
      if (resp.data.code === 0) {
        return mapAccessTokenModel(resp.data.data);
      } else {
        throw new ErrorModel({
          code: resp.data.code,
          message: resp.data.message,
        });
      }
    } catch (error) {
      throw error;
    }
  }
}
