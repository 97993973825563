import {
  SubscriptionDto,
  UserDetailResponse,
  UserResponse,
} from "../../generated-sources/qr-code/api";
import { SubscriptionModel } from "../models/SubcriptionModel";
import { UserDetailModel, UserModel, UserRole } from "../models/UserModel";
import { mapBillDataModel } from "./bill-data-adapter";

export function mapUserModel(dto?: UserResponse): UserModel {
  return {
    id: dto?.id ?? "",
    avatar: dto?.avatar ?? "",
    createDate: dto?.createDate ?? "",
    email: dto?.email ?? "",
    name: dto?.name ?? "",
    projectId: dto?.projectId ?? "",
    projectName: dto?.projectName ?? "",
    role: UserRole[dto?.role ?? ("USER" as keyof typeof UserRole)],
    username: dto?.username ?? "",
  };
}

export function mapUserDetailMode(dto?: UserDetailResponse): UserDetailModel {
  return {
    avatar: dto?.avatar ?? "",
    billingData: mapBillDataModel(dto?.billingData),
    createDate: dto?.createDate ?? "",
    email: dto?.email ?? "",
    id: dto?.id ?? "",
    name: dto?.name ?? "",
    role: UserRole[dto?.role ?? ("USER" as keyof typeof UserRole)],
    username: dto?.username ?? "",
    subscription: mapSubscriptionModel(dto?.subscription),
  };
}

export function mapSubscriptionModel(dto?: SubscriptionDto): SubscriptionModel {
  return {
    id: dto?.id,
    createDate: dto?.createDate,
    expireTime: dto?.expireTime,
    orderId: dto?.orderId,
    payTime: dto?.payTime,
    planId: dto?.planId,
    trial: dto?.trial,
  };
}
