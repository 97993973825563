/* eslint-disable no-useless-escape */

// validate phone
export const regexPhone = /(0[3|5|7|8|9])+([0-9]{8})\b/;
export function validPhoneNumber(phoneNumber: string) {
  if (phoneNumber.replaceAll(" ", "").length === 0) {
    return "error.required";
  }
  if (phoneNumber.trim()?.length > 12) {
    return "error.max_value_phone";
  } else if (!regexPhone.test(phoneNumber)) {
    return "error.invalid_phone";
  }
  return "";
}

// validate email
export const regexEmail = new RegExp(/\S+@\S+\.\S+/);
export function validEmail(email: string): string {
  if (email.replaceAll(" ", "").length === 0) {
    return "error.required";
  }
  if (email.trim().length > 50) {
    return "error.max_value_email";
  }
  if (!regexEmail.test(email.trim())) {
    return "error.invalid_email";
  }
  return "";
}

// validate unsigned letter
export function validateUnsignedLetter(value: string): string {
  const valuePasswordMatch = value.match(
    /[a-zA-Z0-9,`,!,@,#,$,%,^,&,*,(,),_,+,\,-,=,\,[\],{,},;,',:,",\,\,|,,.,<,>,\,/,?,~]+/g,
  );
  if (value.replaceAll(" ", "").length === 0) {
    return "error.required";
  }
  if (value !== String(valuePasswordMatch)) {
    return "error.unsigned_letter";
  }
  return "";
}

export function validateEmptyValue(
  value: string,
  errorMessage?: string,
): string {
  if (!value.trim()) {
    return errorMessage || "error.required";
  }
  return "";
}

export function validateMatchingLength(
  value: string,
  numberCharacters: number,
  errorMessage?: string,
  lengthLesserOrEqual?: number,
  lengthBiggerOrEqual?: number,
): string {
  if (value.trim().length !== numberCharacters) {
    return errorMessage || `This field must be ${numberCharacters} characters`;
  }
  if (lengthLesserOrEqual && !(value.trim().length <= lengthLesserOrEqual)) {
    return errorMessage || `Required length lesser ${lengthLesserOrEqual}`;
  }
  if (lengthBiggerOrEqual && !(value.trim().length >= lengthBiggerOrEqual)) {
    return errorMessage || `Required length bigger ${lengthLesserOrEqual}`;
  }
  return "";
}

export function validateHexValue(
  value: string,
  errorMessage?: string,
  nGroup: number = 2,
): string {
  if (!value.trim().match(/^ *[a-f0-9]+ *$/i)) {
    return errorMessage || "error.invalid_hex";
  }

  if (value.length % nGroup !== 0) {
    return (
      errorMessage ||
      `Required Hex chars${nGroup ? ` with group ${nGroup} letters` : ""}`
    );
  }

  return "";
}

export function isValidHttpUrl(str: string) {
  const pattern = new RegExp(
    "^(https?:\\/\\/)?" + // protocol
      "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
      "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
      "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
      "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
      "(\\#[-a-z\\d_]*)?$", // fragment locator
    "i",
  );
  return pattern.test(str);
}
