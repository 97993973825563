import { FunctionComponent, useEffect, useState } from "react";
import "./step-indicator.css";

const IconCheck = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.4255 6.95053C16.5692 6.7918 16.8125 6.77444 16.9773 6.91115L18.2239 7.94505C18.3992 8.09044 18.4179 8.35263 18.2651 8.52144L10.7888 16.7768C10.63 16.9521 10.3546 16.9522 10.1958 16.7768L6.28046 12.4534C6.12759 12.2846 6.1463 12.0224 6.32159 11.877L7.56817 10.8431C7.733 10.7064 7.97626 10.7238 8.12001 10.8825L10.4923 13.502L16.4255 6.95053Z"
        fill="#12B76A"
      />
      <rect x="0.5" y="0.5" width="23" height="23" rx="11.5" stroke="#D9D9D9" />
    </svg>
  );
};

interface StepIndicatorProps {
  defaultStep?: number;
  data?: string[];
  archivedStep?: number;
  onChange?: (step: number) => void;
  locks?: number[];
}
const StepIndicator: FunctionComponent<StepIndicatorProps> = ({
  data,
  defaultStep = 0,
  archivedStep = 0,
  onChange,
  locks = [],
}) => {
  const [_archivedStep, _setArchivedStep] = useState(archivedStep);
  const [_currentStep, _setCurrentStep] = useState(defaultStep);

  useEffect(() => {
    _setArchivedStep(archivedStep);
  }, [archivedStep]);

  useEffect(() => {
    _setCurrentStep(defaultStep);
  }, [defaultStep]);

  return (
    <div className="step-indicator">
      {data &&
        data.length > 0 &&
        data.map((d, i) => {
          return (
            <div
              onClick={() => {
                if (locks.includes(i)) {
                  return;
                }
                if (i <= _archivedStep) {
                  _setCurrentStep(i);
                }
                onChange && onChange(i);
              }}
              key={d + `${i}`}
            >
              {i <= _archivedStep ? (
                i === _currentStep ? (
                  <span
                    style={{
                      display: "flex",
                      height: "24px",
                      width: "24px",
                      justifyContent: "center",
                      alignItems: "center",
                      backgroundColor: "#12B76A",
                      borderRadius: "100%",
                      color: "#FFFFFF",
                      fontSize: "16px",
                      lineHeight: "20px",
                      fontWeight: "500",
                    }}
                  >
                    {i + 1}
                  </span>
                ) : (
                  <IconCheck />
                )
              ) : (
                <span
                  style={{
                    display: "flex",
                    height: "24px",
                    width: "24px",
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: "transparent",
                    borderRadius: "100%",
                    color: "#858585",
                    fontSize: "16px",
                    lineHeight: "20px",
                    fontWeight: "500",
                    border: "1px solid #D9D9D9",
                  }}
                >
                  {i + 1}
                </span>
              )}
              <span
                style={{
                  color: i === _currentStep ? "#333333" : undefined,
                  fontSize: "16px",
                  lineHeight: "20px",
                  fontWeight: "500",
                }}
              >
                {d}
              </span>
            </div>
          );
        })}
    </div>
  );
};

export default StepIndicator;
