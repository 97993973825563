import {
  bottomBarId,
  editorLayoutHeaderId,
  editorLayoutSideBarId,
  editorSubLayoutId,
} from "@/services/constants";
import "./sidebar-editor.css";
import { NavlinkPath } from "@/services/router-config";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Tooltip } from "antd";
import { useState } from "react";
import {
  NavlinkCustom,
  NavlinkCustomTabProps,
} from "@/components/nav-link/NavLink";
import { IconAddQrX28 } from "@/components/icons/IconAddQrX28";
import { IconMyQrX28 } from "@/components/icons/IconMyQrX28";
import { IconWalletX28 } from "@/components/icons/IconWalletX28";
import { IconUserX28 } from "@/components/icons/IconUserX28";
import { IconLogout } from "@/components/icons/IconLogout";

interface SideBarEditorProps {
  data?: NavlinkCustomTabProps[];
  modeDrawer?: boolean;
  onLogout?: () => void;
}

export const SideBarEditor: React.FC<SideBarEditorProps> = ({
  data = [
    {
      title: "createQr",
      icon: (
        <IconAddQrX28
          color={
            window.location.pathname.startsWith(NavlinkPath.creatQrCode)
              ? "#12B76A"
              : "#ADADAD"
          }
        />
      ),
      isActive: window.location.pathname.startsWith(NavlinkPath.creatQrCode),
      path: NavlinkPath.creatQrCode,
    },
    // {
    //   title: "dashboard",
    //   icon: (
    //     <IconHomeX28
    //       color={
    //         window.location.pathname.startsWith(NavlinkPath.dashboard)
    //           ? "#12B76A"
    //           : "#ADADAD"
    //       }
    //     />
    //   ),
    //   isActive: window.location.pathname.startsWith(NavlinkPath.dashboard),
    //   path: NavlinkPath.dashboard,
    // },
    {
      title: "qrCode",
      icon: (
        <IconMyQrX28
          color={
            window.location.pathname.startsWith(NavlinkPath.qrCode)
              ? "#12B76A"
              : "#ADADAD"
          }
        />
      ),
      isActive: window.location.pathname.startsWith(NavlinkPath.qrCode),
      path: NavlinkPath.qrCode,
    },
    {
      title: "billing",
      icon: (
        <IconWalletX28
          color={
            window.location.pathname.startsWith(NavlinkPath.billing)
              ? "#12B76A"
              : "#ADADAD"
          }
        />
      ),
      isActive: window.location.pathname.startsWith(NavlinkPath.billing),
      path: NavlinkPath.billing,
    },
    // {
    //   title: "orders",
    //   icon: (
    //     <IconPage
    //       color={
    //         window.location.pathname.startsWith(NavlinkPath.orders)
    //           ? "#12B76A"
    //           : "#5C5C5C"
    //       }
    //     />
    //   ),
    //   isActive: window.location.pathname.startsWith(NavlinkPath.orders),
    //   path: NavlinkPath.orders,
    // },
    {
      title: "profile",
      icon: (
        <IconUserX28
          color={
            window.location.pathname.startsWith(NavlinkPath.profile)
              ? "#12B76A"
              : "#ADADAD"
          }
        />
      ),
      isActive: window.location.pathname.startsWith(NavlinkPath.profile),
      path: NavlinkPath.profile,
    },

    // {
    //   title: "User",
    //   icon: (
    //     <IconUser
    //       color={
    //         window.location.pathname.startsWith("/user-")
    //           ? "#12B76A"
    //           : "#5C5C5C"
    //       }
    //     />
    //   ),
    //   isActive: window.location.pathname.startsWith("/user-"),
    //   onTab: () => {
    //     const sidebar = document.getElementById(editorLayoutSideBarId);
    //     const sublayout = document.getElementById(editorSubLayoutId);
    //     const listClass: string[] = sidebar?.className?.split(" ") ?? [];
    //     if (
    //       sidebar &&
    //       sublayout &&
    //       !listClass.includes("sidebar-expanded") &&
    //       sidebar.clientWidth < 100
    //     ) {
    //       sidebar.classList.toggle("sidebar-expanded");
    //       sublayout.classList.toggle("sidebar-expanded");
    //     }
    //   },
    //   data: [
    //     {
    //       title: "All users",
    //       icon: <div style={{ width: "24px", height: "24px" }} />,
    //       isActive: window.location.pathname.startsWith(NavlinkPath.userAll),
    //       path: NavlinkPath.userAll,
    //     },
    //     {
    //       title: "Paying users",
    //       icon: <div style={{ width: "24px", height: "24px" }} />,
    //       isActive: window.location.pathname.startsWith(
    //         NavlinkPath.userPayingUser,
    //       ),
    //       path: NavlinkPath.userPayingUser,
    //     },
    //     {
    //       title: "Non paying users",
    //       icon: <div style={{ width: "24px", height: "24px" }} />,
    //       isActive: window.location.pathname.startsWith(
    //         NavlinkPath.userNonePayingUser,
    //       ),
    //       path: NavlinkPath.userNonePayingUser,
    //     },
    //   ],
    // },

    // {
    //   title: "Finance",
    //   icon: (
    //     <IconWallet
    //       color={
    //         window.location.pathname.startsWith("/finance-")
    //           ? "#12B76A"
    //           : "#5C5C5C"
    //       }
    //     />
    //   ),
    //   isActive: window.location.pathname.startsWith("/finance-"),
    //   onTab: () => {
    //     const sidebar = document.getElementById(editorLayoutSideBarId);
    //     const sublayout = document.getElementById(editorSubLayoutId);
    //     const listClass: string[] = sidebar?.className?.split(" ") ?? [];
    //     if (
    //       sidebar &&
    //       sublayout &&
    //       !listClass.includes("sidebar-expanded") &&
    //       sidebar.clientWidth < 100
    //     ) {
    //       sidebar.classList.toggle("sidebar-expanded");
    //       sublayout.classList.toggle("sidebar-expanded");
    //     }
    //   },
    //   data: [
    //     {
    //       title: "Plants",
    //       icon: <div style={{ width: "24px", height: "24px" }} />,
    //       isActive: window.location.pathname.startsWith(
    //         NavlinkPath.financePlants,
    //       ),
    //       path: NavlinkPath.financePlants,
    //     },
    //     {
    //       title: "Subcriptions",
    //       icon: <div style={{ width: "24px", height: "24px" }} />,
    //       isActive: window.location.pathname.startsWith(
    //         NavlinkPath.financeSubscriptions,
    //       ),
    //       path: NavlinkPath.financeSubscriptions,
    //     },
    //     {
    //       title: "Transactions",
    //       icon: <div style={{ width: "24px", height: "24px" }} />,
    //       isActive: window.location.pathname.startsWith(
    //         NavlinkPath.financeTransactions,
    //       ),
    //       path: NavlinkPath.financeTransactions,
    //     },
    //     {
    //       title: "Payment processors",
    //       icon: <div style={{ width: "24px", height: "24px" }} />,
    //       isActive: window.location.pathname.startsWith(
    //         NavlinkPath.financeProcessors,
    //       ),
    //       path: NavlinkPath.financeProcessors,
    //     },
    //     {
    //       title: "Currencies",
    //       icon: <div style={{ width: "24px", height: "24px" }} />,
    //       isActive: window.location.pathname.startsWith(
    //         NavlinkPath.financeCurrencies,
    //       ),
    //       path: NavlinkPath.financeCurrencies,
    //     },
    //   ],
    // },
    // {
    //   title: "Contents",
    //   icon: (
    //     <IconEdit
    //       color={
    //         window.location.pathname.startsWith("/content-")
    //           ? "#12B76A"
    //           : "#5C5C5C"
    //       }
    //     />
    //   ),
    //   isActive: window.location.pathname.startsWith("/content-"),
    //   onTab: () => {
    //     const sidebar = document.getElementById(editorLayoutSideBarId);
    //     const sublayout = document.getElementById(editorSubLayoutId);
    //     const listClass: string[] = sidebar?.className?.split(" ") ?? [];
    //     if (
    //       sidebar &&
    //       sublayout &&
    //       !listClass.includes("sidebar-expanded") &&
    //       sidebar.clientWidth < 100
    //     ) {
    //       sidebar.classList.toggle("sidebar-expanded");
    //       sublayout.classList.toggle("sidebar-expanded");
    //     }
    //   },
    //   data: [
    //     {
    //       title: "Blog posts",
    //       icon: <div style={{ width: "24px", height: "24px" }} />,
    //       isActive: window.location.pathname.startsWith(
    //         NavlinkPath.contentBlogs,
    //       ),
    //       path: NavlinkPath.contentBlogs,
    //     },
    //     {
    //       title: "Content blocks",
    //       icon: <div style={{ width: "24px", height: "24px" }} />,
    //       isActive: window.location.pathname.startsWith(
    //         NavlinkPath.contentBlocks,
    //       ),
    //       path: NavlinkPath.contentBlocks,
    //     },
    //     {
    //       title: "Custom code",
    //       icon: <div style={{ width: "24px", height: "24px" }} />,
    //       isActive: window.location.pathname.startsWith(
    //         NavlinkPath.contentCustomCode,
    //       ),
    //       path: NavlinkPath.contentCustomCode,
    //     },
    //     {
    //       title: "Pages",
    //       icon: <div style={{ width: "24px", height: "24px" }} />,
    //       isActive: window.location.pathname.startsWith(
    //         NavlinkPath.contentPages,
    //       ),
    //       path: NavlinkPath.contentPages,
    //     },
    //   ],
    // },
    // {
    //   title: "Contacts",
    //   icon: (
    //     <IconContact
    //       color={
    //         window.location.pathname.startsWith("/contact-")
    //           ? "#12B76A"
    //           : "#5C5C5C"
    //       }
    //     />
    //   ),
    //   isActive: window.location.pathname.startsWith("/contact-"),
    //   onTab: () => {
    //     const sidebar = document.getElementById(editorLayoutSideBarId);
    //     const sublayout = document.getElementById(editorSubLayoutId);
    //     const listClass: string[] = sidebar?.className?.split(" ") ?? [];
    //     if (
    //       sidebar &&
    //       sublayout &&
    //       !listClass.includes("sidebar-expanded") &&
    //       sidebar.clientWidth < 100
    //     ) {
    //       sidebar.classList.toggle("sidebar-expanded");
    //       sublayout.classList.toggle("sidebar-expanded");
    //     }
    //   },
    //   data: [
    //     {
    //       title: "Contact form",
    //       icon: <div style={{ width: "24px", height: "24px" }} />,
    //       isActive: window.location.pathname.startsWith(
    //         NavlinkPath.contactsForm,
    //       ),
    //       path: NavlinkPath.contactsForm,
    //     },
    //   ],
    // },
    // {
    //   title: "System",
    //   icon: (
    //     <IconWallet
    //       color={
    //         window.location.pathname.startsWith("/system-")
    //           ? "#12B76A"
    //           : "#5C5C5C"
    //       }
    //     />
    //   ),
    //   isActive: window.location.pathname.startsWith("/system-"),
    //   onTab: () => {
    //     const sidebar = document.getElementById(editorLayoutSideBarId);
    //     const sublayout = document.getElementById(editorSubLayoutId);
    //     const listClass: string[] = sidebar?.className?.split(" ") ?? [];
    //     if (
    //       sidebar &&
    //       sublayout &&
    //       !listClass.includes("sidebar-expanded") &&
    //       sidebar.clientWidth < 100
    //     ) {
    //       sidebar.classList.toggle("sidebar-expanded");
    //       sublayout.classList.toggle("sidebar-expanded");
    //     }
    //   },
    //   data: [
    //     {
    //       title: "Status",
    //       icon: <div style={{ width: "24px", height: "24px" }} />,
    //       isActive: window.location.pathname.startsWith(
    //         NavlinkPath.systemStatus,
    //       ),
    //       path: NavlinkPath.systemStatus,
    //     },
    //     {
    //       title: "Settings",
    //       icon: <div style={{ width: "24px", height: "24px" }} />,
    //       isActive: window.location.pathname.startsWith(
    //         NavlinkPath.systemSetting,
    //       ),
    //       path: NavlinkPath.systemSetting,
    //     },
    //     {
    //       title: "Notifications",
    //       icon: <div style={{ width: "24px", height: "24px" }} />,
    //       isActive: window.location.pathname.startsWith(
    //         NavlinkPath.systemNotification,
    //       ),
    //       path: NavlinkPath.systemNotification,
    //     },
    //     {
    //       title: "Sms Portals",
    //       icon: <div style={{ width: "24px", height: "24px" }} />,
    //       isActive: window.location.pathname.startsWith(
    //         NavlinkPath.systemSmsPortal,
    //       ),
    //       path: NavlinkPath.systemSmsPortal,
    //     },
    //     {
    //       title: "Domains",
    //       icon: <div style={{ width: "24px", height: "24px" }} />,
    //       isActive: window.location.pathname.startsWith(
    //         NavlinkPath.systemDomain,
    //       ),
    //       path: NavlinkPath.systemDomain,
    //     },
    //   ],
    // },
  ],
  modeDrawer = false,
  onLogout,
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [expanded, setExpanded] = useState(!modeDrawer);

  const renderComponent = (datum: NavlinkCustomTabProps) => {
    if ((datum.data?.length ?? 0) === 0) {
      datum.title = t(`navigation.${datum.title}`) ?? "";
      return (
        <NavlinkCustom
          key={`${datum.path}-${datum.title}`}
          {...datum}
          onTab={(v) => {
            datum.onTab && datum.onTab();
            v && navigate(v);
          }}
        />
      );
    } else {
      datum.title = t(`navigation.${datum.title}`) ?? "";
      return (
        <NavlinkCustom
          key={`${datum.path}-${datum.title}`}
          {...datum}
          onTab={(v) => {
            datum.onTab && datum.onTab();
            v && navigate(v);
          }}
        >
          {datum.data?.map((d) => {
            return renderComponent(d);
          })}
        </NavlinkCustom>
      );
    }
  };

  function onToggleSideBar() {
    const sidebar = document.getElementById(editorLayoutSideBarId);
    const sublayout = document.getElementById(editorSubLayoutId);
    const header = document.getElementById(editorLayoutHeaderId);
    const bottomBar = document.getElementById(bottomBarId);

    if (sidebar && sublayout) {
      const tmp = expanded;
      setExpanded(!tmp);
      sidebar.classList.toggle("sidebar-expanded");
      sublayout.classList.toggle("sidebar-expanded");
      header?.classList.toggle("sidebar-expanded");
      bottomBar?.classList.toggle("sidebar-expanded");
    }
  }

  return (
    <aside
      className={
        modeDrawer
          ? `sidebar-editor--drawer`
          : `sidebar-editor sidebar-expanded`
      }
      id={editorLayoutSideBarId}
    >
      <div
        style={{
          position: "fixed",
          top: "0",
          left: "0",
          height: "100vh",
          backgroundColor: "#FFFFFF",
          boxSizing: "border-box",
        }}
      >
        {expanded ? (
          <>
            <div className="sidebar-editor__banner">
              <img />
              <Tooltip placement="right" color="#474747" title={"Open menu"}>
                <div
                  onClick={() => {
                    onToggleSideBar();
                  }}
                  style={{
                    height: "40px",
                    width: "40px",
                    borderRadius: "100%",
                    backgroundColor: "#F7F7F7",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M12.5303 5.46967C12.8232 5.76256 12.8232 6.23744 12.5303 6.53033L7.06066 12L12.5303 17.4697C12.8232 17.7626 12.8232 18.2374 12.5303 18.5303C12.2374 18.8232 11.7626 18.8232 11.4697 18.5303L5.46967 12.5303C5.17678 12.2374 5.17678 11.7626 5.46967 11.4697L11.4697 5.46967C11.7626 5.17678 12.2374 5.17678 12.5303 5.46967ZM18.5303 5.46967C18.8232 5.76256 18.8232 6.23744 18.5303 6.53033L13.0607 12L18.5303 17.4697C18.8232 17.7626 18.8232 18.2374 18.5303 18.5303C18.2374 18.8232 17.7626 18.8232 17.4697 18.5303L11.4697 12.5303C11.1768 12.2374 11.1768 11.7626 11.4697 11.4697L17.4697 5.46967C17.7626 5.17678 18.2374 5.17678 18.5303 5.46967Z"
                      fill="#333333"
                    />
                  </svg>
                </div>
              </Tooltip>
            </div>
          </>
        ) : (
          <>
            <Tooltip placement="right" color="#474747" title={"Open menu"}>
              <div
                onClick={() => {
                  onToggleSideBar();
                }}
                className="sidebar-editor__banner"
              >
                <img />
              </div>
            </Tooltip>
          </>
        )}

        {/* nav link */}
        <nav className="sidebar-editor__nav-link">
          {data.map((d) => {
            return renderComponent(d);
          })}
        </nav>

        {/* logout */}
        <Tooltip placement="right" color="#474747" title={"Logout"}>
          <div
            onClick={() => {
              onLogout && onLogout();
            }}
            className="sidebar-editor__nav-link__logout"
          >
            <IconLogout color="#ADADAD" />
            <span className="text-14--reg" style={{ color: "#858585" }}>
              {t("logout")}
            </span>
          </div>
        </Tooltip>
      </div>
    </aside>
  );
};
