import { IconAddX20 } from "@/components/icons/IconAdd";
import { FormCreateQrVCard } from "../interfaces/FormCreateVCard";
import { VCardTemplate, VCardTemplate1, VCardTemplate2 } from "./VCardTemplate";
import "./style.less";
import { wcHexIsLight } from "@/utils/checkBrightness";

interface PreviewPhoneVCardProps {
  data?: FormCreateQrVCard;
  modePublic?: boolean;
  onDownloadProfile?: () => void;
}

export const PreviewPhoneVCard: React.FC<PreviewPhoneVCardProps> = ({
  data,
  modePublic = false,
  onDownloadProfile,
}) => {
  if (data?.contentStyle === undefined) {
    return <></>;
  }
  return (
    <div
      className="column"
      style={{
        width: "100%",
        height: "100%",
        position: "relative",
      }}
    >
      <div
        className="create-qr-vcard-preview hide-scrollbar"
        style={{
          borderRadius: modePublic ? "0" : undefined,
        }}
      >
        {(() => {
          switch (data?.template) {
            case "0":
              return <VCardTemplate key={data?.template} data={data} />;
            case "1":
              return <VCardTemplate1 key={data?.template} data={data} />;
            case "2":
              return <VCardTemplate2 key={data?.template} data={data} />;
            default:
              return <VCardTemplate key={data?.template} data={data} />;
          }
        })()}
      </div>
      <button
        onClick={() => {
          onDownloadProfile && onDownloadProfile();
        }}
        className="button-primary"
        style={{
          position: modePublic ? "fixed" : "sticky",
          maxWidth: "768px",
          bottom: "12px",
          // marginTop: "100%",
          left: "0px",
          right: "0px",
          marginLeft: "auto",
          marginRight: "auto",
          width: "calc(100% - 24px)",
          borderRadius: "100px",
          backgroundColor: data.contentStyle.primaryColor,
          zIndex: 3,
        }}
      >
        <IconAddX20
          color={
            wcHexIsLight(data.contentStyle.primaryColor) ? "#000000" : "#FFFFFF"
          }
        />
        <span
          style={{
            color: wcHexIsLight(data.contentStyle.primaryColor)
              ? "#000000"
              : "#FFFFFF",
          }}
        >
          Add contact
        </span>
      </button>
    </div>
  );
};
