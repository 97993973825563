export interface TemplateModel {
  id: string;
  name: string;
  type: TemplateType;
  content: string;
  createDate: string;
}

export enum TemplateType {
  VIET_QR = "VIET_QR",
  COMMON = "COMMON",
}
