import { TemplateResponse } from "@/apis/generated-sources/qr-code";
import { TemplateModel, TemplateType } from "../models/TemplateModel";

export function mapTemplateModel(dto?: TemplateResponse): TemplateModel {
  return {
    id: dto?.id ?? "",
    content: dto?.content ?? "",
    createDate: dto?.createDate ?? "",
    name: dto?.name ?? "",
    type: TemplateType[dto?.type as keyof typeof TemplateType],
  };
}
