import { BehaviorSubject } from "rxjs";
import { ErrorModel } from "@/models/ErrorModel";
import { LoginStatus } from "@/models/LoginStatus";
import { Session } from "@/domains/session";

export class AppSession extends Session {
  public loginStatus: BehaviorSubject<LoginStatus>;
  public isLoading: BehaviorSubject<boolean>;
  public error: BehaviorSubject<ErrorModel | null>;
  public isShowBreadcrumb: BehaviorSubject<boolean>;
  public breadcrumb: BehaviorSubject<JSX.Element | null>;
  public isShowMessage: BehaviorSubject<boolean>;
  public message: BehaviorSubject<JSX.Element | null>;
  public bottomBar: BehaviorSubject<JSX.Element | null>;
  public queueModal: BehaviorSubject<JSX.Element | null>;
  public isShowDrawer: BehaviorSubject<boolean>;
  public drawer: BehaviorSubject<JSX.Element | null>;
  public isExpiredSubcription: boolean;
  public isShowSideBar: BehaviorSubject<boolean>;

  constructor() {
    super();
    this.loginStatus = new BehaviorSubject<LoginStatus>(LoginStatus.Idle);
    this.isLoading = new BehaviorSubject<boolean>(false);
    this.error = new BehaviorSubject<ErrorModel | null>(null);
    this.isShowBreadcrumb = new BehaviorSubject<boolean>(false);
    this.breadcrumb = new BehaviorSubject<JSX.Element | null>(null);
    this.isShowMessage = new BehaviorSubject<boolean>(false);
    this.message = new BehaviorSubject<JSX.Element | null>(null);
    this.bottomBar = new BehaviorSubject<JSX.Element | null>(null);
    this.queueModal = new BehaviorSubject<JSX.Element | null>(null);
    this.isShowDrawer = new BehaviorSubject<boolean>(false);
    this.drawer = new BehaviorSubject<JSX.Element | null>(null);
    this.isShowSideBar = new BehaviorSubject<boolean>(false);
    this.isExpiredSubcription = true;
  }

  public closeAll() {
    this.bottomBar.complete();
    this.isShowBreadcrumb.complete();
    this.isLoading.complete();
    this.isShowMessage.complete();
    this.error.complete();
    this.loginStatus.complete();
    this.message.complete();
    this.isShowDrawer.complete();
    this.drawer.complete();
    this.isShowSideBar.complete();
  }
}
