import { FrameQrStyle } from "./interfaces";

export const QrFrame2 = ({
  style = {
    backGroundColor: "#D9D9D9",
    content:
      "https://cdn.britannica.com/17/155017-050-9AC96FC8/Example-QR-code.jpg",
    frameColor: "#000000",
    text: "scan me",
    textColor: "#FFFFFF",
    textFontSize: "32",
    textFontWeight: "500",
  },
}: {
  style?: FrameQrStyle;
}) => {
  return (
    <svg viewBox="0 0 340 471" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M298.65 405.613H41.3493C37.2876 405.613 33.9978 402.426 33.9978 398.492V89.631C33.9978 85.6968 37.2876 82.5103 41.3493 82.5103H298.65C302.712 82.5103 306.002 85.6968 306.002 89.631V398.492C306.002 402.426 302.712 405.613 298.65 405.613Z"
        fill={style.frameColor}
      />
      <path
        d="M40.3254 92.1169C40.3254 90.3701 41.7415 88.9541 43.4882 88.9541H296.512C298.258 88.9541 299.674 90.3701 299.674 92.1169V346.722H40.3254V92.1169Z"
        fill={style.backGroundColor}
      />
      <image
        x="64.8369"
        y="112.5577"
        width="210.326"
        height="210.326"
        href={style.content}
      />

      <path
        d="M207.163 65.6473H186.597C186.597 57.7906 180.224 51.4199 172.364 51.4199C164.505 51.4199 158.131 57.7906 158.131 65.6473H132.837C129.342 65.6473 126.511 68.4849 126.511 71.9706V94.9083H213.488V71.9706C213.488 68.4849 210.658 65.6473 207.163 65.6473ZM172.364 73.5514C168.007 73.5514 164.457 70.0104 164.457 65.6473C164.457 61.2921 168.007 57.7432 172.364 57.7432C176.721 57.7432 180.271 61.2921 180.271 65.6473C180.271 70.0104 176.721 73.5514 172.364 73.5514Z"
        fill={style.frameColor}
      />
      <foreignObject
        width="78%"
        height="128"
        x="11%"
        y="345"
        fontSize={`${style.textFontSize}px`}
        fontWeight={`${style.textFontWeight}`}
        dominantBaseline="middle"
        textAnchor="middle"
        alignmentBaseline="central"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "60",
        }}
      >
        <p
          style={{
            margin: "0",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "inherit",
            color: style.textColor,
            width: "100%",
            textAlign: "center",
            fontSize: `${style.textFontSize}px`,
            lineHeight: `${style.textFontSize}px`,
            fontWeight: style.textFontWeight,
            wordWrap: "break-word",
            whiteSpace: "initial",
            verticalAlign: "middle",
          }}
        >
          {style?.text}
        </p>
      </foreignObject>
    </svg>
  );
};
