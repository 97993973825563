export const dataColor = [
  ["#12B76A", "#ADEDC6"],
  ["#ADEDC6", "#B2DDFF"],
  ["#F05252", "#FBD5D5"],
  ["#FF5A1F", "#FCD9BD"],
  ["#FF9C00", "#FCE96A"],
  ["#273A4C", "#E3E5E2"],
  ["#25215E", "#F2AA3E"],
  ["#014861", "#F5D3CC"],
  ["#88ABE3", "#FCF7F4"],
  ["#111820", "#F3AA4E"],
  ["#60318B", "#FF9EF5"],
  ["#3E282B", "#A37B73"],
];
