import { ErrorModel } from "@/models/ErrorModel";
import { MediaRepository } from "../MediaRepository";
import { QrfyHttpClient } from "../QrfyRepositories";

export class MediaRepositoryImp implements MediaRepository {
  private client: QrfyHttpClient;
  constructor(c: QrfyHttpClient) {
    this.client = c;
  }

  public async uploadImage({ file }: { file: File }): Promise<string> {
    try {
      const resp = await this.client.media.uploadImage({
        file: file,
      });
      if (resp.data.code === 0) {
        return resp.data.data?.url ?? "";
      } else {
        throw new ErrorModel({
          code: resp.data.code,
          message: resp.data.message,
        });
      }
    } catch (error) {
      throw error;
    }
  }

  public async preview({ path }: { path: string }): Promise<File> {
    try {
      const resp = await this.client.media.preview({
        path: path,
      });
      return resp.data;
    } catch (error) {
      throw error;
    }
  }
}
