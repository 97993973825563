import { QrCodeType } from "@/apis/qrfy/models/QrModel";
import "./style.less";

interface ItemOptionQrTypeProps {
  type: QrCodeType;
  title: string;
  content: string;
  isSelected: boolean;
  onTap: () => void;
  icon: string;
}

export const ItemOptionQrType: React.FC<ItemOptionQrTypeProps> = ({
  type,
  content,
  isSelected,
  title,
  onTap,
  icon,
}) => {
  return (
    <div
      key={type}
      onClick={onTap}
      className="create-qr-page__body__list-type-qr__item"
      style={{
        borderColor: isSelected ? "#2E90FA" : undefined,
      }}
    >
      <div>
        <img alt={title} src={icon} style={{ width: "48px", height: "48px" }} />
      </div>
      <span
        className="column"
        style={{
          width: "100%",
          alignItems: "flex-start",
          justifyContent: "center",
          gap: "2px",
        }}
      >
        <span
          className="row"
          style={{ width: "100%", gap: "10px", color: "#333333" }}
        >
          <span className="text-16--md">{title}</span>
        </span>
        <span className="text-14--reg" style={{ color: "#858585" }}>
          {content}
        </span>
      </span>
    </div>
  );
};
