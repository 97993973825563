import { BLoC } from "@/domains/bloc";
import { BehaviorSubject } from "rxjs";
import { AppRepository } from "./repository";
import { AppSession } from "./sessions";
import { AppStorage } from "./storages";
import { useEffect, useRef, useState, useTransition } from "react";
import Environment from "./environment";

export class BaseBloc<T> {
  public stream: BehaviorSubject<T>;
  private _state: T;

  constructor({
    initState,
    callback = () => {},
  }: {
    initState: T;
    callback?: () => void | Promise<void>;
  }) {
    this._state = initState;
    this.stream = new BehaviorSubject<T>(this._state);
    callback();
  }

  set state(s: T) {
    this._state = { ...s };
  }

  get state(): T {
    return this._state;
  }

  public upDateState(s?: T) {
    if (s !== undefined) {
      this._state = { ...s };
    }
    this.stream.next({ ...this._state });
  }
}

export function useBaseBloc<T>(
  data: T,
  callBackState?: (v: T) => void,
): [state: T, setState: (v?: T) => void, isPending: boolean] {
  const _bloc = useRef<BaseBloc<T>>(
    new BaseBloc<T>({ initState: data }),
  ).current;
  const [, _setState] = useState<T>(_bloc.state);
  const [isPending, startTransition] = useTransition();

  function updateState(s?: T) {
    if (s) {
      _bloc.state = s;
    }
    _bloc.upDateState(s);
  }

  useEffect(() => {
    _bloc.stream.subscribe((v) => {
      startTransition(() => {
        _setState({ ...v });
        callBackState && callBackState(v);
      });
    });

    return () => {
      _bloc.stream.complete();
    };
  }, []);

  return [_bloc.state, updateState, isPending];
}

export class AppBloc extends BLoC {
  private static _instance: AppBloc;
  public declare storage: AppStorage;
  public declare session: AppSession;
  public declare repository: AppRepository;

  constructor() {
    super();
    if (AppBloc._instance == undefined) {
      AppBloc._instance = this;
      this.storage = new AppStorage();
      this.repository = new AppRepository(Environment.hostApi);
      this.session = new AppSession();
    }
    return AppBloc._instance;
  }
}
