import React from "react";
import "./popup-custom.css";

type PopupCustomConfirmButton = "confirm" | "delete";
type PopupCustomProps = {
  title?: string;
  content?: string;
  labelClose?: string;
  labelConfirm?: string;
  onClose?: () => void;
  onConfirm?: () => void;
  typeConfirm?: PopupCustomConfirmButton;
};

export const PopupCustom: React.FC<PopupCustomProps> = ({
  title = "",
  content = "",
  onClose,
  onConfirm,
  typeConfirm = "confirm",
  labelClose = "Close",
  labelConfirm = "Confirm",
}) => {
  return (
    <div className="popup-custom">
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          gap: "8px",
          alignItems: "flex-start",
        }}
      >
        {title.length > 0 && (
          <span className="popup-custom__title">{title}</span>
        )}
        {content.length > 0 && (
          <span className="popup-custom__content">{content}</span>
        )}
      </div>
      <div className="popup-custom__footer">
        {onClose && (
          <button onClick={onClose} className="popup-custom__button--cancel">
            {labelClose}
          </button>
        )}
        {onConfirm && typeConfirm === "confirm" && (
          <button onClick={onConfirm} className="popup-custom__button--confirm">
            {labelConfirm}
          </button>
        )}
        {onConfirm && typeConfirm === "delete" && (
          <button onClick={onConfirm} className="popup-custom__button--delete">
            {labelConfirm}
          </button>
        )}
      </div>
    </div>
  );
};
