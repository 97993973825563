import { InputForm2 } from "@/components/input-form/InputForm2";
import "./style.less";
import { BaseBloc } from "@/services/base-bloc";
import { InputSelector } from "@/components/input-selector/InputSelector";
import { FormCreateQrWireless } from "./interfaces/FormCreateQrWireless";
import { useEffect, useMemo, useState } from "react";
import { distinct } from "rxjs";
import {
  WirelessEncrypType,
  WirelessQrModel,
} from "@/apis/qrfy/models/WirelessQrModel";
import { QrCodeModel } from "@/apis/qrfy/models/QrModel";

interface CreateQrWirelessState {
  encryptType: string;
  networkName: string;
  networkPassword: string;
  errors: {
    networkPassword: string;
    networkName: string;
  };
}

interface CreateQrWirelessProps {
  onChage: (v: FormCreateQrWireless) => void;
  initData?: QrCodeModel<WirelessQrModel> | null;
}

export const CreateQrWireless: React.FC<CreateQrWirelessProps> = ({
  onChage,
  initData = null,
}) => {
  const _bloc = useMemo<BaseBloc<CreateQrWirelessState>>(() => {
    return new BaseBloc<CreateQrWirelessState>({
      initState: {
        encryptType: WirelessEncrypType.WEP.toString(),
        networkName: "",
        networkPassword: "",
        errors: {
          networkName: "",
          networkPassword: "",
        },
      },
    });
  }, []);
  const [state, setState] = useState<CreateQrWirelessState>(_bloc.state);

  useEffect(() => {
    _bloc.stream.pipe(distinct()).subscribe((v) => {
      let check = true;
      if (
        v.encryptType.replaceAll(" ", "") === "" ||
        v.networkName.replaceAll(" ", "") === "" ||
        v.networkPassword.replaceAll(" ", "") === "" ||
        v.errors.networkPassword.replaceAll(" ", "") !== ""
      ) {
        check = false;
      }

      if (v && check) {
        onChage({
          encryptType: v.encryptType.trim(),
          networkName: v.networkName.trim(),
          networkPassword: v.networkPassword.trim(),
          done: true,
        });
      } else {
        onChage({
          encryptType: v.encryptType.trim(),
          networkName: v.networkName.trim(),
          networkPassword: v.networkPassword.trim(),
          done: false,
        });
      }
      setState({ ...v });
    });
    if (initData !== null) {
      _bloc.state.encryptType = initData.data.encryptType;
      _bloc.state.networkName = initData.data.networkName;
      _bloc.state.networkPassword = initData.data.networkPassword;
      _bloc.upDateState();
    }
  }, []);

  return (
    <div className="create-qr-wireless">
      <span className="text-16--md" style={{ color: "#333333" }}>
        VietQR Information
      </span>
      <div
        className="row"
        style={{
          width: "100%",
          alignItems: "flex-start",
          gap: "12px",
        }}
      >
        <InputForm2
          defaultValue={state.networkName}
          maxLenght={32}
          intercepter={(v) => {
            if (v.length > 32) {
              return true;
            } else {
              return false;
            }
          }}
          valid={(v) => {
            if (v.length === 0) {
              return "Vui lòng không để trống";
            } else {
              return "";
            }
          }}
          onError={(v) => {
            _bloc.state.errors.networkName = v;
            _bloc.upDateState();
          }}
          onChange={(v) => {
            _bloc.state.networkName = v;
            _bloc.upDateState();
          }}
          required
          title="Network name (SSID)"
          placeHolder="Test"
          isCheckError
        />

        <InputForm2
          defaultValue={state.networkPassword}
          maxLenght={50}
          intercepter={(v) => {
            if (v.length > 63) {
              return true;
            } else {
              return false;
            }
          }}
          valid={(v) => {
            if (v.length === 0) {
              return "Vui lòng không để trống";
            } else if (v.length < 8) {
              return "Mật khẩu Wi-Fi yêu cầu tối thiểu 8 kí tự";
            } else {
              return "";
            }
          }}
          onError={(v) => {
            _bloc.state.errors.networkPassword = v;
            _bloc.upDateState();
          }}
          onChange={(v) => {
            _bloc.state.networkPassword = v;
            _bloc.upDateState();
          }}
          required
          title="Network password"
          isCheckError
          placeHolder="Ex:12345678"
        />
      </div>
      <InputSelector
        isFullwidth
        onChange={(v) => {
          state.encryptType = v;
        }}
        defaultValue={state.encryptType}
        data={Object.keys(WirelessEncrypType)}
      />
    </div>
  );
};
