import axios, { AxiosResponse, InternalAxiosRequestConfig } from "axios";
import { ErrorModel } from "@/models/ErrorModel";
import { LoginStatus } from "@/models/LoginStatus";
import { AppBloc } from "./base-bloc";
import { qrfyConfig } from "@/apis/qrfy/QrfyRepositories";

export function onResponse(res: AxiosResponse) {
  return Promise.resolve(res);
}

export function onRequest(req: InternalAxiosRequestConfig) {
  if (req.headers !== undefined) {
    req.headers["Accept-Language"] = new AppBloc().storage.language;
    if (
      (req.url ?? "").startsWith(qrfyConfig.basePath ?? "") &&
      !(req.url ?? "").includes("/public")
    ) {
      req.headers["Authorization"] =
        qrfyConfig.accessToken !== undefined
          ? `Bearer ${qrfyConfig.accessToken}`
          : undefined;
    }
    // orther api host
    // ---------------
  }
  return Promise.resolve(req);
}
export async function onError(e: any) {
  console.error(">>>ERROR", e);
  const { config, code } = e;
  if (code === "ERR_NETWORK") e.message = `Vui lòng kiểm tra lại kết nối mạng`;
  const originalReq = { ...config };

  const appBloc = new AppBloc();
  switch (e.response.status.toString()) {
    case "401":
      try {
        if (
          appBloc.storage.refreshToken.length > 0 &&
          appBloc.storage.countRefreshToken < 10
        ) {
          qrfyConfig.accessToken = undefined;
          appBloc.storage.countRefreshToken =
            appBloc.storage.countRefreshToken + 1;
          const retRefresh =
            await appBloc.repository.qrfyRepo.auth.refreshToken({
              refreshToken: appBloc.storage.refreshToken,
            });
          if (retRefresh.refreshToken && retRefresh.token) {
            appBloc.storage.accessToken = retRefresh.token;
            appBloc.storage.refreshToken = retRefresh.refreshToken;
            qrfyConfig.accessToken = retRefresh.token;
            if (originalReq) {
              originalReq.headers.Authorization = `Bearer ${retRefresh.token}`;
              setTimeout(() => {
                appBloc.storage.countRefreshToken = 0;
              }, 20000);
              return Promise.resolve(axios(originalReq));
            }
          }
        }
        throw new ErrorModel({ code: -1, message: "Expire Refresh token" });
      } catch (error) {
        appBloc.session.error.next(
          new ErrorModel({ code: e.code, message: e.message }),
        );
        appBloc.session.loginStatus.next(LoginStatus.Expired);
        return Promise.reject(e);
      }
    case "403":
      appBloc.session.error.next(
        new ErrorModel({ code: "403", message: e.message }),
      );
      return Promise.reject(e);
  }
  appBloc.session.error.next(
    new ErrorModel({ code: e.response.status, message: e.message }),
  );
  return Promise.reject(e);
}
