export const IconArrowFowardX20 = ({
  color = "#333333",
}: {
  color?: string;
}) => {
  return (
    <svg
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.80806 4.55806C10.0521 4.31398 10.4479 4.31398 10.6919 4.55806L15.6919 9.55806C15.936 9.80214 15.936 10.1979 15.6919 10.4419L10.6919 15.4419C10.4479 15.686 10.0521 15.686 9.80806 15.4419C9.56398 15.1979 9.56398 14.8021 9.80806 14.5581L13.7411 10.625H5.25C4.90482 10.625 4.625 10.3452 4.625 10C4.625 9.65482 4.90482 9.375 5.25 9.375H13.7411L9.80806 5.44194C9.56398 5.19786 9.56398 4.80214 9.80806 4.55806Z"
        fill={color}
      />
    </svg>
  );
};
