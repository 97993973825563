export const IconCompany = ({ color = "12B76A" }: { color?: string }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.9576 1.11084C13.2907 1.28224 13.5 1.62542 13.5 2V5.98539L20.0812 10.6863C20.344 10.874 20.5 11.1771 20.5 11.5V21H22C22.5523 21 23 21.4477 23 22C23 22.5523 22.5523 23 22 23H2C1.44772 23 1 22.5523 1 22C1 21.4477 1.44772 21 2 21H4.5V7C4.5 6.67705 4.65597 6.37398 4.91876 6.18627L11.9188 1.18627C12.2236 0.968546 12.6245 0.939432 12.9576 1.11084ZM6.5 21H11.5V6.51165C11.4999 6.50333 11.4999 6.495 11.5 6.48667V3.94319L6.5 7.51462V21ZM13.5 8.44319V21H18.5V12.0146L13.5 8.44319Z"
        fill={color}
      />
    </svg>
  );
};
