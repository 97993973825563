export async function downloadFile({
  file,
  type,
  name = "download-file",
  tag = "",
}: {
  file: File | Blob;
  type?: string;
  name?: string;
  tag?: string;
}) {
  const blob = new Blob([file], { type: type });
  const dataUrl = URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.href = dataUrl;
  link.setAttribute("download", `${name}${tag ? tag : ""}`);
  link.click();
  link.remove();
}

export function dataURLtoFile(dataurl: string, filename: string) {
  const arr = dataurl.split(",");
  const mime = arr[0].match(/:(.*?);/)![1];
  const bstr = atob(arr[arr.length - 1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename + "." + mime.split("/")[1], { type: mime });
}

// function dataURLtoFile(dataurl: string, filename: string) {
//   var arr = dataurl.split(","),
//     mime = arr[0].match(/:(.*?);/)[1],
//     bstr = atob(arr[arr.length - 1]),
//     n = bstr.length,
//     u8arr = new Uint8Array(n);
//   while (n--) {
//     u8arr[n] = bstr.charCodeAt(n);
//   }
//   return new File([u8arr], filename, { type: mime });
// }

// function dataURItoBlob(dataURI) {
//   // convert base64 to raw binary data held in a string
//   // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
//   var byteString = atob(dataURI.split(",")[1]);

//   // separate out the mime component
//   var mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];

//   // write the bytes of the string to an ArrayBuffer
//   var ab = new ArrayBuffer(byteString.length);
//   var ia = new Uint8Array(ab);
//   for (var i = 0; i < byteString.length; i++) {
//     ia[i] = byteString.charCodeAt(i);
//   }

//   //Old Code
//   //write the ArrayBuffer to a blob, and you're done
//   //var bb = new BlobBuilder();
//   //bb.append(ab);
//   //return bb.getBlob(mimeString);

//   //New Code
//   return new Blob([ab], { type: mimeString });
// }
