import { BillDataDto } from "../../generated-sources/qr-code/api";
import { BillDataModel } from "../models/BillDataModel";
import { Currency } from "../models/Curency";

export function mapBillDataModel(dto?: BillDataDto): BillDataModel {
  return {
    address: dto?.address ?? "",
    companyName: dto?.companyName ?? "",
    country: dto?.country ?? "",
    currency: Currency[dto?.currency ?? ("VND" as keyof typeof Currency)],
    email: dto?.email ?? "",
    firstname: dto?.firstname ?? "",
    lastName: dto?.lastName ?? "",
    taxId: dto?.taxId ?? "",
  };
}
