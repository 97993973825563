import {
  SocialNetworkModel,
  SocialNetworkType,
} from "@/apis/qrfy/models/QrModel";
import "./style.less";
// import { IconArrowRight } from "@/components/icons/IconArrowRight";
import Environment from "@/services/environment";

interface ItemSocialNetworkPreviewProps {
  socialNetwork: SocialNetworkModel;
}

function getSocialIcon(type: SocialNetworkType): string {
  return `${Environment.host}/icons/ic-${type.toLowerCase()}-x32.svg`;
}

export const ItemSocialNetworkPreview: React.FC<
  ItemSocialNetworkPreviewProps
> = ({ socialNetwork }) => {
  return (
    <a
      href={socialNetwork.url}
      target="_blank"
      rel="noopener noreferrer"
      className="create-qr-vcard-preview__item-social-network-preview"
    >
      <img
        style={{ height: "24px", aspectRatio: 1 }}
        src={getSocialIcon(socialNetwork.type)}
      />
      <div
        className="column"
        style={{
          gap: "2px",
          width: "calc(100% - 12px - 12px - 32px - 24px)",
          alignItems: "flex-start",
        }}
      >
        <span
          className="text-12--reg"
          style={{ textTransform: "capitalize", color: "#707070" }}
        >
          {socialNetwork.type.toLowerCase()}
        </span>
        <span
          className="text-12--reg text-overflow--new-line"
          style={{
            textTransform: "capitalize",
            color: "#333333",
            width: "100%",
            boxSizing: "border-box",
          }}
        >
          {socialNetwork.text}
        </span>
      </div>
      {/* <IconArrowRight /> */}
    </a>
  );
};
