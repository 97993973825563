import { TextQrResponse } from "@/apis/generated-sources/qr-code";
import { TextQrModel } from "../models/TextQrModel";
import { mapQRStyleModel } from "./qr-adapter";

export function mapTextQrModel(dto?: TextQrResponse): TextQrModel {
  return {
    id: dto?.id ?? "",
    content: dto?.content ?? "",
    qrContent: dto?.qrContent ?? "",
    qrName: dto?.qrName ?? "",
    qrStyle: mapQRStyleModel(dto?.qrStyle),
  };
}
