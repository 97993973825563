import { ErrorModel } from "@/models/ErrorModel";
import { PlanRepository } from "../PlanRepository";
import { QrfyHttpClient } from "../QrfyRepositories";
import { mapPlanModel } from "../adapters/plan-adapter";
import { PlanModel } from "../models/PlanModel";

export class PlanRepositoryImp implements PlanRepository {
  private client: QrfyHttpClient;
  constructor(c: QrfyHttpClient) {
    this.client = c;
  }

  public async getList(): Promise<PlanModel[]> {
    try {
      const resp = await this.client.plan.getList1();
      if (resp.data.code === 0) {
        return resp.data.data?.contents?.map((d) => mapPlanModel(d)) ?? [];
      } else {
        throw new ErrorModel({
          code: resp.data.code,
          message: resp.data.message,
        });
      }
    } catch (error) {
      throw error;
    }
  }

  public async getDetail({ id }: { id: string }): Promise<PlanModel> {
    try {
      const resp = await this.client.plan.getDetail2({
        id: id,
      });
      if (resp.data.code === 0) {
        return mapPlanModel(resp.data.data);
      } else {
        throw new ErrorModel({
          code: resp.data.code,
          message: resp.data.message,
        });
      }
    } catch (error) {
      throw error;
    }
  }
}
