import ReactDOM from "react-dom/client";
import "./index.less";
import "@/styles";
import { RouterProvider } from "react-router-dom";
import { AppBloc } from "./services/base-bloc";
import i18n from "./services/i18n";
import { appRouterConfig } from "./router";
import QrLoader from "./components/loader/QrLoader";
import Environment from "./services/environment";

const qrRoot = ReactDOM.createRoot(
  document.getElementById(`root`) as HTMLElement,
  {
    identifierPrefix: `${Environment.hostComponentPrefix}`,
    onRecoverableError(error: any) {
      console.error(error);
    },
  },
);

const appBloc = new AppBloc();
export const router = appRouterConfig(appBloc);

i18n.changeLanguage(appBloc.storage.language).finally(() => {
  qrRoot.render(
    <RouterProvider
      router={router}
      key={"qrfy-app"}
      fallbackElement={<QrLoader />}
    />,
  );
});
