import { ErrorModel } from "@/models/ErrorModel";
import { PublicLinkQrRepository } from "../PublicLinkQrRepository";
import { QrfyHttpClient } from "../QrfyRepositories";
import { mapPublicQrCodeModel } from "../adapters/qr-adapter";
import { PublicQrCodeModel } from "../models/QrModel";

export class PublicLinkQrRepositoryImp implements PublicLinkQrRepository {
  private client: QrfyHttpClient;
  constructor(c: QrfyHttpClient) {
    this.client = c;
  }

  public async preview({ code }: { code: string }): Promise<PublicQrCodeModel> {
    try {
      const resp = await this.client.publicLinkQr.getQrInfoByCode({
        code: code,
      });
      if (resp.data.code === 0) {
        return mapPublicQrCodeModel(resp.data.data);
      } else {
        throw new ErrorModel({
          code: resp.data.code,
          message: resp.data.message,
        });
      }
    } catch (error) {
      throw error;
    }
  }

  public async getVcardFile({ code }: { code: string }): Promise<File> {
    try {
      const resp = await this.client.publicLinkQr.getVcardFile(
        {
          code: code,
        },
        { responseType: "arraybuffer" },
      );
      return resp.data;
    } catch (error) {
      throw error;
    }
  }
}
