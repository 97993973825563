import axios from "axios";
import { Repository } from "@/domains/repository";
import { QrfyRepositories } from "@/apis/qrfy/QrfyRepositories";
import { onError, onRequest, onResponse } from "./intercepter";

export class AppRepository extends Repository {
  qrfyRepo: QrfyRepositories;
  constructor(basePath?: string) {
    super();
    axios.defaults.timeout = 180 * 1000;
    axios.interceptors.request.use(onRequest, onError);
    axios.interceptors.response.use(onResponse, onError);
    this.qrfyRepo = new QrfyRepositories(basePath);
  }
}
