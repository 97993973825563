import React, { ReactNode } from "react";
import "./bottom-bar.css";

const BottomBarCustom: React.FC<{ id: string; children?: ReactNode }> = ({
  id,
  children,
}) => {
  return (
    <div className="bottom-bar sidebar-expanded" id={id}>
      {children}
    </div>
  );
};

export default BottomBarCustom;
