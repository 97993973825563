import { AccessTokenResponse } from "../../generated-sources/qr-code/api";
import { AccessTokenModel } from "../models/AccessTokenModel";

export function mapAccessTokenModel(
  dto?: AccessTokenResponse,
): AccessTokenModel {
  return {
    expiresIn: dto?.expiresIn ?? -1,
    idToken: dto?.idToken ?? "",
    notBeforePolicy: dto?.notBeforePolicy ?? -1,
    otherClaims: dto?.otherClaims ?? {},
    refreshExpiresIn: dto?.refreshExpiresIn ?? -1,
    refreshToken: dto?.refreshToken ?? "",
    scope: dto?.scope ?? "",
    sessionState: dto?.sessionState ?? "",
    token: dto?.token ?? "",
    tokenType: dto?.tokenType ?? "",
  };
}
