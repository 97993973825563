import { PlanResponse } from "../../generated-sources/qr-code/api";
import { PlanModel, PlanTimeUnitType } from "../models/PlanModel";

export function mapPlanModel(dto?: PlanResponse): PlanModel {
  return {
    id: dto?.id ?? "",
    createDate: dto?.createDate ?? "",
    description: dto?.description ?? "",
    enable: dto?.enable ?? false,
    name: dto?.id ?? "",
    price: dto?.price ?? 0,
    timeUnit: PlanTimeUnitType[dto?.timeUnit as keyof typeof PlanTimeUnitType],
    trial: dto?.trial ?? false,
    value: dto?.value ?? 0,
  };
}
