import { QRStyleModel, QrCodeType } from "@/apis/qrfy/models/QrModel";
import "./style.less";
import { useEffect, useMemo } from "react";
import { BaseBloc } from "@/services/base-bloc";
import StreamBuilder from "@/components/stream-builder/StreamBuilder";
import { IconQrX20 } from "@/components/icons/IconQrX20";
import { IconPhoneX20 } from "@/components/icons/IconPhoneX20";
import { IconArrowBackX20 } from "@/components/icons/IconArrowBackX20";
import { InputCheck } from "@/components/input-check/InputCheck";
import { IconArrowFowardX20 } from "@/components/icons/IconArrowFowardX20";
import { IconInfo } from "@/components/icons/IconInfo";
import { IconCheckX20 } from "@/components/icons/IconCheckX20";
import { PreviewPhoneVietQr } from "@/pages/create-qr-vietqr/components/PreviewPhoneVietQr";
import { PreviewQrCodeImage } from "./PreviewQrCodeImage";
import { PreviewPhoneWireLess } from "@/pages/create-qr-wireless/components/PreviewPhoneWireless";
import { PreviewPhoneQrWeb } from "@/pages/create-qr-web/components/PreviewPhoneQrWeb";
import { useTranslation } from "react-i18next";
import { PreviewPhoneVCard } from "@/pages/create-qr-vcard/components/PreviewPhoneVCard";

const IconQrBasePreviewPhone = () => {
  return (
    <svg
      width="73"
      height="73"
      viewBox="0 0 73 73"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M24.2731 24.3972H28.3295V28.5115H24.2731V24.3972Z"
        fill="#858585"
      />
      <path
        d="M44.5613 24.3972H48.6176V28.5115H44.5613V24.3972Z"
        fill="#858585"
      />
      <path
        d="M24.2731 44.9706H28.3295V49.0849H24.2731V44.9706Z"
        fill="#858585"
      />
      <path
        d="M50.6393 36.7401H54.6957V40.8543H50.6393V36.7401Z"
        fill="#858585"
      />
      <path
        d="M36.4422 49.0829H40.4985V53.1972H36.4422V49.0829Z"
        fill="#858585"
      />
      <path
        d="M44.5548 44.9686H48.6112V49.0829H44.5548V44.9686Z"
        fill="#858585"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.6957 21.6794C18.6957 20.0562 19.9928 18.7401 21.5934 18.7401H31.619C33.2196 18.7401 34.5168 20.0562 34.5168 21.6794V31.8496C34.5168 33.4727 33.2196 34.7889 31.619 34.7889H21.5934C19.9928 34.7889 18.6957 33.4727 18.6957 31.8496V21.6794ZM21.7379 21.8258V31.7032H31.4745V21.8258H21.7379ZM38.3676 21.6794C38.3676 20.0562 39.6647 18.7401 41.2653 18.7401H51.2909C52.8915 18.7401 54.1886 20.0562 54.1886 21.6794V31.8496C54.1886 33.4727 52.8915 34.7889 51.2909 34.7889H41.2653C39.6647 34.7889 38.3676 33.4727 38.3676 31.8496V21.6794ZM41.4098 21.8258V31.7032H51.1464V21.8258H41.4098ZM18.6957 41.6305C18.6957 40.0074 19.9928 38.6912 21.5934 38.6912H31.619C33.2196 38.6912 34.5168 40.0074 34.5168 41.6305V51.8008C34.5168 53.4239 33.2196 54.7401 31.619 54.7401H21.5934C19.9928 54.7401 18.6957 53.4239 18.6957 51.8008V41.6305ZM21.7379 41.777V51.6543H31.4745V41.777H21.7379ZM41.8779 42.264H46.2565C47.0966 42.264 47.7776 41.5732 47.7776 40.7211C47.7776 39.869 47.0966 39.1783 46.2565 39.1783H41.3401C39.9567 39.1783 38.8357 40.3158 38.8357 41.7186V45.7084C38.8357 46.5605 39.5167 47.2513 40.3568 47.2513C41.1969 47.2513 41.8779 46.5605 41.8779 45.7084V42.264ZM52.1562 47.158C52.9963 47.158 53.6773 47.8487 53.6773 48.7008V51.6932C53.6773 53.096 52.5562 54.2335 51.1729 54.2335H44.2899C43.4498 54.2335 42.7688 53.5428 42.7688 52.6907C42.7688 51.8386 43.4498 51.1478 44.2899 51.1478H50.6351V48.7008C50.6351 47.8487 51.3161 47.158 52.1562 47.158Z"
        fill="#858585"
      />
      <rect
        x="1.19568"
        y="1.24005"
        width="71"
        height="71"
        rx="3.5"
        stroke="#D9D9D9"
        strokeDasharray="4 4"
      />
    </svg>
  );
};

interface PreviewQrState {
  modeView: boolean;
  isAgreeCreate: boolean;
}

interface PreviewQrProps {
  qrType?: QrCodeType;
  qrStyle: QRStyleModel;
  qrContent: string;
  qrName: string;
  step: number;
  data?: any;
  onNext: () => void;
  onBack: () => void;
}

export const PreviewQr: React.FC<PreviewQrProps> = ({
  qrType,
  step,
  onBack,
  onNext,
  data,
  qrName,
  qrStyle,
  qrContent,
}) => {
  const { t } = useTranslation();
  const _cubit = useMemo<BaseBloc<PreviewQrState>>(() => {
    return new BaseBloc<PreviewQrState>({
      initState: {
        modeView: false,
        isAgreeCreate: true,
      },
    });
  }, []);

  useEffect(() => {
    if (qrType === undefined) {
      _cubit.state.modeView = false;
      _cubit.state.isAgreeCreate = true;
      _cubit.upDateState();
    }
  }, [qrType]);

  // if (data === null || data === undefined) {
  //   return <></>;
  // }

  return (
    <div className="create-qr-page__body__preview">
      {/* title */}
      <div className="create-qr-page__body__preview__header">
        <span
          className="text-20--reg"
          style={{ color: "#333333", lineHeight: "40px" }}
        >
          {qrType ? t(`qr_type.${qrType}`) : "Example"}
        </span>
        <div
          className="column"
          style={{
            borderRadius: "4px",
            border: "1px solid #D9D9D9",
            padding: "10px",
            boxSizing: "border-box",
            justifyContent: "center",
            alignItems: "center",
            cursor: "pointer",
            display: qrType === undefined ? "none" : undefined,
          }}
          onClick={() => {
            _cubit.state.modeView = !_cubit.state.modeView;
            _cubit.upDateState();
          }}
        >
          <StreamBuilder<PreviewQrState, any, any> stream={_cubit.stream}>
            {(c, snap) => {
              if (snap?.data?.modeView === true) {
                return <IconQrX20 />;
              } else {
                return <IconPhoneX20 />;
              }
            }}
          </StreamBuilder>
        </div>
      </div>
      {/* body */}
      <div className="create-qr-page__body__preview__body custom-scroll-bar">
        <div
          className="column"
          style={{
            width: "100%",
            gap: "20px",
          }}
        >
          <StreamBuilder<PreviewQrState, any, any> stream={_cubit.stream}>
            {(c, snap) => {
              if (snap?.data?.modeView === true) {
                return (
                  <PreviewQrCodeImage qrContent={qrContent} qrStyle={qrStyle} />
                );
              } else {
                if (qrType === undefined) {
                  return (
                    <div
                      key={"base_preview_phone"}
                      className="create-qr-page__body__preview__body__phone-preview"
                    >
                      <div
                        className="column"
                        style={{
                          width: "100%",
                          height: "100%",
                          gap: "12px",
                          justifyContent: "center",
                          alignItems: "center",
                          borderRadius: "46px",
                        }}
                      >
                        <IconQrBasePreviewPhone />
                        <span
                          style={{
                            textAlign: "center",
                          }}
                        >
                          Choose a type of QR code to continue
                        </span>
                      </div>
                    </div>
                  );
                } else {
                  if (qrType === QrCodeType.STATIC_WIRELESS) {
                    return (
                      <PreviewPhoneWireLess
                        key={qrType}
                        wifiName={`Join the "${
                          data?.networkName ?? ""
                        }" Wi-Fi network?`}
                      />
                    );
                  }
                  if (
                    qrType === QrCodeType.WEBSITE ||
                    qrType === QrCodeType.STATIC_WEBSITE
                  ) {
                    return (
                      <PreviewPhoneQrWeb
                        key={qrType}
                        url={`${data?.url ?? ""}`}
                      />
                    );
                  }

                  if (qrType === QrCodeType.STATIC_VIETQR) {
                    return <PreviewPhoneVietQr key={qrType} data={data} />;
                  }
                  return (
                    <div
                      key={`${qrType}_preview_phone`}
                      className="create-qr-page__body__preview__body__phone-preview"
                    >
                      <div
                        className="column"
                        style={{
                          width: "100%",
                          height: "100%",
                          gap: "12px",
                          justifyContent: "center",
                          alignItems: "center",
                          borderRadius: "46px",
                        }}
                      >
                        {/* preview phone vietqr */}
                        {/* {qrType === QrCodeType.STATIC_VIETQR && (
                          <PreviewPhoneVietQr data={data} />
                        )} */}

                        {/* preview phone Vcard */}
                        {qrType === QrCodeType.VCARD && (
                          <PreviewPhoneVCard data={data} />
                        )}
                      </div>
                    </div>
                  );
                }
              }
            }}
          </StreamBuilder>
          <div className="create-qr-page__body__preview__body__instruction">
            <IconInfo />
            <StreamBuilder<PreviewQrState, any, any> stream={_cubit.stream}>
              {(c, snap) => {
                if (snap?.data?.modeView === true) {
                  return (
                    <span
                      className="text-14--reg text-overflow--new-line"
                      style={{
                        color: "#5C5C5C",
                        width: "100%",
                        boxSizing: "border-box",
                      }}
                    >
                      IMPORTANT: Dont’t forget to test your QR code
                    </span>
                  );
                } else {
                  return (
                    <span
                      className="text-14--reg text-overflow--new-line"
                      style={{
                        color: "#5C5C5C",
                        width: "100%",
                        boxSizing: "border-box",
                      }}
                    >
                      IMPORTANT: Remember that you can preview and test your QR
                      codes by clicking on the icon
                    </span>
                  );
                }
              }}
            </StreamBuilder>
          </div>
        </div>
      </div>
      {/* footer */}
      <div className="create-qr-page__body__preview__footer">
        {qrType === QrCodeType.STATIC_VIETQR && step === 2 && (
          <div
            className="row"
            style={{
              width: "100%",
              gap: "20px",
            }}
          >
            <InputCheck
              onChange={(v) => {
                _cubit.state.isAgreeCreate = v;
                _cubit.upDateState();
              }}
              defaultValue={_cubit.state.isAgreeCreate}
              wrapStyle={{ gap: "8px" }}
              suffix={
                <span className="text-14--reg" style={{ color: "#333333" }}>
                  I agree with the{" "}
                  <span style={{ color: "#12B76A" }}>terms and condition</span>.
                </span>
              }
            />
          </div>
        )}
        <div
          className="row"
          style={{
            width: "100%",
            gap: "20px",
          }}
        >
          <button
            onClick={() => {
              onBack();
            }}
            style={{ width: "100%" }}
            className="button-primary-outline"
          >
            <IconArrowBackX20 />
            <span>Back</span>
          </button>
          <StreamBuilder<PreviewQrState, any, any>
            selector={(pre, next) => {
              return pre?.isAgreeCreate !== next?.isAgreeCreate;
            }}
            stream={_cubit.stream}
          >
            {(_, snap) => {
              return (
                <button
                  disabled={
                    (step === 1 &&
                      (data === undefined ||
                        data === null ||
                        !data?.done ||
                        qrName.length === 0)) ||
                    (step === 2 && !(snap?.data?.isAgreeCreate ?? false)) ||
                    qrType === undefined
                  }
                  onClick={() => {
                    onNext();
                  }}
                  style={{ width: "100%" }}
                  className="button-primary"
                >
                  <span>{step === 2 ? "Complete" : "Next"}</span>
                  {step === 2 ? (
                    <IconCheckX20 />
                  ) : (
                    <IconArrowFowardX20 color="#FFFFFF" />
                  )}
                </button>
              );
            }}
          </StreamBuilder>
        </div>
      </div>
    </div>
  );
};
