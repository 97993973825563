import { CSSProperties, useCallback, useState } from "react";
import Cropper, { Area, Point } from "react-easy-crop";
import Resizer from "react-image-file-resizer";
import { CardCustom } from "../card-custom/CardCustom";
import { InputFileCustom } from "../input-file/InputFile";
import "./crop-image.css";
import { checkTypeImg, cropImage } from "@/utils/imageUtils";
import { dataURLtoFile } from "@/utils/fileUtils";
import { message } from "antd";
import "@/styles";

const IconClose = ({ color = "#1F2937" }: { color?: string }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.46967 6.46967C6.76256 6.17678 7.23744 6.17678 7.53033 6.46967L12 10.9393L16.4697 6.46967C16.7626 6.17678 17.2374 6.17678 17.5303 6.46967C17.8232 6.76256 17.8232 7.23744 17.5303 7.53033L13.0607 12L17.5303 16.4697C17.8232 16.7626 17.8232 17.2374 17.5303 17.5303C17.2374 17.8232 16.7626 17.8232 16.4697 17.5303L12 13.0607L7.53033 17.5303C7.23744 17.8232 6.76256 17.8232 6.46967 17.5303C6.17678 17.2374 6.17678 16.7626 6.46967 16.4697L10.9393 12L6.46967 7.53033C6.17678 7.23744 6.17678 6.76256 6.46967 6.46967Z"
        fill={color}
      />
    </svg>
  );
};

const IconPlusX16 = ({ color = "#2C5D48" }: { color?: string }) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.00001 2.83325C8.27615 2.83325 8.50001 3.05711 8.50001 3.33325V7.49992H12.6667C12.9428 7.49992 13.1667 7.72378 13.1667 7.99992C13.1667 8.27606 12.9428 8.49992 12.6667 8.49992H8.50001V12.6666C8.50001 12.9427 8.27615 13.1666 8.00001 13.1666C7.72387 13.1666 7.50001 12.9427 7.50001 12.6666V8.49992H3.33334C3.0572 8.49992 2.83334 8.27606 2.83334 7.99992C2.83334 7.72378 3.0572 7.49992 3.33334 7.49992H7.50001V3.33325C7.50001 3.05711 7.72387 2.83325 8.00001 2.83325Z"
        fill={color}
      />
    </svg>
  );
};

const IconMinusX16 = ({ color = "#2C5D48" }: { color?: string }) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.83331 8C2.83331 7.72386 3.05717 7.5 3.33331 7.5H12.6666C12.9428 7.5 13.1666 7.72386 13.1666 8C13.1666 8.27614 12.9428 8.5 12.6666 8.5H3.33331C3.05717 8.5 2.83331 8.27614 2.83331 8Z"
        fill={color}
      />
    </svg>
  );
};

const MAX_ZOOM: number = 10;
const MIN_ZOOM: number = 1;

export const CropImageCustom = ({
  acceptTypeImg,
  maxSizeFile,
  content,
  onSaveHandler,
  minSizeImg = { height: 320, width: 320 },
  labelTitle,
  labelCancel,
  labelConfirm,
  classname,
  style,
}: {
  acceptTypeImg?: string;
  maxSizeFile?: number;
  content: React.ReactNode;
  onSaveHandler: ({ data, file }: { data: string; file: File }) => void;
  minSizeImg: { width: number; height: number };
  labelTitle?: string;
  labelCancel?: string;
  labelConfirm?: string;
  style?: CSSProperties;
  classname?: string;
}) => {
  const [crop, setCrop] = useState<Point>({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [isOpenModalCropImg, setIsOpenModalCropImg] = useState(false);

  const [inforFile, setInforFile] = useState({
    imgFile: "",
    typeFile: "",
    nameFile: "",
  });

  const [croppedAreaPixels, setCroppedAreaPixels] = useState({
    width: minSizeImg.width,
    height: minSizeImg.height,
    x: 0,
    y: 0,
  });

  const onCropComplete = useCallback(
    (croppedArea: Area, croppedAreaPixels: Area) => {
      setCroppedAreaPixels(croppedAreaPixels);
    },
    [],
  );

  const handleClickCropImage = async () => {
    const data: any = await cropImage(
      inforFile.imgFile,
      inforFile.nameFile.replaceAll(`.${inforFile.typeFile.split("/")[1]}`, ""),
      inforFile.typeFile.split("/")[1],
      croppedAreaPixels,
    );
    if (data) {
      try {
        const fileToBlob = new Blob(
          [new Uint8Array(await data.image.arrayBuffer())],
          {
            type: `image/${data.image.type}`,
          },
        );

        Resizer.imageFileResizer(
          fileToBlob,
          minSizeImg.width,
          minSizeImg.height,
          data.image.type.toUpperCase(),
          100,
          0,
          (uri) => {
            onSaveHandler({
              data: uri.toString(),
              file: dataURLtoFile(
                uri.toString(),
                inforFile.nameFile.includes(".")
                  ? inforFile.nameFile
                  : inforFile.nameFile.concat(`.${data.image.type}`),
              ),
            });
          },
          "base64",
        );
        message.success("Cắt ảnh thành công");
      } catch (error) {
        console.error(error);
      }
    } else {
      message.error("Cắt ảnh thất bại");
    }
    setIsOpenModalCropImg(false);
  };

  return (
    <>
      <InputFileCustom
        style={style}
        classname={classname}
        accept={acceptTypeImg}
        onChange={(img, f) => {
          if (
            acceptTypeImg &&
            !checkTypeImg(acceptTypeImg.replaceAll(" ", "").split(","), f.type)
          ) {
            return message.error("Loại ảnh này không được chấp nhận", 5);
          }
          if (maxSizeFile && f.size > maxSizeFile) {
            return message.error(`Kích thước ảnh quá lớn`, 5);
          }
          setInforFile({
            imgFile: img,
            nameFile: f.name,
            typeFile: f.type,
          });
          setIsOpenModalCropImg(true);
        }}
      >
        {content}
      </InputFileCustom>
      {isOpenModalCropImg && (
        <div
          className="overlay-1"
          onClick={() => {
            setIsOpenModalCropImg(false);
          }}
        >
          <div
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            {/* Body */}
            <CardCustom className="crop-image-component__card-custom">
              {/* Header */}
              <div className="row crop-image-component__card-custom__header">
                <span className="crop-image-component__card-custom__header__title">
                  {labelTitle}
                </span>
                <span
                  className="crop-image-component__card-custom__header__icon-close"
                  onClick={() => {
                    setIsOpenModalCropImg(false);
                  }}
                >
                  <IconClose />
                </span>
              </div>
              {/* Body */}
              <div className="column crop-image-component__card-custom__image">
                {/* Crop Container */}
                <div className="crop-image-component__card-custom__image__crop-container">
                  <Cropper
                    image={inforFile.imgFile}
                    crop={crop}
                    zoom={zoom}
                    zoomSpeed={4}
                    maxZoom={MAX_ZOOM}
                    aspect={minSizeImg.width / minSizeImg.height}
                    onCropChange={setCrop}
                    onCropComplete={onCropComplete}
                    onZoomChange={setZoom}
                    onMediaLoaded={(d) => {
                      if (
                        d.naturalHeight < minSizeImg.height &&
                        d.naturalWidth < minSizeImg.width
                      ) {
                        setIsOpenModalCropImg(false);
                        message.error(
                          `Yêu cầu ảnh tối thiểu ${minSizeImg.width} x ${minSizeImg.height}`,
                          5,
                        );
                      }
                    }}
                  />
                </div>
                {/* Button control */}
                <div className="row crop-image-component__card-custom__control">
                  <span
                    onClick={() => {
                      if (zoom <= MIN_ZOOM) return;
                      setZoom(zoom - 1);
                    }}
                  >
                    <IconMinusX16 color="#000000" />
                  </span>
                  <input
                    type="range"
                    min={MIN_ZOOM}
                    max={MAX_ZOOM}
                    value={zoom}
                    className="slider"
                    id="myRange"
                    onChange={(e) => {
                      setZoom(Number(e.target.value));
                    }}
                  />
                  <span
                    onClick={() => {
                      if (zoom >= MAX_ZOOM) return;
                      setZoom(zoom + 1);
                    }}
                  >
                    <IconPlusX16 color="#000000" />
                  </span>
                </div>
              </div>
              {/* Footer */}
              <div className="row crop-image-component__card-custom__footer">
                <button
                  className="button-2"
                  onClick={() => {
                    setIsOpenModalCropImg(false);
                  }}
                >
                  {labelCancel}
                </button>
                <button
                  className="button-1"
                  onClick={() => {
                    handleClickCropImage();
                  }}
                >
                  {labelConfirm}
                </button>
              </div>
            </CardCustom>
          </div>
        </div>
      )}
    </>
  );
};
