import {
  AuthControllerApi,
  DynamicQrControllerApi,
  MediaControllerApi,
  PaymentControllerApi,
  PlanControllerApi,
  PublicLinkQrControllerApi,
  QrCodeControllerApi,
  ReportControllerApi,
  StaticQrControllerApi,
  TemplateControllerApi,
  UserControllerApi,
} from "../generated-sources/qr-code/api";
import {
  Configuration,
  ConfigurationParameters,
} from "../generated-sources/qr-code/configuration";
import { AuthRepository } from "./AuthRepository";
import { DynamicQrRepository } from "./DynamicQrRepository";
import { MediaRepository } from "./MediaRepository";
import { PaymentRepository } from "./PaymentRepository";
import { PlanRepository } from "./PlanRepository";
import { PublicLinkQrRepository } from "./PublicLinkQrRepository";
import { QrCodeRepository } from "./QrCodeRepository";
import { ReportRepository } from "./ReportRepository";
import { StaticQrRepository } from "./StaticQrRepository";
import { TemplateRepository } from "./TemplateRepository";
import { UserRepository } from "./UserRepository";
import { AuthRepositoryImp } from "./implements/AuthRepositoryImp";
import { DynamicQrRepositoryImp } from "./implements/DynamicQrRepositoryImp";
import { MediaRepositoryImp } from "./implements/MediaRepositoryImp";
import { PaymentRepositoryImp } from "./implements/PaymentRepositoryImp";
import { PlanRepositoryImp } from "./implements/PlanRepositoryImp";
import { PublicLinkQrRepositoryImp } from "./implements/PublicLinkQrRepositoryImp";
import { QrCodeRepositoryImp } from "./implements/QrCodeRepositoryImp";
import { ReportRepositoryImp } from "./implements/ReportRepositoryImp";
import { StaticQrRepositoryImp } from "./implements/StaticQrRepositoryImp";
import { TemplateRepositoryImp } from "./implements/TemplateRepositoryImp";
import { UserRepositoryImp } from "./implements/UserRepositoryImp";

export const qrfyConfig: ConfigurationParameters = {
  basePath: "https://api-dev.yana.vn",
};

export class QrfyHttpClient {
  public readonly auth: AuthControllerApi;
  public readonly user: UserControllerApi;
  public readonly staticQr: StaticQrControllerApi;
  public readonly dynamicQr: DynamicQrControllerApi;
  public readonly media: MediaControllerApi;
  public readonly qrCode: QrCodeControllerApi;
  public readonly publicLinkQr: PublicLinkQrControllerApi;
  public readonly report: ReportControllerApi;
  public readonly payment: PaymentControllerApi;
  public readonly plan: PlanControllerApi;
  public readonly template: TemplateControllerApi;

  constructor(cf: Configuration) {
    this.auth = new AuthControllerApi(cf, cf.basePath);
    this.user = new UserControllerApi(cf, cf.basePath);
    this.staticQr = new StaticQrControllerApi(cf, cf.basePath);
    this.dynamicQr = new DynamicQrControllerApi(cf, cf.basePath);
    this.media = new MediaControllerApi(cf, cf.basePath);
    this.qrCode = new QrCodeControllerApi(cf, cf.basePath);
    this.publicLinkQr = new PublicLinkQrControllerApi(cf, cf.basePath);
    this.report = new ReportControllerApi(cf, cf.basePath);
    this.payment = new PaymentControllerApi(cf, cf.basePath);
    this.plan = new PlanControllerApi(cf, cf.basePath);
    this.template = new TemplateControllerApi(cf, cf.basePath);
  }
}

export class QrfyRepositories {
  private static qrfyHttpClient: QrfyHttpClient;
  public readonly auth: AuthRepository;
  public readonly user: UserRepository;
  public readonly staticQr: StaticQrRepository;
  public readonly dynamicQr: DynamicQrRepository;
  public readonly media: MediaRepository;
  public readonly qrCode: QrCodeRepository;
  public readonly publicLinkQr: PublicLinkQrRepository;
  public readonly report: ReportRepository;
  public readonly payment: PaymentRepository;
  public readonly plan: PlanRepository;
  public readonly template: TemplateRepository;

  constructor(basePath?: string) {
    if (basePath) {
      qrfyConfig.basePath = basePath;
    }
    QrfyRepositories.qrfyHttpClient = new QrfyHttpClient(
      new Configuration(qrfyConfig),
    );
    this.auth = new AuthRepositoryImp(QrfyRepositories.qrfyHttpClient);
    this.user = new UserRepositoryImp(QrfyRepositories.qrfyHttpClient);
    this.staticQr = new StaticQrRepositoryImp(QrfyRepositories.qrfyHttpClient);
    this.dynamicQr = new DynamicQrRepositoryImp(
      QrfyRepositories.qrfyHttpClient,
    );
    this.media = new MediaRepositoryImp(QrfyRepositories.qrfyHttpClient);
    this.qrCode = new QrCodeRepositoryImp(QrfyRepositories.qrfyHttpClient);
    this.publicLinkQr = new PublicLinkQrRepositoryImp(
      QrfyRepositories.qrfyHttpClient,
    );
    this.report = new ReportRepositoryImp(QrfyRepositories.qrfyHttpClient);
    this.payment = new PaymentRepositoryImp(QrfyRepositories.qrfyHttpClient);
    this.plan = new PlanRepositoryImp(QrfyRepositories.qrfyHttpClient);
    this.template = new TemplateRepositoryImp(QrfyRepositories.qrfyHttpClient);
  }
}
