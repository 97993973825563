export const IconInfo = ({ color = "#858585" }: { color?: string }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.75 12C21.75 17.3848 17.3848 21.75 12 21.75C6.61522 21.75 2.25 17.3848 2.25 12C2.25 6.61522 6.61522 2.25 12 2.25C17.3848 2.25 21.75 6.61522 21.75 12ZM10.25 10.25C10.25 9.69771 10.6977 9.25 11.25 9.25H12.25C12.8023 9.25 13.25 9.69771 13.25 10.25V15.25H13.75C14.3023 15.25 14.75 15.6977 14.75 16.25C14.75 16.8023 14.3023 17.25 13.75 17.25H10.75C10.1977 17.25 9.75 16.8023 9.75 16.25C9.75 15.6977 10.1977 15.25 10.75 15.25H11.25V11.25C10.6977 11.25 10.25 10.8023 10.25 10.25ZM12.0073 8.44824C12.6184 8.44824 13.1138 7.95287 13.1138 7.3418C13.1138 6.73072 12.6184 6.23535 12.0073 6.23535C11.3963 6.23535 10.9009 6.73072 10.9009 7.3418C10.9009 7.95287 11.3963 8.44824 12.0073 8.44824Z"
        fill={color}
      />
    </svg>
  );
};
