import { ErrorModel } from "@/models/ErrorModel";
import { QrCodeRepository } from "../QrCodeRepository";
import { QrfyHttpClient } from "../QrfyRepositories";
import { QrCodeType, QrCodeModel } from "../models/QrModel";
import { mapQrCodeModel } from "../adapters/qr-adapter";

export class QrCodeRepositoryImp implements QrCodeRepository {
  private client: QrfyHttpClient;
  constructor(c: QrfyHttpClient) {
    this.client = c;
  }

  public async getListQrCode({
    page,
    size,
    sortBy = "createdDate",
    sortDir = "DESC",
    type,
  }: {
    page?: number;
    size?: number;
    sortBy?: string;
    sortDir?: "ASC" | "DESC";
    type?: QrCodeType;
  }): Promise<{
    pageNumber: number;
    pageSize: number;
    totalSize: number;
    totalPage: number;
    items: Array<QrCodeModel<any>>;
  }> {
    try {
      const resp = await this.client.qrCode.getListQrCode({
        page: page,
        size: size,
        sortBy: sortBy,
        sortDir: sortDir,
        type: type,
      });
      if (resp.data.code === 0) {
        return {
          items:
            resp.data.data?.qrCodes?.items?.map((d) => mapQrCodeModel(d)) ?? [],
          pageNumber: resp.data.data?.qrCodes?.pageNumber ?? 0,
          pageSize: resp.data.data?.qrCodes?.pageSize ?? 0,
          totalPage: resp.data.data?.qrCodes?.totalPage ?? 0,
          totalSize: resp.data.data?.qrCodes?.totalSize ?? 0,
        };
      } else {
        throw new ErrorModel({
          code: resp.data.code,
          message: resp.data.message,
        });
      }
    } catch (error) {
      throw error;
    }
  }

  public async deleteAllQrCode(): Promise<boolean> {
    try {
      const resp = await this.client.qrCode.deleteAllQrCode();
      if (resp.data.code === 0) {
        return resp.data.data?.success ?? false;
      } else {
        throw new ErrorModel({
          code: resp.data.code,
          message: resp.data.message,
        });
      }
    } catch (error) {
      throw error;
    }
  }

  public async deleteQr({ id }: { id: string }): Promise<boolean> {
    try {
      const resp = await this.client.qrCode._delete({
        id: id,
      });
      if (resp.data.code === 0) {
        return resp.data.data?.success ?? false;
      } else {
        throw new ErrorModel({
          code: resp.data.code,
          message: resp.data.message,
        });
      }
    } catch (error) {
      throw error;
    }
  }

  public async getDetail({ id }: { id: string }): Promise<QrCodeModel<any>> {
    try {
      const resp = await this.client.qrCode.getDetail1({
        id: id,
      });
      if (resp.data.code === 0) {
        return mapQrCodeModel(resp.data.data);
      } else {
        throw new ErrorModel({
          code: resp.data.code,
          message: resp.data.message,
        });
      }
    } catch (error) {
      throw error;
    }
  }
}
