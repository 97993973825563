export const IconSwapX20 = ({ color = "#333333" }: { color?: string }) => {
  return (
    <svg
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.5467 2.44307C12.7907 2.19899 13.1865 2.19899 13.4305 2.44307L18.459 7.47155C18.6378 7.6503 18.6912 7.91912 18.5945 8.15267C18.4978 8.38622 18.2699 8.53849 18.0171 8.53849H2.99512C2.64994 8.53849 2.37012 8.25867 2.37012 7.91349C2.37012 7.56832 2.64994 7.28849 2.99512 7.28849H16.5082L12.5467 3.32695C12.3026 3.08287 12.3026 2.68715 12.5467 2.44307ZM2.73915 11.8254C2.83589 11.5919 3.06378 11.4396 3.31657 11.4396H18.0171C18.3623 11.4396 18.6421 11.7194 18.6421 12.0646C18.6421 12.4098 18.3623 12.6896 18.0171 12.6896H4.82546L8.787 16.6511C9.03108 16.8952 9.03108 17.2909 8.787 17.535C8.54292 17.7791 8.14719 17.7791 7.90312 17.535L2.87463 12.5065C2.69588 12.3278 2.64241 12.059 2.73915 11.8254Z"
        fill={color}
      />
    </svg>
  );
};
