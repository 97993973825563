import { ErrorModel } from "@/models/ErrorModel";
import { AuthRepository } from "../AuthRepository";
import { QrfyHttpClient } from "../QrfyRepositories";
import { mapAccessTokenModel } from "../adapters/access-token-adapter";
import { AccessTokenModel } from "../models/AccessTokenModel";

export class AuthRepositoryImp implements AuthRepository {
  private client: QrfyHttpClient;
  constructor(c: QrfyHttpClient) {
    this.client = c;
  }

  public async userLogin({
    username,
    password,
  }: {
    username: string;
    password: string;
  }): Promise<AccessTokenModel> {
    try {
      const resp = await this.client.auth.userLogin({
        loginRequest: {
          password: password,
          username: username,
        },
      });
      if (resp.data.code === 0) {
        return mapAccessTokenModel(resp.data.data);
      } else {
        throw new ErrorModel({
          code: resp.data.code,
          message: resp.data.message,
        });
      }
    } catch (error) {
      throw error;
    }
  }

  public async adminLogin({
    username,
    password,
  }: {
    username: string;
    password: string;
  }): Promise<AccessTokenModel> {
    try {
      const resp = await this.client.auth.adminLogin({
        adminLoginRequest: {
          password: password,
          username: username,
        },
      });
      if (resp.data.code === 0) {
        return mapAccessTokenModel(resp.data.data);
      } else {
        throw new ErrorModel({
          code: resp.data.code,
          message: resp.data.message,
        });
      }
    } catch (error) {
      throw error;
    }
  }

  public async logout({
    refreshToken,
  }: {
    refreshToken: string;
  }): Promise<boolean> {
    try {
      const resp = await this.client.auth.logout({
        logoutRequest: {
          refreshToken: refreshToken,
        },
      });
      if (resp.data.code === 0) {
        return resp.data.data?.logout ?? false;
      } else {
        throw new ErrorModel({
          code: resp.data.code,
          message: resp.data.message,
        });
      }
    } catch (error) {
      throw error;
    }
  }

  public async refreshToken({
    refreshToken,
  }: {
    refreshToken: string;
  }): Promise<AccessTokenModel> {
    try {
      const resp = await this.client.auth.refreshToken({
        refreshTokenRequest: {
          refreshToken: refreshToken,
        },
      });
      if (resp.data.code === 0) {
        return mapAccessTokenModel(resp.data.data);
      } else {
        throw new ErrorModel({
          code: resp.data.code,
          message: resp.data.message,
        });
      }
    } catch (error) {
      throw error;
    }
  }
}
