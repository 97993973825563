export const IconLocation = ({ color = "#12B76A" }: { color?: string }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 3.75C8.60323 3.75 5.75 6.75022 5.75 10.579C5.75 11.4566 6.07994 12.5087 6.64944 13.6355C7.21324 14.7511 7.98125 15.8804 8.78489 16.8956C9.58772 17.9098 10.4125 18.7929 11.078 19.4163C11.412 19.7293 11.695 19.9666 11.9072 20.1204C11.9414 20.1451 11.9723 20.1667 12 20.1853C12.0277 20.1667 12.0586 20.1451 12.0928 20.1204C12.305 19.9666 12.588 19.7293 12.922 19.4163C13.5875 18.7929 14.4123 17.9098 15.2151 16.8956C16.0188 15.8804 16.7868 14.7511 17.3506 13.6355C17.9201 12.5087 18.25 11.4566 18.25 10.579C18.25 6.75022 15.3968 3.75 12 3.75ZM11.8351 20.2816C11.8352 20.2814 11.8393 20.2794 11.847 20.2767C11.8388 20.2806 11.835 20.2819 11.8351 20.2816ZM12.1529 20.2767C12.1607 20.2794 12.1648 20.2813 12.1649 20.2816C12.1651 20.2819 12.1612 20.2806 12.1529 20.2767ZM4.25 10.579C4.25 6.03627 7.66478 2.25 12 2.25C16.3352 2.25 19.75 6.03627 19.75 10.579C19.75 11.7943 19.306 13.0919 18.6893 14.3121C18.0669 15.5436 17.2365 16.7588 16.3912 17.8267C15.5451 18.8955 14.6704 19.8338 13.9476 20.511C13.5875 20.8483 13.2542 21.1312 12.973 21.3349C12.8335 21.436 12.6927 21.528 12.5581 21.5981C12.4524 21.6531 12.2433 21.7539 12 21.7539C11.7567 21.7539 11.5476 21.6531 11.4419 21.5981C11.3073 21.528 11.1665 21.436 11.027 21.3349C10.7458 21.1312 10.4125 20.8483 10.0524 20.511C9.3296 19.8338 8.4549 18.8955 7.60879 17.8267C6.76349 16.7588 5.93307 15.5436 5.31069 14.3121C4.694 13.0919 4.25 11.7943 4.25 10.579ZM12 7.20215C10.7574 7.20215 9.75 8.20951 9.75 9.45215C9.75 10.6948 10.7574 11.7021 12 11.7021C13.2426 11.7021 14.25 10.6948 14.25 9.45215C14.25 8.20951 13.2426 7.20215 12 7.20215ZM8.25 9.45215C8.25 7.38108 9.92893 5.70215 12 5.70215C14.0711 5.70215 15.75 7.38108 15.75 9.45215C15.75 11.5232 14.0711 13.2021 12 13.2021C9.92893 13.2021 8.25 11.5232 8.25 9.45215Z"
        fill={color}
      />
    </svg>
  );
};
