import { InputForm2 } from "@/components/input-form/InputForm2";
import "./style.less";
import { BaseBloc } from "@/services/base-bloc";
import { FormCreateQrWeb } from "./interfaces/FormCreateQrWeb";
import { useEffect, useMemo, useState } from "react";
import { distinct } from "rxjs";
import { isValidHttpUrl } from "@/utils/validate";
import { useAppBloc } from "@/contexts/AppContext";
import { PopupConfigParams } from "./components/PopupConfigParams";
import { QrCodeModel } from "@/apis/qrfy/models/QrModel";
import { WebsiteQrModel } from "@/apis/qrfy/models/WebsiteQrModel";
import Environment from "@/services/environment";
import { Switch } from "antd";
import { Expanded } from "@/components/expanded/Expanded";
import { v4 as uuidv4 } from "uuid";

interface CreateQrWebState {
  url: string;
  customPath?: string;
  autoGenURL?: boolean;
  errors: {
    url: string;
    customPath: string;
  };
}

interface CreateQrWebProps {
  onChage: (v: FormCreateQrWeb) => void;
  initData?: QrCodeModel<WebsiteQrModel> | null;
}

export const CreateQrWeb: React.FC<CreateQrWebProps> = ({
  onChage,
  initData = null,
}) => {
  const { appBloc } = useAppBloc();
  const _bloc = useMemo<BaseBloc<CreateQrWebState>>(() => {
    return new BaseBloc<CreateQrWebState>({
      initState: {
        errors: {
          url: "",
          customPath: "",
        },
        url: "",
        customPath: Environment.host + "/" + `${uuidv4().substring(0, 7)}`,
        autoGenURL: true,
      },
    });
  }, []);
  const [state, setState] = useState<CreateQrWebState>(_bloc.state);

  function onEditParam() {
    appBloc.session.queueModal.next(
      <PopupConfigParams
        onClose={() => {
          appBloc.session.queueModal.next(null);
        }}
        onConfirm={(v) => {
          appBloc.session.queueModal.next(null);
          _bloc.state.url = v;
          _bloc.upDateState();
        }}
        url={_bloc.state.url}
      />,
    );
  }

  useEffect(() => {
    _bloc.stream.pipe(distinct()).subscribe((v) => {
      let check = true;
      if (!isValidHttpUrl(v.url)) {
        check = false;
      }
      if (v && check) {
        onChage({
          url: v.url,
          done: true,
          customPath: (v.customPath ?? "").replace(`${Environment.host}/`, ""),
        });
      } else {
        onChage({
          url: v.url,
          done: false,
          customPath: (v.customPath ?? "").replace(`${Environment.host}/`, ""),
        });
      }
      setState({ ...v });
    });
    if (initData !== null) {
      _bloc.state.url = initData.data.url;
      _bloc.state.customPath =
        initData?.data.qrContent ?? _bloc.state.customPath;
      _bloc.upDateState();
    }
  }, []);

  return (
    <div className="create-qr-web">
      {/* web url info */}
      <div className="create-qr-web__info">
        <span className="text-16--md" style={{ color: "#333333" }}>
          Website Information
        </span>
        <InputForm2
          defaultValue={state.url}
          valid={(v) => {
            if (!isValidHttpUrl(v)) {
              return "Url không đúng định dạng";
            } else {
              return "";
            }
          }}
          onError={(v) => {
            _bloc.state.errors.url = v;
            _bloc.upDateState();
          }}
          onChange={(v) => {
            _bloc.state.url = v;
            _bloc.upDateState();
          }}
          required
          title="Web page URL"
          placeHolder="Enter Web page URL"
          isCheckError
        />
        <button
          disabled={!isValidHttpUrl(state.url)}
          onClick={() => {
            onEditParam();
          }}
          style={{ width: "fit-content" }}
          className="button-primary-outline"
        >
          Edit Parameters
        </button>
      </div>

      {/* url config */}
      <Expanded opended title="URL Config">
        <span
          className="text-14--reg"
          style={{
            color: "rgba(133, 133, 133, 1)",
          }}
        >
          Tùy chỉnh định danh địa chỉ của bạn trên qrid.com.
        </span>
        <div
          className="row"
          style={{
            gap: "8px",
          }}
        >
          <Switch
            defaultChecked={_bloc.state.autoGenURL}
            disabled={(initData?.id ?? "") !== ""}
            checked={state.autoGenURL}
            onChange={(e) => {
              _bloc.state.autoGenURL = e;
              if (e) {
                _bloc.state.customPath =
                  Environment.host + "/" + `${uuidv4().substring(0, 7)}`;
              }
              _bloc.upDateState();
            }}
          />
          <span
            className="text-14--reg"
            style={{ color: "rgba(51, 51, 51, 1)" }}
          >
            Tạo tự động
          </span>
        </div>
        <InputForm2
          defaultValue={_bloc.state.customPath}
          isDisable={_bloc.state.autoGenURL}
          title="URL"
          required
          onChange={(v) => {
            _bloc.state.customPath = v;
            _bloc.upDateState();
          }}
          isCheckError
          errorReport
          defaultErrorLabel={state.errors.customPath}
          valid={(v) => {
            if (v.length === 0) {
              return "Vui lòng không để trống";
            }
            if (v.replace(`${Environment.host}/`, "").includes(" ")) {
              return "Vui lòng không để khoảng trống";
            }
            if (!v.startsWith(Environment.host + "/")) {
              return "URl không hợp lệ";
            }
            if (
              v.startsWith(Environment.host + "/") &&
              v.replace(`${Environment.host}/`, "").length < 2
            ) {
              return "URl không hợp lệ";
            }
            return "";
          }}
          onError={(e) => {
            _bloc.state.errors.customPath = e;
            _bloc.upDateState();
          }}
        />
      </Expanded>
    </div>
  );
};
