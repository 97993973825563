import { CustomStorage, Storage } from "@/domains/storage";

class WrapLocalStorage extends CustomStorage {
  public get sizeStorage(): number {
    return localStorage.length;
  }

  public getItem({ key }: { key: string }): string {
    return localStorage.getItem(key) ?? "";
  }
  public setItem({ key, value }: { key: string; value: string }): void {
    return localStorage.setItem(key, value);
  }

  public removeItem({ key }: { key: string }) {
    return localStorage.removeItem(key);
  }

  public clearStorage() {
    return localStorage.clear();
  }
}

class WrapSessionStorage extends CustomStorage {
  public get sizeStorage(): number {
    return sessionStorage.length;
  }

  public getItem({ key }: { key: string }): string {
    return sessionStorage.getItem(key) ?? "";
  }
  public setItem({ key, value }: { key: string; value: string }): void {
    return sessionStorage.setItem(key, value);
  }

  public removeItem({ key }: { key: string }) {
    return sessionStorage.removeItem(key);
  }

  public clearStorage() {
    return sessionStorage.clear();
  }
}

enum KeysStorage {
  accessToken = "accessToken",
  refreshToken = "refreshToken",
  isLogin = "isLogin",
  userInfo = "userInfo",
  language = "language",
  countRefreshToken = "countRefreshToken",
}

export class AppStorage extends Storage {
  private _localStore: WrapLocalStorage;
  private _sessionStore: WrapSessionStorage;
  private _packageName: string = "__QRID__";
  constructor() {
    super();
    this._localStore = new WrapLocalStorage();
    this._sessionStore = new WrapSessionStorage();
  }
  _getKey(key: string): string {
    return `${this._packageName}${key}`;
  }

  // access token
  public get accessToken(): string {
    return (
      this._localStore?.getItem({
        key: `${this._getKey(KeysStorage.accessToken)}`,
      }) ?? ""
    );
  }

  public set accessToken(v: string) {
    this._localStore?.setItem({
      key: `${this._getKey(KeysStorage.accessToken)}`,
      value: v,
    });
  }

  // accrefresh token
  public get refreshToken(): string {
    return (
      this._localStore?.getItem({
        key: `${this._getKey(KeysStorage.refreshToken)}`,
      }) ?? ""
    );
  }

  public set refreshToken(v: string) {
    this._localStore?.setItem({
      key: `${this._getKey(KeysStorage.refreshToken)}`,
      value: v,
    });
  }

  // isLogin
  public get isLogin(): boolean {
    return (
      (this._sessionStore?.getItem({
        key: `${this._getKey(KeysStorage.isLogin)}`,
      }) ?? "") === "true"
    );
  }

  public set isLogin(v: boolean) {
    this._sessionStore?.setItem({
      key: `${this._getKey(KeysStorage.isLogin)}`,
      value: v.toString(),
    });
  }

  // user info
  public get userInfo(): any {
    const obStr =
      this._localStore?.getItem({
        key: `${this._getKey(KeysStorage.userInfo)}`,
      }) ?? "";
    return obStr === "" ? null : JSON.parse(obStr);
  }

  public set userInfo(v: any) {
    this._localStore?.setItem({
      key: `${this._getKey(KeysStorage.userInfo)}`,
      value: JSON.stringify(v),
    });
  }

  // language
  public get language(): string {
    if (
      this._localStore?.getItem({
        key: `${this._getKey(KeysStorage.language)}`,
      }) === ""
    ) {
      return "vi";
    }
    return this._localStore?.getItem({
      key: `${this._getKey(KeysStorage.language)}`,
    });
  }

  public set language(v: string) {
    this._localStore?.setItem({
      key: `${this._getKey(KeysStorage.language)}`,
      value: v,
    });
  }

  // countRefreshToken
  public get countRefreshToken(): number {
    if (
      this._sessionStore?.getItem({
        key: `${this._getKey(KeysStorage.countRefreshToken)}`,
      }) === ""
    ) {
      return 0;
    }
    return parseInt(
      this._sessionStore?.getItem({
        key: `${this._getKey(KeysStorage.countRefreshToken)}`,
      }),
    );
  }

  public set countRefreshToken(v: number) {
    this._sessionStore?.setItem({
      key: `${this._getKey(KeysStorage.countRefreshToken)}`,
      value: v.toString(),
    });
  }

  public clearLocalStorage() {
    const tmp = this.language;
    this._localStore?.clearStorage();
    this.language = tmp;
  }

  public clearSessionStorage() {
    this._sessionStore?.clearStorage();
  }

  // clear all local data
  public clearAll() {
    this.clearSessionStorage();
    this.clearLocalStorage();
  }
}
