export const IconPenX20 = ({ color = "#000000" }: { color?: string }) => {
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Live area" clipPath="url(#clip0_2333_3624)">
      <g id="Vector">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M17.3333 4.01669L16.4499 4.89169V4.90002L13.0999 1.55002V1.54169L13.9833 0.666687L17.3333 4.01669ZM12.2167 4.19167L13.8 5.77501L3.5 16.075H1.91666V14.4917L12.2167 4.19167ZM12.2167 2.42501L0.666664 13.975V17.325H4.01666L15.5667 5.77501L12.2167 2.42501Z"
          fill={color}
        />
        <path
          d="M13.8 5.77501L12.2167 4.19167L1.91666 14.4917V16.075H3.5L13.8 5.77501Z"
          fill={color}
        />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_2333_3624">
        <rect
          width="16.6667"
          height="16.6667"
          fill="white"
          transform="translate(0.666664 0.666687)"
        />
      </clipPath>
    </defs>
  </svg>;
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Live area" clipPath="url(#clip0_2333_3624)">
        <g id="Vector">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M17.3333 4.01669L16.4499 4.89169V4.90002L13.0999 1.55002V1.54169L13.9833 0.666687L17.3333 4.01669ZM12.2167 4.19167L13.8 5.77501L3.5 16.075H1.91666V14.4917L12.2167 4.19167ZM12.2167 2.42501L0.666664 13.975V17.325H4.01666L15.5667 5.77501L12.2167 2.42501Z"
            fill={color}
          />
          <path
            d="M13.8 5.77501L12.2167 4.19167L1.91666 14.4917V16.075H3.5L13.8 5.77501Z"
            fill={color}
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_2333_3624">
          <rect
            width="16.6667"
            height="16.6667"
            fill="white"
            transform="translate(0.666664 0.666687)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
